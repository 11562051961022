import React, { memo } from "react";
import { PricingTiers } from "helpers/constants";
import AppleLogin from "react-apple-login";
import LoginActions from "redux/actions/login";

const AppleSignIn = ({ buttonText, inviteKey, orgId, plan, frequency }) => {
  const getAppleResponse = (data) => {
    const details = {
      email: data?.user?.email,
      name: data?.user?.name ? `${data.user.name.firstName} ${data.user.name.lastName}` : null,
      idToken: data?.authorization?.id_token,
      code: data?.authorization?.code,
    };

    if (inviteKey) {
      details.key = inviteKey;
    }

    if (orgId) {
      details.orgId = orgId;
    }

    if (plan && frequency && plan !== PricingTiers.BASIC) {
      details.plan = plan;
      details.frequency = frequency;
    }

    LoginActions.loginWithApple(details);
  };

  return (
    <AppleLogin
      clientId="com.getzingdata.consolefrontend"
      redirectURI={process.env.REACT_APP_APPLE_REDIRECT_URL}
      usePopup={true}
      callback={getAppleResponse}
      scope="email name"
      responseMode="query"
      render={(renderProps) => {
        return (
          <button
            onClick={renderProps.onClick}
            className="btn btn-lg btn-block btn-white mb-2 d-flex align-items-center justify-content-center"
            type="button"
          >
            <img
              className="avatar avatar-xss mr-2"
              src="/assets/svg/brands/apple-logo.svg"
              alt={buttonText}
              style={{ height: "auto" }}
            />
            {buttonText}
          </button>
        );
      }}
    />
  );
};

export default memo(AppleSignIn);
