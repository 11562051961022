import React, { memo, useEffect, useState } from "react";
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from "reactflow";
import EdgeDropdown from "./edge-dropdown";

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) => {
  const [selectedJoin, setSelectedJoin] = useState(data.joinType || "left");
  const currentEdges = data.useEdges ? data.useEdges() : null;

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    currentEdges?.map((edge) => {
      if (edge.id === id) {
        setSelectedJoin(edge.data.joinType);
      }
    });
  }, [currentEdges]);

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 20,
  });

  const onSelectJoinType = (join, setShowDropdown) => {
    setSelectedJoin(join);
    setShowDropdown(false);

    const { setEdges } = data || {};

    if (setEdges) {
      setEdges((eds) => {
        return eds.map((edge) => {
          if (edge.data.nodeToEdgeArray?.length) {
            const edgeSourceTableName = edge.source.split("_node").shift();
            const edgeTargetTableName = edge.target.split("_node").shift();

            let mainSource;
            let mainTarget;
            edge.data.nodeToEdgeArray.map((o) => {
              if (o.edgeId === id) {
                mainSource = o.sourceTableName;
                mainTarget = o.targetTableName;
              }

              if (edgeSourceTableName === mainSource && edgeTargetTableName === mainTarget) {
                o.joinType = join;

                edge.data = {
                  ...edge.data,
                  joinType: join,
                };
              }
            });
          }

          return edge;
        });
      });
    }
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 10,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <EdgeDropdown
            id={id}
            onSelectJoinType={onSelectJoinType}
            deleteEdgeById={data?.deleteEdgeById}
            selectedJoin={selectedJoin}
            color={style?.stroke}
            nodeToEdgeArray={data?.nodeToEdgeArray}
            source={data?.source}
            target={data?.target}
          />
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default memo(CustomEdge);
