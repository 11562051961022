import { PricingTiers } from "helpers/constants";
import React, { memo } from "react";

const UpgradePro = ({ account }) => {
  const tier = account?.billingPlans?.tier;

  return (
    <div>
      <img
        className="img-fluid mb-3"
        src="/assets/svg/illustrations/collaboration.svg"
        alt="Upgrade to pro"
        style={{ maxWidth: "18rem" }}
      />
      <h4 className="h1 mt-4">Upgrade to Pro</h4>
      <p>
        Your organization is on the {tier || "basic"} plan. Embedded Dashboards are only available for organizations on
        the Pro Plan.
      </p>
      {tier === PricingTiers.STANDARD ? (
        <p>
          You can upgrade your organization by clicking on the &quot;Manage Billing&quot; button{" "}
          <a href="/organization">here</a>.
        </p>
      ) : (
        <p>
          You can upgrade your organization by clicking on the &quot;Upgrade&quot; button in front of your organization{" "}
          <a href="/organization">here</a>.
        </p>
      )}
    </div>
  );
};

export default memo(UpgradePro);
