import React, { memo } from "react";
import UserRow from "./userRow";

const Users = ({ data }) => {
  return (
    <div className="card mt-3">
      <div className="card-header">
        <h4 className="card-header-title">Users</h4>
      </div>

      <div className="table-responsive">
        <table className="table table-nowrap table-align-middle">
          <thead className="thead-light">
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Status</th>
              <th>Joined Date</th>
            </tr>
          </thead>

          <tbody>
            {data?.map((user) => (
              <UserRow key={user.email} user={user} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(Users);
