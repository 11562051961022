import React, { memo, useState, useEffect, useCallback, useMemo } from "react";
import classNames from "classnames";
import Icon from "components/icon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Column from "./column";
import CustomNodeStyles from "./custom-node.module.scss";

const rootNodeTooltip = (
  <Tooltip id="root-node-tooltip">
    Deleting this node will automatically update your root node for the join. If you have more nodes on the canvas, the
    second node that was dragged will be set as the root node. If not, the next node you drag will be set as the root
    node.
  </Tooltip>
);

const CustomNode = ({ id, table: initialTable, data = { isCollapsed: true }, isConnectable }) => {
  const [isCollapsed, setIsCollapsed] = useState(data.isCollapsed);
  const [table, setTable] = useState(initialTable);
  const handleColorsForNode = data.handleColors ? data.handleColors.get(id) : null;

  const currentEdges = data.useEdges ? data.useEdges() : null;
  const includedColumnsInTable = useMemo(() => data?.includedColumns?.get(table?.tableName), [data, table]);

  useEffect(() => {
    if (data.table) {
      setTable(data.table);
    }

    setIsCollapsed(data.isCollapsed);
  }, [data?.table]);

  const onDragStart = (e, nodeType) => {
    e.dataTransfer.setData("application/reactflow", JSON.stringify({ type: nodeType, table }));
    e.dataTransfer.effectAllowed = "move";
  };

  const onClickDelete = useCallback(() => {
    data.deleteNodeById(data.id, currentEdges, data.handleColors);
  }, [data, currentEdges]);

  if (isCollapsed) {
    return (
      <div className={CustomNodeStyles["custom-node"]} onDragStart={(e) => onDragStart(e, "tableNode")} draggable>
        <Icon iconName="tio-drag" />
        {table?.labelName}
      </div>
    );
  }

  return (
    <div className={CustomNodeStyles["custom-node-details"]}>
      <div className={classNames(CustomNodeStyles.header, { [CustomNodeStyles.root]: data.isRoot })}>
        <span>{table?.labelName}</span>
        <div>
          <Icon
            iconName={data?.isColumnsCollapsed ? "tio-chevron-down" : "tio-chevron-up"}
            className={CustomNodeStyles["header-icon"]}
            onClick={() => data?.onClickCollapse(data.id, data?.isColumnsCollapsed ? false : true)}
            isClickable
          />
          {data.isRoot ? (
            <OverlayTrigger placement="top" overlay={rootNodeTooltip}>
              <Icon
                iconName="tio-clear"
                className={classNames("ml-1", CustomNodeStyles["header-icon"])}
                onClick={onClickDelete}
                isClickable
              />
            </OverlayTrigger>
          ) : (
            <Icon
              iconName="tio-clear"
              className={classNames("ml-1", CustomNodeStyles["header-icon"])}
              onClick={onClickDelete}
              isClickable
            />
          )}
        </div>
      </div>
      {table?.columnSchema?.map((col, index) => (
        <Column
          key={index}
          column={col}
          isConnectable={isConnectable}
          includedColumns={includedColumnsInTable}
          tableName={table?.tableName}
          onClickToggleColumn={data.onClickToggleColumn}
          tableId={id}
          isColumnsCollapsed={data?.isColumnsCollapsed}
          handleColorsForCol={
            handleColorsForNode ? handleColorsForNode.filter((c) => c.columnId === col.columnName) : null
          }
        />
      ))}
    </div>
  );
};

export default memo(CustomNode);
