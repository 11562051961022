import React from "react";
import { Button, Dropdown, ButtonGroup } from "react-bootstrap";
import buttonDropdownStyles from "./buttonDropdown.module.scss";

const ButtonDropdown = ({
  primaryButtonTitle,
  onClickPrimaryButton,
  isPrimaryButtonDisabled,
  secondaryButtonTitle,
  onClickSecondaryButton,
  isSecondaryButtonDisabled,
}) => {
  return (
    <>
      <Dropdown as={ButtonGroup}>
        <Button variant="primary" onClick={onClickPrimaryButton} disabled={isPrimaryButtonDisabled}>
          {primaryButtonTitle}
        </Button>
        <Dropdown.Toggle
          split
          variant="primary"
          id="dropdown-split-basic"
          className={buttonDropdownStyles.zDropdownSplit}
        />
        <Dropdown.Menu>
          <Dropdown.Item
            as={Button}
            onClick={onClickSecondaryButton}
            disabled={isSecondaryButtonDisabled}
            className={buttonDropdownStyles.zDropdownButtonSecondary}
          >
            {secondaryButtonTitle}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ButtonDropdown;
