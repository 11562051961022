import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  hydrated: false,
  loading: false,
  show: false,
  showSaveSuccess: false,
  showSaveTablesSuccess: false,
  error: undefined,
  hasError: {},
  help: {},
  datasource: null,
  data: [],
  pages: {},
  items: {},
  showDeleteSuccess: false,
  tokensRefreshed: false,
  adminOptionsUpdated: false,
  fewshots: null,
  fewshotCategories: null,
  loadingCategories: false,
};
const reducer = function (state = initialState, action) {
  if (action.type === Constants.DATASOURCE_CREATE) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.DATASOURCE_CREATE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      datasource: action.response,
    };
  }

  if (action.type === Constants.DATASOURCE_CREATE_HIDE) {
    return {
      ...state,
      showSaveSuccess: false,
      showSaveTablesSuccess: false,
    };
  }

  if (action.type === Constants.DATASOURCE_GET) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.DATASOURCE_GET_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      data: action.response.data,
      pages: action.response.pages,
      items: action.response.items,
    };
  }

  if (action.type === Constants.DATASOURCE_GET_BY_ID) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.DATASOURCE_GET_BY_ID_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      datasource: action.response,
    };
  }

  if (action.type === Constants.DATASOURCE_SAVE) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.DATASOURCE_SAVE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      datasource: action.response,
    };
  }

  if (action.type === Constants.DATASOURCE_DELETE) {
    return {
      ...state,
      loading: true,
      showDeleteSuccess: false,
      hydrated: false,
    };
  }

  if (action.type === Constants.DATASOURCE_DELETE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      showDeleteSuccess: !action.err,
    };
  }

  if (action.type === Constants.DATASOURCE_TABLES_SAVE || action.type === Constants.DATASOURCE_CSV_TABLES_DELETE) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.DATASOURCE_TABLES_SAVE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showSaveTablesSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.DATASOURCE_GET_FIRST) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.DATASOURCE_GET_FIRST_RESPONSE) {
    return {
      ...state,
      loading: false,
      hydrated: true,
      first: action.response,
    };
  }

  if (action.type === Constants.DATASOURCE_UPDATE_LIVE_SHEET_TOKENS) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.DATASOURCE_UPDATE_LIVE_SHEET_TOKENS_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      tokensRefreshed: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.DATASOURCE_CLEAR_TOKEN_REFRESH_STATUS) {
    return {
      ...state,
      tokensRefreshed: false,
    };
  }

  if (action.type === Constants.DATASOURCE_UPDATE_RESTRICT_SELECT) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.DATASOURCE_UPDATE_RESTRICT_SELECT_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      adminOptionsUpdated: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.DATASOURCE_CLEAR_UPDATE_RESTRICT_SELECT_STATUS) {
    return {
      ...state,
      adminOptionsUpdated: false,
    };
  }

  if (action.type === Constants.DATASOURCE_CSV_TABLES_DELETE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      tableDeleteSuccess: !validation.error,
    };
  }

  if (action.type === Constants.DATASOURCE_CLEAR_CSV_TABLES_DELETE_STATUS) {
    return {
      ...state,
      tableDeleteSuccess: false,
    };
  }

  if (action.type === Constants.DATASOURCE_GET_FEWSHOTS) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.DATASOURCE_GET_FEWSHOTS_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      fewshots: action.response,
    };
  }

  if (action.type === Constants.DATASOURCE_GET_HELPER_TEXT) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.DATASOURCE_GET_HELPER_TEXT_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      helperTexts: action.response,
    };
  }

  if (action.type === Constants.DATASOURCE_GET_FEWSHOT_CATEGORIES) {
    return {
      ...state,
      loadingCategories: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.DATASOURCE_GET_FEWSHOT_CATEGORIES_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingCategories: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      fewshotCategories: action.response,
    };
  }

  return state;
};

export default reducer;
