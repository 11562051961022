import React, { memo, useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import "./color-picker.scss";

const ColorPicker = ({
  color,
  isDisabled,
  index,
  customColors,
  setCustomColors,
  settingsFormData,
  setSelectedColorsOption,
  setSettingsFormData,
  setActiveColorPickerIndex,
  activeColorPickerIndex,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const colorPickerWrapper = useRef();
  useOnClickOutside(colorPickerWrapper, () => setShowPicker(false));

  useEffect(() => {
    if (index !== activeColorPickerIndex) {
      setShowPicker(false);
    }
  }, [activeColorPickerIndex, index]);

  const onClickColorContainer = () => {
    setShowPicker(true);
    colorPickerWrapper.current.focus();
    setActiveColorPickerIndex(index);

    if (settingsFormData && setSelectedColorsOption && setSettingsFormData) {
      const formData = settingsFormData;
      formData.set("useCustomColors", "true");
      setSelectedColorsOption("custom");
      setSettingsFormData(formData);
    }
  };

  const onChangeComplete = (color) => {
    const updatedColors = [...customColors];
    updatedColors[index] = color.hex;
    setCustomColors(updatedColors);
  };

  return (
    <div className="z-color-picker" id="picker" ref={colorPickerWrapper}>
      <div
        className="d-flex color-value"
        onClick={isDisabled ? undefined : onClickColorContainer}
        style={!isDisabled ? { cursor: "pointer" } : { cursor: "default" }}
      >
        <div style={{ backgroundColor: color }} className="color-display"></div>
        <div>{color}</div>
      </div>
      {showPicker && (
        <div className="picker-container">
          <ChromePicker color={color} onChangeComplete={onChangeComplete} className="picker" disableAlpha={true} />
        </div>
      )}
    </div>
  );
};

export default memo(ColorPicker);
