import React, { memo, useCallback, useEffect, useState } from "react";
import FilesTableRow from "./files-table-row";
import "./csv.scss";

const FilesTable = ({
  step,
  filesToImport,
  setFilesToImport,
  setTableNames,
  tableNames,
  filesToSave,
  setFilesToSave,
  uploadCorrectedCsvFile,
  isUpdate = false,
  existingTables,
  setHasNameDuplicates,
  tables,
  setTables,
}) => {
  const [files, setFiles] = useState(filesToImport);

  const onClickSkipImport = (e, fileId) => {
    let tableToSkip;

    let includedFiles = [...filesToSave];
    includedFiles = includedFiles.map((file) => {
      if (file.fileId === fileId) {
        if (file.mode === "create") {
          tableToSkip = file.tableName;
        }
        return { ...file, included: !e.target.checked };
      } else {
        return { ...file };
      }
    });

    setFilesToSave([...includedFiles]);

    if (tableToSkip) {
      let includedTables = [...tables];
      includedTables = includedTables.filter((t) => t.tableName !== tableToSkip);
      setTables([...includedTables]);
    }
  };

  const onClickDelete = useCallback(
    (fileId) => {
      const includedFiles = [...files];
      const toImport = includedFiles.filter((file) => file.fileId !== fileId);
      setFiles(toImport);
      setFilesToImport(toImport);

      const newTableNames = { ...tableNames };
      if (newTableNames[fileId]) {
        delete newTableNames[fileId];
        setTableNames({ ...newTableNames });
      }
    },
    [tableNames, files]
  );

  const onChangeFileName = useCallback(
    ({ fileId, value }) => {
      const newTableNames = { ...tableNames };

      if (isUpdate) {
        if (newTableNames[fileId]) {
          newTableNames[fileId].name = value;
        } else {
          newTableNames[fileId] = {
            name: value,
          };
        }
      } else {
        newTableNames[fileId] = value;
      }
      setTableNames({ ...newTableNames });
    },
    [tableNames]
  );

  const onChangeAction = useCallback(
    ({ fileId, value }) => {
      const newTableNames = { ...tableNames };

      if (newTableNames[fileId]) {
        newTableNames[fileId].mode = value;
      } else {
        newTableNames[fileId] = {
          mode: value,
        };
      }
      setTableNames({ ...newTableNames });
    },
    [tableNames]
  );

  useEffect(() => {
    if (step === 1) {
      const newTableNames = { ...tableNames };

      files.forEach((f) => {
        if (f.included) {
          if (isUpdate) {
            if (newTableNames[f.fileId]) {
              newTableNames[f.fileId].name = f.parentSheet || f.origFilename;
            } else {
              newTableNames[f.fileId] = {
                name: f.parentSheet || f.origFilename,
              };
            }
          } else {
            newTableNames[f.fileId] = f.parentSheet || f.origFilename;
          }
        }
      });

      setTableNames({ ...newTableNames });
    }
  }, [files, step, isUpdate]);

  if (step === 1) {
    return (
      <div className="csv-row-container my-3">
        <div className="d-flex mb-2">
          <div className="csv-table-header">Import</div>
          {isUpdate && <div className="csv-table-header">Action</div>}
          <div className="csv-table-header">Table Name</div>
          <div className="csv-table-header action"></div>
        </div>
        <div>
          {files?.map((file, index) => {
            return (
              <FilesTableRow
                key={index}
                file={file}
                onClickDelete={onClickDelete}
                onChangeFileName={onChangeFileName}
                tableNames={tableNames}
                step={step}
                isUpdate={isUpdate}
                existingTables={existingTables}
                onChangeAction={onChangeAction}
                setHasNameDuplicates={setHasNameDuplicates}
                autoPopulateName={files?.length === 1}
              />
            );
          })}
        </div>
      </div>
    );
  }

  if (step === 2) {
    return (
      <div className="csv-row-container my-3">
        <div className="d-flex mb-2">
          <div className="csv-table-header status">Status</div>
          <div className="csv-table-header">Import</div>
          <div className="csv-table-header mode">Action</div>
          <div className="csv-table-header">Table Name</div>
        </div>
        <div>
          {filesToSave?.map((file, index) => {
            return (
              <FilesTableRow
                key={index}
                file={file}
                step={step}
                uploadCorrectedCsvFile={uploadCorrectedCsvFile}
                onClickSkipImport={onClickSkipImport}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};

export default memo(FilesTable);
