import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const capitalize = (string) => {
  if (!string) return null;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const ErrorDiv = styled.div`
  padding: 0.5rem;
  border: thin solid #f1f1f1ba;
  margin-top: 0.5rem;
  width: fit-content;
  border-radius: 5px;
`;

const CloseButton = styled.button`
  margin-left: 15px;
  margin-top: 1px;
  color: white;
  font-weight: 300;
`;

const Alert = ({ onClose, type, message, className, style, details }) => {
  let close;
  if (onClose) {
    close = (
      <CloseButton type="button" className="close" onClick={onClose}>
        &times;
      </CloseButton>
    );
  }

  return (
    <div className={`alert alert-${type} ${className || ""}`} style={style}>
      {close}
      {message}
      {details && <ErrorDiv>{capitalize(details)}</ErrorDiv>}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(["success", "info", "warning", "danger", "soft-primary"]),
  onClose: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
};

export default Alert;
