import React, { memo, useState } from "react";
import Search from "components/search";
import CustomNode from "./custom-node";

const TablesSidebar = ({ tables }) => {
  const [filteredTables, setFilteredTables] = useState([...tables]);

  const onSearchChange = (searchTerm) => {
    const filtered = tables?.filter((data) => {
      return data["labelName"].toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredTables([...filtered]);
  };

  return (
    <div className="d-flex flex-column">
      <Search placeholder="Search tables and views" onChange={onSearchChange} />
      {filteredTables?.map((t) => (
        <CustomNode key={t.tableName} table={t} />
      ))}
    </div>
  );
};

export default memo(TablesSidebar);
