import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  hydrated: false,
  loading: false,
  loadingTransfer: false,
  showSaveSuccess: false,
  transferSuccess: false,
  error: undefined,
  hasError: {},
  help: {},
  organization: {},
  organizations: [],
  data: [],
  pages: {},
  items: {},
  showDeleteSuccess: false,
  organizationsByRole: null,
  loadingOrgsByRole: false,
  showPersonalizationSaveSuccess: false,
  personalizationSaveError: undefined,
  customLogo: null,
  loadingCustomLogo: false,
};
const reducer = function (state = initialState, action) {
  if (action.type === Constants.ORGANIZATION_CREATE_HIDE) {
    return {
      ...state,
      showSaveSuccess: false,
    };
  }

  if (action.type === Constants.ORGANIZATION_CREATE) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.ORGANIZATION_CREATE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      organization: action.response,
    };
  }

  if (action.type === Constants.ORGANIZATION_MY_GET) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.ORGANIZATION_MY_GET_RESPONSE) {
    const validation = ParseValidation(action.response);
    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      organizations: action.response,
      data: action.response.data,
      pages: action.response.pages,
      items: action.response.items,
    };
  }

  if (action.type === Constants.ORGANIZATION_OWNER_GET) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.ORGANIZATION_OWNER_GET_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      organizations: action.response,
    };
  }

  if (action.type === Constants.ORGANIZATION_GET_BY_ID) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.ORGANIZATION_GET_BY_ID_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      organization: action.response,
    };
  }

  if (action.type === Constants.ORGANIZATION_TRANSFER_OWNERSHIP) {
    return {
      ...state,
      loadingTransfer: true,
    };
  }

  if (action.type === Constants.ORGANIZATION_TRANSFER_OWNERSHIP_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingTransfer: false,
      transferSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.ORGANIZATION_SAVE) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.ORGANIZATION_SAVE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      organization: action.response,
    };
  }

  if (action.type === Constants.ORGANIZATION_DELETE) {
    return {
      ...state,
      loading: true,
      showDeleteSuccess: false,
      hydrated: false,
    };
  }

  if (action.type === Constants.ORGANIZATION_DELETE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      showDeleteSuccess: !action.err,
    };
  }

  if (action.type === Constants.ORGANIZATION_GET_BY_ROLE) {
    return {
      ...state,
      loading: true,
      loadingOrgsByRole: true,
    };
  }

  if (action.type === Constants.ORGANIZATION_GET_BY_ROLE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      loadingOrgsByRole: false,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      organizationsByRole: action.response,
    };
  }

  if (action.type === Constants.ORGANIZATION_UPDATE_SAVE_SUCCESS) {
    return {
      ...state,
      showSaveSuccess: false,
    };
  }

  if (action.type === Constants.ORGANIZATION_CLEAR_TRANSFER_STATE) {
    return {
      ...state,
      transferSuccess: false,
    };
  }

  if (action.type === Constants.ORGANIZATION_SAVE_PERSONALIZATION) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.ORGANIZATION_SAVE_PERSONALIZATION_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showPersonalizationSaveSuccess: !action.err,
      personalizationSaveError: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.ORGANIZATION_GET_LOGO) {
    return {
      ...state,
      loadingCustomLogo: true,
    };
  }

  if (action.type === Constants.ORGANIZATION_GET_LOGO_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingCustomLogo: false,
      customLogo: action.response.logoUrl,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.ORGANIZATION_CLEAR_SAVE_STATE) {
    return {
      ...state,
      showPersonalizationSaveSuccess: false,
    };
  }

  return state;
};

export default reducer;
