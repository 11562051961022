import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class FieldAliasActions {
  static async createOrUpdate(data) {
    ApiActions.post(
      "/api/v1/field-alias",
      data,
      Store,
      Constants.ALIAS_CREATE_OR_UPDATE,
      Constants.ALIAS_CREATE_OR_UPDATE_RESPONSE
    );
  }

  static hideAliasCreate() {
    Store.dispatch({
      type: Constants.ALIAS_HIDE_SUCCESS_STATE,
    });
  }

  static async getAliasesByDatasource(datasourceId, query) {
    ApiActions.get(
      `/api/v1/field-alias/datasource/${datasourceId}`,
      query,
      Store,
      Constants.ALIAS_GET_BY_DATASOURCE,
      Constants.ALIAS_GET_BY_DATASOURCE_RESPONSE
    );
  }

  static async delete(id) {
    ApiActions.delete(
      `/api/v1/field-alias/${id}`,
      undefined,
      Store,
      Constants.ALIAS_DELETE,
      Constants.ALIAS_DELETE_RESPONSE
    );
  }

  static hideAliasDelete() {
    Store.dispatch({
      type: Constants.ALIAS_HIDE_DELETE_STATE,
    });
  }
}

export default FieldAliasActions;
