import { combineReducers } from "redux";
import Login from "./login";
import Signup from "./signup";
import Logout from "./logout";
import Profile from "./profile";
import DataSource from "./datasource";
import Invitation from "./invitation";
import ForgotPassword from "./forgot-password";
import ResetPassword from "./reset-password";
import Organization from "./organization";
import QueryManager from "./query-manager";
import PublicLink from "./public-link";
import Embed from "./embed";
import Join from "./join";
import User from "./user";
import Account from "./account";
import Fewshot from "./fewshot";
import Alias from "./alias";
import HelperText from "./helper-text";
import Category from "./fewshot-category";

export default combineReducers({
  login: Login,
  signup: Signup,
  logout: Logout,
  settings: Profile,
  datasource: DataSource,
  invitation: Invitation,
  forgotPassword: ForgotPassword,
  resetPassword: ResetPassword,
  organization: Organization,
  queryManager: QueryManager,
  publicLink: PublicLink,
  embed: Embed,
  join: Join,
  user: User,
  account: Account,
  fewshot: Fewshot,
  aliases: Alias,
  helperText: HelperText,
  fewshotCategories: Category,
});
