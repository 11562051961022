import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoginActions from "redux/actions/login";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import amplitude from "amplitude-js";
import { useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Buffer } from "buffer";
import { msalConfig } from "helpers/azure-ad-config";
import Alert from "components/alert";
import Footer from "components/footer/footerlogin";
import Loading from "components/loading";
import Logo from "components/logo/logo";
import { ssoProviders } from "helpers/constants";

const schema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
});

const LoginSsoPage = () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const { instance: azureAdInstance, accounts } = useMsal();

  const { token } = useParams();
  let emailForInvite, key, orgId;

  if (token) {
    const _token = Buffer.from(token, "base64").toString("binary");
    const [_ordId, _email, _key] = _token.split(":");
    emailForInvite = _email;
    key = _key;
    orgId = _ordId;
  }

  const [msalInstance, setMsalInstance] = useState(azureAdInstance);
  const { error, loading, ssoResponse } = useSelector((s) => s.login);
  const [alerts, setAlerts] = useState([]);
  const [email, setEmail] = useState(emailForInvite);
  const [azureAdInteractionOngoing, setIsAzureAdInteractionOngoing] = useState(false);

  // initialize amplitude instance
  const amplitudeInstance = amplitude.getInstance();

  const onError = () => {
    const newAlerts = [
      <Alert
        key="danger"
        type="danger"
        message="If Google sign in is not working, please restart your browser and make sure you're not in incognito mode. If this issue persists please contact Zing Support."
      />,
    ];

    setAlerts(newAlerts);
    amplitudeInstance.logEvent("SSO_LOGIN_ERROR");
  };

  useEffect(() => {
    const origBodyClass = document.body.className;
    document.body.className = "main";
    return () => {
      document.body.className = origBodyClass;
    };
  });

  const checkEmailForLogin = (data, e) => {
    e.preventDefault();
    setAlerts([]);

    const { email } = data;
    setEmail(email);
    localStorage.setItem("sso-email", email);

    LoginActions.verifySsoLogin({ email });
  };

  useEffect(() => {
    if (error) {
      alerts.push(<Alert key="danger" type="danger" message={error} />);
      setAlerts([...alerts]);
    }
  }, [error]);

  useEffect(() => {
    if (accounts?.length) {
      azureAdInstance.setActiveAccount(accounts[0]);
    }
  }, [accounts, msalInstance]);

  const initiateAzureLogin = async (newAzureAdInstance) => {
    setIsAzureAdInteractionOngoing(true);
    const zingAccessScope = `${ssoResponse.clientId}/zing-access`;
    const scopes = {
      loginHint: email,
      scopes: [zingAccessScope],
      prompt: "select_account",
    };

    if (ssoResponse?.user) {
      delete scopes.prompt;
    }

    try {
      let loginResponse;
      if (ssoResponse?.user && accounts?.length) {
        try {
          loginResponse = await newAzureAdInstance.acquireTokenSilent({
            scopes: [zingAccessScope],
            account: accounts[0],
            loginHint: email,
          });
        } catch (e) {
          loginResponse = await newAzureAdInstance.loginPopup(scopes);
        }
      } else {
        loginResponse = await newAzureAdInstance.loginPopup(scopes);
      }

      if (loginResponse?.accessToken && loginResponse?.account?.homeAccountId) {
        LoginActions.loginWithSso({
          email,
          accessToken: loginResponse.accessToken,
          idToken: loginResponse.idToken,
          homeAccountId: loginResponse.account.homeAccountId,
          localAccountId: loginResponse.account.localAccountId,
          key,
          orgId,
        });

        amplitudeInstance.logEvent("LOGIN_SSO_AZUREAD", {
          email: email,
        });
        setIsAzureAdInteractionOngoing(false);
      } else {
        setIsAzureAdInteractionOngoing(false);
        amplitudeInstance.logEvent("LOGIN_SSO_AZUREAD_ERROR", {
          email: email,
          message: "Could not obtain response from SSO Provider.",
        });

        setAlerts([<Alert key="danger" type="danger" message="Could not obtain response from SSO Provider." />]);
      }
    } catch (err) {
      setIsAzureAdInteractionOngoing(false);
      amplitudeInstance.logEvent("LOGIN_SSO_AZUREAD_ERROR", {
        email: email,
        message: err?.message,
      });

      setAlerts([
        <Alert key="danger" type="danger" message={err.message || "An error occurred while trying to sign you in."} />,
      ]);
    }
  };

  const initializeNewInstance = async () => {
    let newAzureAdInstance = new PublicClientApplication(msalConfig);
    await newAzureAdInstance.initialize();
    return newAzureAdInstance;
  };

  useEffect(() => {
    if (ssoResponse?.ssoProvider === ssoProviders.AZURE_AD) {
      const newConfig = { ...msalConfig };
      newConfig.auth.clientId = ssoResponse.clientId;
      newConfig.auth.authority = `https://login.microsoftonline.com/${ssoResponse.tenantId}`;

      initializeNewInstance().then((newAzureAdInstance) => {
        setMsalInstance(newAzureAdInstance);
        initiateAzureLogin(newAzureAdInstance);
      });
    }
  }, [ssoResponse]);

  return (
    <main id="content" role="main" className="main">
      <div
        className="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{ height: "32rem", backgroundImage: "url(./assets/svg/components/abstract-bg-4.svg)" }}
      >
        <figure className="position-absolute right-0 bottom-0 left-0">
          <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>
      <div className="container py-5 py-sm-7">
        <Logo
          outerClassName="d-flex justify-content-center mb-5"
          innerClassName="z-index-2"
          style={{ width: "15rem" }}
        />
        <div className="row justify-content-center">
          <div className="col-md-7 col-lg-5">
            <div className="card card-lg mb-5">
              <Loading loading={loading || azureAdInteractionOngoing}></Loading>

              <div className="card-body">
                {alerts}
                <form onSubmit={handleSubmit(checkEmailForLogin, onError)}>
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-4">Sign In</h1>
                      <p>Log in with your single sign-on credentials</p>
                    </div>
                  </div>

                  <div className="js-form-message form-group">
                    <label className="input-label" htmlFor="signinSrEmail">
                      Your email
                    </label>
                    <input
                      type="email"
                      className={"form-control form-control-lg" + (errors.email ? " is-invalid" : "")}
                      name="email"
                      tabIndex={1}
                      ref={register}
                      placeholder="work-email@work-domain.com"
                      aria-label="work-email@work-domain.com"
                      defaultValue={email}
                    />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                  </div>
                  <button type="submit" className="btn btn-lg btn-block btn-primary">
                    Sign in
                  </button>
                  <span className="divider text-muted mb-4 my-5">OR</span>
                  <Link className="w-100 btn btn-link" to="/login">
                    Log in using a different method
                  </Link>
                </form>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginSsoPage;
