import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, ListGroup } from "react-bootstrap";
import OrganizationActions from "redux/actions/organization";
import ProfileActions from "redux/actions/profile";
import Alert from "components/alert";
import Loading from "components/loading";

const ModalDeleteAccount = ({ show, handleClose }) => {
  const {
    loading: loadingOrganization,
    loadingTransfer,
    error: errorOrganization,
    organizations,
    transferSuccess,
  } = useSelector((s) => s.organization);

  const { error: errorSettings, loadingDeleteAccount, showDeleteSuccess } = useSelector((s) => s.settings);

  const [step, setStep] = useState(0);
  const [orgs, setOrgs] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [selectUsers, setSelectUsers] = useState(false);
  const [loadingText, setLoadingText] = useState(null);

  useEffect(() => {
    let noUsers = true;

    if (organizations && organizations.length > 0) {
      for (const organization of organizations) {
        if (organization.users && organization.users.length > 0) {
          setSelectUsers(true);
          noUsers = false;
          break;
        }
      }

      setOrgs(organizations.filter((c) => c.users.length > 0));
    }

    if (noUsers) {
      setStep(1);
    } else {
      setStep(0);
    }
  }, [organizations]);

  useEffect(() => {
    if (show) {
      OrganizationActions.getOwnerOrganization();
    }
  }, [show]);

  const handleSelectUserTransfer =
    (...params) =>
    (event) => {
      event.preventDefault();

      const [id, index] = params;

      const org = { ...orgs[index] };
      const orgUsers = [...org.users];

      org.users = orgUsers.map((c) => {
        let selected = false;
        if (c._id === id) {
          selected = true;
        }

        return {
          ...c,
          selected,
        };
      });

      const _orgs = [...orgs];
      _orgs[index] = org;

      setOrgs(_orgs);
    };

  const selectedUsersEmails = () => {
    if (orgs) {
      return orgs.map((org) => org.users.find((u) => u.selected)?.email).join(", ");
    }

    return "";
  };

  const deleteAccount = (event) => {
    event.preventDefault();

    if (selectUsers) {
      const data = orgs.map((org) => {
        return {
          organizationId: org.organization._id,
          userId: org.users.find((u) => u.selected)._id,
          removeOwner: true,
        };
      });

      setLoadingText("Transferring ownership...");
      OrganizationActions.transferOwnership(data);
    } else {
      _deleteAccount();
    }
  };

  const _deleteAccount = () => {
    setLoadingText("Deleting your account...");
    ProfileActions.deleteAccount();
  };

  useEffect(() => {
    if (transferSuccess) {
      _deleteAccount();
    }
  }, [transferSuccess]);

  useEffect(() => {
    if (showDeleteSuccess) {
      setTimeout(() => {
        window.location.href = "/logout";
      }, 3000);
    }
  }, [showDeleteSuccess]);

  const next = () => {
    setStep(1);
  };

  const close = () => {
    setStep(0);
    setSelectUsers(false);
    setOrgs([]);
    setAlerts([]);
    setLoadingText(null);
    handleClose();
  };

  useEffect(() => {
    if (errorOrganization || errorSettings) {
      const newAlerts = alerts.concat(
        <Alert key="danger" type="danger" message={errorOrganization || errorSettings} />
      );

      setAlerts(newAlerts);
    }
  }, [errorOrganization, errorSettings]);

  return (
    <Modal backdrop="static" keyboard={false} show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading text={loadingText} loading={loadingTransfer || loadingOrganization || loadingDeleteAccount}></Loading>

        <div style={step === 1 && !showDeleteSuccess ? {} : { display: "none" }}>
          {alerts}

          <h4 className="mb-5">Are you really sure you want to delete your Zing Data account?</h4>
          <h5 className="text-danger mb-5">You will not be able to undo this action.</h5>

          {!selectUsers ? (
            <p>All data sources, saved questions, and access to shared questions will be lost.</p>
          ) : (
            <p>You will transfer ownership of questions and data sources to {selectedUsersEmails()}</p>
          )}
        </div>

        <div style={step === 0 ? {} : { display: "none" }}>
          <p>
            Because you have team mates who are part of your organization(s), you must transfer ownership of your
            organization, datasources and questions to a teammate before you can delete your account.
            <br></br>
            <br></br>
            If an organization has a billing plan, this will also be transferred to the new owner. The new owner will be
            requested to add payment details to continue the billing plan at the end of the current cycle.
          </p>
          <h5 className="mb-5">Please select who you want to transfer ownership for each organization.</h5>

          {alerts}

          <div className="accordion" id="accordionExample">
            {orgs.map((organization, index) => (
              <div className="card" id={`heading${index}`} key={index}>
                <a
                  className={`card-header card-btn btn-block ${index === 0 ? "" : "collapsed"}`}
                  data-toggle="collapse"
                  data-target={`#collapse${index}`}
                  aria-expanded={index === 0}
                  aria-controls={`collapse${index}`}
                >
                  Organization #{index + 1}
                  <span className="card-btn-toggle">
                    <span className="card-btn-toggle-default">
                      <i className="tio-add"></i>
                    </span>
                    <span className="card-btn-toggle-active">
                      <i className="tio-remove"></i>
                    </span>
                  </span>
                </a>

                <div
                  id={`collapse${index}`}
                  className={`collapse ${index === 0 ? "show" : ""}`}
                  aria-labelledby={`heading${index}`}
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <ListGroup className="mt-3" variant="flush">
                      {organization.users.length === 0
                        ? ""
                        : organization.users.map((user) => (
                            <ListGroup.Item key={user._id} value={user._id}>
                              {user.email}

                              {user.selected ? (
                                <button disabled="disabled" className="btn btn-sm btn-secondary float-right">
                                  <i className="tio-done mr-2"></i>
                                  Selected
                                </button>
                              ) : (
                                <a
                                  className="btn btn-sm btn-primary float-right"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleSelectUserTransfer(user._id, index)}
                                >
                                  Transfer Ownership
                                </a>
                              )}
                            </ListGroup.Item>
                          ))}
                    </ListGroup>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div style={showDeleteSuccess ? {} : { display: "none" }}>
          <div className="text-center">
            <img
              className="img-fluid mb-3"
              src="/assets/svg/illustrations/create.svg"
              alt="Image Description"
              style={{ maxWidth: "15rem" }}
            />
            <div className="mb-4">
              <h2>Success!</h2>
              <p>Your account was deleted, you will be signed out.</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={close}
          disabled={showDeleteSuccess || loadingOrganization || loadingTransfer || loadingDeleteAccount}
        >
          Close
        </Button>

        {step === 0 ? (
          <Button
            disabled={
              orgs.filter((c) => c.users.some((u) => u.selected)).length !== orgs.length ||
              loadingOrganization ||
              loadingTransfer ||
              loadingDeleteAccount
            }
            variant="primary"
            onClick={next}
          >
            Next
          </Button>
        ) : (
          ""
        )}

        {step === 1 ? (
          <Button
            disabled={loadingOrganization || loadingTransfer || loadingDeleteAccount}
            style={showDeleteSuccess ? { display: "none" } : {}}
            variant="danger"
            onClick={deleteAccount}
          >
            Delete
          </Button>
        ) : (
          ""
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteAccount;
