import { getConnectedEdges } from "reactflow";

export const getColumnType = (tables, tableName, column) => {
  let typeObj;
  tables.forEach((t) => {
    if (t.tableName === tableName) {
      const col = t.columnSchema.filter((c) => c.columnName === column).shift();
      typeObj = {
        type: col.dataType,
        isNumeric: col.isNumeric,
        isDate: col.isDate,
        isTime: col.isTime,
        isLatitude: col.isLatitude,
        isLongitude: col.isLongitude,
      };
    }
  });

  return typeObj;
};

export const updateHandleColorsOnNode = (updatedHandleColors, setHandleColors, setNodes, setEdges) => {
  if (setHandleColors) {
    setHandleColors(updatedHandleColors);
  }

  setNodes((nds) => {
    return nds.map((node) => {
      node.data = {
        ...node.data,
        handleColors: updatedHandleColors,
      };

      return node;
    });
  });

  setEdges((eds) => {
    return eds.map((edge) => {
      edge.data = {
        ...edge.data,
        handleColors: updatedHandleColors,
      };

      return edge;
    });
  });
};

export const deleteHandleColors = (edgesToDelete, colors, setHandleColors, setNodes, setEdges) => {
  if (edgesToDelete?.length) {
    let handleColorsAfterDeletion = new Map(colors);

    edgesToDelete.map((edge) => {
      const { source, sourceHandle, target, targetHandle } = edge;
      const sourceHandleColors = handleColorsAfterDeletion.get(source);
      const updatedSourceColors = sourceHandleColors?.filter(
        (c) => !(c.columnId === sourceHandle && c.type === "right")
      );

      if (updatedSourceColors?.length) {
        handleColorsAfterDeletion.set(source, updatedSourceColors);
      } else {
        handleColorsAfterDeletion.delete(source);
      }

      const targetHandleColors = handleColorsAfterDeletion.get(target);
      const updatedTargetColors = targetHandleColors?.filter(
        (c) => !(c.columnId === targetHandle && c.type === "left")
      );

      if (updatedTargetColors?.length) {
        handleColorsAfterDeletion.set(target, updatedTargetColors);
      } else {
        handleColorsAfterDeletion.delete(target);
      }
    });

    updateHandleColorsOnNode(handleColorsAfterDeletion, setHandleColors, setNodes, setEdges);
  }
};

export const updateNodeToEdgeArray = ({ array, source, target, joinType, setNodeToEdgeArray, setEdges, edgeId }) => {
  const sourceTableName = source.split("_node").shift();
  const targetTableName = target.split("_node").shift();

  let joinTypeForEdge;

  const data = {
    source,
    target,
    joinType,
    sourceTableName,
    targetTableName,
    edgeId,
  };

  if (!array.length) {
    data.order = 1;
  } else {
    const existingObj = array
      .filter((o) => o.sourceTableName === sourceTableName && o.targetTableName === targetTableName)
      .pop();

    if (existingObj) {
      data.order = existingObj.order + 1;
      data.joinType = existingObj.joinType;
      joinTypeForEdge = data.joinType;
    } else {
      data.order = 1;
    }
  }

  array.push(data);
  setNodeToEdgeArray([...array]);

  setEdges((eds) => {
    return eds.map((edge) => {
      edge.data = {
        ...edge.data,
        nodeToEdgeArray: array,
      };

      return edge;
    });
  });
  return joinTypeForEdge;
};

export const updateRootNode = (nodeToDelete, setNodes) => {
  if (!nodeToDelete.data.isRoot) {
    return null;
  }

  const nodeIndexToDelete = nodeToDelete.data.index;

  let updatedNodeId;
  setNodes((nds) => {
    if (nds?.length <= 1) {
      updatedNodeId = 0;
      return [];
    }

    const sortedNodes = nds.sort((a, b) => a.data.index - b.data.index);
    const positionOfNodeToDelete = sortedNodes.findIndex((n) => n.data.index === nodeIndexToDelete);
    const positionOfNextRootNode = positionOfNodeToDelete + 1;
    const nextRootNode = sortedNodes[positionOfNextRootNode];

    return nds.map((node) => {
      if (node?.id === nextRootNode.id) {
        node.data = {
          ...node.data,
          isRoot: true,
        };
      }

      return node;
    });
  });

  return updatedNodeId;
};

export const deleteEdgeFromNodeToEdgeArray = ({ edgesToDelete, setNodeToEdgeArray, setEdges }) => {
  let updatedArray;
  // eslint-disable-next-line no-unused-expressions
  edgesToDelete?.map((edge) => {
    const source = edge.source;
    const target = edge.target;
    const { nodeToEdgeArray } = edge.data;

    if (!updatedArray) {
      updatedArray = nodeToEdgeArray;
    }

    let edgeOrder;
    updatedArray = updatedArray.filter((a) => {
      if (a.edgeId === edge.id) {
        edgeOrder = a.order;
      }
      return a.edgeId !== edge.id;
    });

    if (edgeOrder === 1) {
      updatedArray.map((e) => {
        if (e.source === source && e.target === target) {
          e.order = e.order - 1;
        }
      });
    }
  });

  setEdges((eds) => {
    return eds.map((edge) => {
      edge.data = {
        ...edge.data,
        nodeToEdgeArray: updatedArray,
      };

      return edge;
    });
  });

  setNodeToEdgeArray(updatedArray);
};

export const checkOrphanTables = (nodes, edges) => {
  let hasOrhpanTables = false;
  // eslint-disable-next-line no-unused-expressions
  nodes?.map((n) => {
    const connectedEdges = getConnectedEdges([n], edges);

    if (!connectedEdges?.length) {
      hasOrhpanTables = true;
    }
  });

  return hasOrhpanTables;
};
