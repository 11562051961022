import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { IntercomProvider } from "react-use-intercom";

export const PublicRoute = ({ component: Component, googleauth, intercom, ...rest }) => {
  const intercomAppId = process.env.REACT_APP_INTERCOM_ID;

  if (googleauth && intercom) {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <IntercomProvider appId={intercomAppId}>
          <Component {...rest} />
        </IntercomProvider>
      </GoogleOAuthProvider>
    );
  }

  if (googleauth) {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Component {...rest} />
      </GoogleOAuthProvider>
    );
  }

  if (intercom) {
    return (
      <IntercomProvider appId={intercomAppId}>
        <Component {...rest} />
      </IntercomProvider>
    );
  }

  return <Component {...rest} />;
};
