import React, { memo, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";
import { useToggle } from "hooks/useToggle";
import Logo from "components/logo/logo";
import ModalCropImage from "components/modal/modal-crop-image";
import ColorPicker from "components/color-picker";
import Alert from "components/alert";
import OrganizationActions from "redux/actions/organization";
import { defaultColors, fontOptions } from "helpers/constants";
import "./organization.scss";
import classNames from "classnames";

const Personalization = ({ organizationId }) => {
  const inputFile = useRef(null);
  const {
    showPersonalizationSaveSuccess,
    personalizationSaveError,
    organization: { defaultProperties },
  } = useSelector((s) => s.organization);

  const [selectedLogoOption, setSelectedLogoOption] = useState(defaultProperties?.useCustomLogo ? "custom" : "default");
  const [selectedColorsOption, setSelectedColorsOption] = useState(
    defaultProperties?.useCustomColors ? "custom" : "default"
  );
  const [selectedFontOption, setSelectedFontOption] = useState(defaultProperties?.useCustomFont ? "custom" : "default");

  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropImageModal, toggleCropImageModal] = useToggle(false);
  const [settingsFormData, setSettingsFormData] = useState(new FormData());
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [customColors, setCustomColors] = useState(defaultProperties?.colorPalette || defaultColors);
  const [selectedFont, setSelectedFont] = useState(defaultProperties?.font);
  const [activeColorPickerIndex, setActiveColorPickerIndex] = useState(0);

  const selectImage = (event) => {
    const image = event.target.files[0];

    if (image?.size > 30720) {
      setFileSizeError(true);
    } else if (!["image/png", "image/gif", "image/webp"].includes(image?.type)) {
      setFileTypeError(true);
    } else {
      setFileSizeError(false);
      setFileTypeError(false);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSelectedImage(reader.result.toString() || "");
      });
      reader.readAsDataURL(image);
      toggleCropImageModal();
    }
  };

  const onSelectFileClick = () => {
    inputFile.current.click();
  };

  const onSaveCroppedImage = async (croppedImage) => {
    setCroppedImage(URL.createObjectURL(croppedImage));
    const fileName = `custom-logo_${organizationId}_${moment().toISOString()}.png`;
    const file = new File([croppedImage], fileName);

    const formData = settingsFormData;
    formData.set("file", file);
    formData.set("useCustomLogo", "true");
    setSelectedLogoOption("custom");
    setSettingsFormData(formData);

    toggleCropImageModal();
  };

  const handleSavePersonalization = () => {
    const formData = settingsFormData;

    if (JSON.stringify(defaultColors) !== JSON.stringify(customColors)) {
      customColors.forEach((c) => formData.append("colors", c));
    }

    if (selectedFont) {
      formData.set("font", selectedFont);
    }

    if (selectedLogoOption === "custom") {
      formData.set("useCustomLogo", "true");
    } else {
      formData.set("useCustomLogo", "false");
    }

    if (selectedColorsOption === "custom") {
      formData.set("useCustomColors", "true");
    } else {
      formData.set("useCustomColors", "false");
    }

    if (selectedFontOption === "custom") {
      formData.set("useCustomFont", "true");
    } else {
      formData.set("useCustomFont", "false");
    }

    setSettingsFormData(formData);
    OrganizationActions.updatePersonalizationSettings(organizationId, formData);
  };

  useEffect(() => {
    if (showPersonalizationSaveSuccess) {
      OrganizationActions.getCustomLogo();
      OrganizationActions.clearSaveState();
    }
  }, [showPersonalizationSaveSuccess]);

  const onChangeRadio = (e) => {
    const formData = settingsFormData;
    const { name, id } = e.target;

    if (name === "logo") {
      if (id === "custom-logo") {
        formData.set("useCustomLogo", "true");
        setSelectedLogoOption("custom");
      } else {
        formData.set("useCustomLogo", "false");
        setSelectedLogoOption("default");
      }
    } else if (name === "colors") {
      if (id === "custom-colors") {
        formData.set("useCustomColors", "true");
        setSelectedColorsOption("custom");
      } else {
        formData.set("useCustomColors", "false");
        setSelectedColorsOption("default");
      }
    } else if (name === "font") {
      if (id === "custom-font") {
        formData.set("useCustomFont", "true");
        setSelectedFontOption("custom");
      } else {
        formData.set("useCustomFont", "false");
        setSelectedFontOption("default");
      }
    }

    setSettingsFormData(formData);
  };

  const onChangeFont = (e) => {
    setSelectedFont(e.value);

    const formData = settingsFormData;
    formData.set("useCustomFont", "true");
    setSelectedFontOption("custom");
    setSettingsFormData(formData);
  };

  return (
    <>
      <div className="card mt-5 mb-3 personalization">
        <div className="card-header card-header-content-between border-bottom">
          <h4 className="card-header-title">Personalization</h4>
        </div>

        <div className="card-body">
          {fileSizeError && (
            <Alert key="danger" type="danger" message="The file size should not exceed 30KB." className="mb-3" />
          )}
          {fileTypeError && <Alert key="danger" type="danger" message="The file type is not valid." className="mb-3" />}
          {showPersonalizationSaveSuccess && (
            <Alert
              key="success"
              type="success"
              message="Personalization settings have been successfully saved."
              className="mb-3"
            />
          )}
          {personalizationSaveError && (
            <Alert key="danger" type="danger" message={personalizationSaveError} className="mb-3" />
          )}
          {/* Logo */}
          <div className="row border-bottom">
            <div className="col-md-4 col-sm-6">
              <Form.Check
                type="radio"
                name="logo"
                id="default-logo"
                label="Default Logo"
                className="personalization-radio"
                onChange={onChangeRadio}
                checked={selectedLogoOption === "default" ? true : false}
              />
              <div className={classNames("p-3", { "inactive-column": selectedLogoOption === "custom" })}>
                <Logo outerClassName="navbar-brand" innerClassName="navbar-brand-logo" />
              </div>
            </div>
            <div className="col-8 col-sm-6">
              <Form.Check
                type="radio"
                name="logo"
                id="custom-logo"
                label="Custom Logo"
                className="personalization-radio"
                onChange={onChangeRadio}
                checked={selectedLogoOption === "custom" ? true : false}
              />
              <div className={classNames("pt-3 pb-3 pl-4", { "inactive-column": selectedLogoOption === "default" })}>
                {croppedImage && (
                  <img className="mb-3" style={{ maxWidth: "200px" }} src={croppedImage} alt="new-custom-logo" />
                )}
                {defaultProperties?.logoUrl && !croppedImage && (
                  <img
                    className="mb-3"
                    style={{ maxWidth: "200px" }}
                    src={defaultProperties.logoUrl}
                    alt="custom-logo"
                  />
                )}
                <li>The image file should be in PNG, GIF or WEBP format</li>
                <li>Up to 360x180 pixels and no bigger than 30KB </li>
                <button className="btn btn-md btn-outline-primary mt-3" onClick={onSelectFileClick}>
                  Select File to Upload
                </button>
                <input
                  type="file"
                  className="js-file-attach avatar-uploader-input"
                  id="logoUploader"
                  accept="image/*"
                  ref={inputFile}
                  onChange={selectImage}
                />
              </div>
            </div>
          </div>
          {/* Colors */}
          <div className="row pt-3 border-bottom">
            <div className="col-md-4 col-sm-6">
              <Form.Check
                type="radio"
                name="colors"
                id="default-colors"
                label="Default Colors"
                className="personalization-radio"
                onChange={onChangeRadio}
                checked={selectedColorsOption === "default" ? true : false}
              />
              <div
                className={classNames("p-3 d-flex flex-wrap", { "inactive-column": selectedColorsOption === "custom" })}
              >
                {defaultColors.map((color, i) => (
                  <ColorPicker key={i} color={color} isDisabled />
                ))}
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <Form.Check
                type="radio"
                name="colors"
                id="custom-colors"
                label="Custom Colors"
                className="personalization-radio"
                onChange={onChangeRadio}
                checked={selectedColorsOption === "custom" ? true : false}
              />
              <div
                className={classNames("p-3 d-flex flex-wrap", {
                  "inactive-column": selectedColorsOption === "default",
                })}
              >
                {customColors.map((color, i) => (
                  <ColorPicker
                    key={i}
                    color={color}
                    index={i}
                    customColors={customColors}
                    setCustomColors={setCustomColors}
                    settingsFormData={settingsFormData}
                    setSelectedColorsOption={setSelectedColorsOption}
                    setSettingsFormData={setSettingsFormData}
                    setActiveColorPickerIndex={setActiveColorPickerIndex}
                    activeColorPickerIndex={activeColorPickerIndex}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* Fonts */}
          <div className="row pt-3">
            <div className="col-md-4 col-sm-6">
              <Form.Check
                type="radio"
                name="font"
                id="default-font"
                label="Default Font"
                className="personalization-radio"
                onChange={onChangeRadio}
                checked={selectedFontOption === "default" ? true : false}
              />
              <div className={classNames("p-3", { "inactive-column": selectedFontOption === "custom" })}>
                <div className="form-group">
                  <input type="text" className="form-control" name="default-font" disabled value="Noto Sans" />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <Form.Check
                type="radio"
                name="font"
                id="custom-font"
                label="Custom Font"
                className="personalization-radio"
                onChange={onChangeRadio}
                checked={selectedFontOption === "custom" ? true : false}
              />
              <div className={classNames("pt-3 pb-3 pl-4", { "inactive-column": selectedFontOption === "default" })}>
                <Select
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  options={fontOptions}
                  value={fontOptions.find((f) => f.value === selectedFont)}
                  onChange={onChangeFont}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={handleSavePersonalization}
              className="btn btn-primary"
              disabled={!!fileSizeError || !!fileTypeError}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
      {showCropImageModal && (
        <ModalCropImage
          file={selectedImage}
          show={showCropImageModal}
          handleClose={toggleCropImageModal}
          handleSave={onSaveCroppedImage}
          maxWidth={360}
          maxHeight={180}
          width={360}
          height={180}
          unit="px"
          aspectRatio={2 / 1}
        />
      )}
    </>
  );
};

export default memo(Personalization);
