import React, { memo, useEffect, useState } from "react";
import Select from "react-select";
import { capitalize } from "helpers/capitalize";
import { csvUpdateTableActions } from "helpers/constants";

const FilesTableRow = ({
  step,
  file,
  tableNames,
  onClickDelete,
  onChangeFileName,
  uploadCorrectedCsvFile,
  onClickSkipImport,
  isUpdate = false,
  existingTables,
  onChangeAction,
  setHasNameDuplicates,
}) => {
  const [showInputIcon, setShowInputIcon] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [action, setAction] = useState();
  const [isHeader, setIsHeader] = useState(false);

  useEffect(() => {
    if (step === 1 && file.parentSheet && file.parentFileId) {
      onChangeFileName({ fileId: file.fileId, value: file.parentSheet });
    }
  }, [file, step]);

  useEffect(() => {
    if (file.origFilename.includes(".xlsx")) {
      if (!file.included) {
        setIsHeader(true);
      }
    }
  }, [file]);

  const checkForDuplicateName = () => {
    const allTableNames = Object.values(tableNames);

    let hasDuplicate;
    if (allTableNames?.length > 1 && inputValue) {
      let duplicate;
      if (isUpdate) {
        duplicate = allTableNames?.filter((t) => t.name === inputValue);
      } else {
        duplicate = allTableNames?.filter((name) => name === inputValue);
      }

      if (duplicate?.length > 1) {
        hasDuplicate = true;
      } else {
        hasDuplicate = false;
      }
    } else {
      hasDuplicate = false;
    }

    setHasNameDuplicates(hasDuplicate);
    return hasDuplicate;
  };

  const renderFileName = () => {
    if (file.origFilename.includes(".xlsx")) {
      if (file.included) {
        return file.parentSheet.length > 50 ? `${file.parentSheet.substring(0, 50)}...` : file.parentSheet;
      } else {
        return file.origFilename.length > 50 ? (
          <b>{`${file.origFilename.substring(0, 50)}...`}</b>
        ) : (
          <b>{file.origFilename}</b>
        );
      }
    } else {
      return file.origFilename.length > 50 ? `${file.origFilename.substring(0, 50)}...` : file.origFilename;
    }
  };

  if (step === 1) {
    if (isHeader) {
      return (
        <div className="d-flex">
          <div className="csv-table-data" style={{ width: "100%" }}>
            {file.origFilename.length > 50 ? (
              <b>{`${file.origFilename.substring(0, 50)}...`}</b>
            ) : (
              <b>{file.origFilename}</b>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex">
        <div className="csv-table-data">{renderFileName()}</div>
        {isUpdate && (
          <div className="csv-table-data">
            {file?.included ? (
              <Select
                menuPosition={"fixed"}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  control: (styles) => ({ ...styles, backgroundColor: "#f2f4f6" }),
                }}
                className="w-100 csv-dropdown"
                options={csvUpdateTableActions}
                onChange={(e) => {
                  onChangeAction({ fileId: file.fileId, value: e.value });
                  setAction(e.value);
                }}
                defaultValue={csvUpdateTableActions[0]}
              />
            ) : (
              <></>
            )}
          </div>
        )}
        <div className="csv-table-data csv-data-input">
          {file?.included ? (
            !action || action === "create" ? (
              <>
                <input
                  type="text"
                  className="form-control"
                  name="tableName"
                  placeholder="Rename..."
                  autoComplete="off"
                  value={
                    isUpdate
                      ? tableNames[file.fileId]?.name?.endsWith(".csv")
                        ? tableNames[file.fileId]?.name?.split(".csv")[0]
                        : tableNames[file.fileId]?.name
                      : tableNames[file.fileId]?.endsWith(".csv")
                      ? tableNames[file.fileId]?.split(".csv")[0]
                      : tableNames[file.fileId]
                  }
                  onChange={(e) => {
                    onChangeFileName({ fileId: file.fileId, value: e.target.value });
                    if (e.target.value) {
                      setShowInputIcon(true);
                      setInputValue(e.target.value);
                    } else {
                      setShowInputIcon(false);
                      setInputValue();
                    }
                  }}
                  style={{ padding: "0 10px" }}
                />
                {showInputIcon && (
                  <>
                    {checkForDuplicateName() ? (
                      <i className="tio-warning-outlined mr-2" style={{ color: "#E50000" }}></i>
                    ) : (
                      <i className="tio-checkmark-circle-outlined mr-2" style={{ color: "#55BF40" }}></i>
                    )}
                  </>
                )}
              </>
            ) : (
              <Select
                menuPosition={"fixed"}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  control: (styles) => ({ ...styles, backgroundColor: "#f2f4f6" }),
                }}
                className="w-100 csv-dropdown"
                options={existingTables.map((t) => ({ value: t.tableName, label: t.tableName }))}
                onChange={(e) => {
                  onChangeFileName({ fileId: file.fileId, value: e.value });
                }}
              />
            )
          ) : (
            <></>
          )}
        </div>
        <div className="csv-table-data action" onClick={file?.included ? () => onClickDelete(file.fileId) : undefined}>
          {file?.included ? <img className="img-fluid" src="./assets/img/delete-icon.svg" alt="delete table" /> : <></>}
        </div>
      </div>
    );
  }

  if (step === 2) {
    return (
      <>
        <div className="d-flex">
          <div className="csv-table-data status">
            {file?.error ? (
              <>
                <i className="tio-warning-outlined mr-2" style={{ color: "#FF8000", marginTop: "2px" }} />
                Alert
              </>
            ) : (
              <>
                <i className="tio-checkmark-circle-outlined mr-2" style={{ color: "#55BF40", marginTop: "2px" }} />
                OK
              </>
            )}
          </div>
          <div className="csv-table-data">
            {file?.origFilename?.length > 50 ? `${file?.origFilename.substring(0, 50)}...` : file?.origFilename}
          </div>
          <div className="csv-table-data mode">{capitalize(file?.mode)}</div>
          <div className="csv-table-data">{file?.tableName}</div>
        </div>
        {file?.error && (
          <div className="csv-error-container">
            <p>{file.error?.length > 200 ? `${file.error.substring(0, 200)}...` : file.error}</p>
            <div className="d-flex">
              <button className="btn fw-semibold btn-link p-0" onClick={uploadCorrectedCsvFile}>
                <i className="tio-upload-outlined"></i> Upload Corrected File
              </button>
              <div className="form-group ml-3 mb-0">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name="skipImport"
                    onChange={(e) => onClickSkipImport(e, file.fileId)}
                    checked={!file.included}
                    style={{ zIndex: 5 }}
                  />
                  <label className="skip-import custom-control-label font-size-md fw-semibold text-primary">
                    {" "}
                    Skip Import
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default memo(FilesTableRow);
