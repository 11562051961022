import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";
import AuthService from "../../helpers/auth";

class Actions {
  static async logout() {
    ApiActions.delete("/api/logout", undefined, Store, Constants.LOGOUT, Constants.LOGOUT_RESPONSE, () => {
      AuthService.logout();
    });
  }
}

export default Actions;
