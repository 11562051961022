import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal, Button, ListGroup } from "react-bootstrap";
import OrganizationActions from "redux/actions/organization";
import Alert from "components/alert";
import Loading from "components/loading";

const ModalTransferOrgOwnership = ({ show, handleClose, loggedInUserId, orgHasBillingPlan }) => {
  const navigate = useNavigate();
  const {
    loading: loadingOrganization,
    loadingTransfer,
    error: errorOrganization,
    organization,
    transferSuccess,
  } = useSelector((s) => s.organization);

  const [alerts, setAlerts] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState();
  const [orgAdmins, setOrgAdmins] = useState();
  const [loadingText, setLoadingText] = useState(null);
  const [shouldRemoveOwner, setShouldRemoveOwner] = useState(false);

  const close = () => {
    setSelectedUserId("");
    setAlerts([]);
    OrganizationActions.clearTransferState();
    handleClose();
  };

  useEffect(() => {
    if (!organization.users || !organization.users.length) {
      setOrgAdmins([]);
      return;
    }

    const adminUsers = organization.users?.map((u) => {
      const org = u.organizations?.filter((o) => o._id === organization._id).pop();
      if (org?.groups?.admin) {
        return u;
      }
    });
    setOrgAdmins(adminUsers.filter(Boolean).filter((user) => user._id.toString() !== loggedInUserId.toString()));
  }, [organization]);

  const onClickTransferOwnership = (event) => {
    event.preventDefault();

    const data = [
      {
        organizationId: organization._id,
        userId: selectedUserId,
        removeOwner: shouldRemoveOwner,
      },
    ];

    setLoadingText("Transferring ownership...");
    OrganizationActions.transferOwnership(data);
  };

  useEffect(() => {
    if (transferSuccess) {
      close();
      navigate("/organization");
    }
  }, [transferSuccess]);

  useEffect(() => {
    if (errorOrganization) {
      setAlerts([<Alert key="danger" type="danger" message={errorOrganization} />]);
    }
  }, [errorOrganization]);

  const onClickRemoveMyselfFromOrg = (e) => {
    setShouldRemoveOwner(e.target.checked);
  };

  if (!orgAdmins || !orgAdmins.length) {
    return (
      <Modal backdrop="static" keyboard={false} show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Transfer Ownership of Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>You don&apos;t have any admins in the organization to transfer ownership.</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal backdrop="static" keyboard={false} show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Transfer Ownership of Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading text={loadingText} loading={loadingTransfer || loadingOrganization}></Loading>
        <div>
          <p>
            Transferring organization ownership will transfer all datasources, questions, dashboards, etc., to the new
            owner.
          </p>
          <p className="mb-5">
            <b>Please select who you want to transfer ownership of the organization.</b>
          </p>

          {alerts}

          <div className="accordion">
            <div className="card mb-4">
              <div className="card-body pt-0 pb-0">
                <ListGroup variant="flush">
                  {orgAdmins?.map((user) => (
                    <ListGroup.Item
                      key={user._id}
                      value={user._id}
                      className="d-flex align-items-center justify-content-between"
                    >
                      {user.email}
                      {selectedUserId === user._id ? (
                        <button disabled="disabled" className="btn btn-sm btn-secondary float-right">
                          <i className="tio-done mr-2"></i>
                          Selected
                        </button>
                      ) : (
                        <a
                          className="btn btn-sm btn-white float-right"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedUserId(user._id)}
                        >
                          Select User
                        </a>
                      )}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </div>
            <p>
              Tick the below checkbox, if you want to remove yourself from this organization when transferring
              ownership.
            </p>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked={shouldRemoveOwner}
                onChange={onClickRemoveMyselfFromOrg}
                style={{ zIndex: 5 }}
              />
              <label className="custom-control-label text-dark"> Remove myself from this organization</label>
            </div>
          </div>
          {orgHasBillingPlan && (
            <div className="mt-5">
              <p>
                The billing plan for this organization will be transferred to the new owner. The new user will be
                requested to attach a new payment method to continue the plan after the current billing cycle ends.
              </p>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button disabled={loadingTransfer || !selectedUserId} variant="primary" onClick={onClickTransferOwnership}>
          Transfer Ownership
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTransferOrgOwnership;
