import React from "react";
import PropTypes from "prop-types";
import { Container, Button } from "react-bootstrap";
import Logo from "components/logo/logo";
import AuthService from "../../helpers/auth";

const PublicHeader = ({ redirectAfterLoginUrl = "" }) => {
  const isUserLoggedIn = AuthService.isLoggedIn();

  return (
    <React.Fragment>
      <header
        id="header"
        className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered"
        style={{ zIndex: 9999 }}
      >
        <Container>
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="navbar-brand-wrapper">
              <Logo
                outerClassName="navbar-brand"
                innerClassName="navbar-brand-logo"
                href={isUserLoggedIn ? "/" : "https://getzingdata.com/"}
              />
            </div>
            <div>
              {!isUserLoggedIn && (
                <Button
                  className="mr-md-4 mr-2"
                  variant="link"
                  href={`/login${redirectAfterLoginUrl ? `?redirectUrl=${redirectAfterLoginUrl}` : ""}`}
                >
                  Log in
                </Button>
              )}
              <Button variant="primary" type="button" href="/download-app">
                Get Zing App
              </Button>
            </div>
          </div>
        </Container>
      </header>
    </React.Fragment>
  );
};

PublicHeader.propTypes = {
  redirectAfterLoginUrl: PropTypes.string,
};

export default PublicHeader;
