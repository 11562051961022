import React from "react";
import Footer from "components/footer/footerlogin";
import Logo from "components/logo/logo";

const PublicPageLayout = ({ children }) => {
  return (
    <main id="content" role="main" className="main">
      <div
        className="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{
          height: "32rem",
          backgroundImage: "url(../assets/svg/components/abstract-bg-4.svg)",
        }}
      >
        <figure className="position-absolute right-0 bottom-0 left-0">
          <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>
      <div className="container py-5 py-sm-7 d-flex flex-column justify-content-center" style={{ height: "95vh" }}>
        <Logo
          outerClassName="d-flex justify-content-center mb-5"
          innerClassName="z-index-2"
          style={{ width: "15rem" }}
        />
        <div className="row justify-content-center">
          <div className="col-md-7 col-lg-5">
            <div className="card card-lg mb-5">
              <div className="card-body">{children}</div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default PublicPageLayout;
