import React from "react";
import { Route, Routes } from "react-router-dom";
import amplitude from "amplitude-js";

import { PrivateRoute } from "components/privateroute";
import { PublicRoute } from "components/publicroute";
import { redirect } from "components/route-redirect";

// Load Pages
import Login from "pages/login";
import Logout from "pages/logout";
import Signup from "pages/signup";
import Dashboard from "pages/dashboard";
import Datasource from "pages/datasource";
import Profile from "pages/profile";
import NotFound from "pages/not-found";
import DatasourceForm from "pages/datasource/form";
import ForgotPassword from "pages/forgot-password";
import ResetPassword from "pages/reset-password";
import AcceptInvite from "pages/accept-invite";
import Invitation from "pages/invitation";
import Organization from "pages/organization";
import OrganizationDetails from "pages/organization/org-details";
import DownloadApp from "pages/download-app";
import ChartPage from "pages/chart";
import SignupSuccessPage from "pages/signup-success";
import EmailVerificationPage from "pages/email-verification";
import DatasourceSettings from "pages/datasource/datasource-settings";
import LoginSsoPage from "pages/login/sso";

const AppUniversal = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);

  return (
    <div>
      <Routes>
        <Route exact path="/login" element={<PublicRoute googleauth={true} component={Login} />} />
        <Route exact path="/login-sso" element={<PublicRoute googleauth={true} component={LoginSsoPage} />} />
        <Route exact path="/login-sso/:token" element={<PublicRoute googleauth={true} component={LoginSsoPage} />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password/:token" element={<ResetPassword />} />
        <Route exact path="/signup" element={<PublicRoute googleauth={true} component={Signup} />} />
        <Route exact path="/signup/:token" element={<PublicRoute googleauth={true} component={Signup} />} />
        <Route exact path="/logout" element={<PublicRoute intercom={true} googleauth={true} component={Logout} />} />
        <Route exact path="/accept-invite/:token" element={<AcceptInvite />} />
        <Route exact path="/question/:id" element={<DownloadApp />} />
        <Route exact path="/chart/:id" element={<PublicRoute component={ChartPage} />} />
        <Route exact path="/download-app" element={<PublicRoute component={DownloadApp} />} />
        <Route exact path="/signup-success/:token" element={<PublicRoute component={SignupSuccessPage} />} />
        <Route exact path="/confirm-email/:token" element={<PublicRoute component={EmailVerificationPage} />} />

        <Route exact path="/dashboard" element={<PrivateRoute component={Dashboard} googleauth={true} />} />
        <Route exact path="/datasource" element={<PrivateRoute component={Datasource} googleauth={true} />} />
        <Route exact path="/profile" element={<PrivateRoute component={Profile} />} />
        <Route exact path="/" element={<PrivateRoute component={Dashboard} googleauth={true} />} />
        <Route exact path="/datasource/:id" element={<PrivateRoute component={DatasourceForm} />} />
        <Route exact path="/invitation" element={<PrivateRoute component={Invitation} />} />
        <Route exact path="/organization" element={<PrivateRoute component={Organization} />} />
        <Route exact path="/organization/:id" element={<PrivateRoute component={OrganizationDetails} />} />
        <Route
          exact
          path="/datasource/:id/settings"
          element={<PrivateRoute component={DatasourceSettings} googleauth={true} />}
        />

        <Route render={redirect({ from: "/moved", to: "/", code: 301 })} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default AppUniversal;
