import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ShowPassword from "components/form/show-password";
import ProfileActions from "redux/actions/profile";
import Footer from "components/footer/footermain";
import Alert from "components/alert";
import NavbarVertical from "components/navbar/navbarvertical";
import NavbarSettings from "pages/profile/components/navbar";
import Header from "components/navbar/header";
import { useSelector } from "react-redux";
import Loading from "components/loading";
import ModalDeleteAccount from "../../components/modal/modal-delete-account";
import ModalTransferAccountOwnership from "components/modal/modal-transfer-account-ownership";
import OrganizationActions from "redux/actions/organization";
import AuthService from "../../helpers/auth";
import moment from "moment";
import styled from "styled-components";
import ModalCropImage from "components/modal/modal-crop-image";
import { useToggle } from "hooks/useToggle";

const UserImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const schemaName = yup.object().shape({
  name: yup.string().max(30, "Name cannot be longer than 30 characters").required("Name is required"),
});

const schemaPassword = yup.object().shape({
  password: yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  newPassword: yup.string().min(6, "New Password must be at least 6 characters").required("New Password is required"),
  confirmNewPassword: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: "smooth" });

const ProfilePage = () => {
  const {
    register: registerName,
    handleSubmit: handleSubmitName,
    errors: errorsName,
  } = useForm({
    resolver: yupResolver(schemaName),
  });
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    errors: errorsPassword,
    reset: resetPassword,
  } = useForm({
    resolver: yupResolver(schemaPassword),
  });

  const { register: registerSettings, handleSubmit: handleSubmitSettings } = useForm();

  const errorsRef = useRef(null);
  const executeScroll = () => scrollToRef(errorsRef);

  const { error, loading, account, showSaveSuccess, type, newPhotoUrl } = useSelector((s) => s.settings);
  const { accountData } = useSelector((s) => s.account);

  const [notifyWhenInviteIsAccepted, setNotifyWhenInviteIsAccepted] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userPhotoUrl, setUserPhotoUrl] = useState(null);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [showCropImage, setShowCropImage] = useState(false);
  const [isTransferAccountModalOpen, toggleTransferAccountModal] = useToggle();

  const {
    user: { photoUrl },
  } = AuthService.getUser();

  let name;
  if (account?.name) {
    const { first, middle, last } = account.name;
    name = first;

    if (middle) name = `${name} ${middle}`;
    if (last) name = `${name} ${last}`;
  }

  const user = useSelector((s) => s.login.user);
  let userAuth;
  if (user) {
    userAuth = user._id;
  } else {
    userAuth = localStorage.getItem("userauth");
  }

  const handleCloseDeleteAccount = () => {
    setShowDeleteAccount(false);
    ProfileActions.hideDeleteAccount();
  };

  const handleShowDeleteAccount = () => setShowDeleteAccount(true);

  const handleCloseCropImage = () => {
    setShowCropImage(false);
    ProfileActions.hideCropImage();
  };
  const handleShowCropImage = () => setShowCropImage(true);
  const handleSave = (croppedImage) => saveUserImage(croppedImage);

  const selectImage = (event) => {
    const image = event.target.files[0];

    const reader = new FileReader();
    reader.addEventListener("load", () => setSelectedImage(reader.result.toString() || ""));
    reader.readAsDataURL(image);

    handleShowCropImage();
  };

  const saveUserImage = (croppedImage) => {
    // Create an object of formData
    const formData = new FormData();

    const fileName = `cropped_image_${moment().toISOString()}.png`;
    const file = new File([croppedImage], fileName);

    // Update the formData object
    formData.append("file", file, fileName);

    ProfileActions.savePicture(formData);

    handleCloseCropImage();
  };

  // componentWillMount
  useEffect(() => {
    window.dashboardOnReady();

    ProfileActions.getDetails(userAuth);
    OrganizationActions.getOrganization();

    if (photoUrl) {
      setUserPhotoUrl(photoUrl);
    }

    // lets get the body classes ready for Dashboard
    const origBodyClass = document.body.className;
    document.body.className = "main footer-offset";

    return () => {
      // componentUnMount
      document.body.className = origBodyClass;
    };
  }, []);

  useEffect(() => {
    if (newPhotoUrl) {
      const refreshedNewPhotoUrl = `${newPhotoUrl}?r=${moment().toISOString()}`;
      setUserPhotoUrl(refreshedNewPhotoUrl);

      const authUser = AuthService.getUser();

      authUser.user.photoUrl = refreshedNewPhotoUrl;

      AuthService.setUser({ ...authUser });
    }
  }, [newPhotoUrl]);

  useEffect(() => {
    if (!error) {
      if (type === "password") {
        resetPassword("password");
        resetPassword("newPassword");
        resetPassword("confirmNewPassword");

        ProfileActions.hideDetailsSaveSuccess();
      }
    } else {
      setAlerts([<Alert key="danger" type="danger" message={error} />]);

      executeScroll();
    }

    if (showSaveSuccess) {
      setAlerts([<Alert key="success" type="success" message={"Settings successfully updated!"} />]);

      executeScroll();
    }
  }, [error, type, showSaveSuccess]);

  useEffect(() => {
    if (account.user) {
      if (account.user.settings) {
        setNotifyWhenInviteIsAccepted(account.user.settings.notifyWhenInviteIsAccepted);
      }
    }
  }, [account]);

  const doSaveName = (data, e) => {
    e.preventDefault();

    ProfileActions.saveDetails({
      name: data.name,
    });
  };

  const doSavePassword = (data, e) => {
    e.preventDefault();

    ProfileActions.savePassword({
      password: data.password,
      newPassword: data.newPassword,
    });
  };

  const doSaveSettings = (data, e) => {
    e.preventDefault();

    ProfileActions.saveSettings({
      notifyWhenInviteIsAccepted: data.notifyWhenInviteIsAccepted,
    });
  };

  const handleInputNotifyWhenInviteIsAccepted = (e) => {
    setNotifyWhenInviteIsAccepted(e.target.checked);
  };

  return (
    <>
      <Header user={user} userUpdatedImage={userPhotoUrl} />
      <NavbarVertical />
      <main id="content" role="main" className="main" style={isMobile ? { paddingTop: "7.75rem" } : {}}>
        <div className="content container-fluid">
          <div className="page-header pb-0">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title">Profile</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <NavbarSettings accountData={accountData} />
            <div className="col-lg-9">
              <div ref={errorsRef}>{alerts}</div>
              <div className="card mb-3 mb-lg-5">
                <Loading loading={loading}></Loading>
                <div className="profile-cover">
                  <div className="profile-cover-img-wrapper">
                    <img
                      id="profileCoverImg"
                      className="profile-cover-img"
                      src="./assets/img/1920x400/img2.jpg"
                      alt="Description"
                    />
                  </div>
                </div>
                <label
                  className="avatar avatar-xxl avatar-circle avatar-border-lg profile-cover-avatar"
                  htmlFor="avatarUploader"
                >
                  <UserImage
                    id="avatarImg"
                    className="avatar-img"
                    style={{
                      backgroundImage: `url(${userPhotoUrl || "./assets/svg/illustrations/profilepic.svg"})`,
                    }}
                    alt={name}
                    referrerpolicy="no-referrer"
                  />

                  <input
                    type="file"
                    className="js-file-attach avatar-uploader-input"
                    id="avatarUploader"
                    accept="image/*"
                    onChange={selectImage}
                  />

                  <span className="avatar-uploader-trigger">
                    <i className="tio-edit avatar-uploader-icon shadow-soft"></i>
                  </span>
                </label>
              </div>
              <div className="card mb-3 mb-lg-5">
                <Loading loading={loading} />
                <div className="card-header">
                  <h2 className="card-title h4">Basic information</h2>
                </div>
                <div className="card-body">
                  <form key={1} onSubmit={handleSubmitName(doSaveName)}>
                    <div className="row">
                      <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label input-label">
                        Full name{" "}
                        <i
                          className="tio-help-outlined text-body ml-1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Displayed on to your teammates within the app, and how they'll find you in search to @mention you."
                        />
                      </label>
                      <div className="col-sm-9">
                        <div className="js-form-message form-group">
                          <input
                            type="text"
                            className={"form-control form-control-lg" + (errorsName.name ? " is-invalid" : "")}
                            name="name"
                            id="name"
                            placeholder="First Name"
                            aria-label="Your first name"
                            tabIndex={1}
                            ref={registerName}
                            defaultValue={name}
                          />
                          <div className="invalid-feedback">{errorsName.name?.message}</div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div id="passwordSection" className="card mb-3 mb-lg-5">
                <Loading loading={loading}></Loading>
                <div className="card-header">
                  <h4 className="card-title">Change your password</h4>
                </div>
                <div className="card-body">
                  <form key={2} onSubmit={handleSubmitPassword(doSavePassword)}>
                    <div className="row form-group">
                      <label htmlFor="currentPasswordLabel" className="col-sm-3 col-form-label input-label">
                        Current password
                      </label>
                      <div className="col-sm-9">
                        <ShowPassword
                          name="password"
                          id="password"
                          ref={registerPassword}
                          errors={errorsPassword.password}
                          placeholder="6+ characters required"
                          tabIndex={2}
                        ></ShowPassword>
                      </div>
                    </div>
                    <div className="row form-group">
                      <label htmlFor="newPassword" className="col-sm-3 col-form-label input-label">
                        New password
                      </label>
                      <div className="col-sm-9">
                        <ShowPassword
                          name="newPassword"
                          id="newPassword"
                          ref={registerPassword}
                          errors={errorsPassword.newPassword}
                          placeholder="6+ characters required"
                          tabIndex={3}
                        ></ShowPassword>
                      </div>
                    </div>
                    <div className="row form-group">
                      <label htmlFor="confirmNewPasswordLabel" className="col-sm-3 col-form-label input-label">
                        Confirm new password
                      </label>
                      <div className="col-sm-9">
                        <ShowPassword
                          name="confirmNewPassword"
                          id="confirmNewPassword"
                          ref={registerPassword}
                          errors={errorsPassword.confirmNewPassword}
                          placeholder="6+ characters required"
                          tabIndex={4}
                        ></ShowPassword>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div id="preferencesSection" className="card mb-3 mb-lg-5">
                <Loading loading={loading}></Loading>
                <div className="card-header">
                  <h4 className="card-title">Preferences</h4>
                </div>
                <div className="card-body">
                  <form key={3} onSubmit={handleSubmitSettings(doSaveSettings)}>
                    <label className="row form-group toggle-switch" htmlFor="preferencesSwitch1">
                      <span className="col-8 col-sm-9 toggle-switch-content ml-0">
                        <span className="d-block text-dark">Notify me when a team mate accepts my invite</span>
                        <span className="d-block font-size-sm">
                          Will send an e-mail when a team mate accepts your invite
                        </span>
                      </span>
                      <span className="col-4 col-sm-3">
                        <input
                          type="checkbox"
                          ref={registerSettings}
                          name="notifyWhenInviteIsAccepted"
                          className="toggle-switch-input"
                          checked={notifyWhenInviteIsAccepted}
                          onChange={handleInputNotifyWhenInviteIsAccepted}
                          id="preferencesSwitch1"
                        />
                        <span className="toggle-switch-label ml-auto">
                          <span className="toggle-switch-indicator"></span>
                        </span>
                      </span>
                    </label>

                    <div className="d-flex justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {accountData && (
                <div id="transferAccountSection" className="card mb-3 mb-lg-5">
                  <div className="card-header">
                    <h4 className="card-title">Transfer billing account</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <label className="row form-group">
                        <span className="col-12 col-sm-12 ml-0">
                          <span className="d-block text-dark">
                            When you transfer your Zing Data billing account, you would not have access to the billing
                            information anymore. The ownership of all organizations in the billing account will be
                            transferred to the new owner along with the datasources, questions, dashboards in those
                            organizations. You will still remain an admin of these organizations.
                          </span>
                        </span>
                      </label>

                      <div className="d-flex justify-content-end">
                        <button type="button" onClick={toggleTransferAccountModal} className="btn btn-outline-primary">
                          Transfer
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              <div id="deleteAccountSection" className="card mb-3 mb-lg-5">
                <Loading loading={loading}></Loading>
                <div className="card-header">
                  <h4 className="card-title">Delete your account</h4>
                </div>
                <div className="card-body">
                  <form key={5} onSubmit={handleSubmitSettings(doSaveSettings)}>
                    <label className="row form-group">
                      <span className="col-12 col-sm-12 ml-0">
                        <span className="d-block text-dark">
                          When you delete your Zing Data account, you lose access to our services, and we permanently
                          delete your personal data. This cannot be undone.
                        </span>
                      </span>
                    </label>

                    <div className="d-flex justify-content-end">
                      <button type="button" onClick={handleShowDeleteAccount} className="btn btn-danger">
                        Delete
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div id="stickyBlockEndPoint" />
            </div>
          </div>
        </div>
        {showDeleteAccount && (
          <ModalDeleteAccount user={user} show={showDeleteAccount} handleClose={handleCloseDeleteAccount} />
        )}
        {showCropImage && (
          <ModalCropImage
            file={selectedImage}
            show={showCropImage}
            handleClose={handleCloseCropImage}
            handleSave={handleSave}
          />
        )}
        {isTransferAccountModalOpen && (
          <ModalTransferAccountOwnership show={isTransferAccountModalOpen} handleClose={toggleTransferAccountModal} />
        )}
        <Footer />
      </main>
    </>
  );
};

export default ProfilePage;
