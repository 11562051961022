import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Loading from "components/loading";
import Alert from "components/alert";
import AliasesTable from "components/datasource/aliases/aliases-table";
import QueryManagerActions from "redux/actions/query-manager";
import DatasourceActions from "redux/actions/datasource";

import "reactflow/dist/style.css";

const Aliases = ({ datasource, setAlerts, setHasError }) => {
  const filteredTables = datasource?.tables?.filter((t) => t.tableName !== null && t.tableName !== "null");
  const [tables, setTables] = useState(filteredTables);
  const [, setHasColumns] = useState(false);
  const { loading, loadingTablesAndColumns, jobId, timeoutError, queryResult, connectionErr, jobState } = useSelector(
    (s) => s.queryManager
  );
  const { showSaveTablesSuccess } = useSelector((s) => s.datasource);

  useEffect(() => {
    const datasourceTables = tables;
    const hasCols = datasourceTables?.every((obj) => obj.columnSchema && obj.columnSchema.length);
    setHasColumns(hasCols);

    if (!hasCols) {
      QueryManagerActions.getTablesAndColumns({ datasource: datasource?._id, version: 2 });
    }
  }, [tables]);

  useEffect(() => {
    if (jobId) {
      QueryManagerActions.getQueryById(jobId);
    }
  }, [jobId]);

  useEffect(() => {
    if (showSaveTablesSuccess) {
      DatasourceActions.hideDataSourceCreate();
    }
  }, [showSaveTablesSuccess]);

  const updateSavedTables = (updatedTables) => {
    DatasourceActions.updateTables(datasource._id, {
      tables: updatedTables.map((table) => ({
        tableName: table?.tableName,
        schemaName: table.schemaName,
        labelName: table.labelName,
        included: table.included,
        columnSchema: table.columnSchema,
      })),
    });
  };

  useEffect(() => {
    if (timeoutError || (queryResult !== undefined && queryResult !== null && !Array.isArray(queryResult))) {
      setHasError(true);
      setAlerts(
        <Alert
          key="danger"
          type="danger"
          message="Connection to database failed, please check your connection settings."
          details={connectionErr}
        />
      );
      QueryManagerActions.clearState();
    } else if (jobState === "completed") {
      setAlerts([]);
      setHasError(false);

      const updatedTables = tables.map((t) => {
        const resultTable = queryResult
          ?.filter(
            (res) =>
              res.table_name === t.tableName &&
              (res.schema_name && t.schemaName ? res.schema_name === t.schemaName : true)
          )
          .shift();
        if (resultTable) {
          return {
            ...t,
            columnSchema: resultTable.column_schema,
          };
        }
      });
      setTables(updatedTables?.filter(Boolean));
      setHasColumns(true);
      updateSavedTables(updatedTables?.filter(Boolean));
      QueryManagerActions.clearState();
    }
  }, [connectionErr, queryResult, timeoutError, jobState]);

  return (
    <>
      <Loading loading={loadingTablesAndColumns || loading}></Loading>
      <AliasesTable tables={tables} datasource={datasource} setAlerts={setAlerts} />
    </>
  );
};

export default memo(Aliases);
