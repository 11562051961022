import React from "react";
import { Modal, Button } from "react-bootstrap";

const ModalDeletePublicLinks = ({ show, handleClose, deletePublicLinks, index }) => {
  return (
    <Modal backdrop="static" keyboard={false} show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Public Links</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h4 className="mb-5">
            This action will delete all public links of the questions that belong to this organization.
          </h4>
          <p>Do you wish to continue?</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => deletePublicLinks(index)}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeletePublicLinks;
