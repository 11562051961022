import React, { memo, useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useToggle } from "hooks/useToggle";
import { Button, Card, Form, Table } from "react-bootstrap";
import Loading from "components/loading";
import Alert from "components/alert";
import ModalDeleteFieldAlias from "components/modal/modal-delete-field-alias";
import ModalCancelEditingAlias from "components/modal/modal-cancel-editing-alias";
import QueryManagerActions from "redux/actions/query-manager";
import FieldAliasActions from "redux/actions/alias";
import AuthService from "../../../helpers/auth";
import aliasStyles from "../datasource.module.scss";

const AliasesTable = ({ setAlerts, tables, datasource }) => {
  const { user } = AuthService.getUser();

  const { data, loading, deleteError, showDeleteSuccess, showSaveSuccess, errorStatus } = useSelector((s) => s.aliases);
  const [aliasesData, setAliasesData] = useState(data || []);
  const [showDeleteAliasModal, toggleDeleteAliasModal] = useToggle();
  const [showAliasCancelEditingModal, toggleAliasCancelEditingModal] = useToggle();
  const [selectedAliasToDelete, setSelectedAliasToDelete] = useState();
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [tableOptions, setTableOptions] = useState();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const fetchData = useCallback(() => {
    if (datasource?._id) {
      FieldAliasActions.getAliasesByDatasource(datasource._id, {});
    }
  }, [datasource]);

  useEffect(() => {
    if (!data && datasource) {
      fetchData();
    }
  }, [data, datasource]);

  useEffect(() => {
    const aliasData = data?.map((d) => {
      return {
        ...d,
        columnOptions: tables
          ?.map((t) => {
            if (t.tableName === d.tableName) {
              return t.columnSchema.map((c) => ({
                label: c.columnName,
                value: c.columnName,
              }));
            }
          })
          .filter(Boolean)
          .pop(),
        isExisting: true,
      };
    });
    setAliasesData(aliasData || []);
  }, [data, tables]);

  useEffect(() => {
    if (!aliasesData?.length) {
      setIsEditingMode(false);
    }

    const areAllColumnsFilled = aliasesData?.every(
      (obj) => obj.tableName && obj.columnName && obj.alias && (obj.isNew || obj.isUpdated || obj.isExisting)
    );

    if (areAllColumnsFilled) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [aliasesData]);

  useEffect(() => {
    if (showSaveSuccess) {
      setIsEditingMode(false);
      setAlerts([<Alert key="success" type="success" message="Field aliases saved successfully!" />]);
      fetchData();
      FieldAliasActions.hideAliasCreate();
    }
  }, [showSaveSuccess]);

  useEffect(() => {
    if (deleteError) {
      setAlerts([<Alert key="danger" type="danger" message={deleteError} />]);
      toggleDeleteAliasModal();

      if (errorStatus === 404) {
        fetchData();
      }
    } else if (!loading && showDeleteSuccess) {
      setSelectedAliasToDelete();
      toggleDeleteAliasModal();
      fetchData();
      setAlerts([<Alert key="success" type="success" message="Alias deleted successfully!" />]);
      FieldAliasActions.hideAliasDelete();
    }
  }, [deleteError, loading, showDeleteSuccess, errorStatus]);

  useEffect(() => {
    const datasourceTables = tables;
    const hasColumns = datasourceTables?.every((obj) => obj.columnSchema && obj.columnSchema.length);

    if (!hasColumns && datasource) {
      QueryManagerActions.getTablesAndColumns({ datasource: datasource?._id, version: 2 });
    }

    const options = datasourceTables?.map((table) => {
      if (table.included) {
        return {
          label: table.labelName,
          value: table.tableName,
        };
      }
    });

    setTableOptions(options?.filter(Boolean));
  }, [tables, datasource]);

  const onClickAddEdit = () => {
    setIsEditingMode(true);

    const updatedData = [...aliasesData];
    const lastTableName = updatedData[updatedData?.length - 1]?.tableName;

    const columnOptions = lastTableName
      ? tables
          ?.map((t) => {
            if (t.tableName === lastTableName) {
              return t.columnSchema.map((c) => ({
                label: c.columnName,
                value: c.columnName,
              }));
            }
          })
          .filter(Boolean)
          .pop()
      : null;

    updatedData.push({
      isNew: true,
      tableName: lastTableName || null,
      columnName: null,
      alias: null,
      columnOptions: columnOptions || null,
    });
    setAliasesData(updatedData);
  };

  const onClickCancel = () => {
    toggleAliasCancelEditingModal();
  };

  const onClickCancelConfirm = () => {
    setIsEditingMode(false);

    let updatedData = [...aliasesData];
    updatedData = updatedData.filter((row) => row.tableName && row.columnName && row.alias);
    setAliasesData(updatedData);
    toggleAliasCancelEditingModal();
  };

  const onChangeTableName = (e, rowIndex) => {
    const updatedData = aliasesData.map((aliasRow, index) => {
      if (index !== rowIndex) {
        return aliasRow;
      }

      return {
        ...aliasRow,
        ...(aliasRow.isExisting ? { isUpdated: true } : {}),
        tableName: e.value,
        columnOptions: tables
          ?.map((t) => {
            if (t.tableName === e.value) {
              return t.columnSchema.map((c) => ({
                label: c.columnName,
                value: c.columnName,
              }));
            }
          })
          .filter(Boolean)
          .pop(),
      };
    });

    setAliasesData(updatedData);
  };

  const onChangeAlias = (e, rowIndex) => {
    const updatedData = aliasesData.map((aliasRow, index) =>
      index === rowIndex
        ? {
            ...aliasRow,
            ...(aliasRow.isExisting ? { isUpdated: true } : {}),
            alias: e.target.value,
          }
        : aliasRow
    );

    setAliasesData(updatedData);
  };

  const onChangeFieldName = (e, rowIndex) => {
    const updatedData = aliasesData.map((aliasRow, index) =>
      index === rowIndex
        ? {
            ...aliasRow,
            ...(aliasRow.isExisting ? { isUpdated: true } : {}),
            columnName: e.value,
          }
        : { ...aliasRow }
    );

    setAliasesData(updatedData);
  };

  const onClickDelete = (rowIndex) => {
    const updatedData = [...aliasesData];
    const rowToDelete = updatedData[rowIndex];

    if (rowToDelete?.isExisting) {
      setSelectedAliasToDelete(rowToDelete);
      toggleDeleteAliasModal();
    } else {
      updatedData.splice(rowIndex, 1);
      setAliasesData(updatedData);
    }
  };

  const onClickDeleteConfirm = () => {
    FieldAliasActions.delete(selectedAliasToDelete?._id);
  };

  const onClickSave = () => {
    const dataToSave = aliasesData?.map((a) => ({
      tableName: a.tableName,
      columnName: a.columnName,
      alias: a.alias,
      isNew: a.isNew,
      isUpdated: a.isUpdated,
      aliasId: a._id,
    }));

    FieldAliasActions.createOrUpdate({
      datasourceId: datasource._id,
      aliases: dataToSave,
    });
  };

  const renderEditableCell = (cellName, row, rowIndex) => {
    switch (cellName) {
      case "tableCell":
        if (!isEditingMode) {
          return row.tableName;
        } else {
          let selectedOption;
          if (row.tableName) {
            selectedOption = tableOptions.find((t) => t.value === row.tableName);
          }

          return (
            <Select
              options={tableOptions}
              onChange={(e) => onChangeTableName(e, rowIndex)}
              defaultValue={selectedOption}
              maxMenuHeight={156}
              isSearchable
            />
          );
        }
      case "fieldNameCell":
        if (!isEditingMode) {
          return row.columnName;
        } else {
          let selectedOption;
          if (row.tableName && row.columnName) {
            selectedOption = row.columnOptions?.find((t) => t.value === row.columnName);
          }

          return (
            <Select
              options={row.columnOptions}
              isDisabled={!row.columnOptions?.length}
              onChange={(e) => onChangeFieldName(e, rowIndex)}
              defaultValue={selectedOption}
              maxMenuHeight={156}
              isSearchable
            />
          );
        }
      case "aliasCell":
        if (!isEditingMode) {
          return row.alias;
        } else {
          return (
            <Form>
              <Form.Group className="mb-0">
                <Form.Control
                  type="text"
                  placeholder="Enter alias..."
                  onChange={(e) => onChangeAlias(e, rowIndex)}
                  value={row.alias || ""}
                />
              </Form.Group>
            </Form>
          );
        }
      default:
        return null;
    }
  };

  return (
    <Card
      className={classNames(aliasStyles.aliasTable, {
        [aliasStyles.noBorderTable]: isEditingMode,
      })}
    >
      <Loading loading={loading}></Loading>
      <Card.Header
        className={classNames("d-flex justify-content-end", {
          "justify-content-between": !loading && !aliasesData?.length,
        })}
      >
        {!loading && !aliasesData?.length ? <span>Datasource does not have any aliases.</span> : <></>}
        {isEditingMode ? (
          <>
            <button onClick={onClickAddEdit} className="btn btn-outline-primary mr-2">
              <i className="tio-add" /> Add New
            </button>
            <button onClick={onClickCancel} className="btn btn-outline-primary mr-2">
              Cancel
            </button>
            <Button variant="primary" onClick={onClickSave} disabled={isSaveDisabled}>
              Save
            </Button>
          </>
        ) : (
          <Button variant="primary" onClick={onClickAddEdit}>
            <i className="tio-edit" /> Add / Edit
          </Button>
        )}
      </Card.Header>
      {aliasesData?.length ? (
        <Card.Body className="px-0">
          <Table responsive striped className={aliasStyles.table}>
            <thead>
              <tr>
                <th className="border-0 text-dark">Table</th>
                <th className="border-0 text-dark">Field Name</th>
                <th className="border-0 text-dark">Alias</th>
                <th className="border-0 text-dark">Created By</th>
                <th className="border-0 text-dark"></th>
              </tr>
            </thead>
            <tbody>
              {aliasesData.map((aliasRow, index) => {
                return (
                  <tr key={index}>
                    <td className={aliasStyles.tableCell}>{renderEditableCell("tableCell", aliasRow, index)}</td>
                    <td className={aliasStyles.tableCell}>{renderEditableCell("fieldNameCell", aliasRow, index)}</td>
                    <td className={aliasStyles.tableCell}>{renderEditableCell("aliasCell", aliasRow, index)}</td>
                    <td className={aliasStyles.tableCell}>{aliasRow.createdBy?.email || user?.email}</td>
                    <td className={classNames("d-flex justify-content-end border-bottom-0", aliasStyles.tableCell)}>
                      {!isEditingMode || (isEditingMode && !aliasRow.isExisting) ? (
                        <button className="btn btn-sm btn-outline-danger ml-2" onClick={() => onClickDelete(index)}>
                          <i className="tio-delete"></i>
                        </button>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      ) : (
        <></>
      )}
      {showDeleteAliasModal && (
        <ModalDeleteFieldAlias
          show={showDeleteAliasModal}
          handleClose={toggleDeleteAliasModal}
          dataToDelete={selectedAliasToDelete}
          onClickDeleteConfirm={onClickDeleteConfirm}
        />
      )}
      {showAliasCancelEditingModal && (
        <ModalCancelEditingAlias
          show={showAliasCancelEditingModal}
          handleClose={toggleAliasCancelEditingModal}
          onClickConfirm={onClickCancelConfirm}
        />
      )}
    </Card>
  );
};

export default memo(AliasesTable);
