import React, { memo, useState, useMemo, useEffect } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useToggle } from "hooks/useToggle";
import Table from "components/table/table";
import ModalDeleteJoin from "components/modal/modal-delete-join";
import JoinActions from "redux/actions/join";
import Alert from "components/alert";

const JoinsTable = ({
  refresh,
  setRefresh,
  fetchData,
  setAlerts,
  refreshData,
  isTouchDevice,
  onClickEditJoin,
  onClickViewJoin,
}) => {
  const { data, items, pages, loading, error, showDeleteSuccess, errorStatus } = useSelector((s) => s.join);
  const [joins, setJoinsData] = useState(data);
  const [showDeleteJoinModal, toggleDeleteJoinModal] = useToggle();
  const [selectedJoinToDelete, setSelectedJoinToDelete] = useState();

  useEffect(() => {
    if (!data) {
      fetchData({ pageSize: 10, pageIndex: 0 });
    }
  }, [data]);

  useEffect(() => {
    setJoinsData(data);
  }, [data]);

  useEffect(() => {
    if (error) {
      setAlerts([<Alert key="danger" type="danger" message={error} />]);
      toggleDeleteJoinModal();

      if (errorStatus === 404) {
        refreshData();
      }
    } else if (!loading && showDeleteSuccess) {
      setSelectedJoinToDelete();
      toggleDeleteJoinModal();
      refreshData();
      setAlerts([<Alert key="success" type="success" message="Join deleted successfully!" />]);
      JoinActions.updateJoinDeleteSuccess();
    }
  }, [error, loading, showDeleteSuccess]);

  const handleDeleteJoinClick = async (id) => {
    setSelectedJoinToDelete(id);
    toggleDeleteJoinModal();
  };

  const deleteJoin = (id) => {
    JoinActions.delete(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Join Name",
        id: "join-name",
        acessor: "join-name",
        Cell: (row) => {
          if (isTouchDevice) {
            return <span onClick={() => onClickViewJoin(row.row.original._id)}>{row.row.original.name}</span>;
          }
          return row.row.original.name;
        },
      },
      {
        Header: "Date Created",
        accessor: "timeCreated",
        Cell: (row) => {
          return format(new Date(row.row.original.timeCreated), "MMM dd, yyyy");
        },
      },
      {
        Header: "Created By",
        id: "owner",
        Cell: (row) => {
          return row.row.original.createdBy?.email;
        },
        canSort: true,
      },
      {
        Header: "",
        id: "actions",
        Cell: (row) => {
          return (
            <div className="d-flex justify-content-end">
              <div>
                {!isTouchDevice ? (
                  <button className="btn btn-sm btn-white" onClick={() => onClickEditJoin(row.row.original._id)}>
                    <i className="tio-edit"></i> Edit
                  </button>
                ) : (
                  <button className="btn btn-sm btn-white" onClick={() => onClickViewJoin(row.row.original._id)}>
                    <i className="tio-open-in-new"></i> View
                  </button>
                )}
                <button
                  className="btn btn-sm btn-outline-danger ml-2"
                  onClick={() => handleDeleteJoinClick(row.row.original)}
                >
                  <i className="tio-delete"></i> Delete
                </button>
              </div>
            </div>
          );
        },
        sortable: false,
        filterable: false,
      },
    ],
    [isTouchDevice]
  );

  if (!joins?.length) {
    return <span>No joins generated yet.</span>;
  }

  return (
    <>
      <Table
        columns={columns}
        data={joins ?? []}
        items={items ?? []}
        loading={loading}
        pageCount={pages?.total ?? 0}
        refresh={refresh}
        cancelRefresh={() => setRefresh(false)}
        fetchData={fetchData}
      />
      {showDeleteJoinModal && (
        <ModalDeleteJoin
          show={showDeleteJoinModal}
          handleClose={toggleDeleteJoinModal}
          joinId={selectedJoinToDelete?._id}
          deleteJoin={deleteJoin}
          joinName={selectedJoinToDelete?.name}
        />
      )}
    </>
  );
};

export default memo(JoinsTable);
