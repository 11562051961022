import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";
import amplitude from "amplitude-js";
import AuthService from "../../helpers/auth";

const recordChartError = (err, response, id) => {
  if (err) {
    const instance = amplitude.getInstance();
    const user = AuthService.getUser();

    if (user) {
      const userId = user?.user?._id;
      instance.setUserId(userId);
    }

    instance.logEvent("ERROR_PUBLIC_DASHBOARD", {
      chartId: id,
      errorCode: response.statusCode,
      errorMessage: response.error,
    });
  }
};

class PublicLinkActions {
  static async getChart(id) {
    ApiActions.get(
      `/api/public-links/${id}`,
      undefined,
      Store,
      Constants.CHARTS_GET_BY_ID,
      Constants.CHARTS_GET_BY_ID_RESPONSE,
      (err, response) => {
        recordChartError(err, response, id);
      }
    );
  }

  static async getPublicLinksForOrg(orgId, query) {
    ApiActions.get(
      `/api/v1/public-links/organization/${orgId}`,
      query,
      Store,
      Constants.PUBLIC_LINKS_GET_BY_ORG,
      Constants.PUBLIC_LINKS_GET_BY_ORG_RESPONSE
    );
  }

  static async deletePublicLink(id) {
    ApiActions.delete(
      `/api/public-links/${id}`,
      undefined,
      Store,
      Constants.PUBLIC_LINKS_DELETE,
      Constants.PUBLIC_LINKS_DELETE_RESPONSE
    );
  }

  static updatePublicLinkDeleteSuccess() {
    Store.dispatch({
      type: Constants.PUBLIC_LINKS_UPDATE_DELETE_SUCCESS,
    });
  }
}

export default PublicLinkActions;
