import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  data: null,
  loading: false,
  error: undefined,
  hasError: {},
  pages: {},
  items: {},
  showSaveSuccess: false,
  showUpdateSuccess: false,
  showDeleteSuccess: false,
};

const reducer = function (state = initialState, action) {
  if (action.type === Constants.FEWSHOTS_CREATE_NEW) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.FEWSHOTS_CREATE_NEW_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      data: action.response,
    };
  }

  if (action.type === Constants.FEWSHOTS_CLEAR_SUCCESS_STATE) {
    return {
      ...state,
      showSaveSuccess: false,
      showUpdateSuccess: false,
      showDeleteSuccess: false,
    };
  }

  if (action.type === Constants.FEWSHOTS_DELETE) {
    return {
      ...state,
      loading: true,
      showDeleteSuccess: false,
    };
  }

  if (action.type === Constants.FEWSHOTS_DELETE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      error: validation.error,
      hasError: validation.hasError,
      showDeleteSuccess: !action.err,
      errorStatus: validation.statusCode,
    };
  }

  if (action.type === Constants.FEWSHOTS_UPDATE) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.FEWSHOTS_UPDATE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showUpdateSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      data: action.response,
    };
  }

  return state;
};

export default reducer;
