import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  data: null,
  loading: false,
  error: undefined,
  deleteError: undefined,
  hasError: {},
  showSaveSuccess: false,
  showDeleteSuccess: false,
  showUpdateSuccess: false,
  errorStatus: null,
};

const reducer = function (state = initialState, action) {
  if (action.type === Constants.ALIAS_CREATE_OR_UPDATE) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.ALIAS_CREATE_OR_UPDATE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
    };
  }

  if (action.type === Constants.ALIAS_HIDE_SUCCESS_STATE) {
    return {
      ...state,
      showSaveSuccess: false,
    };
  }

  if (action.type === Constants.ALIAS_GET_BY_DATASOURCE) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.ALIAS_GET_BY_DATASOURCE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      error: validation.error,
      hasError: validation.hasError,
      data: action.response,
    };
  }

  if (action.type === Constants.ALIAS_DELETE) {
    return {
      ...state,
      loading: true,
      showDeleteSuccess: false,
    };
  }

  if (action.type === Constants.ALIAS_DELETE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      deleteError: validation.error,
      hasError: validation.hasError,
      showDeleteSuccess: !action.err,
      errorStatus: validation.statusCode,
    };
  }

  if (action.type === Constants.ALIAS_HIDE_DELETE_STATE) {
    return {
      ...state,
      showDeleteSuccess: false,
    };
  }

  return state;
};

export default reducer;
