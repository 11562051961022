import React, { memo, useState, useMemo, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNodesState, useEdgesState } from "reactflow";

import ViewJoin from "components/datasource/joins/view-join";
import CustomNode from "components/datasource/joins/custom-node";
import CustomEdge from "components/datasource/joins/custom-edge";
import Loading from "components/loading";
import Alert from "components/alert";
import QueryManagerActions from "redux/actions/query-manager";
import DatasourceActions from "redux/actions/datasource";

import "reactflow/dist/style.css";

const ViewJoinCanvas = ({ datasource, setAlerts, setHasError, onClickGoBack, selectedJoin }) => {
  const filteredTables = datasource?.tables?.filter((t) => t.tableName !== null && t.tableName !== "null");
  const [tables, setTables] = useState(filteredTables);

  const { showSaveTablesSuccess } = useSelector((s) => s.datasource);
  const { loading, loadingTablesAndColumns, jobId, timeoutError, queryResult, connectionErr, jobState } = useSelector(
    (s) => s.queryManager
  );

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [includedColumns, setIncludedColumns] = useState();
  const [hasColumns, setHasColumns] = useState(false);

  const nodeTypes = useMemo(() => ({ tableNode: CustomNode }), []);
  const edgeTypes = useMemo(() => ({ tableEdge: CustomEdge }), []);

  useEffect(() => {
    const datasourceTables = tables;
    const hasCols = datasourceTables?.every((obj) => obj.columnSchema && obj.columnSchema.length);
    setHasColumns(hasCols);

    if (!hasCols) {
      QueryManagerActions.getTablesAndColumns({ datasource: datasource?._id, version: 2 });
    }
  }, [tables]);

  useEffect(() => {
    if (jobId) {
      QueryManagerActions.getQueryById(jobId);
    }
  }, [jobId]);

  useEffect(() => {
    if (showSaveTablesSuccess) {
      DatasourceActions.hideDataSourceCreate();
    }
  }, [showSaveTablesSuccess]);

  const updateSavedTables = (updatedTables) => {
    DatasourceActions.updateTables(datasource._id, {
      tables: updatedTables.map((table) => ({
        tableName: table?.tableName,
        schemaName: table.schemaName,
        labelName: table.labelName,
        included: table.included,
        columnSchema: table.columnSchema,
      })),
    });
  };

  useEffect(() => {
    if (timeoutError || (queryResult !== undefined && queryResult !== null && !Array.isArray(queryResult))) {
      setHasError(true);
      setAlerts(
        <Alert
          key="danger"
          type="danger"
          message="Connection to database failed, please check your connection settings."
          details={connectionErr}
        />
      );
      QueryManagerActions.clearState();
    } else if (jobState === "completed") {
      setAlerts([]);
      setHasError(false);

      const updatedTables = tables.map((t) => {
        const resultTable = queryResult
          ?.filter(
            (res) =>
              res.table_name === t.tableName &&
              (res.schema_name && t.schemaName ? res.schema_name === t.schemaName : true)
          )
          .shift();
        if (resultTable) {
          return {
            ...t,
            columnSchema: resultTable.column_schema,
          };
        }
      });
      setTables(updatedTables?.filter(Boolean));
      setHasColumns(true);
      updateSavedTables(updatedTables?.filter(Boolean));
      QueryManagerActions.clearState();
    }
  }, [connectionErr, queryResult, timeoutError, jobState]);

  return (
    <div className="card">
      <div className="card-body px-0">
        <Loading loading={loadingTablesAndColumns || loading}></Loading>
        <div>
          <div className="card-header pt-0">
            <div className="col-5 px-0 d-flex">
              <Button variant="secondary" className="btn-ghost-secondary border-0" onClick={onClickGoBack}>
                Go Back
              </Button>
            </div>
          </div>
          <ViewJoin
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            database={datasource?.databasename}
            googleBigQuery={datasource?.googleBigQuery}
            tables={tables}
            nodes={nodes}
            edges={edges}
            setNodes={setNodes}
            setEdges={setEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            includedColumns={includedColumns}
            setIncludedColumns={setIncludedColumns}
            selectedJoin={selectedJoin}
            hasColumns={hasColumns}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ViewJoinCanvas);
