/* eslint-disable no-script-url */
import React, { useEffect, useState, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import styled from "styled-components";
import NavbarVertical from "components/navbar/navbarvertical";
import Logo from "components/logo/logo";
import ModalInviteUser from "components/modal/modal-invite-user";
import InvitationActions from "redux/actions/invitation";
import AccountActions from "redux/actions/account";
import AuthService from "../../helpers/auth";
import amplitude from "amplitude-js";
import OrganizationActions from "redux/actions/organization";

const UserImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const Header = ({ user, userUpdatedImage, refreshData }) => {
  const [userPhotoUrl, setUserPhotoUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState();
  const { user: loggedInUser } = AuthService.getUser();
  const { photoUrl } = loggedInUser || {};
  const { accountData } = useSelector((s) => s.account);
  const { customLogo: customLogoFromOrg, loadingCustomLogo } = useSelector((s) => s.organization);
  const [customLogo, setCustomLogo] = useState(localStorage.getItem("customLogo"));

  useEffect(() => {
    OrganizationActions.getCustomLogo();
  }, []);

  useEffect(() => {
    if (!loadingCustomLogo) {
      if (customLogoFromOrg) {
        localStorage.setItem("customLogo", customLogoFromOrg);
        setCustomLogo(customLogoFromOrg);
      } else {
        localStorage.removeItem("customLogo");
        setCustomLogo();
      }
    }
  }, [customLogoFromOrg]);

  let email, name;
  if (user) {
    email = user.email;
    name = user.roles && user.roles.account && user.roles.account.name ? user.roles.account.name : "";
  } else {
    email = localStorage.getItem("email");
    name = localStorage.getItem("name");
  }

  const { boot } = useIntercom();

  const bootWithProps = () =>
    boot({
      name,
      email,
    });

  // initialize amplitude instance
  const instance = amplitude.getInstance();

  useEffect(() => {
    if (!accountData) {
      AccountActions.getAccountTier();
    }
  }, [accountData]);

  useEffect(() => {
    if (photoUrl) {
      setUserPhotoUrl(photoUrl);
    }
  }, []);

  useEffect(() => {
    if (userUpdatedImage) {
      setUserPhotoUrl(userUpdatedImage);
    }
  }, [userUpdatedImage]);

  useEffect(() => {
    bootWithProps();
  }, [boot]);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, [window.location.pathname]);

  const [showInviteUser, setShowInviteUser] = useState(false);
  const handleCloseInviteUser = () => {
    setShowInviteUser(false);
    InvitationActions.hideInvitationCreate();

    if (currentPage === "/invitation") {
      refreshData();
    }
  };
  const handleShowInviteUser = () => setShowInviteUser(true);

  const handleToApp = useCallback(() => {
    instance.logEvent("GO_TO_APP_TAPPED");
  }, []);

  return (
    <>
      <header
        id="header"
        className="navbar navbar-expand-xl navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered"
      >
        <div className="container">
          <div className="js-mega-menu navbar-nav-wrap" style={{ justifyContent: "center" }}>
            <div className="navbar-brand-wrapper" style={{ minWidth: "105px" }}>
              <Logo
                outerClassName="navbar-brand"
                innerClassName="navbar-brand-logo"
                href="/dashboard"
                customLogo={customLogo}
                loadingCustomLogo={loadingCustomLogo}
              />
            </div>
            <div className="navbar-nav-wrap-content-right">
              <ul className="navbar-nav align-items-center flex-row">
                <li className="nav-item">
                  <a className="btn btn-outline-primary" onClick={handleShowInviteUser}>
                    <i className="tio-user-add mr-1" /> Invite Users
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="btn btn-primary"
                    rel="noopener noreferrer"
                    href="/web"
                    target="_blank"
                    onClick={handleToApp}
                  >
                    <i className="tio-apps" /> Zing App
                  </a>
                </li>
                <li className="nav-item">
                  <div className="hs-unfold">
                    <a
                      className="js-hs-unfold-invoker navbar-dropdown-account-wrapper"
                      data-hs-unfold-options='{
                        "target": "#accountNavbarDropdown",
                        "type": "css-animation"
                      }'
                    >
                      <div className="avatar avatar-sm avatar-circle">
                        <UserImage
                          className="avatar-img"
                          style={{
                            backgroundImage: `url(${userPhotoUrl || "/assets/svg/illustrations/profilepic.svg"})`,
                          }}
                          alt={name}
                          referrerpolicy="no-referrer"
                        />

                        <span className="avatar-status avatar-sm-status avatar-status-success" />
                      </div>
                    </a>
                    <div
                      id="accountNavbarDropdown"
                      className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account"
                      style={{ width: "fit-content" }}
                    >
                      <div className="dropdown-item">
                        <div className="media align-items-center">
                          <div className="media-body">
                            <span className="card-title h5">{name}</span>
                            <span className="card-text">{email}</span>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown-divider" />

                      <a className="dropdown-item" href="/profile">
                        <span className="text-truncate pr-2" title="Profile">
                          Profile
                        </span>
                      </a>
                      <div className="dropdown-divider" />

                      <Link to="/logout" className="dropdown-item">
                        <span className="text-truncate pr-2" title="Sign out">
                          Sign out
                        </span>
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className="navbar-toggler btn btn-ghost-secondary rounded-circle"
                    aria-label="Toggle navigation"
                    aria-expanded="false"
                    aria-controls="navbarNavMenu"
                    data-toggle="collapse"
                    data-target="#navbarNavMenu"
                  >
                    <i className="tio-menu-hamburger" />
                  </button>
                </li>
              </ul>
            </div>
            <NavbarVertical />
          </div>
        </div>
        <ModalInviteUser user={user} show={showInviteUser} handleClose={handleCloseInviteUser}></ModalInviteUser>
      </header>
    </>
  );
};

export default memo(Header);
