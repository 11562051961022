import React, { memo, useCallback } from "react";
import "./csv.scss";

const CsvFilesList = ({ files, setFiles, databasename, isExistingFiles = false, setFilesToDelete, filesToDelete }) => {
  const onClickDelete = useCallback(
    (file) => {
      const index = files.indexOf(file);
      if (index !== -1) {
        files.splice(index, 1);
      }

      setFiles([...files]);

      if (isExistingFiles) {
        setFilesToDelete([...filesToDelete, file]);
      }
    },
    [files]
  );

  const renderExistingFileName = (fileName, parentSheet) => {
    if (!fileName) return databasename;

    if (fileName && parentSheet) {
      return `${fileName?.length > 50 ? `${fileName?.substring(0, 50)}...` : fileName}${
        parentSheet ? ` (Sheet: ${parentSheet})` : ""
      }`;
    }

    return fileName;
  };

  if (isExistingFiles) {
    return (
      <div className="csv-row-container mt-5 mb-3" style={{ maxHeight: "280px" }}>
        <div className="d-flex mb-2">
          <div className="csv-table-header">Current Data</div>
          <div className="csv-table-header">Renamed To</div>
          <div className="csv-table-header action"></div>
        </div>
        <div>
          {files?.map((file, index) => {
            return (
              <div className="d-flex" key={index}>
                <div className="csv-table-data">{renderExistingFileName(file.fileName, file.parentSheet)}</div>
                <div className="csv-table-data">
                  {file.tableName?.length > 50 ? `${file.tableName?.substring(0, 50)}...` : file.tableName}
                </div>
                <div className="csv-table-data action" onClick={() => onClickDelete(file)}>
                  <img className="img-fluid" src="./assets/img/delete-icon.svg" alt="delete file" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="csv-row-container mt-5 mb-3" style={{ maxHeight: "280px" }}>
      <div className="d-flex mb-2">
        <div className="csv-table-header" style={{ width: "calc(100% - 150px)" }}>
          Import
        </div>
        <div className="csv-table-header size">File Size</div>
        <div className="csv-table-header action"></div>
      </div>
      <div>
        {files?.map((file, index) => {
          return (
            <div className="d-flex" key={index}>
              <div className="csv-table-data" style={{ width: "calc(100% - 150px)" }}>
                {file.name.length > 50 ? `${file.name.substring(0, 50)}...` : file.name}
              </div>
              <div className="csv-table-data size">{Math.ceil(file.size / 1000)} kB</div>
              <div className="csv-table-data action" onClick={() => onClickDelete(file)}>
                <img className="img-fluid" src="./assets/img/delete-icon.svg" alt="delete file" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(CsvFilesList);
