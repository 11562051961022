import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class CategoryActions {
  static async create(data) {
    ApiActions.post(
      "/api/v1/categories",
      data,
      Store,
      Constants.CATEGORY_CREATE_NEW,
      Constants.CATEGORY_CREATE_NEW_RESPONSE
    );
  }

  static clearSuccessState() {
    Store.dispatch({
      type: Constants.CATEGORY_CLEAR_SUCCESS_STATE,
    });
  }

  static async update(data) {
    ApiActions.put("/api/v1/categories", data, Store, Constants.CATEGORY_UPDATE, Constants.CATEGORY_UPDATE_RESPONSE);
  }

  static async delete(id) {
    ApiActions.delete(
      `/api/v1/categories/${id}`,
      undefined,
      Store,
      Constants.CATEGORY_DELETE,
      Constants.CATEGORY_DELETE_RESPONSE
    );
  }
}

export default CategoryActions;
