import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";
import AuthService from "../../helpers/auth";

const setDatasource = (err, response, data, id) => {
  if (!err) {
    const user = AuthService.getUser();

    if (user.datasourceList) {
      let index;
      let datasource;

      if (id && user.datasourceList.length > 0) {
        index = user.datasourceList.findIndex((c) => c._id === id);
      }

      if (index > -1) {
        datasource = user.datasourceList[index];
        // update current one
        datasource.tables = data.tables;
        datasource.settings = data.settings;
      } else {
        // new datasource
        datasource = {
          _id: response._id,
          tables: data.tables,
          settings: data.settings,
        };

        user.datasourceList.push(datasource);
      }

      AuthService.setUser(user);
    }
  }
};

const deleteTablesInDatasource = (err, id, tableNamesToDelete) => {
  if (!err) {
    const user = AuthService.getUser();
    const { datasourceList } = user;
    const datasource = datasourceList?.filter((d) => d._id === id.toString()).shift();

    let newTables;

    if (datasource) {
      const tables = datasource.tables;
      if (tables?.length) {
        newTables = tables.map((t) => {
          if (!tableNamesToDelete.includes(t.tableName)) {
            return t;
          }
        });
      }
    }

    user.datasourceList.forEach((d) => {
      if (d._id === id.toString()) {
        d.tables = newTables;
        return;
      }
    });

    AuthService.setUser(user);
  }
};

const deleteDatasource = (err, response, id) => {
  if (!err) {
    const user = AuthService.getUser();
    if (user.datasourceList && user.datasourceList.length > 0 && id) {
      const index = user.datasourceList.findIndex((c) => c._id === id);
      user.datasourceList.splice(index, 1);

      AuthService.setUser(user);
    }
  }
};

class Actions {
  static async getFirstDataSource() {
    ApiActions.get(
      "/api/datasources/first",
      undefined,
      Store,
      Constants.DATASOURCE_GET_FIRST,
      Constants.DATASOURCE_GET_FIRST_RESPONSE
    );
  }

  static async getDataSource(query = {}) {
    ApiActions.get("/api/datasources/my", query, Store, Constants.DATASOURCE_GET, Constants.DATASOURCE_GET_RESPONSE);
  }

  static async getDataSourceById(id) {
    ApiActions.get(
      `/api/v2/datasources/${id}`,
      undefined,
      Store,
      Constants.DATASOURCE_GET_BY_ID,
      Constants.DATASOURCE_GET_BY_ID_RESPONSE
    );
  }

  static async getDataSourceByIdForEditing(id) {
    ApiActions.get(
      `/api/datasources/${id}/edit`,
      undefined,
      Store,
      Constants.DATASOURCE_GET_BY_ID,
      Constants.DATASOURCE_GET_BY_ID_RESPONSE
    );
  }

  static async update(id, data) {
    ApiActions.put(
      `/api/datasources/${id}`,
      data,
      Store,
      Constants.DATASOURCE_SAVE,
      Constants.DATASOURCE_SAVE_RESPONSE
    );
  }

  static async updateTables(id, data) {
    ApiActions.put(
      `/api/datasources/${id}/tables`,
      data,
      Store,
      Constants.DATASOURCE_TABLES_SAVE,
      Constants.DATASOURCE_TABLES_SAVE_RESPONSE,
      (err, response) => {
        setDatasource(err, response, data, id);
      }
    );
  }

  static hideDataSourceCreate() {
    Store.dispatch({
      type: Constants.DATASOURCE_CREATE_HIDE,
    });
  }

  static hideDataSourceGoogleDrive() {
    Store.dispatch({
      type: Constants.DATASOURCE_GOOGLE_DRIVE_HIDE,
    });
  }

  static async createNew(data) {
    ApiActions.post(
      "/api/datasources",
      data.datasource,
      Store,
      Constants.DATASOURCE_CREATE,
      Constants.DATASOURCE_CREATE_RESPONSE,
      (err, response) => {
        setDatasource(err, response, data.datasource);
      }
    );
  }

  static async delete(id) {
    ApiActions.delete(
      `/api/datasources/${id}`,
      undefined,
      Store,
      Constants.DATASOURCE_DELETE,
      Constants.DATASOURCE_DELETE_RESPONSE,
      (err, response) => {
        deleteDatasource(err, response, id);
      }
    );
  }

  static async updateLiveGoogleSheetTokens(id, data) {
    ApiActions.put(
      `/api/datasources/${id}/tokens`,
      data,
      Store,
      Constants.DATASOURCE_UPDATE_LIVE_SHEET_TOKENS,
      Constants.DATASOURCE_UPDATE_LIVE_SHEET_TOKENS_RESPONSE
    );
  }

  static clearLiveSheetTokenRefreshStatus() {
    Store.dispatch({
      type: Constants.DATASOURCE_GOOGLE_DRIVE_HIDE,
    });
  }

  static async updateRestrictToSelect(id, data) {
    ApiActions.put(
      `/api/datasources/${id}/admin-settings`,
      data,
      Store,
      Constants.DATASOURCE_UPDATE_RESTRICT_SELECT,
      Constants.DATASOURCE_UPDATE_RESTRICT_SELECT_RESPONSE
    );
  }

  static clearUpdateRestrictToSelectStatus() {
    Store.dispatch({
      type: Constants.DATASOURCE_CLEAR_UPDATE_RESTRICT_SELECT_STATUS,
    });
  }

  static async deleteTables(id, data) {
    ApiActions.deleteData(
      `/api/datasources/${id}/tables`,
      data,
      Store,
      Constants.DATASOURCE_CSV_TABLES_DELETE,
      Constants.DATASOURCE_CSV_TABLES_DELETE_RESPONSE,
      (err) => {
        deleteTablesInDatasource(err, id, data);
      }
    );
  }

  static clearTableDeleteStatus() {
    Store.dispatch({
      type: Constants.DATASOURCE_CLEAR_CSV_TABLES_DELETE_STATUS,
    });
  }

  static async getFewshots(id, data = {}) {
    ApiActions.post(
      `/api/v1/datasource/${id}/fewshots`,
      data,
      Store,
      Constants.DATASOURCE_GET_FEWSHOTS,
      Constants.DATASOURCE_GET_FEWSHOTS_RESPONSE
    );
  }

  static async getHelperText(id) {
    ApiActions.get(
      `/api/v1/datasource/${id}/helper-text`,
      undefined,
      Store,
      Constants.DATASOURCE_GET_HELPER_TEXT,
      Constants.DATASOURCE_GET_HELPER_TEXT_RESPONSE
    );
  }

  static async getFewshotCategories(id, query = {}) {
    ApiActions.get(
      `/api/v1/datasource/${id}/fewshot-categories`,
      query,
      Store,
      Constants.DATASOURCE_GET_FEWSHOT_CATEGORIES,
      Constants.DATASOURCE_GET_FEWSHOT_CATEGORIES_RESPONSE
    );
  }
}

export default Actions;
