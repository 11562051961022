import React from "react";
import { Modal } from "react-bootstrap";

const ModalDeleteDatasource = ({
  show,
  handleClose,
  handleConfirm,
  deleteDatasourceName,
  isDeleteButtonDisabled,
  setIsDeleteButtonDisabled,
}) => {
  const onChangeDeleteInput = (e) => {
    if (e.target.value === "delete") {
      setIsDeleteButtonDisabled(false);
    } else {
      setIsDeleteButtonDisabled(true);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Data Source</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete <b>{deleteDatasourceName}</b>?
        </p>
        <p>
          All questions, dashboards and public links associated with this source will be deleted as well. This cannot be
          undone!
        </p>
        <p>
          Type <span style={{ color: "#E50000" }}>delete</span> to confirm
        </p>
        <form>
          <div className="js-form-message form-group">
            <input
              type="text"
              className="form-control"
              name="delete"
              placeholder="e.g. delete"
              onChange={onChangeDeleteInput}
              autoComplete="off"
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-primary" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-sm btn-outline-danger" onClick={handleConfirm} disabled={isDeleteButtonDisabled}>
          Delete Forever
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteDatasource;
