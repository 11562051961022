import React from "react";
import AuthService from "../../helpers/auth";

const FooterLogin = () => {
  const isUserLoggedIn = AuthService.isLoggedIn();

  return (
    <div className="text-center py-5">
      <small className="text-cap mb-4">Built by data lovers from</small>
      <div className="w-85 mx-auto mb-5">
        <div className="row justify-content-between">
          <div className="col">
            <img className="img-fluid" src="/assets/svg/brands/fb-grey.png" alt="Facebook Logo" />
          </div>
          <div className="col">
            <img className="img-fluid mt-1" src="/assets/svg/brands/microsoft-logo-Gray.png" alt="Microsoft Logo" />
          </div>
          <div className="col">
            <img className="img-fluid" src="/assets/svg/brands/linkedIn-grey.png" alt="Linkedin Logo" />
          </div>
        </div>
      </div>

      <div className="w-85 mx-auto">
        <div className="row justify-content-between">
          <div className="col">
            <a
              className="text-cap"
              style={{ color: "inherit" }}
              href={isUserLoggedIn ? "/" : "https://getzingdata.com/"}
            >
              Home
            </a>
          </div>
          <div className="col">|</div>
          <div className="col">
            <a className="text-cap" style={{ color: "inherit" }} href="https://docs.getzingdata.com/docs/">
              Documentation
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterLogin;
