import React, { useEffect, useState, useCallback, memo } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { Modal, Button } from "react-bootstrap";
import amplitude from "amplitude-js";
import { datasourceTypes, datasourceExcelAndCsvSteps, queueNames, PricingTiers } from "helpers/constants";
import DatasourceActions from "redux/actions/datasource";
import QueryManagerActions from "redux/actions/query-manager";
import ProfileActions from "redux/actions/profile";
import { capitalize } from "helpers/capitalize";
import Loading from "components/loading";
import Alert from "components/alert";
import FileDropZone from "components/datasource/csv/file-dropzone";
import ProFeature from "components/datasource/pro-feature";
import FilesTable from "components/datasource/csv/files-table";
import SuccessMessage from "components/datasource/success-message";
import AuthService from "../../helpers/auth";
import CsvFilesList from "components/datasource/csv/csv-files-list";

const ModalEditDatasource = ({ datasourceType, show, handleClose, dataToEdit }) => {
  // Amplitude
  const instance = amplitude.getInstance();

  const { user } = AuthService.getUser();
  const userAuth = user?._id || localStorage.getItem("userauth");

  // Intercom
  const { update, trackEvent: intercomTrackEvent } = useIntercom();
  const updateWithProps = () => {
    return update({
      name: user?.roles?.account?.name,
      userId: userAuth,
      updatedAt: new Date(),
      email: user?.email,
    });
  };

  useEffect(() => {
    updateWithProps();
  }, []);

  const { handleSubmit, setValue } = useForm();

  const { accountData } = useSelector((s) => s.account);
  const { first, showSaveSuccess, loading, showSaveTablesSuccess, tableDeleteSuccess } = useSelector(
    (s) => s.datasource
  );

  const {
    loading: loadingQuery,
    loadingCsvUpload,
    loadingCsvJob,
    jobId,
    jobStateDbImporter,
    dbImporterValue,
    type: queueType,
    timeoutErrorDbImporter,
    error,
    loadingCsvImport,
    csvAction,
    timeoutError,
    connectionErr,
    queryResult,
    jobState,
  } = useSelector((s) => s.queryManager);

  let [step, setStep] = useState(0);
  const [type, setType] = useState("");
  const [organization] = useState(dataToEdit?.organization?._id);
  const [alerts, setAlerts] = useState([]);
  const [steps, setSteps] = useState(datasourceExcelAndCsvSteps);
  const [orgTier, setOrgTier] = useState(PricingTiers.BASIC);
  const [tables, setTables] = useState(dataToEdit.tables);

  // csv upload v2
  const [files, setFiles] = useState();
  const [filesToImport, setFilesToImport] = useState(dbImporterValue?.files);
  const [filesToSave, setFilesToSave] = useState(dbImporterValue?.csvUpload?.files);
  const [hasFileLimitError, setHasFileLimitError] = useState(false);
  const [tableNamesForCsvImport, setTableNamesForCsvImport] = useState({});
  const [hasNameDuplicates, setHasNameDuplicates] = useState(false);
  const [existingFiles, setExistingFiles] = useState(dataToEdit.tables);
  const [filesToDelete, setFilesToDelete] = useState([]);

  useEffect(() => {
    const existingFilesInfo = [...existingFiles];
    let uploadedFiles = [];

    // eslint-disable-next-line no-unused-expressions
    dataToEdit?.csv?.uploadInfo?.forEach((upload) => {
      uploadedFiles.unshift([...upload.files]);
    });

    uploadedFiles = uploadedFiles.flat(1);

    const updatedExistingFilesInfo = existingFilesInfo?.map((f) => {
      const file = { ...f };
      const uploadedFile = uploadedFiles.find((upFile) => upFile.tableName === file.tableName);
      if (uploadedFile) {
        file.fileName = uploadedFile.origFilename;
        file.fileId = uploadedFile.fileId;

        if (uploadedFile.parentSheet) {
          file.parentSheet = uploadedFile.parentSheet;
        }
      }
      return file;
    });

    setExistingFiles([...updatedExistingFilesInfo]);
  }, [dataToEdit]);

  useEffect(() => {
    if (!dbImporterValue) {
      setFilesToImport();
      setFilesToSave();
      setHasFileLimitError(false);
      setTableNamesForCsvImport({});
    }

    if (dbImporterValue?.files) {
      setFilesToImport(dbImporterValue.files);
    }

    if (dbImporterValue?.csvUpload?.files) {
      setFilesToSave(dbImporterValue.csvUpload.files?.filter((f) => f.included));

      let tablesToSave = dbImporterValue.csvUpload.files.map((table) => {
        const isTableAlreadyIncluded = tables.filter((t) => t.tableName === table.tableName)?.length;
        if (table.included && table.tableName && !isTableAlreadyIncluded) {
          return {
            tableName: table.tableName,
            schemaName: "public",
            labelName: capitalize(table.tableName),
            included: table.included,
          };
        }
      });

      tablesToSave = tablesToSave.filter(Boolean);

      setTables([...tables, ...tablesToSave]);
    }
  }, [dbImporterValue]);

  useEffect(() => {
    if (dataToEdit?.organization?._id && accountData) {
      const org = accountData?.billingPlans.find((plan) => plan.organizationId === dataToEdit?.organization?._id);
      const orgTier = org?.tier;
      setOrgTier(orgTier);
    }
  }, [dataToEdit, accountData]);

  const setDatasourceType = useCallback(
    (type) => {
      setValue("type", type, { shouldValidate: true });
      setType(type);

      if (type === datasourceTypes.UPLOADED_CSV) {
        setSteps(datasourceExcelAndCsvSteps);
      }
    },
    [setValue]
  );

  useEffect(() => {
    ProfileActions.getDetails(userAuth);
  }, [userAuth]);

  const doStartCsvUpload = (data, e) => {
    e.preventDefault();
    setAlerts([]);

    if (files?.length) {
      const formData = new FormData();
      files.forEach((file) => formData.append("files", file));
      formData.append("newDS", false);
      formData.append("datasource", dataToEdit._id);
      formData.append("organization", dataToEdit.organization?._id);

      if (!accountData || !orgTier || orgTier === PricingTiers.BASIC) {
        QueryManagerActions.startCsvUpload(formData);
      } else {
        QueryManagerActions.startLargeCsvUpload(formData);
      }
    } else if (filesToDelete?.length) {
      const tableNamesToDelete = filesToDelete?.map((t) => t.tableName);
      DatasourceActions.deleteTables(dataToEdit._id, { tables: tableNamesToDelete });
    }
  };

  const onClickNextCsvImportEdit = () => {
    const toImport = Object.entries(tableNamesForCsvImport)?.map((file) => {
      const fileId = file[0];
      const isFileIncluded = filesToImport.filter((f) => f.fileId === fileId && f.included)?.length;

      const tableName = file[1].name?.endsWith(".csv") ? file[1].name?.split(".csv")[0] : file[1];

      if (isFileIncluded) {
        return {
          fileId,
          tableName: tableName,
          mode: file[1].mode || "create",
        };
      }
    });

    const payload = {
      csvUploadId: dbImporterValue?._id,
      files: toImport.filter(Boolean),
    };

    QueryManagerActions.startCsvImport(payload);
  };

  const updateTableCsvImport = () => {
    const tableNamesToDelete = filesToDelete?.map((t) => t.tableName);

    if (tableNamesToDelete?.length) {
      DatasourceActions.deleteTables(dbImporterValue._id, { tables: tableNamesToDelete });
    } else {
      QueryManagerActions.refreshTables({ datasource: dbImporterValue._id });
    }
  };

  const checkErrorsInFilesToSave = useCallback(() => {
    const filesWithErrors = filesToSave?.filter((f) => f.error && f.included);
    if (filesWithErrors?.length) return true;
    return false;
  }, [filesToSave]);

  const uploadCorrectedCsvFile = () => {
    setStep(0);
    setFiles();
  };

  const next = () => {
    setStep(++step);
  };

  const previous = () => {
    setStep(--step);
  };

  const close = () => {
    setSteps(datasourceExcelAndCsvSteps);
    setAlerts([]);
    setTables([]);
    setDatasourceType("");
    setType("");
    setStep(0);
    handleClose();
    setFiles();
    setTableNamesForCsvImport({});
    setHasFileLimitError(false);
    setFilesToSave();
    setFilesToImport();
    setFilesToDelete();

    QueryManagerActions.clearImportState();
  };

  useEffect(() => {
    if (timeoutError || (queryResult !== null && queryResult !== undefined && !Array.isArray(queryResult))) {
      const newAlerts = alerts.concat(
        <Alert key="danger" type="danger" message="Failed to perform action." details={connectionErr} />
      );

      setAlerts(newAlerts);
      instance.logEvent("CSV_TABLE_REFRESH_ERROR", {
        jobId: jobId,
        jobState: jobState,
        error: connectionErr,
      });

      intercomTrackEvent("database-connection-failed", {
        type,
      });
    } else if (jobState === "completed") {
      instance.logEvent("CSV_TABLE_REFRESH_SUCCESS", {
        jobId: jobId,
        jobState: jobState,
      });

      setStep(3);
    }
  }, [jobState, queryResult, timeoutError]);

  useEffect(() => {
    if (type === datasourceTypes.UPLOADED_CSV && csvAction === "upload") {
      if (timeoutErrorDbImporter || error) {
        instance.logEvent("UPDATE_CSV_UPLOAD_ERROR", {
          jobId: jobId,
          jobState: jobStateDbImporter,
          csvAction,
        });

        intercomTrackEvent("database-connection-failed", {
          type,
        });
      } else if (jobStateDbImporter === "completed") {
        instance.logEvent("UPDATE_CSV_UPLOAD_SUCCESS", {
          jobId: jobId,
          jobState: jobStateDbImporter,
          csvAction,
        });

        setStep(1);
      }
    }
  }, [jobStateDbImporter, timeoutErrorDbImporter, dbImporterValue, type, csvAction, jobId]);

  useEffect(() => {
    if (type === datasourceTypes.UPLOADED_CSV && csvAction === "import") {
      if (timeoutErrorDbImporter || error) {
        instance.logEvent("UPDATE_CSV_IMPORT_ERROR", {
          jobId: jobId,
          jobState: jobStateDbImporter,
        });

        intercomTrackEvent("database-connection-failed", {
          type,
          csvAction,
        });
      } else if (jobStateDbImporter === "completed") {
        instance.logEvent("UPDATE_CSV_IMPORT_SUCCESS", {
          jobId: jobId,
          jobState: jobStateDbImporter,
          csvAction,
        });

        setStep(2);
      }
    }
  }, [jobStateDbImporter, timeoutErrorDbImporter, jobId, type, csvAction]);

  useEffect(() => {
    if (jobId && queueType) {
      if (queueType === queueNames.QUERYWORKER) {
        QueryManagerActions.getQueryById(jobId);
      } else if (queueType === queueNames.INGESTION) {
        QueryManagerActions.getQueryIngestorById(jobId);
      } else if (queueType === queueNames.DB_IMPORTER) {
        QueryManagerActions.getQueryDbImporterById(jobId);
      }
    }
  }, [jobId, queueType]);

  useEffect(() => {
    if (showSaveSuccess || showSaveTablesSuccess) {
      next();
      setTables([]);
    }
  }, [showSaveSuccess, showSaveTablesSuccess]);

  useEffect(() => {
    if (tableDeleteSuccess) {
      setStep(3);
      setTables([]);
      QueryManagerActions.refreshTables({ datasource: dataToEdit._id });
      DatasourceActions.clearTableDeleteStatus();
    }
  }, [tableDeleteSuccess]);

  useEffect(() => {
    if (error) {
      const newAlerts = alerts.concat(<Alert key="danger" type="danger" message={error} />);
      setAlerts(newAlerts);
    }
  }, [error]);

  useEffect(() => {
    if (show) {
      DatasourceActions.getFirstDataSource();
      setStep(0);
      setSteps(datasourceExcelAndCsvSteps);
    }
  }, [show]);

  useEffect(() => {
    if (datasourceType) {
      setDatasourceType(datasourceType);
    }
  }, [datasourceType, setDatasourceType]);

  const handleToApp = useCallback(() => {
    instance.logEvent("START_QUERYING_TAPPED");
  }, []);

  const onClickPreviousOnProFeature = () => {
    setHasFileLimitError(false);
  };

  const checkTableNames = () => {
    const toImport = filesToImport.filter((f) => f.included);
    const tablesWithNames = Object.values(tableNamesForCsvImport).filter((t) => t.name);
    if (tableNamesForCsvImport && toImport?.length !== Object.values(tablesWithNames)?.length) {
      return true;
    }

    return false;
  };

  // eslint-disable-next-line no-console
  const onError = (errors) => console.log("onError", errors);

  return (
    <Modal
      size="xl"
      backdrop="static"
      header="Create new"
      keyboard={false}
      show={show}
      onHide={close}
      centered
      style={{ minHeight: "675px" }}
    >
      <div className="modal-header">
        <h5 className="modal-title">Edit Data Source</h5>
        <button type="button" className="btn btn-icon btn-sm btn-ghost-secondary" onClick={close} aria-label="Close">
          <i className="tio-clear tio-lg"></i>
        </button>
      </div>
      <div className="modal-body">
        <form className="js-step-form">
          <ul className="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-5">
            {steps.map((el, i) => (
              <li key={i} className={`step-item ${step === el.step ? " active" : ""}`}>
                <a className="step-content-wrapper">
                  <span className="step-icon step-icon-soft-dark">{el.step + 1}</span>
                  <div className="step-content">
                    <span className="step-title">{el.title}</span>
                  </div>
                </a>
              </li>
            ))}
          </ul>

          {(loading || loadingQuery) && <Loading loading={loading || loadingQuery}></Loading>}

          {(loadingCsvUpload || (loadingCsvJob && csvAction === "upload")) && (
            <Loading
              loading={loadingCsvUpload || (loadingCsvJob && csvAction === "upload")}
              text="Importing Files"
              secondaryText="This could take a moment..."
              showLoadingText={false}
              opacity={1}
            />
          )}

          {(loadingCsvImport || (loadingCsvJob && csvAction === "import")) && (
            <Loading
              loading={(loadingCsvJob && csvAction === "import") || loadingCsvImport}
              text="Completing Import"
              secondaryText="This could take a moment..."
              showLoadingText={false}
              opacity={1}
            />
          )}

          {alerts}

          {step === 0 && (
            <>
              <div>
                <CsvFilesList
                  files={existingFiles}
                  setFiles={setExistingFiles}
                  databasename={dataToEdit?.databasename}
                  isExistingFiles
                  setFilesToDelete={setFilesToDelete}
                  filesToDelete={filesToDelete}
                />
              </div>
              {filesToDelete?.length ? (
                <div className="d-flex my-5 align-items-center">
                  <h5 className="mb-0 text-danger">
                    When you hit &quot;Next&quot;, the following tables will be deleted:
                  </h5>
                  <p className="text-danger ml-2 mb-0" style={{ marginTop: "-2px" }}>
                    {filesToDelete.map((f) => f.tableName).join(", ")}
                  </p>
                </div>
              ) : undefined}
              <div>
                {type === datasourceTypes.UPLOADED_CSV && (
                  <div className="row">
                    <div className="col-sm-12">
                      {organization && (!orgTier || orgTier === PricingTiers.BASIC) && hasFileLimitError ? (
                        <ProFeature
                          organization={organization}
                          setHasFileLimitError={setHasFileLimitError}
                          onClickPreviousOnProFeature={onClickPreviousOnProFeature}
                        />
                      ) : (
                        <>
                          <label className="input-label">Add Data</label>
                          <FileDropZone
                            files={files}
                            tier={orgTier}
                            setFiles={setFiles}
                            setHasFileLimitError={setHasFileLimitError}
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {step === 1 && type === datasourceTypes.UPLOADED_CSV && filesToImport && (
            <FilesTable
              step={1}
              filesToImport={filesToImport}
              setFilesToImport={setFilesToImport}
              setTableNames={setTableNamesForCsvImport}
              tableNames={tableNamesForCsvImport}
              isUpdate
              existingTables={existingFiles}
              setHasNameDuplicates={setHasNameDuplicates}
            />
          )}

          {step === 2 && type === datasourceTypes.UPLOADED_CSV && filesToSave && (
            <div id="createDataSourceStepImportCsv">
              <FilesTable
                step={2}
                filesToSave={filesToSave}
                setFilesToSave={setFilesToSave}
                uploadCorrectedCsvFile={uploadCorrectedCsvFile}
                tables={tables}
                setTables={setTables}
              />
            </div>
          )}

          {step === 3 && type === datasourceTypes.UPLOADED_CSV && (
            <div id="createDataSourceStepSuccessMessage">
              <SuccessMessage
                first={first}
                handleToApp={handleToApp}
                datasourceId={dbImporterValue?._id || dataToEdit?._id}
              />
            </div>
          )}
        </form>
      </div>
      <Modal.Footer
        className={type === datasourceTypes.UPLOADED_CSV ? "justify-content-between" : "justify-content-end"}
      >
        {step === 0 &&
          type === datasourceTypes.UPLOADED_CSV &&
          (!orgTier || orgTier === PricingTiers.BASIC) &&
          !hasFileLimitError && (
            <div className="mb-2">
              <i className="tio-info-outlined" style={{ color: "#1D73C9" }}></i> You are on the free plan and limited to
              one upload at a time.
              <Link to={`/organization/${organization}?upgrade=true`}> Upgrade to Pro</Link> to upload multiple files at
              once.
            </div>
          )}

        {step === 0 && type === datasourceTypes.UPLOADED_CSV && orgTier && orgTier !== PricingTiers.BASIC && (
          <div className="mb-2">
            <i className="tio-info-outlined" style={{ color: "#1D73C9" }}></i> You are on the {orgTier} plan and you can
            upload multiple files at once. 100MB max each.
          </div>
        )}

        {step === 1 && type === datasourceTypes.UPLOADED_CSV && (
          <div className="mb-2">
            <b>* Replace</b> will overwrite your existing tables. This can not be undone.
          </div>
        )}

        <div className="w-100 d-flex justify-content-end">
          <Button variant="default" className="mr-1" onClick={close} disabled={loading || loadingQuery}>
            Close
          </Button>

          {step > 0 && step < 2 && (
            <Button variant="secondary" onClick={previous} className="mr-1">
              Previous
            </Button>
          )}

          {step === 0 && type === datasourceTypes.UPLOADED_CSV && (
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit(doStartCsvUpload, onError)}
              disabled={loading || loadingQuery || (!files && !filesToDelete?.length) || hasFileLimitError || error}
            >
              Next
            </Button>
          )}

          {step === 1 && type === datasourceTypes.UPLOADED_CSV && (
            <Button
              variant="primary"
              type="button"
              onClick={onClickNextCsvImportEdit}
              disabled={
                !tableNamesForCsvImport ||
                !dbImporterValue?._id ||
                !filesToImport ||
                checkTableNames() ||
                error ||
                hasNameDuplicates
              }
            >
              Next
            </Button>
          )}

          {step === 2 && type === datasourceTypes.UPLOADED_CSV && (
            <Button
              variant="primary"
              type="button"
              onClick={updateTableCsvImport}
              disabled={loadingCsvUpload || loadingCsvJob || loadingCsvImport || checkErrorsInFilesToSave()}
            >
              Next
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(ModalEditDatasource);
