import React, { useState, useRef, useCallback, useEffect, memo } from "react";
import ReactFlow, { ReactFlowProvider, Controls, useEdges } from "reactflow";
import classNames from "classnames";
import { updateHandleColorsOnNode, updateNodeToEdgeArray } from "helpers/joins";
import joinCanvasStyles from "../datasource.module.scss";

let nodeId = 0;

const ViewJoin = ({
  nodeTypes,
  edgeTypes,
  googleBigQuery,
  tables,
  nodes,
  edges,
  setNodes,
  setEdges,
  onNodesChange,
  onEdgesChange,
  setIncludedColumns,
  selectedJoin,
  hasColumns,
}) => {
  let timerForError;
  const reactFlowWrapper = useRef(null);

  const [, setReactFlowInstance] = useState(null);
  const [handleColors, setHandleColors] = useState(new Map());
  const [highlightCanvas] = useState(false);
  const [nodeToEdgeArray, setNodeToEdgeArray] = useState([]);

  const setNewHandleColorsForEdge = useCallback(
    (updatedHandleColors, source, sourceHandle, target, targetHandle, color) => {
      const sourceTableColors = updatedHandleColors.get(source) || [];
      const colorForSourceColumn = sourceTableColors?.filter((c) => c.columnId === sourceHandle).shift();
      if (!colorForSourceColumn || colorForSourceColumn?.type !== "right") {
        sourceTableColors.push({ columnId: sourceHandle, type: "right", color });
      }
      const targetTableColors = updatedHandleColors.get(target) || [];
      const colorForTargetColumn = targetTableColors?.filter((c) => c.columnId === targetHandle).shift();
      if (!colorForTargetColumn || colorForTargetColumn?.type !== "left") {
        targetTableColors.push({ columnId: targetHandle, type: "left", color });
      }
      updatedHandleColors.set(source, sourceTableColors);
      updatedHandleColors.set(target, targetTableColors);
    },
    []
  );

  // set initial nodes
  useEffect(() => {
    const includedMap = new Map();
    if (hasColumns) {
      tables.forEach((t) => {
        const cols = t.columnSchema?.map((col) => col.columnName);
        includedMap.set(t.tableName, cols);
      });
    }

    const initialNodes = [];
    const updatedIncludedColumns = new Map(includedMap);

    // eslint-disable-next-line no-unused-expressions
    selectedJoin?.tables?.map((t) => {
      updatedIncludedColumns.set(t.tableName, t.includedColumns);
      setIncludedColumns(updatedIncludedColumns);
    });

    // eslint-disable-next-line no-unused-expressions
    selectedJoin?.tables?.map((t) => {
      const tableFromDatasource = tables.filter((table) => table.tableName === t.tableName).shift();
      const table = {
        tableName: t.tableName,
        schemaName: t.schemaName,
        columnSchema: tableFromDatasource.columnSchema,
        labelName: tableFromDatasource.labelName,
        included: tableFromDatasource.included,
      };

      const node = {
        id: t.nodeId,
        table,
        googleBigQuery,
        type: "tableNode",
        position: t.position,
        data: {
          type: "tableNode",
          isCollapsed: false,
          id: t.nodeId,
          includedColumns: updatedIncludedColumns,
          isColumnsCollapsed: t.isCollapsed,
          table,
          useEdges,
          isRoot: t.isRoot,
          index: t.index,
        },
      };

      const lastNodeId = t.nodeId.split("_").pop();
      if (nodeId < lastNodeId) {
        nodeId = lastNodeId;
      }

      initialNodes.push(node);
    });

    setNodes(initialNodes);
  }, [selectedJoin, tables, hasColumns]);

  // set initial edges
  useEffect(() => {
    const initialEdges = [];
    const updatedHandleColors = new Map(handleColors);

    // eslint-disable-next-line no-unused-expressions
    selectedJoin?.connections?.map((c) => {
      // set handle colors
      setNewHandleColorsForEdge(
        updatedHandleColors,
        c.source,
        c.sourceHandle,
        c.target,
        c.targetHandle,
        c.style.stroke
      );

      const edge = {
        id: c.edgeId,
        type: "tableEdge",
        source: c.source,
        sourceHandle: c.sourceHandle,
        target: c.target,
        targetHandle: c.targetHandle,
        style: c.style,
        data: {
          // deleteEdgeById,
          joinType: c.joinType,
          setEdges,
          handleColors: {},
          nodeToEdgeArray,
          source: c.source,
          target: c.target,
          useEdges,
        },
      };

      initialEdges.push(edge);

      setHandleColors(updatedHandleColors);
    });

    setEdges(initialEdges);

    initialEdges.map((e) => {
      updateNodeToEdgeArray({
        array: nodeToEdgeArray,
        source: e.source,
        target: e.target,
        joinType: e.data.joinType,
        setNodeToEdgeArray,
        setEdges,
        edgeId: e.id,
      });
    });
  }, [selectedJoin]);

  // set initial handle colors to nodes and edges
  useEffect(() => {
    updateHandleColorsOnNode(handleColors, undefined, setNodes, setEdges);
  }, [handleColors]);

  useEffect(() => {
    return () => clearTimeout(timerForError);
  }, []);

  return (
    <div className="d-flex flex-column">
      <div className={classNames("col-12", joinCanvasStyles.zJoins)}>
        <ReactFlowProvider>
          <div className={classNames("col-12 col-lg-9", joinCanvasStyles.reactflowWrapper)} ref={reactFlowWrapper}>
            <ReactFlow
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onInit={setReactFlowInstance}
              defaultViewport={{ x: 0, y: 0, zoom: 2 }}
              className={classNames(joinCanvasStyles["canvas"], {
                [joinCanvasStyles["react-flow"]]: !nodes?.length,
                [joinCanvasStyles.highlight]: highlightCanvas,
              })}
              fitView
            >
              <Controls />
            </ReactFlow>
          </div>
        </ReactFlowProvider>
      </div>
    </div>
  );
};

export default memo(ViewJoin);
