import React, { memo, useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Button, Card, Form, Table } from "react-bootstrap";
import DatasourceActions from "redux/actions/datasource";
import HelperTextActions from "redux/actions/helper-text";
import Loading from "components/loading";
import ModalAddOrEditHelperText from "components/modal/modal-add-edit-helper-text";
import ModalDeleteHelperText from "components/modal/modal-delete-helper-text";
import fewshotStyles from "./datasource.module.scss";

const NlqHelperText = ({ datasource }) => {
  const { helperTexts, loading } = useSelector((s) => s.datasource);
  const { showDeleteSuccess } = useSelector((s) => s.helperText);

  const [showModal, setShowModal] = useState(false);
  const [selectedHelperText, setSelectedHelperText] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [helperTextToDelete, setHelperTextToDelete] = useState();

  const fetchHelperText = () => {
    DatasourceActions.getHelperText(datasource._id);
  };

  useEffect(() => {
    if (datasource._id) {
      fetchHelperText();
    }
  }, [datasource]);

  const handleCloseModal = (data) => {
    const { shouldRefreshData } = data || {};
    setShowModal(false);
    setShowDeleteModal(false);
    setSelectedHelperText();
    setHelperTextToDelete();

    if (shouldRefreshData) {
      fetchHelperText();
    }
  };

  useEffect(() => {
    handleCloseModal({ shouldRefreshData: true });
    HelperTextActions.clearFewshotSuccessState();
  }, [showDeleteSuccess]);

  const onClickEdit = (e, helperText) => {
    e.preventDefault();
    setSelectedHelperText(helperText);
    setShowModal(true);
  };

  const onClickDelete = (e, helperText) => {
    e.preventDefault();
    setHelperTextToDelete(helperText);
    setShowDeleteModal(true);
  };

  const onClickDeleteConfirm = () => {
    HelperTextActions.delete(helperTextToDelete?._id);
  };

  return (
    <Card className={fewshotStyles.fewshots}>
      <Card.Header className="d-flex justify-content-between">
        <div className="text-dark">
          <i className={classNames("tio-info-outlined", fewshotStyles.infoIcon)} /> Helper text would guide the users
          about the type of natural language questions that can be asked on a datasource.
        </div>
        <Button variant="primary" onClick={() => setShowModal(true)}>
          <i className="tio-add" /> Add New Helper Text
        </Button>
      </Card.Header>
      <Card.Body>
        <Loading loading={loading}></Loading>
        <Form>
          <Table responsive striped>
            {helperTexts?.length ? (
              <thead>
                <tr>
                  <th className="border-0 text-dark">#</th>
                  <th className="border-0 text-dark">Helper Text</th>
                </tr>
              </thead>
            ) : (
              <></>
            )}
            {helperTexts?.length ? (
              <tbody>
                {helperTexts.map((helperText, index) => {
                  return (
                    <tr key={helperText._id}>
                      <td>{index + 1}</td>
                      <td>{helperText.text}</td>
                      <td className="d-flex justify-content-end border-bottom-0">
                        <button
                          className="btn btn-sm btn-outline-secondary"
                          onClick={(e) => onClickEdit(e, helperText)}
                        >
                          <i className="tio-edit"></i>
                        </button>
                        <button
                          className="btn btn-sm btn-outline-danger ml-2"
                          onClick={(e) => onClickDelete(e, helperText)}
                        >
                          <i className="tio-delete"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <></>
            )}
          </Table>
        </Form>
        {!loading && !helperTexts?.length ? (
          <span>Datasource doesn&apos;t have any helper text for natural language questions.</span>
        ) : (
          <></>
        )}
      </Card.Body>
      {showModal && (
        <ModalAddOrEditHelperText
          show={showModal}
          handleClose={handleCloseModal}
          selectedHelperText={selectedHelperText}
          datasourceId={datasource?._id}
        />
      )}
      {showDeleteModal && (
        <ModalDeleteHelperText
          show={showDeleteModal}
          handleClose={handleCloseModal}
          onClickDeleteConfirm={onClickDeleteConfirm}
          helperTextToDelete={helperTextToDelete}
        />
      )}
    </Card>
  );
};

export default memo(NlqHelperText);
