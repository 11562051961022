import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import FewshotActions from "redux/actions/fewshot";
import { fewshotTypes } from "helpers/constants";
import Alert from "components/alert";
import Loading from "components/loading";
import SqlInput from "components/datasource/examples/highlighted-text-area";
import DynamicFieldsTable from "components/datasource/examples/dynamic-fields-table";
import FieldBadge from "components/datasource/examples/field-badge";

const ModalAddOrEditNlqExample = ({ show, handleClose, selectedFewshot, datasource, categories, onClickEditList }) => {
  const [prompt, setPrompt] = useState(selectedFewshot?.prompt || "");
  const [response, setResponse] = useState(selectedFewshot?.response || "");
  const [type, setType] = useState(selectedFewshot?.type || "regular");
  const [analysisName, setAnalysisName] = useState(selectedFewshot?.analysisName || "");
  const [field, setField] = useState("");
  const [dynamicFields, setDynamicFields] = useState(selectedFewshot?.dynamicFields || []);
  const [alerts, setAlerts] = useState([]);
  const [aliases, setAliases] = useState(selectedFewshot?.aliases || []);
  const [alias, setAlias] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(
    selectedFewshot?.categories?.map((c) => ({ id: c._id, label: c.name, value: c.name })) || []
  );

  useEffect(() => {
    if (Array.isArray(categories)) {
      const options = categories?.map((c) => ({ label: c.name, value: c.name, id: c._id }));
      setCategoryOptions(options);
    }
  }, [categories]);

  const { loading, error, showSaveSuccess, showUpdateSuccess } = useSelector((s) => s.fewshot);

  useEffect(() => {
    if (type === "regular" || type === "exactmatch") {
      if (!prompt || !response) {
        setIsSaveDisabled(true);
      } else {
        setIsSaveDisabled(false);
      }
    } else if (type === "analysis") {
      const areDynamicFieldsValid = dynamicFields?.every((obj) => obj.name && obj.table && obj.column);

      if (!response || !areDynamicFieldsValid || !analysisName) {
        setIsSaveDisabled(true);
      } else {
        setIsSaveDisabled(false);
      }
    }
  }, [type, prompt, response, dynamicFields, analysisName]);

  const close = () => {
    setAlerts([]);
    handleClose();
  };

  useEffect(() => {
    if (error) {
      setAlerts([<Alert key="danger" type="danger" message={error} />]);
    }
  }, [error]);

  useEffect(() => {
    setAlerts([]);
  }, [show]);

  useEffect(() => {
    if (showSaveSuccess || showUpdateSuccess) {
      handleClose({ shouldRefreshData: true });
      FewshotActions.clearFewshotSuccessState();
    }
  }, [showSaveSuccess, showUpdateSuccess]);

  const onChange = ({ name, value }) => {
    switch (name) {
      case "prompt":
        setPrompt(value);
        break;
      case "response":
        setResponse(value);
        break;
      case "analysisName":
        setAnalysisName(value);
        break;
      case "field":
        setField(value);
        break;
      case "alias":
        setAlias(value);
        break;
      default:
        break;
    }
  };

  const onClickSave = () => {
    setAlerts([]);

    FewshotActions.create({
      datasourceId: datasource?._id,
      prompt: prompt?.trim(),
      response: response?.trim(),
      type,
      categories: selectedCategories?.map((c) => c.id),
      ...(type === "analysis" ? { dynamicFields: dynamicFields, analysisName: analysisName, aliases: aliases } : {}),
    });
  };

  const onClickUpdate = () => {
    setAlerts([]);

    FewshotActions.update(selectedFewshot?._id, {
      prompt: prompt?.trim(),
      response: response?.trim(),
      type,
      categories: selectedCategories?.map((c) => c.id),
      ...(type === "analysis" ? { dynamicFields: dynamicFields, analysisName: analysisName, aliases: aliases } : {}),
    });
  };

  const handleTypeChange = (event) => {
    if (event) {
      setType(event.value);
    } else {
      setType("");
    }
  };

  const addDynamicField = () => {
    const existingFields = [...dynamicFields];
    const isExisting = existingFields.filter((f) => f.name === field)?.pop();
    if (!isExisting) {
      existingFields.push({ name: field, required: true, multiple: true });
      setDynamicFields(existingFields);
      setField("");
    } else {
      setAlerts([<Alert key="danger" type="danger" message="Field is already added." />]);
    }
  };

  const addAliasName = () => {
    const existingAliases = [...aliases];
    if (!existingAliases.includes(alias)) {
      existingAliases.push(alias);
      setAliases(existingAliases);
      setAlias("");
    } else {
      setAlerts([<Alert key="danger" type="danger" message="Alias is already added." />]);
    }
  };

  const handleCategoryChange = (categories) => {
    setSelectedCategories(categories);
  };

  return (
    <Modal backdrop="static" size="xl" show={show} onHide={handleClose} keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>{selectedFewshot ? "Edit Example" : "Add Example"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading}></Loading>
        {alerts}
        <Form>
          <div className="d-flex">
            <Form.Group className="d-flex flex-column w-49 mb-3 mr-4">
              <Form.Label className="fw-semiboldlight">Example Type</Form.Label>
              <Select
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "rgba(140, 152, 164, 0.25)" : "rgba(231, 234, 243, 0.7)",
                    boxShadow: state.isFocused ? "0 0 1rem 0 rgba(140, 152, 164, 0.25)" : "",
                  }),
                }}
                className="w-100"
                classNamePrefix="select"
                options={fewshotTypes}
                value={fewshotTypes.find((c) => c.value === type)}
                defaultValue={fewshotTypes[0]}
                onChange={handleTypeChange}
              />
            </Form.Group>
            <Form.Group className="d-flex flex-column w-49 mb-3">
              <Form.Label className="fw-semiboldlight">
                <span className="d-flex justify-content-between">
                  <span>Categories</span>
                  <button type="button" className="btn btn-link p-0" onClick={onClickEditList}>
                    Edit List
                  </button>
                </span>
              </Form.Label>
              <Select
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "rgba(140, 152, 164, 0.25)" : "rgba(231, 234, 243, 0.7)",
                    boxShadow: state.isFocused ? "0 0 1rem 0 rgba(140, 152, 164, 0.25)" : "",
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: "#E6F2FD",
                  }),
                  multiValueLabel: (base) => ({
                    ...base,
                    color: "#092D53",
                  }),
                  multiValueRemove: (base) => ({
                    ...base,
                    ":hover": {
                      color: "black",
                      cursor: "pointer",
                    },
                  }),
                }}
                isMulti
                isSearchable
                isClearable
                className="w-100"
                classNamePrefix="multi-select"
                options={categoryOptions}
                value={selectedCategories}
                onChange={handleCategoryChange}
                isDisabled={!categories?.length}
                placeholder={categories?.length ? "Select category..." : "No categories"}
              />
            </Form.Group>
          </div>
          {type === "analysis" ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label className="fw-semiboldlight">Analysis Name</Form.Label>
                <Form.Control
                  type="text"
                  name="analysisName"
                  placeholder="Enter analysis name..."
                  aria-label="Enter analysis name"
                  value={analysisName}
                  onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="fw-semiboldlight">Analysis Name Aliases (also known as)</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Enter alias name..."
                    aria-label="Enter alias name"
                    name="alias"
                    value={alias}
                    onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.target.value) {
                        e.preventDefault();
                        addAliasName();
                      }
                    }}
                  />
                  <Button variant="primary" onClick={addAliasName} disabled={!alias}>
                    Add
                  </Button>
                </InputGroup>
                {aliases?.length ? (
                  <div className="d-flex flex-wrap">
                    {aliases?.map((a, index) => (
                      <h3 key={index} className="mr-2">
                        <FieldBadge field={a} setFields={setAliases} fields={aliases} />
                      </h3>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="fw-semiboldlight">Dynamic Fields</Form.Label>
                {dynamicFields?.length ? (
                  <span>
                    <i className="tio-info-outlined" style={{ color: "#1D73C9", marginLeft: "0.5rem" }} /> Dynamic
                    fields need to be linked to a table and column
                  </span>
                ) : (
                  <></>
                )}
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Enter dynamic field..."
                    aria-label="Enter dynamic field"
                    name="field"
                    value={field}
                    onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.target.value) {
                        e.preventDefault();
                        addDynamicField();
                      }
                    }}
                  />
                  <Button variant="primary" onClick={addDynamicField} disabled={!field}>
                    Add
                  </Button>
                </InputGroup>
                {dynamicFields?.length ? (
                  <DynamicFieldsTable
                    dynamicFields={dynamicFields}
                    setDynamicFields={setDynamicFields}
                    tables={datasource?.tables}
                  />
                ) : (
                  <></>
                )}
              </Form.Group>
            </>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label className="fw-semiboldlight">Question Text</Form.Label>
              <Form.Control
                type="text"
                name="prompt"
                placeholder="Enter question text..."
                aria-label="Enter question text"
                value={prompt}
                onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
              />
            </Form.Group>
          )}
          <SqlInput
            label="SQL"
            className="mb-3"
            value={response}
            setValue={setResponse}
            highlightWords={dynamicFields?.map((f) => f.name)}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={selectedFewshot ? onClickUpdate : onClickSave}
          disabled={isSaveDisabled}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddOrEditNlqExample;
