import React, { memo } from "react";
import SetupHelpBox from "components/datasource/setup-help-box";
import { datasourceSetupHelp } from "helpers/constants";

const HelpSection = () => {
  return (
    <div className="help-section container-fluid mt-5 d-flex flex-wrap justify-content-center px-0">
      {datasourceSetupHelp.map((h) => (
        <div key={h.title} className="col-12 col-sm-6 col-md-4">
          <SetupHelpBox title={h.title} description={h.description} buttonText={h.buttonText} type={h.type} id={h.id} />
        </div>
      ))}
    </div>
  );
};

export default memo(HelpSection);
