import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class UserActions {
  static async getUserRole(orgId) {
    ApiActions.get(
      `/api/users/organization/${orgId}/is-admin`,
      undefined,
      Store,
      Constants.USER_IS_ADMIN,
      Constants.USER_IS_ADMIN_RESPONSE
    );
  }
}

export default UserActions;
