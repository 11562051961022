import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Alert from "components/alert";
import Loading from "components/loading";
import OrganizationActions from "redux/actions/organization";

const schema = yup.object().shape({
  name: yup.string().max(100, "Name cannot be longer than 100 characters").required(),
});

const ModalAddOrganization = ({ show, handleClose }) => {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  // eslint-disable-next-line no-console
  const onError = (errors, e) => console.log("onError", errors, e);

  const { loading, error, showSaveSuccess } = useSelector((s) => s.organization);

  const [alerts, setAlerts] = useState([]);

  const save = (data, e) => {
    e.preventDefault();
    OrganizationActions.create({ ...data });
  };

  const close = () => {
    setAlerts([]);
    handleClose();
  };

  useEffect(() => {
    if (error) {
      const newAlerts = alerts.concat(<Alert key="danger" type="danger" message={error} />);

      setAlerts(newAlerts);
    }
  }, [error]);

  useEffect(() => {
    setAlerts([]);
  }, [show]);

  return (
    <Modal backdrop="static" show={show} onHide={handleClose} keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading}></Loading>

        <div style={showSaveSuccess ? { display: "none" } : {}}>
          {alerts}

          <form>
            <div className="js-form-message form-group">
              <label className="input-label" htmlFor="name">
                Name
              </label>

              <input
                type="text"
                className={"form-control " + (errors.name ? " is-invalid" : "")}
                name="name"
                placeholder="Organization name"
                aria-label="Organization name"
                ref={register}
              />
              <div className="invalid-feedback">{errors.name?.message}</div>
            </div>
          </form>
        </div>

        <div style={showSaveSuccess ? {} : { display: "none" }}>
          <div className="text-center">
            <img
              className="img-fluid mb-3"
              src="./assets/svg/illustrations/create.svg"
              alt="Image Description"
              style={{ maxWidth: "15rem" }}
            />
            <div className="mb-4">
              <h2>Success!</h2>
              <p>Organization created successfully.</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button
          type="submit"
          style={showSaveSuccess ? { display: "none" } : {}}
          variant="primary"
          onClick={handleSubmit(save, onError)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddOrganization;
