import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Footer from "components/footer/footermain";
import Header from "components/navbar/header";
import ModalDataSource from "components/modal/modal-create-datasource";
import { useSelector } from "react-redux";
import Actions from "redux/actions/datasource";
import OrganizationActions from "redux/actions/organization";
import AuthService from "../../helpers/auth";
import { DEMO_DATABASE } from "helpers/constants";
import amplitude from "amplitude-js";
import DatasourceTypesSection from "./datasource-types-section";
import HelpSection from "./help-section";
import "./dashboard.scss";

const DashboardPage = () => {
  const user = useSelector((s) => s.login.user);
  const organizationsByRole = useSelector((s) => s.organization.organizationsByRole);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  // initialize amplitude instance
  const instance = amplitude.getInstance();

  const [show, setShow] = useState(false);
  const [hasCustomDatasource, setHasCustomDatasource] = useState(false);
  const [type, setType] = useState(null);

  const { datasourceList } = AuthService.getUser();

  useEffect(() => {
    OrganizationActions.getOrganizationsByRole("admin");
  }, []);

  const handleClose = () => {
    setShow(false);
    setType(null);
    Actions.hideDataSourceCreate();
  };

  const handleShow = (type) => {
    setType(type);
    setShow(true);

    instance.logEvent("DATASOURCE_CREATE_TAPPED", {
      datasource_type: type,
      clicked_from: "/dashboard",
    });
  };

  useEffect(() => {
    if (datasourceList?.length > 1) {
      setHasCustomDatasource(true);
    } else if (datasourceList?.length === 1) {
      if (
        datasourceList[0].host === DEMO_DATABASE.host &&
        datasourceList[0].port === DEMO_DATABASE.port &&
        datasourceList[0].type === DEMO_DATABASE.type &&
        datasourceList[0].databasename === DEMO_DATABASE.databasename
      ) {
        setHasCustomDatasource(false);
      } else {
        setHasCustomDatasource(true);
      }
    }
  }, [datasourceList]);

  useEffect(() => {
    window.dashboardOnReady();
    const origBodyClass = document.body.className;
    document.body.className = "footer-offset";

    return () => {
      document.body.className = origBodyClass;
    };
  }, []);

  return (
    <>
      <Header user={user} />
      <main id="content" role="main" className="main" style={isMobile ? { paddingTop: "7.75rem" } : {}}>
        <div className="content container container-fluid">
          <div className="row justify-content-lg-center">
            <div className="col-lg-12 p-md-0">
              {!hasCustomDatasource && (
                <div className="alert alert-soft-dark alert-dismissible fade show mb-0" role="alert">
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">
                      <i className="tio-clear tio-lg" />
                    </span>
                  </button>
                  <div className="media align-items-center">
                    <img
                      className="avatar avatar-xl mr-3"
                      src="./assets/svg/illustrations/yelling-reverse.svg"
                      alt="Welcome"
                    />
                    <div className="media-body">
                      <h3 className="alert-heading mb-1">Welcome!</h3>
                      <p className="mb-0">
                        Get started by connecting a data source, or head straight to Zing App and explore the sample
                        data set.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <DatasourceTypesSection handleShow={handleShow} />
            <HelpSection />
          </div>
        </div>
        {show && (
          <ModalDataSource
            datasourceType={type}
            show={show}
            handleClose={handleClose}
            organizationsByRole={organizationsByRole}
          />
        )}
        <Footer />
      </main>
    </>
  );
};

export default DashboardPage;
