import React, { useEffect, useState, useRef } from "react";
import Loading from "components/loading";
import { ListGroup } from "react-bootstrap";
import { debounce } from "lodash";

const DataSourceTables = ({ tables, handleInclude, includeAll, handleIncludeAll }) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const debouncedSearch = useRef(
    debounce(async (criteria, tables) => {
      setLoading(true);

      if (criteria) {
        const criteriaLowerCase = criteria.toLowerCase();

        for (const table of tables) {
          table.show = table.labelName.toLowerCase().includes(criteriaLowerCase);
        }
      } else {
        tables.forEach((table) => {
          table.show = true;
        });
      }

      setLoading(false);
    }, 400)
  ).current;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value, tables);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className={"form-control"}
                name="search"
                id="search"
                onChange={handleSearchChange}
                value={search}
                placeholder="Search by name"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <label className="row form-group toggle-switch" htmlFor="includeAllRadio">
              <span className="col-8 col-sm-9 toggle-switch-content ml-0">
                <span className="d-block text-dark">Include All</span>
              </span>
              <span className="col-4 col-sm-3">
                <input
                  type="checkbox"
                  name="includeAllRadio"
                  className="toggle-switch-input"
                  checked={includeAll}
                  onChange={(event) => handleIncludeAll(event)}
                  id="includeAllRadio"
                />
                <span className="toggle-switch-label ml-auto">
                  <span className="toggle-switch-indicator"></span>
                </span>
              </span>
            </label>
          </div>
        </div>

        <div
          className="row"
          style={{
            maxHeight: "400px",
            display: "block",
            overflowY: "auto",
          }}
        >
          <div className="col-sm-12">
            <Loading loading={loading}></Loading>

            <ListGroup className="" variant="flush">
              {!tables || tables.length === 0
                ? ""
                : tables.map((table, index) =>
                    table.show ? (
                      <ListGroup.Item key={table?.tableName} value={table?.tableName}>
                        <label className="row mb-0 form-group toggle-switch" htmlFor={`tableRadio${index}`}>
                          <span className="col-8 col-sm-9 toggle-switch-content ml-0">
                            <span className="d-block text-dark">{table.labelName}</span>
                            <small>{table.schemaName}</small>
                          </span>
                          <span className="col-4 col-sm-3">
                            <input
                              type="checkbox"
                              name={`tableRadio${index}`}
                              className="toggle-switch-input"
                              checked={table.included}
                              onChange={(event) => handleInclude(event, index)}
                              id={`tableRadio${index}`}
                            />
                            <span className="toggle-switch-label ml-auto">
                              <span className="toggle-switch-indicator"></span>
                            </span>
                          </span>
                        </label>
                      </ListGroup.Item>
                    ) : (
                      ""
                    )
                  )}
            </ListGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataSourceTables;
