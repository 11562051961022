import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class AccountActions {
  static async getAccountTier() {
    ApiActions.get(
      "/api/accounts/my/plans",
      undefined,
      Store,
      Constants.ACCOUNTS_PLANS_GET,
      Constants.ACCOUNTS_PLANS_GET_RESPONSE
    );
  }

  static async upgradeOrganization(data) {
    ApiActions.post(
      "/api/accounts/upgrade",
      data,
      Store,
      Constants.ACCOUNTS_UPGRADE_ORG,
      Constants.ACCOUNTS_UPGRADE_ORG_RESPONSE,
      (err, response) => {
        if (!err && response?.redirectUrl) {
          window.location = `${response.redirectUrl}`;
        }
      }
    );
  }

  static async manageBilling() {
    ApiActions.post(
      "/api/accounts/billing",
      undefined,
      Store,
      Constants.ACCOUNTS_BILLING,
      Constants.ACCOUNTS_BILLING_RESPONSE,
      (err, response) => {
        if (!err && response?.redirectUrl) {
          window.location = `${response.redirectUrl}`;
        }
      }
    );
  }

  static clearUpgradeState() {
    Store.dispatch({
      type: Constants.ACCOUNTS_UPGRADE_CLEAR_STATE,
    });
  }

  static async getAdminsOfAllOrgsInAccount() {
    ApiActions.get(
      "/api/accounts/organization/admin",
      undefined,
      Store,
      Constants.ACCOUNTS_GET_ADMINS_OF_ALL_ORGS,
      Constants.ACCOUNTS_GET_ADMINS_OF_ALL_ORGS_RESPONSE
    );
  }

  static async transferOwnership(data) {
    ApiActions.put(
      `/api/accounts/transfer-ownership`,
      data,
      Store,
      Constants.ACCOUNT_TRANSFER_OWNERSHIP,
      Constants.ACCOUNT_TRANSFER_OWNERSHIP_RESPONSE
    );
  }
}

export default AccountActions;
