import React, { useState, useRef, useCallback, useEffect, memo } from "react";
import ReactFlow, { ReactFlowProvider, addEdge, Controls, getConnectedEdges, useEdges } from "reactflow";
import classNames from "classnames";
import Alert from "components/alert";
import { stringTypes, numericTypes } from "helpers/constants";
import {
  getColumnType,
  deleteHandleColors,
  updateHandleColorsOnNode,
  updateNodeToEdgeArray,
  updateRootNode,
  deleteEdgeFromNodeToEdgeArray,
} from "helpers/joins";
import TablesSidebar from "./tables-sidebar";
import { edgeColors } from "./edgeColors";
import joinCanvasStyles from "../datasource.module.scss";

let nodeId = 0;
let startIndex = 0;
const getId = () => `node_${++nodeId}`;

const EditJoin = ({
  nodeTypes,
  edgeTypes,
  googleBigQuery,
  tables,
  nodes,
  edges,
  setNodes,
  setEdges,
  onNodesChange,
  onEdgesChange,
  includedColumns,
  setIncludedColumns,
  selectedJoin,
  hasColumns,
}) => {
  let timerForError;
  const reactFlowWrapper = useRef(null);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [showConnectionError, setShowConnectionError] = useState(false);
  const [isColumnsCollapsed, setIsColumnsCollapsed] = useState(false);
  const [handleColors, setHandleColors] = useState(new Map());
  const [highlightCanvas, setHighlightCanvas] = useState(false);
  const [nodeToEdgeArray, setNodeToEdgeArray] = useState([]);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    setHighlightCanvas(true);
  };

  const deleteNodeById = (id, edges, colors) => {
    let edgeIdsToDelete;
    let edgesToDelete;

    setNodes((nds) => {
      const nodeToDelete = nds.filter((node) => node.id === id);
      edgesToDelete = getConnectedEdges(nodeToDelete, edges);
      edgeIdsToDelete = edgesToDelete?.map((e) => e.id);

      const updatedNodeId = updateRootNode(nodeToDelete.shift(), setNodes);

      if (updatedNodeId === 0) {
        nodeId = updatedNodeId;
      }

      deleteHandleColors(edgesToDelete, colors, setHandleColors, setNodes, setEdges);

      if (edgeIdsToDelete?.length) {
        deleteEdgeFromNodeToEdgeArray({ edgesToDelete, setNodeToEdgeArray, setEdges });
      }

      return nds.filter((node) => node.id !== id);
    });

    setEdges((eds) => [...eds.filter((edge) => !edgeIdsToDelete.includes(edge.id))]);
  };

  const deleteEdgeById = (id) => {
    let edgesToDelete;
    setEdges((eds) => {
      edgesToDelete = eds.filter((edge) => edge.id === id);
      deleteHandleColors(edgesToDelete, eds[0].data.handleColors, setHandleColors, setNodes, setEdges);
      deleteEdgeFromNodeToEdgeArray({ edgesToDelete, setNodeToEdgeArray, setEdges });
      return eds.filter((edge) => edge.id !== id);
    });
  };

  const setNewHandleColorsForEdge = useCallback(
    (updatedHandleColors, source, sourceHandle, target, targetHandle, color) => {
      const sourceTableColors = updatedHandleColors.get(source) || [];
      const colorForSourceColumn = sourceTableColors?.filter((c) => c.columnId === sourceHandle).shift();
      if (!colorForSourceColumn || colorForSourceColumn?.type !== "right") {
        sourceTableColors.push({ columnId: sourceHandle, type: "right", color });
      }
      const targetTableColors = updatedHandleColors.get(target) || [];
      const colorForTargetColumn = targetTableColors?.filter((c) => c.columnId === targetHandle).shift();
      if (!colorForTargetColumn || colorForTargetColumn?.type !== "left") {
        targetTableColors.push({ columnId: targetHandle, type: "left", color });
      }
      updatedHandleColors.set(source, sourceTableColors);
      updatedHandleColors.set(target, targetTableColors);
    },
    []
  );

  const onConnectEdge = useCallback(
    (params) => {
      let color;
      for (let i = startIndex; i < edgeColors.length + startIndex; i++) {
        if (edgeColors[i % edgeColors.length]) {
          color = edgeColors[i % edgeColors.length];
          startIndex++;
          break;
        }
      }

      // set handle colors
      const updatedHandleColors = new Map(handleColors);
      setNewHandleColorsForEdge(
        updatedHandleColors,
        params.source,
        params.sourceHandle,
        params.target,
        params.targetHandle,
        color
      );

      updateHandleColorsOnNode(updatedHandleColors, setHandleColors, setNodes, setEdges);

      let newEdgeIndexForKey = 0;
      if (nodeToEdgeArray?.length) {
        const lastElementEdgeId = nodeToEdgeArray[nodeToEdgeArray.length - 1].edgeId;
        const i = lastElementEdgeId?.split("_").pop();
        newEdgeIndexForKey = i + 1;
      }

      const edgeId = `edge_${params.source}_${params.target}_${newEdgeIndexForKey}`;

      // update node-to-edge array for join type syncing
      const joinTypeForEdge = updateNodeToEdgeArray({
        array: nodeToEdgeArray,
        source: params.source,
        target: params.target,
        joinType: "left",
        setNodeToEdgeArray,
        setEdges,
        edgeId,
      });

      const updatedParams = {
        ...params,
        type: "tableEdge",
        style: {
          stroke: color,
        },
        id: edgeId,
        data: {
          deleteEdgeById,
          joinType: joinTypeForEdge || "left",
          setEdges,
          handleColors: updatedHandleColors,
          nodeToEdgeArray,
          source: params.source,
          target: params.target,
          useEdges,
        },
      };
      return setEdges((eds) => addEdge(updatedParams, eds));
    },
    [handleColors, nodeToEdgeArray]
  );

  const onClickToggleColumn = (tableId, tableName, column, visibility) => {
    const updatedColumns = includedColumns;
    const cols = updatedColumns.get(tableName);

    let updatedCols;
    if (!visibility) {
      // exclude column
      updatedCols = cols.filter((c) => c !== column);
    } else {
      // include column
      cols.push(column);
      updatedCols = [...cols];
    }

    updatedColumns.set(tableName, updatedCols);
    setIncludedColumns(new Map(updatedColumns));

    setNodes((nds) => {
      return nds.map((node) => {
        if (node.id === tableId) {
          node.data = {
            ...node.data,
            includedColumns: updatedColumns,
          };
        }

        return node;
      });
    });
  };

  const onClickCollapse = (tableId, value) => {
    setIsColumnsCollapsed(value);

    setNodes((nds) => {
      return nds.map((node) => {
        if (node.id === tableId) {
          node.data = {
            ...node.data,
            isColumnsCollapsed: value,
          };
        }

        return node;
      });
    });
  };

  const onDropNode = useCallback(
    (event) => {
      event.preventDefault();
      setHighlightCanvas(false);

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const dataString = event.dataTransfer.getData("application/reactflow");
      const data = dataString ? JSON.parse(dataString) : null;
      const type = data?.type;

      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const nodeIndex = nodeId;
      const newNodeId = `${data?.table?.tableName}_${getId()}` || getId();
      const newNode = {
        id: newNodeId,
        table: data?.table,
        googleBigQuery,
        type,
        position,
        data: {
          ...data,
          isCollapsed: false,
          id: newNodeId,
          includedColumns,
          isColumnsCollapsed,
          deleteNodeById,
          useEdges,
          onClickToggleColumn,
          onClickCollapse,
          isRoot: nodeIndex === 0,
          index: nodeIndex,
        },
      };

      setNodes((nodes) => nodes.concat(newNode));
    },
    [reactFlowInstance, nodes]
  );

  const isValidConnection = useCallback((connection) => {
    const { source, sourceHandle, target, targetHandle } = connection;
    const sourceTableName = source.split("_node").shift();
    const targetTableName = target.split("_node").shift();

    const sourceHandleColumnType = getColumnType(tables, sourceTableName, sourceHandle);
    const targetHandleColumnType = getColumnType(tables, targetTableName, targetHandle);

    if (
      (stringTypes.includes(sourceHandleColumnType.type.toLowerCase()) &&
        stringTypes.includes(targetHandleColumnType.type.toLowerCase())) ||
      (numericTypes.includes(sourceHandleColumnType.type.toLowerCase()) &&
        numericTypes.includes(targetHandleColumnType.type.toLowerCase())) ||
      (sourceHandleColumnType.isDate && targetHandleColumnType.isDate) ||
      (sourceHandleColumnType.isTime && targetHandleColumnType.isTime) ||
      (sourceHandleColumnType.isNumeric && targetHandleColumnType.isNumeric) ||
      (sourceHandleColumnType.isLatitude && targetHandleColumnType.isLatitude) ||
      (sourceHandleColumnType.isLongitude && targetHandleColumnType.isLongitude) ||
      sourceHandleColumnType.type.toLowerCase() === targetHandleColumnType.type.toLowerCase()
    ) {
      setShowConnectionError(false);
      return true;
    }

    setShowConnectionError(true);
    timerForError = setTimeout(() => setShowConnectionError(false), 3000);
    return false;
  }, []);

  // set initial nodes
  useEffect(() => {
    const includedMap = new Map();
    if (hasColumns) {
      tables.forEach((t) => {
        const cols = t.columnSchema?.map((col) => col.columnName);
        includedMap.set(t.tableName, cols);
      });
    }

    const initialNodes = [];
    const updatedIncludedColumns = new Map(includedMap);

    // eslint-disable-next-line no-unused-expressions
    selectedJoin?.tables?.map((t) => {
      updatedIncludedColumns.set(t.tableName, t.includedColumns);
      setIncludedColumns(updatedIncludedColumns);
    });

    // eslint-disable-next-line no-unused-expressions
    selectedJoin?.tables?.map((t) => {
      const tableFromDatasource = tables.filter((table) => table.tableName === t.tableName).shift();
      const table = {
        tableName: t.tableName,
        schemaName: t.schemaName,
        columnSchema: tableFromDatasource.columnSchema,
        labelName: tableFromDatasource.labelName,
        included: tableFromDatasource.included,
      };

      const node = {
        id: t.nodeId,
        table,
        googleBigQuery,
        type: "tableNode",
        position: t.position,
        data: {
          type: "tableNode",
          isCollapsed: false,
          id: t.nodeId,
          includedColumns: updatedIncludedColumns,
          isColumnsCollapsed: t.isCollapsed,
          table,
          deleteNodeById,
          useEdges,
          onClickToggleColumn,
          onClickCollapse,
          isRoot: t.isRoot,
          index: t.index,
        },
      };

      const lastNodeId = t.nodeId.split("_").pop();
      if (nodeId < lastNodeId) {
        nodeId = lastNodeId;
        startIndex = lastNodeId;
      }

      initialNodes.push(node);
    });

    setNodes(initialNodes);
  }, [selectedJoin, tables, hasColumns]);

  // set initial edges
  useEffect(() => {
    const initialEdges = [];
    const updatedHandleColors = new Map(handleColors);

    // eslint-disable-next-line no-unused-expressions
    selectedJoin?.connections?.map((c) => {
      // set handle colors
      const sourceTable = selectedJoin?.tables
        ?.filter((node) => node.nodeId?.toLowerCase() === c.source?.toLowerCase())
        .pop();
      const sourceHandle = sourceTable?.includedColumns
        .filter((col) => col?.toLowerCase() === c.sourceHandle?.toLowerCase())
        .pop();

      const targetTable = selectedJoin?.tables
        ?.filter((node) => node.nodeId?.toLowerCase() === c.target?.toLowerCase())
        .pop();
      const targetHandle = targetTable?.includedColumns
        .filter((col) => col?.toLowerCase() === c.targetHandle?.toLowerCase())
        .pop();

      setNewHandleColorsForEdge(
        updatedHandleColors,
        c.source,
        c.sourceHandle,
        c.target,
        c.targetHandle,
        c.style.stroke
      );

      const edge = {
        id: c.edgeId,
        type: "tableEdge",
        source: c.source,
        sourceHandle: sourceHandle || c.sourceHandle,
        target: c.target,
        targetHandle: targetHandle || c.targetHandle,
        style: c.style,
        data: {
          deleteEdgeById,
          joinType: c.joinType,
          setEdges,
          handleColors: {},
          nodeToEdgeArray,
          source: c.source,
          target: c.target,
          useEdges,
        },
      };

      initialEdges.push(edge);

      setHandleColors(updatedHandleColors);
    });

    setEdges(initialEdges);

    initialEdges.map((e) => {
      updateNodeToEdgeArray({
        array: nodeToEdgeArray,
        source: e.source,
        target: e.target,
        joinType: e.data.joinType,
        setNodeToEdgeArray,
        setEdges,
        edgeId: e.id,
      });
    });
  }, [selectedJoin]);

  // set initial handle colors to nodes and edges
  useEffect(() => {
    updateHandleColorsOnNode(handleColors, undefined, setNodes, setEdges);
  }, [handleColors]);

  useEffect(() => {
    return () => clearTimeout(timerForError);
  }, []);

  const onDragLeave = useCallback(() => {
    setHighlightCanvas(false);
  }, []);

  return (
    <div className="d-flex flex-column">
      <div className={classNames("col-12", joinCanvasStyles.zJoins)}>
        <ReactFlowProvider>
          <div className={classNames("col-12 col-lg-9", joinCanvasStyles.reactflowWrapper)} ref={reactFlowWrapper}>
            <ReactFlow
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnectEdge}
              onInit={setReactFlowInstance}
              onDrop={onDropNode}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              isValidConnection={isValidConnection}
              defaultViewport={{ x: 0, y: 0, zoom: 1.8 }}
              className={classNames(joinCanvasStyles["canvas"], {
                [joinCanvasStyles["react-flow"]]: !nodes?.length,
                [joinCanvasStyles.highlight]: highlightCanvas,
              })}
              fitView
            >
              <Controls />
            </ReactFlow>
          </div>
          <div className={classNames("col-12 col-lg-3", joinCanvasStyles.sidebar)}>
            <TablesSidebar tables={tables} />
          </div>
        </ReactFlowProvider>
      </div>
      {showConnectionError && (
        <div className={classNames("col-12 col-md-9 text-center", joinCanvasStyles.error)}>
          <Alert
            key="danger"
            type="danger"
            message="You cannot join fields of different types."
            onClose={() => setShowConnectionError(false)}
          />
        </div>
      )}
    </div>
  );
};

export default memo(EditJoin);
