import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class QueryManagerActions {
  static async getQueryById(id) {
    ApiActions.getPoll(
      `/api/querymanager/${id}`,
      undefined,
      Store,
      Constants.QUERY_MANAGER_GET_QUERY,
      Constants.QUERY_MANAGER_GET_QUERY_RESPONSE,
      (response) => {
        if (response.state === "completed" || response.state === "failed") return response;
        else return false;
      }
    );
  }

  static async checkConnection(data) {
    ApiActions.post(
      "/api/querymanager/check-connection",
      data,
      Store,
      Constants.QUERY_MANAGER_CHECK_CONNECTION,
      Constants.QUERY_MANAGER_CHECK_CONNECTION_RESPONSE
    );
  }

  static async startIngestion(data) {
    ApiActions.post(
      "/api/querymanager/ingestor",
      data,
      Store,
      Constants.QUERY_MANAGER_START_INGESTION,
      Constants.QUERY_MANAGER_START_INGESTION_RESPONSE
    );
  }

  static async getQueryIngestorById(id) {
    ApiActions.getPoll(
      `/api/querymanager/ingestor/${id}`,
      undefined,
      Store,
      Constants.QUERY_MANAGER_GET_QUERY_INGESTION,
      Constants.QUERY_MANAGER_GET_QUERY_INGESTION_RESPONSE,
      (response) => {
        if (response.state === "completed" || response.state === "failed") return response;
        else return false;
      }
    );
  }

  static async startDbImporter(data) {
    ApiActions.post(
      "/api/querymanager/db-importer",
      data,
      Store,
      Constants.QUERY_MANAGER_START_DB_IMPORTER,
      Constants.QUERY_MANAGER_START_DB_IMPORTER_RESPONSE
    );
  }

  static async getQueryDbImporterById(id) {
    ApiActions.getPoll(
      `/api/querymanager/db-importer/${id}`,
      undefined,
      Store,
      Constants.QUERY_MANAGER_GET_QUERY_DB_IMPORTER,
      Constants.QUERY_MANAGER_GET_QUERY_DB_IMPORTER_RESPONSE,
      (response) => {
        if (response.state === "completed" || response.state === "failed") return response;
        else return false;
      },
      500
    );
  }

  static async startCsvUpload(data) {
    ApiActions.post(
      "/api/v2/querymanager/csv/upload",
      data,
      Store,
      Constants.QUERY_MANAGER_START_CSV_UPLOAD,
      Constants.QUERY_MANAGER_START_CSV_UPLOAD_RESPONSE,
      () => {},
      true
    );
  }

  static async startLargeCsvUpload(data) {
    ApiActions.post(
      "/api/v2/querymanager/csv/large-upload",
      data,
      Store,
      Constants.QUERY_MANAGER_START_CSV_UPLOAD,
      Constants.QUERY_MANAGER_START_CSV_UPLOAD_RESPONSE,
      () => {},
      true
    );
  }

  static async startCsvImport(data) {
    ApiActions.post(
      "/api/v2/querymanager/csv/import",
      data,
      Store,
      Constants.QUERY_MANAGER_START_CSV_IMPORT,
      Constants.QUERY_MANAGER_START_CSV_IMPORT_RESPONSE
    );
  }

  static clearImportState() {
    Store.dispatch({
      type: Constants.QUERY_MANAGER_CLEAR_IMPORT_STATE,
    });
  }

  static async getTablesAndColumns(data) {
    ApiActions.post(
      "/api/querymanager/tables",
      data,
      Store,
      Constants.QUERY_MANAGER_GET_TABLES_AND_COLUMNS,
      Constants.QUERY_MANAGER_GET_TABLES_AND_COLUMNS_RESPONSE
    );
  }

  static clearState() {
    Store.dispatch({
      type: Constants.QUERY_MANAGER_CLEAR_STATE,
    });
  }

  static async validateJoin(data) {
    ApiActions.post(
      "/api/querymanager/validate-join",
      data,
      Store,
      Constants.QUERY_MANAGER_VALIDATE_JOIN,
      Constants.QUERY_MANAGER_VALIDATE_JOIN_RESPONSE
    );
  }

  static async refreshTables(data) {
    ApiActions.post(
      "/api/querymanager/refresh-tables",
      data,
      Store,
      Constants.QUERY_MANAGER_REFRESH_TABLES,
      Constants.QUERY_MANAGER_REFRESH_TABLES_RESPONSE
    );
  }
}

export default QueryManagerActions;
