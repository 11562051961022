import React from "react";
import DynamicFieldsRow from "./dynamic-fields-row";
import "./examples.scss";

const DynamicFieldsTable = ({ setDynamicFields, dynamicFields, tables }) => {
  const tableOptions = tables?.map((t) => ({ label: t.tableName, value: t.tableName }));

  return (
    <div className="col-12 px-0 ex-table-wrapper">
      <div className="ex-row-container pb-1">
        <div className="col-2 px-0">Name</div>
        <div className="col-9 d-flex px-0">
          <div className="col-4 px-0 ex-table-header table-select">Table</div>
          <div className="col-4 px-0 ex-table-header column-select">Field</div>
          <div className="col-3 px-0 ex-table-header input-select">Input Selection</div>
        </div>
      </div>
      {dynamicFields?.map((df, index) => (
        <DynamicFieldsRow
          key={index}
          dynamicField={df}
          tableOptions={tableOptions}
          tables={tables}
          setDynamicFields={setDynamicFields}
          dynamicFields={dynamicFields}
        />
      ))}
    </div>
  );
};

export default DynamicFieldsTable;
