import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Actions from "redux/actions/reset-password";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Buffer } from "buffer";
import ShowPassword from "components/form/show-password";
import Footer from "components/footer/footerlogin";
import Alert from "components/alert";
import Loading from "components/loading";
import Logo from "components/logo/logo";

const schema = yup.object().shape({
  key: yup.string().required("Key is required"),
  email: yup.string().email("Email is invalid").required("Email is required"),
  password: yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
  confirmPassword: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const { register, handleSubmit, errors, formState, setValue, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { isDirty, isValid } = formState;

  const { loading, error, success } = useSelector((s) => s.resetPassword);

  const { token } = useParams();

  if (token) {
    const _token = Buffer.from(token, "base64").toString("binary");
    const [email, key] = _token.split(":");

    if (email) {
      setValue("email", email, { shouldValidate: true });
    }

    if (key) {
      setValue("key", key, { shouldValidate: true });
    }
  }

  // eslint-disable-next-line no-console
  const onError = (errors, e) => console.log("onError", errors, e);

  const goToLogin = () => {
    reset("key");
    reset("email");
    reset("password");
    reset("confirmPassword");
    Actions.dispatch();
    navigate("/login");
  };

  useEffect(() => {
    // lets get the body classes ready for Dashboard
    const origBodyClass = document.body.className;
    document.body.className = "main";

    return () => {
      // put componentUnMount logic in here
      document.body.className = origBodyClass;
    };
  });

  const doReset = (data, e) => {
    e.preventDefault();

    const { key, email, password, confirmPassword } = data;

    let isValid = true;
    if (confirmPassword !== password) {
      isValid = false;
    }

    if (isValid) {
      Actions.reset({ email, password, key });
    }
  };

  const alerts = [];

  if (success) {
    alerts.push(
      <div key="success">
        <Alert key="success" type="success" message="Your password has been reset. Please login to continue." />
        <div className="text-center mt-2">
          <a className="btn btn-link" onClick={goToLogin}>
            <i className="tio-chevron-left"></i> Back to Sign in
          </a>
        </div>
      </div>
    );
  }

  if (error) {
    alerts.push(<Alert key="danger" type="danger" message={error} />);
  }

  return (
    <>
      <main id="content" role="main" className="main">
        <div
          className="position-fixed top-0 right-0 left-0 bg-img-hero"
          style={{ height: "32rem", backgroundImage: "url(/assets/svg/components/abstract-bg-4.svg)" }}
        >
          <figure className="position-absolute right-0 bottom-0 left-0">
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
              <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
            </svg>
          </figure>
        </div>
        <div className="container py-5 py-sm-7">
          <Logo
            outerClassName="d-flex justify-content-center mb-5"
            innerClassName="z-index-2"
            style={{ width: "15rem" }}
          />
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="card card-lg mb-5">
                <Loading loading={loading}></Loading>

                <div className="card-body">
                  {alerts}
                  <form style={success ? { display: "none" } : {}} onSubmit={handleSubmit(doReset, onError)}>
                    <div className="text-center">
                      <div className="mb-5">
                        <h1 className="display-4">Reset password?</h1>
                        <p>Please fill up all fields to reset your password.</p>
                      </div>
                    </div>

                    <div className="js-form-message form-group">
                      <label className="input-label" htmlFor="key">
                        Key
                      </label>

                      <div className="input-group input-group-merge">
                        <input
                          type="text"
                          className={"form-control form-control-lg" + (errors.key ? " is-invalid" : "")}
                          name="key"
                          id="key"
                          placeholder="Key"
                          aria-label="fullname"
                          ref={register}
                          readOnly={true}
                          tabIndex={1}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="tio-lock"></i>
                          </span>
                        </div>
                        <div className="invalid-feedback">{errors.key?.message}</div>
                      </div>
                    </div>

                    <div className="js-form-message form-group">
                      <label className="input-label" htmlFor="email">
                        Your email
                      </label>

                      <div className="input-group input-group-merge">
                        <input
                          type="email"
                          className={"form-control form-control-lg" + (errors.email ? " is-invalid" : "")}
                          id="email"
                          name="email"
                          tabIndex={2}
                          ref={register}
                          readOnly={true}
                          placeholder="email@address.com"
                          aria-label="email@address.com"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="inputGroupMergeEmailOptionalAddOn">
                            <i className="tio-lock"></i>
                          </span>
                        </div>
                      </div>
                      <div className="invalid-feedback">{errors.email?.message}</div>
                    </div>

                    <div className="js-form-message form-group">
                      <label className="input-label" htmlFor="password">
                        Password
                      </label>
                      <ShowPassword
                        id="password"
                        name="password"
                        ref={register}
                        errors={errors.password}
                        placeholder="8+ characters required"
                        tabIndex={3}
                      ></ShowPassword>
                    </div>

                    <div className="js-form-message form-group">
                      <label className="input-label" htmlFor="confirmPassword">
                        Confirm password
                      </label>
                      <ShowPassword
                        name="confirmPassword"
                        id="confirmPassword"
                        ref={register}
                        errors={errors.confirmPassword}
                        placeholder="8+ characters required"
                        tabIndex={4}
                      ></ShowPassword>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-lg btn-block btn-primary"
                      disabled={success || !isDirty || !isValid}
                    >
                      Set password
                    </button>

                    <div className="text-center mt-2">
                      <Link className="btn btn-link" to="/login">
                        <i className="tio-chevron-left"></i> Back to Sign in
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ResetPasswordPage;
