import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";
import AuthService from "../../helpers/auth";
import amplitude from "amplitude-js";

const Qs = require("qs");

const setLogin = (err, response) => {
  AuthService.logout();

  if (!err) {
    const query = Qs.parse(window.location.search.substring(1));

    if (response && response.session && response.session._id) {
      window.localStorage.setItem("userauth", response.session._id);
      if (response.user && response.user.email) {
        window.localStorage.setItem("email", response.user.email);
      }
      if (response.user && response.user.roles && response.user.roles.account && response.user.roles.account.name) {
        window.localStorage.setItem("name", response.user.roles.account.name);
      }

      const { user, datasource, datasources, datasourceList, firebaseToken, redirectUrl } = response;

      amplitude.getInstance().setUserId(user._id, true);

      AuthService.setUser({
        user,
        datasource,
        datasources,
        datasourceList,
        firebaseToken,
      });

      if (query.returnUrl) {
        window.location = query.returnUrl;
      } else if (redirectUrl) {
        window.location = `${redirectUrl}`;
      } else {
        const userOrgs = user?.organizations;
        const isMemberOrViewerOnly = userOrgs.every((o) => o.groups.member || o.groups.viewer);

        if (isMemberOrViewerOnly) {
          window.location = "/web";
        } else {
          window.location = query.redirectUrl || "/dashboard";
        }
      }
    } else if (query.returnUrl) {
      window.location.href = query.returnUrl;
    }
  }
};

class LoginActions {
  static async login(data) {
    ApiActions.post("/api/login", data.login, Store, Constants.LOGIN, Constants.LOGIN_RESPONSE, (err, response) => {
      setLogin(err, response);
    });
  }

  static async getGoogleTokens(data) {
    ApiActions.post(
      "/api/login/google/token",
      data,
      Store,
      Constants.LOGIN_GOOGLE_GET_TOKEN,
      Constants.LOGIN_GOOGLE_GET_TOKEN_RESPONSE
    );
  }

  static async clearTokens() {
    Store.dispatch({
      type: Constants.CLEAR_TOKENS,
    });
  }

  static async loginWithApple(data) {
    ApiActions.post(
      "/api/v1/login/apple",
      data,
      Store,
      Constants.LOGIN_WITH_APPLE,
      Constants.LOGIN_WITH_APPLE_RESPONSE,
      (err, response) => {
        setLogin(err, response);
      }
    );
  }

  static async verifySsoLogin(data) {
    ApiActions.post(
      "/api/v1/login/verify-sso",
      data,
      Store,
      Constants.VERIFY_LOGIN_WITH_SSO,
      Constants.VERIFY_LOGIN_WITH_SSO_RESPONSE
    );
  }

  static async loginWithSso(data) {
    ApiActions.post(
      "/api/v1/login/sso",
      data,
      Store,
      Constants.LOGIN_WITH_SSO,
      Constants.LOGIN_WITH_SSO_RESPONSE,
      (err, response) => {
        setLogin(err, response);
      }
    );
  }
}

export default LoginActions;
