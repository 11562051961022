import React, { useEffect, useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import CategoryActions from "redux/actions/fewshot-category";
import "../../components/datasource/examples/examples.scss";

const ModalAddOrEditExampleCategory = ({ show, handleClose, datasource, categories }) => {
  const [newCategory, setNewCategory] = useState("");
  const [updatedCategories, setUpdatedCategories] = useState(categories.map((c) => ({ ...c })));

  useEffect(() => {
    setUpdatedCategories([...categories]);
  }, [categories]);

  const onChangeNewCategory = (e) => {
    setNewCategory(e.target.value);
  };

  const addNewCategory = () => {
    CategoryActions.create({ datasourceId: datasource?._id, name: newCategory?.trim() });
    setNewCategory("");
  };

  const onChangeExistingCategory = (e, category) => {
    const existingCategories = updatedCategories.map((c) => ({ ...c }));
    const modified = existingCategories.map((cat) => {
      if (cat._id === category._id) {
        cat.name = e.target.value;
      }

      return cat;
    });

    setUpdatedCategories([...modified]);
  };

  const deleteExistingCategory = (category) => {
    CategoryActions.delete(category?._id);
  };

  const updateCategories = (category) => {
    if (category?.name) {
      CategoryActions.update({ categories: [{ id: category._id, name: category.name, color: category.color }] });
    } else {
      CategoryActions.update({
        categories: updatedCategories?.map((c) => ({ id: c._id, name: c.name, color: c.color })),
      });
    }
  };

  const onClickSave = () => {
    updateCategories();
    handleClose();
  };

  return (
    <Modal
      backdrop="static"
      size="md"
      show={show}
      onHide={handleClose}
      keyboard={false}
      centered
      style={{ zIndex: 9999 }}
      backdropClassName="custom-backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title>Categories</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {updatedCategories?.length ? (
            <>
              {updatedCategories.map((c, index) => (
                <Form.Group key={index} className="mb-3">
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="category"
                      name="category"
                      value={c.name}
                      onChange={(e) => onChangeExistingCategory(e, c)}
                      onBlur={() => updateCategories(c)}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => deleteExistingCategory(c)}
                      style={{ maxHeight: "41.89px", backgroundColor: "#F2F4F6" }}
                    >
                      <img className="img-fluid" src="../../assets/img/delete-icon.svg" alt="delete category" />
                    </Button>
                  </InputGroup>
                </Form.Group>
              ))}
              <hr />
            </>
          ) : (
            <></>
          )}
          <Form.Group className="mb-3">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Add new category"
                aria-label="Add new category"
                name="category"
                value={newCategory}
                onChange={onChangeNewCategory}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.target.value) {
                    e.preventDefault();
                    addNewCategory();
                  }
                }}
              />
              <Button variant="primary" disabled={!newCategory} onClick={addNewCategory} style={{ minWidth: "54px" }}>
                <i className="tio-add" />
              </Button>
            </InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button type="submit" variant="primary" onClick={onClickSave}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddOrEditExampleCategory;
