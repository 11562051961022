/**
 * Configuration object to be passed to MSAL instance on creation.
 */
export const msalConfig = {
  auth: {
    clientId: "zing-test",
    authority: "zing-test",
    redirectUri: `${process.env.REACT_APP_AZUREAD_REDIRECT_URL}/blank.html`,
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
      },
    },
    allowNativeBroker: false,
  },
};
