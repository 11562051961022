import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class Actions {
  static async reset(data) {
    ApiActions.post("/api/login/reset", data, Store, Constants.RESET_PASSWORD, Constants.RESET_PASSWORD_RESPONSE);
  }

  static dispatch() {
    Store.dispatch({
      type: Constants.RESET_PASSWORD_MOUNT,
    });
  }
}

export default Actions;
