import React, { memo, useCallback, useState } from "react";
import classNames from "classnames";
import Icon from "components/icon";

const Search = ({ placeholder, initialValue = "", onChange }) => {
  const [searchTerm, setSearchTerm] = useState(initialValue);

  const handleClearClick = useCallback(() => {
    setSearchTerm("");
    onChange("");
  }, [onChange]);

  const handleSearchChange = useCallback(
    (e) => {
      setSearchTerm(e.target.value);
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div className="my-4">
      <div className="input-group input-group-merge">
        <input
          type="text"
          className="js-form-search form-control"
          placeholder={placeholder}
          onChange={handleSearchChange}
          value={searchTerm}
        />
        <button type="button" className="input-group-append input-group-text">
          <Icon
            iconName={classNames({ "tio-search": !searchTerm, "tio-clear": searchTerm })}
            className="ml-2 opacity-50"
            onClick={searchTerm ? handleClearClick : undefined}
          />
        </button>
      </div>
    </div>
  );
};

export default memo(Search);
