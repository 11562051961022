import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Modal, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import Alert from "components/alert";
import Loading from "components/loading";
import InvitationActions from "redux/actions/invitation";
import { ORGGROUPS } from "helpers/constants";

const schema = yup.object().shape({
  email: yup.string().email("Email is invalid"),
});

const ModalUpdateRole = ({ invitation, show, handleClose, refreshData, setSuccessAlert }) => {
  const existingRole = invitation.groups ? Object.keys(invitation.groups).shift() : null;

  const { errors, control } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const [alerts, setAlerts] = useState([]);
  const [role, setRole] = useState(ORGGROUPS.find((g) => g.value === existingRole)?.value);

  const { loading, error, showUpdateRoleSuccess } = useSelector((s) => s.invitation);

  const handleChangeRole = (event) => {
    if (event) {
      setRole(event.value);
    } else {
      setRole(null);
    }
  };

  const onClickUpdate = () => {
    InvitationActions.updateRole(invitation._id, { role });
  };

  const closeModal = () => {
    handleClose();
    setAlerts([]);
  };

  useEffect(() => {
    if (showUpdateRoleSuccess) {
      closeModal();
      refreshData();
      setSuccessAlert([<Alert key="success" type="success" message="Invitation role was updated successfully." />]);
      InvitationActions.hideInvitationUpdateRole();
    }
  }, [showUpdateRoleSuccess]);

  useEffect(() => {
    if (error) {
      setAlerts([<Alert key="danger" type="danger" message={error} />]);
      InvitationActions.hideInvitationUpdateRole();
    }
  }, [error]);

  return (
    <Modal backdrop="static" show={show} onHide={closeModal} keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading}></Loading>
        {alerts}
        <div>
          <p className="mb-5">Select the new role for &quot;{invitation.email}&quot; from the list below.</p>
          <form>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="projectNameNewProjectLabel" className="input-label">
                    Role
                    <i
                      className="tio-help-outlined text-body ml-1"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Editors are able to view datasource tables, create, edit and copy questions and dashboards. Viewers are only able to view questions and dashboards shared with the entire organization."
                    />
                  </label>
                  <Controller
                    name="role"
                    control={control}
                    render={({ ref }) => (
                      <Select
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                        className={errors.type ? " is-invalid" : ""}
                        inputRef={ref}
                        options={ORGGROUPS}
                        isClearable
                        value={ORGGROUPS?.find((c) => c.value === role)}
                        onChange={handleChangeRole}
                        defaultValue={ORGGROUPS.find((o) => o.value === existingRole)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={onClickUpdate} disabled={existingRole === role}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalUpdateRole;
