import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MsalProvider } from "@azure/msal-react";
import AppUniversal from "./app-universal";

const App = ({ msalInstance }) => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} itemProp="url"></link>
      </Helmet>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <AppUniversal />
        </MsalProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
