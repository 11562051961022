import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FiEdit as EditIcon } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";
import { useToggle } from "hooks/useToggle";
import { format } from "date-fns";
import { PricingTiers, ORGGROUPS_ADMIN } from "helpers/constants";

import Footer from "components/footer/footermain";
import Header from "components/navbar/header";
import Alert from "components/alert";
import Loading from "components/loading";
import ModalEditOrganizationName from "components/modal/modal-edit-org-name";
import UpgradePro from "components/upgrade-pro";

import OrganizationActions from "redux/actions/organization";
import ProfileActions from "redux/actions/profile";
import EmbedActions from "redux/actions/embed";
import AuthService from "../../helpers/auth";
import PublicLinksTable from "./public-links-table";
import EmbedIdsTable from "./embed-ids-table";
import ApiKeysTable from "./api-keys-table";
import OrgSettings from "./org-settings";
import Overview from "./overview";
import Users from "./users";
import Personalization from "./personalization";

const alertStyle = {
  width: "100%",
  marginBottom: 0,
  marginTop: "5px",
};

const OrganizationDetails = () => {
  const { id: organizationId } = useParams();
  const { user } = AuthService.getUser();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const {
    account: profile,
    loading: loadingSettings,
    error: errorSettings,
    showSaveSuccess: showSaveSuccessSettings,
  } = useSelector((s) => s.settings);

  const { loading: loadingOrg, organization: org, error } = useSelector((s) => s.organization);
  const { loading: loadingPublicLinks } = useSelector((s) => s.publicLink);
  const {
    creatingApiKey,
    showCreateSuccess,
    errorCreatingApiKey,
    loading: loadingEmbeds,
    loadingApiKeys,
  } = useSelector((s) => s.embed);

  const { name, account, role: loggedInUserRole, account: organizationAccount } = { ...org };
  const isEmbeddingEnabled =
    account?.billingPlans?.tier === PricingTiers.PRO || account?.billingPlans?.tier === PricingTiers.ENTERPRISE;

  const planCancellationDate =
    organizationAccount?.billingPlans?.cancellation?.cancel_at_period_end &&
    new Date(organizationAccount?.billingPlans?.cancellation?.cancel_at) > new Date() &&
    organizationAccount?.billingPlans?.cancellation?.cancel_at;

  const [alerts, setAlerts] = useState([]);
  const [hasGoogleId, setHasGoogleId] = useState(false);
  const [userId] = useState(user?._id);
  const [organization, setOrganization] = useState(org);
  const [activeTab, setActiveTab] = useState(1);
  const [showEditNameModal, toggleEditNameModal] = useToggle();
  const [showDeletePublicLinksModal, toggleDeletePublicLinksModal] = useToggle();

  useEffect(() => {
    ProfileActions.getDetails(userId);
    OrganizationActions.getOrganizationById(organizationId);
  }, [organizationId]);

  useEffect(() => {
    setOrganization(org);
  }, [org]);

  useEffect(() => {
    if (error || errorSettings) {
      setAlerts([<Alert key="danger" type="danger" style={alertStyle} message={error || errorSettings} />]);
      setOrganization({ ...org });
    } else if (showSaveSuccessSettings) {
      setAlerts([
        <Alert key="success" type="success" style={alertStyle} message="Organization settings successfully updated!" />,
      ]);
    }
  }, [error, showSaveSuccessSettings, errorSettings]);

  useEffect(() => {
    if (profile?.user) {
      if (profile.user.google && profile.user.google.id) {
        setHasGoogleId(true);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (errorCreatingApiKey) {
      setAlerts([<Alert key="danger" type="danger" style={alertStyle} message={errorCreatingApiKey} />]);
    } else if (showCreateSuccess) {
      setAlerts([<Alert key="success" type="success" style={alertStyle} message="Generated API key successfully!" />]);
    }
  }, [errorCreatingApiKey, showCreateSuccess]);

  const handleInputRequireSSO = (e) => {
    const updatedOrganization = {
      ...organization,
      settings: {
        ...organization.settings,
        requireGoogleSSO: e.target.checked,
      },
    };
    setOrganization({ ...updatedOrganization });
  };

  const handleInputEnableCustomSQL = (e) => {
    const updatedOrganization = {
      ...organization,
      settings: {
        ...organization.settings,
        enableCustomSQL: e.target.checked,
      },
    };
    setOrganization({ ...updatedOrganization });
  };

  const handleInputAllowInviteOutsideDomain = (e) => {
    const updatedOrganization = {
      ...organization,
      settings: {
        ...organization.settings,
        allowInviteOutsideDomain: e.target.checked,
      },
    };
    setOrganization({ ...updatedOrganization });
  };

  const handleInputEnablePublicSharing = (e) => {
    if (organization.settings.enablePublicShare && organization.hasPublicLinks) {
      toggleDeletePublicLinksModal();
    } else {
      const updatedOrganization = {
        ...organization,
        settings: {
          ...organization.settings,
          enablePublicShare: e.target.checked,
        },
      };
      setOrganization({ ...updatedOrganization });
    }
  };

  const deletePublicLinks = () => {
    const updatedOrganization = {
      ...organization,
      settings: {
        ...organization.settings,
        enablePublicShare: false,
      },
    };
    setOrganization({ ...updatedOrganization });
    toggleDeletePublicLinksModal();
  };

  const handleSaveOrganizationSettings = () => {
    const data = [
      {
        organizationId: organization._id,
        allowInviteOutsideDomain: organization.settings.allowInviteOutsideDomain,
        requireGoogleSSO: organization.settings.requireGoogleSSO,
        enableCustomSQL: organization.settings.enableCustomSQL,
        enablePublicShare: organization.settings.enablePublicShare,
      },
    ];

    ProfileActions.saveOrganizationSettings(data);
  };

  const handleGenerateApiKeyClick = () => {
    EmbedActions.createApiKey({ organizationId });
  };

  useEffect(() => {
    window.dashboardOnReady();

    const origBodyClass = document.body.className;
    document.body.className = "footer-offset";

    return () => {
      document.body.className = origBodyClass;
    };
  }, []);

  return (
    <>
      <Header user={user} />
      <main id="content" role="main" className="main" style={isMobile ? { paddingTop: "7.75rem" } : {}}>
        <div className="content container-fluid">
          <Loading loading={loadingOrg || loadingApiKeys || loadingEmbeds || loadingPublicLinks}></Loading>
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-title">
                  Organization: {name}
                  {organization?.ownerId === userId && (
                    <EditIcon
                      color="gray"
                      className="ml-3"
                      size="1.2rem"
                      style={{ marginTop: "-4px", cursor: "pointer" }}
                      onClick={toggleEditNameModal}
                    />
                  )}
                </h1>
              </div>
            </div>
            <div className="w-100">{alerts}</div>

            <ul className="nav nav-tabs page-header-tabs mt-4">
              <li className="nav-item" onClick={() => setActiveTab(1)} style={{ cursor: "pointer" }}>
                <span className={`nav-link ${activeTab === 1 ? "active" : ""}`}>Settings</span>
              </li>
              {loggedInUserRole === ORGGROUPS_ADMIN && (
                <li className="nav-item" onClick={() => setActiveTab(2)} style={{ cursor: "pointer" }}>
                  <span className={`nav-link ${activeTab === 2 ? "active" : ""}`}>Public Links</span>
                </li>
              )}
              {loggedInUserRole === ORGGROUPS_ADMIN && (
                <li className="nav-item" onClick={() => setActiveTab(3)} style={{ cursor: "pointer" }}>
                  <span className={`nav-link ${activeTab === 3 ? "active" : ""}`}>Embeds</span>
                </li>
              )}
            </ul>
          </div>
          <div>
            {activeTab === 1 && (
              <>
                {planCancellationDate && (
                  <Alert
                    type="info"
                    message={`This plan will be cancelled on ${format(
                      new Date(planCancellationDate),
                      "MMM dd, yyyy"
                    )}. If you need to continue the plan, click on "Manage Subscription" to renew the plan.`}
                  />
                )}
                <Overview
                  accountData={organizationAccount}
                  organizationId={organizationId}
                  organizationOwner={organization?.ownerId}
                  loggedInUserId={userId}
                />
                <OrgSettings
                  hasGoogleId={hasGoogleId}
                  organization={organization}
                  handleInputRequireSSO={handleInputRequireSSO}
                  userId={userId}
                  handleInputAllowInviteOutsideDomain={handleInputAllowInviteOutsideDomain}
                  handleInputEnableCustomSQL={handleInputEnableCustomSQL}
                  handleInputEnablePublicSharing={handleInputEnablePublicSharing}
                  handleSaveOrganizationSettings={handleSaveOrganizationSettings}
                  loadingSettings={loadingSettings}
                  showDeletePublicLinksModal={showDeletePublicLinksModal}
                  toggleDeletePublicLinksModal={toggleDeletePublicLinksModal}
                  deletePublicLinks={deletePublicLinks}
                />
                {loggedInUserRole === ORGGROUPS_ADMIN && <Personalization organizationId={organizationId} />}
                {organization?.users?.length && organization?.ownerId?.toString() === user?._id && (
                  <Users data={organization?.users} />
                )}
              </>
            )}
            {activeTab === 2 && (
              <div>
                <div className="card mt-3">
                  <div className="card-header">
                    <h4 className="card-title">Questions with Public Links Enabled</h4>
                  </div>
                  <div className="card-body">
                    <PublicLinksTable orgId={organizationId} setAlerts={setAlerts} />
                  </div>
                </div>
              </div>
            )}
            {activeTab === 3 && (
              <>
                {isEmbeddingEnabled ? (
                  <div>
                    <div className="card mt-3">
                      <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title">API Keys</h4>
                        <button className="btn btn-primary btn-sm" onClick={handleGenerateApiKeyClick}>
                          <i className="bi-people-file me-1"></i> {creatingApiKey ? "Generating" : "Generate API Key"}
                        </button>
                      </div>
                      <div className="card-body">
                        <div className="col-12 alert alert-soft-primary" role="alert">
                          See our{" "}
                          <a
                            href="https://docs.getzingdata.com/docs/embedded-analytics/"
                            target="__blank"
                            rel="noopener noreferrer"
                            className="alert-link"
                          >
                            documentation
                          </a>{" "}
                          on how to embed.
                        </div>
                        <ApiKeysTable
                          orgId={organizationId}
                          setAlerts={setAlerts}
                          showCreateSuccess={showCreateSuccess}
                        />
                      </div>
                    </div>
                    <div className="card mt-3">
                      <div className="card-header">
                        <h4 className="card-title">Questions with Embed IDs Enabled</h4>
                      </div>
                      <div className="card-body">
                        <EmbedIdsTable orgId={organizationId} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card col-lg-4 p-5 m-auto text-center">
                    <UpgradePro account={account} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <Footer />
      </main>
      {showEditNameModal && (
        <ModalEditOrganizationName
          name={name}
          id={organizationId}
          show={showEditNameModal}
          handleClose={toggleEditNameModal}
          setAlerts={setAlerts}
          alertStyle={alertStyle}
        />
      )}
    </>
  );
};

export default OrganizationDetails;
