import React, { memo, useMemo } from "react";
import classNames from "classnames";
import { Handle, Position } from "reactflow";
import Icon from "components/icon";
import CustomNodeStyles from "./custom-node.module.scss";
import { numericTypes } from "helpers/constants";

const handleStyles = {
  top: "unset",
  height: "17px",
  width: "6px",
  minWidth: "1px",
  minHeight: "unset",
  borderRadius: 0,
  border: "none",
  WebkitTransform: "none",
  transform: "none",
  backgroundColor: "#ffffff",
};

const Column = ({
  column,
  isConnectable,
  includedColumns,
  tableName,
  onClickToggleColumn,
  tableId,
  isColumnsCollapsed,
  handleColorsForCol,
}) => {
  const isColumnIncluded = includedColumns?.includes(column.columnName);

  const columnTypeImage = useMemo(() => {
    const type = column.dataType;
    if (
      type?.toLowerCase() === "numeric" ||
      type?.toLowerCase() === "integer" ||
      column.isNumeric ||
      numericTypes.includes(type?.toLowerCase())
    ) {
      return "/assets/svg/joins/numeric.svg";
    }

    if (type?.toLowerCase() === "date" || column.isDate || column.isTime) {
      return "/assets/svg/joins/date-time.svg";
    }

    return "/assets/svg/joins/string.svg";
  }, []);

  const leftHandle = handleColorsForCol?.filter((c) => c.type === "left").shift();
  const leftHandleColor = leftHandle?.color;

  const rightHandle = handleColorsForCol?.filter((c) => c.type === "right").shift();
  const rightHandleColor = rightHandle?.color;

  if (isColumnsCollapsed && !isColumnIncluded) {
    return null;
  }

  return (
    <div className={classNames("d-flex align-items-center", CustomNodeStyles.column)}>
      {isColumnIncluded && (
        <Handle
          type="target"
          position={Position.Left}
          id={column.columnName}
          isConnectable={isConnectable}
          style={{
            ...handleStyles,
            left: "0px",
            backgroundColor: leftHandleColor || "transparent",
            width: leftHandleColor ? "2px" : "6px",
          }}
        />
      )}
      <img className={CustomNodeStyles.image} src={columnTypeImage} alt="Data type" />
      <div className="pl-1 d-flex justify-content-between w-100">
        <div className={classNames({ "text-muted": !isColumnIncluded })}>{column.columnName}</div>
        {isColumnIncluded ? (
          <Icon
            iconName="tio-visible-outlined"
            className={classNames("ml-2", CustomNodeStyles.icon)}
            isClickable
            onClick={() => onClickToggleColumn(tableId, tableName, column.columnName, false)}
          />
        ) : (
          <Icon
            iconName="tio-hidden-outlined"
            className={classNames("ml-2", CustomNodeStyles.icon)}
            isClickable
            onClick={() => onClickToggleColumn(tableId, tableName, column.columnName, true)}
          />
        )}
      </div>
      {isColumnIncluded && (
        <Handle
          type="source"
          position={Position.Right}
          id={column.columnName}
          isConnectable={isConnectable}
          style={{
            ...handleStyles,
            right: "0px",
            backgroundColor: rightHandleColor || "transparent",
            width: rightHandleColor ? "2px" : "6px",
          }}
        />
      )}
    </div>
  );
};

export default memo(Column);
