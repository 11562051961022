import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import amplitude from "amplitude-js";
import axios from "axios";
import PublicLinkActions from "redux/actions/public-link";
import Alert from "components/alert";
import Loading from "components/loading";
import PublicHeader from "components/navbar/public-header";
import AuthService from "../../helpers/auth";
import Graph from "./components/graph";

const ChartPage = () => {
  const { id: chartId } = useParams();
  const isUserLoggedIn = AuthService.isLoggedIn();

  const [chartResult, setChartResult] = useState();
  const [loadingChartResult, setLoadingChartResult] = useState(false);
  const [fetchChartResultError, setFetchChartResultError] = useState();

  const chartConfig = useSelector((s) => s.publicLink.data);
  const loadingChartConfig = useSelector((s) => s.publicLink.loading);
  const fetchChartConfigError = useSelector((s) => s.publicLink.error);

  const fetchChartConfig = useCallback(async () => {
    await PublicLinkActions.getChart(chartId);
  }, [chartId]);

  const fetchChartResult = useCallback(async () => {
    setLoadingChartResult(true);
    try {
      const chartResponse = await axios.get(
        `https://storage.googleapis.com/${process.env.REACT_APP_CHARTS_BUCKET_NAME}/${chartId}.json`
      );
      setLoadingChartResult(false);

      if (!chartResponse || !chartResponse.data) {
        setFetchChartResultError("Could not fetch chart result");
      } else {
        if (chartConfig?.chartType === "DataTable") {
          if (chartResponse.data?.rows) {
            setChartResult(chartResponse.data.rows);
          } else if (chartResponse.data?.data) {
            setChartResult(chartResponse.data.data);
          } else {
            setChartResult(chartResponse.data);
          }
        } else {
          if (chartResponse.data?.data) {
            setChartResult(chartResponse.data.data);
          } else if (chartResponse.data?.chart) {
            setChartResult(chartResponse.data.chart);
          } else {
            setChartResult(chartResponse.data);
          }
        }
      }
    } catch (e) {
      setLoadingChartResult(false);
      setFetchChartResultError(e);
    }
  }, [chartId, chartConfig]);

  useEffect(() => {
    if (!chartConfig?.questionText && !loadingChartConfig && !fetchChartConfigError) {
      fetchChartConfig();
    }
  }, [chartConfig, fetchChartConfig, fetchChartConfigError, loadingChartConfig]);

  useEffect(() => {
    if (chartConfig?.questionText) {
      fetchChartResult();
    }
  }, [chartConfig, fetchChartResult]);

  useEffect(() => {
    const instance = amplitude.getInstance();

    if (isUserLoggedIn) {
      const user = AuthService.getUser();
      const userId = user?.user?._id;
      instance.setUserId(userId);
    }

    if (chartConfig?.questionText) {
      instance.logEvent("VIEW_PUBLIC_DASHBOARD", {
        chartType: chartConfig.chartType,
        question: chartConfig?.questionText,
      });
    }
  }, [chartConfig]);

  if (loadingChartConfig || loadingChartResult) {
    return (
      <>
        <PublicHeader />
        <main id="content" role="main" className="main">
          <div className="container py-5 py-sm-7">
            <Loading loading={loadingChartConfig || loadingChartResult}></Loading>
          </div>
        </main>
      </>
    );
  }

  if (chartConfig?.chartType === "Map") {
    return (
      <>
        <PublicHeader />
        <main id="content" role="main" className="main">
          <div className="container py-5 py-sm-7">
            <div className="card col-lg-4 p-5 m-auto text-center align-items-center">
              <img
                className="img-fluid mb-3"
                src="/assets/svg/illustrations/in-development.svg"
                alt="DataTable In Development"
                style={{ maxWidth: "18rem" }}
              />
              <h4 className="h1 mt-4">Coming Soon!</h4>
              <p>We are currently working on making Maps available for public viewing. Stay Tuned.</p>
            </div>
          </div>
        </main>
      </>
    );
  }

  return (
    <>
      <PublicHeader redirectAfterLoginUrl="/web/#/" />
      <main id="content" role="main" className="main">
        <div className="container py-5 py-sm-7">
          <div className="row justify-content-center">
            {fetchChartConfigError && !fetchChartResultError && (
              <Alert
                key="danger"
                type="danger"
                message="Could not fetch chart for the given URL. Please check the link and try again."
                className="text-center"
              />
            )}
            {fetchChartResultError && (
              <Alert
                key="danger"
                type="danger"
                message="An error occurred while fetching the chart. Please try again and let us know if this issue persists by emailing us at hello@getzingdata.com."
                className="text-center"
              />
            )}
            {chartResult && (
              <div className="col-sm-12 py-md-10 py-5 px-md-10 px-2">
                <Graph chartResult={chartResult} chartConfig={chartConfig} />
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default ChartPage;
