import React, { memo, useCallback } from "react";
import { PopupModal } from "react-calendly";
import amplitude from "amplitude-js";
import { useToggle } from "hooks/useToggle";
import ModalSendEmail from "components/modal/modal-send-email";

const SetupHelpBox = ({ title, description, buttonText, type, id }) => {
  const [showCalendlyPopup, toggleCalendlyPopup] = useToggle();
  const [showEmailModal, toggleEmailModal] = useToggle();
  const instance = amplitude.getInstance();

  const onClick = useCallback(() => {
    switch (type) {
      case "call": {
        instance.logEvent("CLICK_SCHEDULE_CALL");
        return toggleCalendlyPopup();
      }
      case "docs": {
        instance.logEvent("CLICK_VIEW_DOCS");
        window.open("https://docs.getzingdata.com/docs/setting-up-a-data-source/", "_blank", "noreferrer");
        return;
      }
      case "email": {
        instance.logEvent("CLICK_SEND_EMAIL_TO_TEAMMATE");
        return toggleEmailModal();
      }
    }
  }, [type]);

  return (
    <>
      <div className="card card-body mt-4">
        <h4 className="mb-3">{title}</h4>
        <p>{description}</p>
        <button className="btn btn-outline-primary btn-sm mt-2" id={id} onClick={onClick}>
          {buttonText}
        </button>
      </div>
      {showCalendlyPopup && (
        <PopupModal
          url="https://calendly.com/zingdata/zing-data-demo?hide_gdpr_banner=1&text_color=000000'"
          onModalClose={toggleCalendlyPopup}
          open={showCalendlyPopup}
          rootElement={document.getElementById("app-mount")}
          styles={{ height: "500px" }}
        />
      )}
      {showEmailModal && <ModalSendEmail show={showEmailModal} handleClose={toggleEmailModal} />}
    </>
  );
};

export default memo(SetupHelpBox);
