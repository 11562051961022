import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { Modal, Button, ListGroup } from "react-bootstrap";
import * as yup from "yup";
import InvitationActions from "redux/actions/invitation";
import Alert from "components/alert";
import Loading from "components/loading";
import OrganizationActions from "redux/actions/organization";
import Select from "react-select";
import { ORGGROUPS, ORGGROUPS_VIEWER, PricingTiers } from "helpers/constants";
// import { isFreeEmail } from "helpers/check-free-emails";

const schema = yup.object().shape({
  email: yup.string().email("Email is invalid").required(),
});

const ModalInviteUser = ({ user, show, handleClose }) => {
  let loggedDomain;
  let userAuth;

  if (user) {
    userAuth = user._id;
    loggedDomain = user.email.split("@").pop();
  } else {
    userAuth = localStorage.getItem("userauth");
    const localStorageEmail = localStorage.getItem("email");
    if (localStorageEmail) {
      loggedDomain = localStorageEmail.split("@").pop();
    }
  }

  const { register, errors, formState, setValue, getValues, reset, control } = useForm({
    mode: "onBlur",
  });

  const { loading, error, showSaveSuccess } = useSelector((s) => s.invitation);
  const {
    loading: loadingOrganization,
    error: errorOrganization,
    organizationsByRole,
  } = useSelector((s) => s.organization);

  const [alerts, setAlerts] = useState([]);
  const [emails, setEmails] = useState([]);
  const [userId, setUserId] = useState(userAuth);
  const [allowInviteOutsideDomain, setAllowInviteOutsideDomain] = useState(false);
  const [organizationsSelect, setOrganizationsSelect] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationGroupId, setOrganizationGroupId] = useState(ORGGROUPS[0].value);
  const [emailValidationError, setEmailValidationError] = useState();
  const [hasBillingPlanForSelectedOrg, setHasBillingPlanForSelectedOrg] = useState();

  useEffect(() => {
    if (organizationId) {
      const org = organizationsByRole?.filter((o) => o._id === organizationId).pop();
      const orgPlan = org?.account?.billingPlans;

      if (
        orgPlan &&
        (orgPlan.status === "active" || orgPlan.status === "trialing") &&
        orgPlan.tier !== PricingTiers.BASIC &&
        orgPlan.tier !== PricingTiers.ENTERPRISE
      ) {
        setHasBillingPlanForSelectedOrg(true);
      } else {
        setHasBillingPlanForSelectedOrg(false);
      }
    }
  }, [organizationId, organizationsByRole]);

  const handleChange = (event) => {
    if (event) {
      setOrganizationId(event.value);
    } else {
      setOrganizationId(null);
    }
  };

  const handleChangeOrgGroup = (event) => {
    if (event) {
      setOrganizationGroupId(event.value);
    } else {
      setOrganizationGroupId(null);
    }
  };

  const handleAddEmail = (event) => {
    event.preventDefault();

    const email = getValues("email");
    getEmails(email);
  };

  const onPasteEmail = async (event) => {
    event.preventDefault();

    const text = event.clipboardData.getData("Text");
    if (text) {
      getEmails(text);
    }
  };

  const getEmails = async (email) => {
    let emailsSplit = [];

    if (email) {
      if (email.indexOf(",") > -1) {
        emailsSplit = email.split(",").filter((c) => c);
      } else if (email.indexOf(";") > -1) {
        emailsSplit = email.split(";").filter((c) => c);
      }
    }

    if (emailsSplit.length > 0) {
      let newList = emails;

      for (let _email of emailsSplit) {
        _email = _email.trim();
        const isValid = await validateEmail(_email);
        if (isValid) {
          newList = newList.concat(_email);
        }
      }

      setEmails(newList);
      reset({
        email: null,
      });
    } else {
      setValue("email", email, { shouldValidate: true });

      if (formState.isValid && email && !errors?.email) {
        const isValid = await validateEmail(email);
        if (isValid) {
          const newList = emails.concat(email);
          setEmails(newList);
          reset({
            email: null,
          });
        }
      }
    }
  };

  const handleRemoveEmail = (email) => (event) => {
    event.preventDefault();
    emails.splice(emails.indexOf(email), 1);
    setEmails([...emails]);
  };

  const validateEmail = async (email) => {
    let valid = true;

    const isValidEmail = await schema.isValid({ email: email });

    if (!isValidEmail) {
      valid = false;
      setEmailValidationError({
        type: "manual",
        message: "Invalid Email",
      });
    } else {
      if (emails.indexOf(email) !== -1) {
        setEmailValidationError({
          type: "manual",
          message: "E-mail is already added.",
        });

        valid = false;
      } else {
        if (!allowInviteOutsideDomain) {
          const domain = email.split("@").pop();

          if (loggedDomain.toString() !== domain.toString()) {
            setEmailValidationError({
              type: "manual",
              message: "E-mails from a different domain are not allowed.",
            });

            valid = false;
          }
        }
      }
    }

    if (valid) {
      setEmailValidationError(null);
    }

    return valid;
  };

  const save = () => {
    if (!organizationId) {
      setAlerts([<Alert key="danger" type="danger" message="Organization is required." />]);

      return;
    }

    InvitationActions.create({
      organizationId,
      organizationGroupId,
      emails,
    });
  };

  const close = () => {
    reset({
      email: null,
    });
    setEmails([]);
    setAlerts([]);
    setUserId(null);
    setAllowInviteOutsideDomain(false);
    handleClose();
    setEmailValidationError(null);
  };

  useEffect(() => {
    if (organizationsByRole?.length > 0) {
      const [..._orgs] = organizationsByRole;
      const org = _orgs.find((c) => c.settings?.allowInviteOutsideDomain);
      if (org) {
        setAllowInviteOutsideDomain(true);
      }

      setOrganizationsSelect(
        organizationsByRole.map((c) => ({
          label: c.name,
          value: c._id,
        }))
      );

      const defaultOrg = _orgs.find((c) => c.isDefault);
      setOrganizationId(defaultOrg?._id);
    } else {
      setOrganizationsSelect([]);
      setOrganizationId(null);
    }
  }, [organizationsByRole, userId]);

  useEffect(() => {
    if (error || errorOrganization) {
      setAlerts(<Alert key="danger" type="danger" message={error || errorOrganization} />);
    }
  }, [error, errorOrganization]);

  useEffect(() => {
    if (show) {
      setAlerts([]);
      OrganizationActions.getOrganizationsByRole("admin");
    }
  }, [show]);

  return (
    <Modal backdrop="static" show={show} onHide={close} keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Invite Team Members</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading || loadingOrganization}></Loading>

        <div style={showSaveSuccess ? { display: "none" } : {}}>
          <h6 className={hasBillingPlanForSelectedOrg ? "mb-3" : "mb-5"}>
            Team mates you invite will get an email inviting them to join Zing and will have access to any data sources
            you&apos;ve set up.
          </h6>
          {hasBillingPlanForSelectedOrg && (
            <p className="mb-5 text-success">
              <b>
                The selected organization is on a paid plan. When a user accepts an invite, they&apos;ll be added to
                your plan.
              </b>
            </p>
          )}

          {alerts}

          <form>
            <div className="js-form-message form-group">
              <label className="input-label" htmlFor="signupSrEmail">
                Email Address
              </label>
              <div className="input-group">
                <input
                  type="email"
                  className={"form-control " + (errors.email || emailValidationError?.message ? " is-invalid" : "")}
                  name="email"
                  placeholder="user@example.com"
                  aria-label="user@example.com"
                  ref={register}
                  onPaste={onPasteEmail}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddEmail(e);
                    }
                  }}
                />

                <div className="input-group-append">
                  <a className="btn btn-icon btn-primary" disabled={!formState.isDirty} onClick={handleAddEmail}>
                    <i className="tio-add"></i>
                  </a>
                </div>

                {errors.email ? (
                  <div className="invalid-feedback">{errors.email.message}</div>
                ) : emailValidationError ? (
                  <div className="invalid-feedback">{emailValidationError.message}</div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <ListGroup className="mb-3" variant="flush">
              {emails.length === 0
                ? "Your list is empty, please add an email"
                : emails.map((email) => (
                    <ListGroup.Item key={email} value={email}>
                      <i className="tio-done"></i>
                      {email}
                      <a
                        className="text-danger float-right"
                        style={{ cursor: "pointer" }}
                        onClick={handleRemoveEmail(email)}
                      >
                        <i className="tio-delete-outlined"></i> remove
                      </a>
                    </ListGroup.Item>
                  ))}
            </ListGroup>

            <hr></hr>

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="projectNameNewProjectLabel" className="input-label">
                    Organization
                  </label>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue="something"
                    render={({ ref }) => (
                      <Select
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                        className={errors.type ? " is-invalid" : ""}
                        inputRef={ref}
                        options={organizationsSelect}
                        isClearable
                        value={organizationsSelect?.find((c) => c.value === organizationId)}
                        onChange={handleChange}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="projectNameNewProjectLabel" className="input-label">
                    Role
                    <i
                      className="tio-help-outlined text-body ml-1"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Editors can create and edit questions, Members can ask questions but not edit others' questions, Viewers can only view existing questions"
                    />
                  </label>
                  <Controller
                    name="orggroup"
                    control={control}
                    defaultValue="something"
                    render={({ ref }) => (
                      <Select
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                        className={errors.type ? " is-invalid" : ""}
                        inputRef={ref}
                        options={ORGGROUPS}
                        isClearable
                        value={ORGGROUPS?.find((c) => c.value === organizationGroupId)}
                        onChange={handleChangeOrgGroup}
                        defaultValue={ORGGROUPS.find((o) => o.value === ORGGROUPS_VIEWER)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div style={showSaveSuccess ? {} : { display: "none" }}>
          <div className="text-center">
            <img
              className="img-fluid mb-3"
              src="/assets/svg/illustrations/create.svg"
              alt="Description"
              style={{ maxWidth: "15rem" }}
            />
            <div className="mb-4">
              <h2>Success!</h2>
              <p>Invite(s) successfully sent.</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button
          disabled={emails.length === 0 || !organizationId}
          style={showSaveSuccess ? { display: "none" } : {}}
          variant="primary"
          onClick={save}
        >
          Invite
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalInviteUser;
