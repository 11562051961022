import React, { memo, useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button, Card } from "react-bootstrap";
import DatasourceActions from "redux/actions/datasource";
import Loading from "components/loading";
import Alert from "components/alert";

const AdminSettings = ({ datasource, fetchDatasource, setAlerts }) => {
  const restrictToSelect = useMemo(() => !datasource?.adminSettings?.isCreateDropAllowed, [datasource]);
  const { adminOptionsUpdated } = useSelector((s) => s.datasource);
  const { loading } = useSelector((s) => s.queryManager);

  const [restricted, setRestricted] = useState(restrictToSelect);

  const toggleRestricted = useCallback((e) => {
    setRestricted(e.target.checked);
  }, []);

  const saveSettings = (e) => {
    e.preventDefault();

    DatasourceActions.updateRestrictToSelect(datasource?._id, {
      restrictToSelect: restricted,
    });
  };

  useEffect(() => {
    if (adminOptionsUpdated) {
      setAlerts([<Alert key="success" type="success" message="Saved options successfully." />]);
      fetchDatasource(datasource._id);
      DatasourceActions.clearUpdateRestrictToSelectStatus();
    }
  }, [adminOptionsUpdated]);

  return (
    <Card>
      <Card.Body>
        <Loading loading={loading}></Loading>
        <form className="js-step-form">
          <label className="form-group d-flex align-items-center justify-content-between w-100 toggle-switch">
            <span className="d-block text-dark">
              Restrict users to select statements (no Create, Update, Drop, etc.)
            </span>
            <input
              type="checkbox"
              name="rowLevelSecurity"
              className="toggle-switch-input"
              checked={restricted}
              onChange={toggleRestricted}
              id="rowLevelSecurityCheckbox"
            />
            <span className="toggle-switch-label ml-3">
              <span className="toggle-switch-indicator"></span>
            </span>
          </label>
        </form>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        <Button variant="primary" onClick={saveSettings}>
          {loading ? "Saving" : "Save"}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default memo(AdminSettings);
