import React, { useEffect, useState, useCallback } from "react";
import Loading from "components/loading";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment";

const ModalListGoogleDriveFiles = ({ show, onHide, onSelect, accessToken, getSheetNames }) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState(files);
  const [selectedFile, setSelectedFile] = useState({});
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    let searchedFiles;

    if (e.target.value) {
      searchedFiles = files?.filter((file) => {
        return file.name.toLowerCase().includes(e.target.value.toLowerCase());
      });
    } else {
      searchedFiles = files;
    }

    setFilteredFiles(searchedFiles);
  };

  const getQuery = (criteria) => {
    let q = "mimeType='application/vnd.google-apps.spreadsheet'";

    if (criteria) {
      q = `${q} and name contains '${criteria}'`;
    }

    return q;
  };

  const getGoogleFiles = useCallback(async (criteria = "", accessToken) => {
    try {
      setLoading(true);

      const responseFiles = await axios.get(`https://www.googleapis.com/drive/v3/files`, {
        params: {
          q: getQuery(criteria),
          fields: "files(id,name,createdTime,modifiedTime,size,owners,exportLinks)",
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setFiles(responseFiles.data.files);
      setFilteredFiles(responseFiles.data.files);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      getGoogleFiles("", accessToken);
    }
  }, [accessToken, getGoogleFiles]);

  const close = () => {
    setFiles([]);
    setSearch("");
    onHide();
  };

  const selectSheet = (file) => {
    for (const _file of files) {
      if (_file.id === file.id) {
        setSelectedFile(_file);
      }

      _file.selected = _file.id === file.id;
    }

    setFiles([...files]);
  };

  const doSave = () => {
    onSelect(selectedFile);
    getSheetNames(selectedFile);
    onHide();
  };

  return (
    <Modal backdrop="static" size="xl" header="Google Drive Files" keyboard={false} show={show} onHide={close} centered>
      <Modal.Header closeButton>
        <Modal.Title>Please select your google drive spreadsheet</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Loading loading={loading}></Loading>

        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className={"form-control"}
                name="search"
                id="search"
                onChange={handleSearchChange}
                value={search}
                placeholder="Search by file name"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-sm-12"
            style={{
              maxHeight: "450px",
              display: "block",
              overflowY: "auto",
            }}
          >
            <table className="table table-lg table-thead-bordered table-nowrap table-align-middle card-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Last Modified</th>
                </tr>
              </thead>

              <tbody>
                {!filteredFiles || filteredFiles.length === 0
                  ? ""
                  : filteredFiles.map((file, index) => {
                      return (
                        <tr
                          onClick={() => selectSheet(file)}
                          key={`${file.name}-${index}`}
                          style={{
                            backgroundColor: file.selected ? "rgba(0,0,0,0.05)" : "",
                            color: file.selected ? "#212529" : "",
                            cursor: "pointer",
                          }}
                        >
                          <td title={file.name}>
                            <div className="d-inline-block mr-2">
                              <img
                                style={{ width: "25px" }}
                                alt="sheet"
                                src={`/assets/img/databases/google_sheets.png`}
                              />
                            </div>
                            {file.name.length > 50 ? `${file.name.substring(0, 50)}...` : file.name}
                          </td>
                          <td>{file?.owners[0]?.displayName}</td>
                          <td>{moment(file.modifiedTime).fromNow()}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>

        <Button variant="primary" type="submit" onClick={doSave} disabled={!selectedFile.id}>
          Select
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalListGoogleDriveFiles;
