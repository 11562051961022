import React, { memo, useCallback, useEffect, useState, useMemo } from "react";
import * as yup from "yup";
import Select from "react-select";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Button, Card } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleLogin } from "@react-oauth/google";

import QueryManagerActions from "redux/actions/query-manager";
import DatasourceActions from "redux/actions/datasource";
import LoginActions from "redux/actions/login";
import Loading from "components/loading";
import Alert from "components/alert";
import { googleOAuthTokenErrors } from "helpers/constants";

const schema = yup.object().shape({
  table: yup.string().required("Table is required"),
  field: yup.string().required("Email field is required"),
});

const RowLevelSecuritySettings = ({ datasource, fetchDatasource, setAlerts, user }) => {
  const lookupField = useMemo(() => datasource?.tables?.find((t) => (t.lookupEmailField ? t : false)), [datasource]);
  const { showSaveTablesSuccess, tokensRefreshed } = useSelector((s) => s.datasource);
  const { jobId, queryResult, loadingTablesAndColumns, loading, connectionErr, timeoutError, jobState } = useSelector(
    (s) => s.queryManager
  );
  const { tokens } = useSelector((s) => s.login);

  const {
    control,
    setValue,
    getValues,
    formState: { isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [hasRowLevelSecurity, setHasRowLevelSecurity] = useState(!!lookupField);
  const [tableOptions, setTableOptions] = useState();
  const [selectedTable, setSelectedTable] = useState();
  const [columnOptions, setColumnOptions] = useState();
  const [selectedField, setSelectedField] = useState();
  const [hasError, setHasError] = useState();

  const isDisabled = useMemo(() => {
    if (lookupField && !hasRowLevelSecurity) {
      return false;
    } else if (hasRowLevelSecurity && isValid) {
      return false;
    }

    return true;
  }, [lookupField, hasRowLevelSecurity, isValid]);

  const toggleRowLevelSecurity = useCallback((e) => {
    setHasRowLevelSecurity(e.target.checked);

    setSelectedTable();
    setSelectedField();
  }, []);

  const saveSettings = (e) => {
    e.preventDefault();

    DatasourceActions.updateTables(datasource._id, {
      tables: datasource.tables.map((table) => {
        let columns = table.columnSchema;

        if (!columns) {
          const tableWithColumns = queryResult?.filter((t) => t.table_name === selectedTable.tableName).shift();
          columns = tableWithColumns?.column_schema;
        }

        const updatedTable = {
          tableName: table?.tableName,
          schemaName: table.schemaName,
          labelName: table.labelName,
          included: table.included,
          columnSchema: columns,
        };

        if (hasRowLevelSecurity && table.tableName === selectedTable?.tableName) {
          updatedTable.lookupEmailField = selectedField;
        }

        return updatedTable;
      }),
    });
  };

  const handleTableChange = (event) => {
    if (event) {
      const previousTable = getValues("table");
      setValue("table", event.value, { shouldValidate: true });
      const table = datasource?.tables?.find((t) => t.tableName === event.value);
      setSelectedTable(table);

      if (previousTable !== event.value) {
        setValue("field", "", { shouldValidate: true });
        setSelectedField();
      }
    } else {
      setValue("table", "", { shouldValidate: true });
      setSelectedTable();
      setSelectedField();
    }
  };

  const handleFieldChange = (event) => {
    if (event) {
      setValue("field", event.value, { shouldValidate: true });
      setSelectedField(event.value);
    } else {
      setValue("field", "", { shouldValidate: true });
      setSelectedField();
    }
  };

  // eslint-disable-next-line no-console
  const onError = (errors, e) => console.log("onError", errors, e);

  const responseGoogle = async (response) => {
    LoginActions.getGoogleTokens({
      code: response.code,
    });
  };

  const loginGoogleAuthFlow = useGoogleLogin({
    onSuccess: responseGoogle,
    onFailure: onError,
    scope: "https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/drive.readonly",
    flow: "auth-code",
    hint: user.email,
  });

  useEffect(() => {
    if (tokens) {
      DatasourceActions.updateLiveGoogleSheetTokens(datasource._id, {
        accessToken: tokens.tokens.access_token,
        refreshToken: tokens.tokens.refresh_token,
      });
    }
  }, [tokens]);

  useEffect(() => {
    if (tokensRefreshed) {
      DatasourceActions.clearLiveSheetTokenRefreshStatus();
      window.location.reload();
    }
  }, [tokensRefreshed]);

  useEffect(() => {
    if (timeoutError || (queryResult !== undefined && queryResult !== null && !Array.isArray(queryResult))) {
      setHasError(true);

      let errorMessage;

      if (googleOAuthTokenErrors.includes(connectionErr)) {
        if (user?._id?.toString() === datasource?.userId?.toString()) {
          errorMessage =
            "Your Google access token has expired. Please refresh your access by following the Google auth flow via the popup.";
        } else {
          errorMessage =
            "The Google access token for this datasource has expired. Please contact the owner of the datasource to refresh access.";
        }

        setAlerts([<Alert key="danger" type="danger" message={errorMessage} details={connectionErr} />]);

        loginGoogleAuthFlow();
      } else {
        setAlerts([
          <Alert
            key="danger"
            type="danger"
            message="Connection to database failed, please check your connection settings."
            details={connectionErr}
          />,
        ]);
      }
    } else if (jobState === "completed") {
      setAlerts([]);
      setHasError(false);
    }
  }, [connectionErr]);

  useEffect(() => {
    if (lookupField) {
      const table = datasource?.tables?.find((t) => t.tableName === lookupField.tableName);
      setSelectedTable(table);
      setSelectedField(lookupField.lookupEmailField);
      setValue("field", lookupField.lookupEmailField);
    }
  }, [lookupField]);

  useEffect(() => {
    if (jobId) {
      QueryManagerActions.getQueryById(jobId);
    }
  }, [jobId]);

  useEffect(() => {
    const datasourceTables = datasource?.tables;
    const hasColumns = datasourceTables?.every((obj) => obj.columnSchema && obj.columnSchema.length);

    if (!hasColumns) {
      QueryManagerActions.getTablesAndColumns({ datasource: datasource?._id, version: 2 });
    }

    const options = datasourceTables?.map((table) => {
      if (table.included) {
        return {
          label: table.labelName,
          value: table.tableName,
        };
      }
    });

    setTableOptions(options.filter(Boolean));
  }, [datasource?.tables]);

  useEffect(() => {
    let columns = selectedTable?.columnSchema;
    if (!columns) {
      const tableWithColumns = queryResult?.filter((t) => t.table_name === selectedTable?.tableName).shift();
      columns = tableWithColumns?.column_schema;
    }

    const options = columns?.map((col) => {
      const type = col.dataType?.toLowerCase();
      if (
        type === "character varying" ||
        col.columnName?.toLowerCase()?.includes("email") ||
        type === "text" ||
        type === "string" ||
        type === "character"
      ) {
        return {
          label: col.columnName,
          value: col.columnName,
        };
      }
    });

    setColumnOptions(options?.filter(Boolean));
  }, [selectedTable, queryResult]);

  useEffect(() => {
    if (showSaveTablesSuccess) {
      setAlerts([<Alert key="success" type="success" message="Saved lookup table successfully." />]);
      fetchDatasource(datasource._id);
      DatasourceActions.hideDataSourceCreate();
    }
  }, [showSaveTablesSuccess]);

  return (
    <Card>
      <Card.Body>
        <Loading loading={loadingTablesAndColumns || loading}></Loading>
        <form className="js-step-form">
          <label
            className={classNames("form-group d-flex align-items-center justify-content-between w-100", {
              "toggle-switch": !hasError,
            })}
          >
            <span className="d-block text-dark">
              Row level security (see{" "}
              <a href="https://docs.getzingdata.com/docs/row-level-security/" rel="noopener noreferrer" target="_blank">
                documentation
              </a>
              )
            </span>
            <input
              type="checkbox"
              name="rowLevelSecurity"
              className="toggle-switch-input"
              checked={hasRowLevelSecurity}
              onChange={toggleRowLevelSecurity}
              id="rowLevelSecurityCheckbox"
              disabled={hasError}
            />
            <span className="toggle-switch-label ml-3">
              <span className="toggle-switch-indicator"></span>
            </span>
          </label>
          {hasRowLevelSecurity && (
            <>
              <p>Read user attributes from:</p>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label className="input-label">Table</label>
                      <Controller
                        name="table"
                        control={control}
                        render={() => {
                          let selectedValue;
                          if (!selectedTable) {
                            selectedValue = null;
                          } else {
                            selectedValue = tableOptions?.find((c) => c.value === selectedTable.tableName);
                          }

                          return (
                            <Select
                              styles={{
                                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                              }}
                              options={tableOptions}
                              isClearable
                              value={selectedValue}
                              onChange={handleTableChange}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label className="input-label">User Email Field</label>
                      <Controller
                        name="field"
                        control={control}
                        render={() => {
                          let selectedValue;
                          if (!selectedField) {
                            selectedValue = null;
                          } else {
                            selectedValue = columnOptions?.find((c) => c.value === selectedField);
                          }

                          return (
                            <Select
                              styles={{
                                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                              }}
                              options={columnOptions}
                              isClearable
                              value={selectedValue}
                              onChange={handleFieldChange}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        <Button variant="primary" onClick={saveSettings} disabled={isDisabled}>
          {isSubmitting ? "Saving" : "Save"}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default memo(RowLevelSecuritySettings);
