import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { PricingTiers } from "helpers/constants";
import { capitalize } from "helpers/capitalize";
import { useToggle } from "hooks/useToggle";
import Actions from "redux/actions/organization";
import AccountActions from "redux/actions/account";

import Footer from "components/footer/footermain";
import Header from "components/navbar/header";
import Table from "components/table/table";
import ModalConfirmation from "components/form/modal-confirmation";
import Alert from "components/alert";
import ModalAddOrganization from "components/modal/modal-add-organization";
import ModalUpgradePlan from "components/modal/modal-upgrade-plan";
import AuthService from "../../helpers/auth";

const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: "smooth" });

const OrganizationPage = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const [upgradeId, setUpgradeId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [showCreateModal, setCreateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isUpgradeModalOpen, toggleUpgradeModal] = useToggle();

  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const errorsRef = useRef(null);
  const executeScroll = () => scrollToRef(errorsRef);

  const user = useSelector((s) => s.login.user);
  const { error, data, items, pages, loading, showDeleteSuccess } = useSelector((s) => s.organization);
  const { accountData } = useSelector((s) => s.account);
  const {
    user: { _id: userId },
  } = AuthService.getUser();

  const hasBillingPlan = useMemo(() => {
    let hasBillingPlan = false;
    // eslint-disable-next-line no-unused-expressions
    accountData?.billingPlans?.map((org) => {
      const tier = org?.tier;
      if (tier === PricingTiers.STANDARD || tier === PricingTiers.PRO || tier === PricingTiers.ENTERPRISE) {
        hasBillingPlan = true;
      }
    });
    localStorage.setItem("hasPlan", hasBillingPlan);
    return hasBillingPlan;
  }, [data]);

  const handleCloseCreateModal = () => {
    setCreateModal(false);
    Actions.hideOrganizationCreate();
    refreshData();
  };

  const handleShowCreateModal = () => {
    setAlerts([]);
    setCreateModal(true);
  };

  const handleCloseUpgradeModal = () => {
    toggleUpgradeModal();
    AccountActions.clearUpgradeState();
    refreshData();
  };

  const handleConfirmDeleteModal = () => {
    setShowDelete(false);
    Actions.delete(deleteId);
    setDeleteId("");
    setDeleteMessage("");
  };

  const handleCloseDeleteModal = () => {
    setShowDelete(false);
    setDeleteId("");
    setDeleteMessage("");
  };

  const onChangeSearch = (e) => {
    const input = e.currentTarget.value;
    // eslint-disable-next-line no-useless-escape
    if (/^[a-zA-Z0-9!@#$%^&()_\-=\[\]{};':"\\|,.<>\/?]*$/.test(input)) {
      setSearchValue(input);
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchQuery(searchValue), 500);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  const handleEditClick = (data) => {
    window.location = `/organization/${data._id}`;
  };

  const handleDeleteClick = (data) => {
    let message = "Are you sure you want to delete this organization? This cannot be undone.";
    if (
      data?.account?.billingPlans &&
      data?.account?.billingPlans?.tier !== "basic" &&
      (data?.account?.billingPlans?.status === "active" || data?.account?.billingPlans?.status === "trialing")
    ) {
      message =
        "Are you sure you want to delete this organization? Your billing plan for the organization will be cancelled. This cannot be undone.";
    }

    setDeleteId(data._id);
    setDeleteMessage(message);
    setShowDelete(true);
  };

  const refreshData = () => {
    setRefresh(true);
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const handleUpgradeClick = (data) => {
    setUpgradeId(data._id);
    toggleUpgradeModal();
  };

  const handleManageBilling = useCallback(() => {
    AccountActions.manageBilling();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Organization Name",
        id: "name",
        acessor: "name",
        Cell: (row) => {
          const { owner, account } = row.row.original;
          const tier = account?.billingPlans?.tier;
          const tierStatus = account?.billingPlans?.status;

          let badgeColor;
          if (!tier || tier === PricingTiers.BASIC) {
            badgeColor = "badge-success";
          } else if (tier === PricingTiers.STANDARD) {
            badgeColor = "badge-info";
          } else if (tier === PricingTiers.PRO) {
            badgeColor = "badge-warning";
          } else {
            badgeColor = "badge-danger";
          }

          return (
            <a
              onClick={() => {
                if (owner && owner[0]?._id === userId) {
                  handleEditClick(row.row.original);
                }
              }}
            >
              <div className="ml-3">
                <span
                  className={`d-block h5 mb-0 ${owner && owner[0]?._id === userId ? "text-hover-primary" : ""}`}
                  style={{ cursor: owner && owner[0]?._id === userId ? "pointer" : "default" }}
                >
                  {row.row.original.name}
                </span>

                {owner && owner[0]?._id === userId ? <span className="badge badge-primary">Owner</span> : ""}
                <span className={`badge ${badgeColor} ${owner && owner[0]?._id === userId ? " ml-1" : ""}`}>
                  {capitalize(account?.billingPlans?.tier || "basic")}
                </span>
                {tierStatus === "trialing" && <span className="badge badge-secondary ml-1">Trial</span>}
              </div>
            </a>
          );
        },
        canSort: true,
      },
      {
        Header: "Date Created",
        accessor: "timeCreated",
        Cell: (row) => {
          return format(new Date(row.row.original.timeCreated), "MMM dd, yyyy");
        },
      },
      {
        Header: "Owner",
        id: "owner",
        Cell: (row) => {
          return row.row.original.owner[0]?.roles.account.name || "";
        },
        canSort: true,
      },
      {
        Header: "",
        id: "actions",
        Cell: (row) => {
          const { owner, isDefault, account } = row.row.original;
          const { billingPlans = [] } = account || {};

          return (
            <div className="d-flex align-items-center">
              <button className="btn btn-sm btn-outline-secondary" onClick={() => handleEditClick(row.row.original)}>
                <i className="tio-settings-outlined"></i> Settings
              </button>
              {owner &&
                owner[0]?._id === userId &&
                (billingPlans?.tier === PricingTiers.BASIC || !billingPlans?.tier) && (
                  <button
                    className="btn btn-sm btn-outline-success ml-2"
                    onClick={() => handleUpgradeClick(row.row.original)}
                  >
                    <i className="tio-arrow-upward"></i> Upgrade
                  </button>
                )}
              {owner && owner[0]?._id === userId && !isDefault && (
                <button
                  className="btn btn-sm btn-outline-danger ml-2"
                  onClick={() => handleDeleteClick(row.row.original)}
                >
                  <i className="tio-delete-outlined"></i> Delete
                </button>
              )}
            </div>
          );
        },
        sortable: false,
        filterable: false,
      },
    ],
    []
  );

  const fetchData = useCallback(({ pageSize, pageIndex, sort, filtered }) => {
    Actions.getOrganization({ limit: pageSize, page: pageIndex + 1, sort, q: filtered });
  }, []);

  useEffect(() => {
    if (showDeleteSuccess) {
      refreshData();
      setAlerts([<Alert key="success" type="success" message="Organization deleted successfully." />]);
    }
  }, [showDeleteSuccess]);

  useEffect(() => {
    if (error) {
      setAlerts([<Alert key="danger" type="danger" message={error} />]);
      executeScroll();
    }
  }, [error]);

  useEffect(() => {
    window.dashboardOnReady();
    const origBodyClass = document.body.className;
    document.body.className = "footer-offset";

    return () => {
      document.body.className = origBodyClass;
    };
  }, []);

  return (
    <>
      <Header user={user} />

      <main id="content" role="main" className="main" style={isMobile ? { paddingTop: "7.75rem" } : {}}>
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-title">Organizations</h1>
              </div>

              <div className="col-sm-auto">
                {hasBillingPlan && (
                  <a className="btn btn-outline-secondary mr-2" onClick={handleManageBilling}>
                    Manage Billing
                  </a>
                )}
                <a className="btn btn-primary" onClick={handleShowCreateModal}>
                  New organization
                </a>
              </div>
            </div>
          </div>

          <div ref={errorsRef}>{alerts}</div>

          <div className="card">
            <div className="card-header">
              <div className="row justify-content-between align-items-center flex-grow-1">
                <div className="col-sm-6 col-md-4 mb-3 mb-sm-0">
                  <form>
                    <div className="input-group input-group-merge input-group-flush">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="tio-search"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        value={searchValue}
                        onChange={onChangeSearch}
                        className="form-control"
                        placeholder="Search organizations"
                        aria-label="Search organizations"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Table
              columns={columns}
              data={data ?? []}
              items={items ?? []}
              loading={loading}
              filtered={searchQuery}
              fetchData={fetchData}
              pageCount={pages?.total ?? 0}
              refresh={refresh}
              cancelRefresh={() => setRefresh(false)}
            ></Table>
          </div>
        </div>
        {showCreateModal && <ModalAddOrganization show={showCreateModal} handleClose={handleCloseCreateModal} />}
        <Footer />
      </main>

      {showDelete && (
        <ModalConfirmation
          title="You are about to delete an organization"
          message={deleteMessage}
          confirmButtonText="Yes, delete it!"
          show={showDelete}
          handleClose={handleCloseDeleteModal}
          handleConfirm={handleConfirmDeleteModal}
        />
      )}
      {isUpgradeModalOpen && upgradeId && (
        <ModalUpgradePlan
          show={isUpgradeModalOpen}
          handleClose={handleCloseUpgradeModal}
          organizationId={upgradeId}
          hasAtLeastOneBillingPlan={hasBillingPlan}
        />
      )}
    </>
  );
};

export default OrganizationPage;
