export const datasources = [
  {
    value: "postgres",
    label: "PostgreSQL",
  },
  {
    value: "mysql",
    label: "MySQL",
  },
  {
    value: "google_bigquery",
    label: "Google BigQuery",
  },
  {
    value: "google_sheets",
    label: "Google Sheets",
  },
  {
    value: "uploaded_csv",
    label: "Excel or CSV",
  },
  {
    value: "snowflake",
    label: "Snowflake",
  },
  {
    value: "trino",
    label: "Starburst / Trino",
  },
  {
    value: "presto",
    label: "Presto",
  },
  {
    value: "amazon_redshift",
    label: "Amazon Redshift",
  },
  {
    value: "databricks",
    label: "Databricks",
  },
  {
    value: "mssql",
    label: "SQL Server",
  },
  {
    value: "clickhouse",
    label: "ClickHouse",
  },
];

export const datasourceTypes = {
  POSTGRES: "postgres",
  MYSQL: "mysql",
  GOOGLE_BIGQUERY: "google_bigquery",
  GOOGLE_SHEETS: "google_sheets",
  GOOGLE_SHEETS_LIVE: "google_sheets_live",
  SNOWFLAKE: "snowflake",
  TRINO: "trino",
  PRESTO: "presto",
  AMAZON_REDSHIFT: "amazon_redshift",
  DATABRICKS: "databricks",
  MICROSOFT_SQL: "mssql",
  UPLOADED_CSV: "uploaded_csv",
  CLICKHOUSE: "clickhouse",
};

export const DATASOURCES_WITH_SSL_CUSTOMCERTS = [
  datasourceTypes.CLICKHOUSE,
  datasourceTypes.POSTGRES,
  datasourceTypes.MYSQL,
  datasourceTypes.MICROSOFT_SQL,
];

export const DATASOURCES_WITH_SSHTUNNEL = [datasourceTypes.POSTGRES, datasourceTypes.MYSQL];

// eslint-disable-next-line no-useless-escape
export const GOOGLE_SHEETS_URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^https?\:\/\/(www\.|docs\.)?google\.[a-z]+\/spreadsheets\/d\/\*?([a-zA-Z0-9\-]*)\/?([a-zA-Z0-9\-_#&=/?]*)$/i;

export const SNOWFLAKE_HOSTNAME_REGEX = /^http(s)?:\/\//i;

export const datasourceGoogleSheetSteps = [
  {
    step: 0,
    title: "Configure",
  },
  {
    step: 1,
    title: "Import",
  },
  {
    step: 2,
    title: "Confirm",
  },
];

export const datasourceLiveGoogleSheetSteps = [
  {
    step: 0,
    title: "Configure",
  },
  {
    step: 1,
    title: "Connect",
  },
  {
    step: 2,
    title: "Confirm",
  },
];

export const datasourceRegularSteps = [
  {
    step: 0,
    title: "Configure",
  },
  {
    step: 1,
    title: "Tables",
  },
  {
    step: 2,
    title: "Confirm",
  },
];

export const datasourceExcelAndCsvSteps = [
  {
    step: 0,
    title: "Configure",
  },
  {
    step: 1,
    title: "Tables",
  },
  {
    step: 2,
    title: "Import",
  },
  {
    step: 3,
    title: "Confirm",
  },
];

export const queueNames = {
  QUERYWORKER: "queryworker",
  INGESTION: "ingestion",
  DB_IMPORTER: "db-importer",
};

export const ORGGROUPS = [
  {
    value: "viewer",
    label: "Viewer",
  },
  {
    value: "member",
    label: "Member",
  },
  {
    value: "editor",
    label: "Editor",
  },
  {
    value: "admin",
    label: "Admin",
  },
];

export const ORGGROUPS_ADMIN = "admin";
export const ORGGROUPS_VIEWER = "viewer";
export const ORGGROUPS_MEMBER = "member";
export const ORGGROUPS_EDITOR = "editor";

export const msSqlAuthTypes = [
  {
    value: "default",
    label: "Default Authentication",
  },
  {
    value: "azure-active-directory-msi-app-service",
    label: "Azure Active Directory Msi App Service Authentication",
  },
  {
    value: "azure-active-directory-msi-vm",
    label: "Azure Active Directory Msi Vm Authentication",
  },
  {
    value: "azure-active-directory-access-token",
    label: "Azure Active Directory Access Token Authentication",
  },
  {
    value: "azure-active-directory-password",
    label: "Azure Active Directory Password Authentication",
  },
  {
    value: "azure-active-directory-service-principal-secret",
    label: "Azure Active Directory Service Principal Secret",
  },
  {
    value: "ntlm",
    label: "Ntlm Authentication",
  },
];

export const DEMO_DATABASE = {
  host: "demodatabase01.getzingdata.com",
  databasename: "animal",
  port: 15432,
  type: "postgres",
};

export const PricingTiers = {
  BASIC: "basic",
  STANDARD: "standard",
  PRO: "pro",
  ENTERPRISE: "enterprise",
};

export const datasourceSetupHelp = [
  {
    id: "help-schedule-call",
    title: "Get help from our team",
    description: "Schedule a session with us to get help with setting up or to ask any questions.",
    buttonText: "Schedule a call",
    type: "call",
  },
  {
    id: "help-read-docs",
    title: "Read documentation",
    description: "Get step-by-step instructions for connecting a datasource.",
    buttonText: "View docs",
    type: "docs",
  },
  {
    id: "help-email-teammate",
    title: "Ask a teammate for credentials",
    description: "Send an email to a teammate for help connecting a datasource.",
    buttonText: "Send an email",
    type: "email",
  },
];

export const stringTypes = ["string", "text", "character varying", "character", "varchar", "UUID", "nvarchar"];

export const numericTypes = ["numeric", "integer", "number", "smallint", "bigint", "int64", "int32"];

export const DATASOURCES_WITH_NO_JOINS = [datasourceTypes.GOOGLE_SHEETS, datasourceTypes.GOOGLE_SHEETS_LIVE];

export const csvUpdateTableActions = [
  {
    label: "Create new table",
    value: "create",
  },
  {
    label: "Append",
    value: "append",
  },
  {
    label: "Replace *",
    value: "replace",
  },
];

export const googleOAuthTokenErrors = ["invalid_grant", "invalid_token", "invalid token", "invalid_rapt"];

export const sampleDatasource = {
  host: "demodatabase01.getzingdata.com",
  databasename: "animal",
};

export const ssoProviders = {
  AZURE_AD: "azureAd",
  OKTA: "okta",
};

export const defaultColors = [
  "#4B87B9",
  "#C06C84",
  "#F67280",
  "#F8B195",
  "#74B49B",
  "#00A8B5",
  "#494CA2",
  "#FFCD60",
  "#FFF0DB",
  "#EEEEEE",
];

export const fontOptions = [
  {
    label: "Arial",
    value: "Arial",
  },
  {
    label: "Noto Sans",
    value: "NotoSans",
  },
];

export const fewshotTypes = [
  {
    label: "Regular",
    value: "regular",
  },
  {
    label: "Analysis question",
    value: "analysis",
  },
  {
    label: "Run question as custom SQL if exact match",
    value: "exactmatch",
  },
];
