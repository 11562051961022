import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  accountData: null,
  loading: false,
  success: false,
  error: null,
  hasError: false,
  creatingSession: false,
  upgradeSuccess: false,
  upgradeError: null,
  adminsOfAllOrgs: null,
  loadingAdmins: false,
  loadingTransfer: false,
  transferSuccess: false,
  transferError: null,
};

const reducer = function (state = initialState, action) {
  if (action.type === Constants.ACCOUNTS_PLANS_GET) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.ACCOUNTS_PLANS_GET_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      success: !action.err,
      error: validation.error,
      hasError: !!validation.error,
      accountData: validation.error ? null : action.response?.accountData,
    };
  }

  if (action.type === Constants.ACCOUNTS_UPGRADE_ORG) {
    return {
      ...state,
      creatingSession: true,
    };
  }

  if (action.type === Constants.ACCOUNTS_UPGRADE_ORG_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      creatingSession: false,
      upgradeSuccess: !action.err,
      upgradeError: validation.error,
      hasError: !!validation.error,
    };
  }

  if (action.type === Constants.ACCOUNTS_UPGRADE_CLEAR_STATE) {
    return {
      ...state,
      creatingSession: false,
      upgradeSuccess: false,
      upgradeError: null,
    };
  }

  if (action.type === Constants.ACCOUNTS_GET_ADMINS_OF_ALL_ORGS) {
    return {
      ...state,
      loadingAdmins: true,
    };
  }

  if (action.type === Constants.ACCOUNTS_GET_ADMINS_OF_ALL_ORGS_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingAdmins: false,
      adminsOfAllOrgs: action.response,
      errorLoadingAdmins: validation.error,
    };
  }

  if (action.type === Constants.ACCOUNT_TRANSFER_OWNERSHIP) {
    return {
      ...state,
      loadingTransfer: true,
    };
  }

  if (action.type === Constants.ACCOUNT_TRANSFER_OWNERSHIP_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingTransfer: false,
      transferSuccess: !action.err,
      transferError: validation.error,
    };
  }

  return state;
};

export default reducer;
