import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  hydrated: false,
  loading: false,
  loadingDeleteAccount: false,
  showSaveSuccess: false,
  showDeleteSuccess: false,
  error: undefined,
  hasError: {},
  help: {},
  account: {},
  type: "",
};
const reducer = function (state = initialState, action) {
  if (action.type === Constants.GET_DETAILS) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.GET_DETAILS_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      account: action.response,
    };
  }

  if (action.type === Constants.SAVE_DETAILS) {
    return {
      ...state,
      loading: true,
      name: action.request.data.name,
      type: "",
    };
  }

  if (action.type === Constants.SAVE_DETAILS_RESPONSE) {
    const validation = ParseValidation(action.response);
    return {
      ...state,
      loading: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      type: "name",
    };
  }

  if (action.type === Constants.HIDE_DETAILS_SAVE_SUCCESS) {
    return {
      ...state,
      showSaveSuccess: false,
    };
  }

  if (action.type === Constants.SAVE_PASSWORD) {
    return {
      ...state,
      loading: true,
      type: "",
    };
  }

  if (action.type === Constants.SAVE_PASSWORD_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      user: action.response.user,
      type: "password",
    };
  }

  if (action.type === Constants.SAVE_SETTINGS) {
    return {
      ...state,
      loading: true,
      type: "",
    };
  }

  if (action.type === Constants.SAVE_SETTINGS_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      user: action.response.user,
      type: "settings",
    };
  }

  if (action.type === Constants.SAVE_ORGANIZATION_SETTINGS) {
    return {
      ...state,
      loading: true,
      type: "",
    };
  }

  if (action.type === Constants.SAVE_ORGANIZATION_SETTINGS_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      type: "organizations",
    };
  }

  if (action.type === Constants.DELETE_ACCOUNT) {
    return {
      ...state,
      loadingDeleteAccount: true,
    };
  }

  if (action.type === Constants.DELETE_ACCOUNT_RESPONSE) {
    const validation = ParseValidation(action.response);
    return {
      ...state,
      loadingDeleteAccount: false,
      showDeleteSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.SAVE_PICTURE) {
    return {
      ...state,
      loading: true,
      newPhotoUrl: null,
      type: "",
    };
  }

  if (action.type === Constants.SAVE_PICTURE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      newPhotoUrl: action.response?.photoUrl,
      type: "picture",
    };
  }

  return state;
};

export default reducer;
