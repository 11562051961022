import React, { memo } from "react";
import { datasources } from "helpers/constants";
import DatasourceTypeBox from "./datasource-type-box";

const DatasourceTypesSection = ({ handleShow }) => {
  return (
    <div className="card mt-5 mx-3 mx-md-0">
      <div className="card-header">
        <h2 className="card-title h4">Set up a data source</h2>
      </div>
      <div className="card-body d-flex flex-wrap">
        {datasources.map((d) => (
          <div className="col-12 col-md-6 col-lg-4 col-xl-3" key={d.label}>
            <DatasourceTypeBox name={d.label} value={d.value} handleShow={handleShow} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(DatasourceTypesSection);
