import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class OrganizationActions {
  static async getOrganization(query = {}) {
    ApiActions.get(
      "/api/organizations/my",
      query,
      Store,
      Constants.ORGANIZATION_MY_GET,
      Constants.ORGANIZATION_MY_GET_RESPONSE
    );
  }

  static async getOwnerOrganization() {
    ApiActions.get(
      "/api/organizations/my/owner",
      undefined,
      Store,
      Constants.ORGANIZATION_OWNER_GET,
      Constants.ORGANIZATION_OWNER_GET_RESPONSE
    );
  }

  static async getOrganizationsByRole(role) {
    ApiActions.get(
      `/api/organizations/my/${role}`,
      undefined,
      Store,
      Constants.ORGANIZATION_GET_BY_ROLE,
      Constants.ORGANIZATION_GET_BY_ROLE_RESPONSE
    );
  }

  static async getOrganizationById(id) {
    ApiActions.get(
      `/api/organizations/${id}`,
      undefined,
      Store,
      Constants.ORGANIZATION_GET_BY_ID,
      Constants.ORGANIZATION_GET_BY_ID_RESPONSE
    );
  }

  static async transferOwnership(data) {
    ApiActions.put(
      `/api/organizations/transfer-ownership`,
      {
        organizations: data,
      },
      Store,
      Constants.ORGANIZATION_TRANSFER_OWNERSHIP,
      Constants.ORGANIZATION_TRANSFER_OWNERSHIP_RESPONSE
    );
  }

  static hideOrganizationCreate() {
    Store.dispatch({
      type: Constants.ORGANIZATION_CREATE_HIDE,
    });
  }

  static async create(data) {
    ApiActions.post(
      "/api/organizations",
      data,
      Store,
      Constants.ORGANIZATION_CREATE,
      Constants.ORGANIZATION_CREATE_RESPONSE
    );
  }

  static async update(id, data) {
    ApiActions.put(
      `/api/organizations/${id}`,
      data,
      Store,
      Constants.ORGANIZATION_SAVE,
      Constants.ORGANIZATION_SAVE_RESPONSE
    );
  }

  static async delete(id) {
    ApiActions.delete(
      `/api/organizations/${id}`,
      undefined,
      Store,
      Constants.ORGANIZATION_DELETE,
      Constants.ORGANIZATION_DELETE_RESPONSE
    );
  }

  static updateSaveSuccess() {
    Store.dispatch({
      type: Constants.ORGANIZATION_UPDATE_SAVE_SUCCESS,
    });
  }

  static clearTransferState() {
    Store.dispatch({
      type: Constants.ORGANIZATION_CLEAR_TRANSFER_STATE,
    });
  }

  static async updatePersonalizationSettings(id, data) {
    ApiActions.put(
      `/api/v1/organizations/${id}/personalization`,
      data,
      Store,
      Constants.ORGANIZATION_SAVE_PERSONALIZATION,
      Constants.ORGANIZATION_SAVE_PERSONALIZATION_RESPONSE,
      null,
      true
    );
  }

  static async getCustomLogo() {
    ApiActions.get(
      "/api/v1/organizations/logo",
      undefined,
      Store,
      Constants.ORGANIZATION_GET_LOGO,
      Constants.ORGANIZATION_GET_LOGO_RESPONSE
    );
  }

  static clearSaveState() {
    Store.dispatch({
      type: Constants.ORGANIZATION_CLEAR_SAVE_STATE,
    });
  }
}

export default OrganizationActions;
