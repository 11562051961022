import React, { memo } from "react";

const SuccessMessage = ({ first, handleToApp, datasourceId }) => {
  if (!first) {
    return (
      <div className="text-center mt-5 my-4">
        <img
          className="img-fluid mb-3"
          src="./assets/img/check-circle.svg"
          alt="Check circle"
          style={{ maxWidth: "15rem", marginTop: "2rem" }}
        />
        <div>
          <h2>Success!</h2>
          <p>Your new datasource has been successfully created.</p>
          <a
            className="btn btn-primary"
            href={datasourceId ? `/web/sources/${datasourceId}` : "/web"}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleToApp}
          >
            Start Querying
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="text-center mt-5 my-4">
      <img
        className="img-fluid mb-3"
        src="./assets/img/check-circle.svg"
        alt="Check circle"
        style={{ maxWidth: "15rem", marginTop: "2rem" }}
      />
      <div>
        <h2>Download the Zing Data app to query your data!</h2>
        <p>We just e-mailed you a download link.</p>
        <p>
          Download the Zing Data app for iOS or Android, then login with the same credentials you did here to start
          querying and visualizing data.
        </p>
        <a
          className="btn btn-primary"
          href={datasourceId ? `/web/sources/${datasourceId}` : "/web"}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleToApp}
        >
          Start Querying
        </a>
      </div>
    </div>
  );
};

export default memo(SuccessMessage);
