import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Buffer } from "buffer";
import PublicPageLayout from "layouts/PublicPageLayout";
import SignupActions from "redux/actions/signup";

const SignupSuccessPage = () => {
  const { loading, resentConfirmation } = useSelector((s) => s.signup);

  const { token } = useParams();
  let email;

  if (token) {
    const _token = Buffer.from(token, "base64").toString("binary");
    const [_email] = _token.split(":");
    email = _email;
  }

  const onClickResendConfirmation = () => {
    SignupActions.resendEmailConfirmation({ email });
  };

  if (resentConfirmation && !loading) {
    return (
      <PublicPageLayout>
        <h2 className="mb-3 text-center">Resent confirmation email</h2>
        <p className="text-center">
          We have resent an email verification link. Please verify your email to get started.
        </p>
      </PublicPageLayout>
    );
  }

  return (
    <PublicPageLayout>
      <>
        <h2 className="text-center">Thank you for signing up!</h2>
        <p className="text-center mt-3">We just emailed you a verification link. Verify your email to get started.</p>
        <div className="d-flex justify-content-center mt-5">
          <Button onClick={onClickResendConfirmation}>Resend Email Verification Link</Button>
        </div>
      </>
    </PublicPageLayout>
  );
};

export default SignupSuccessPage;
