import { queueNames } from "../../helpers/constants";
import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  hydrated: false,
  loading: false,
  show: false,
  showSaveSuccess: false,
  error: undefined,
  hasError: {},
  help: {},
  datasource: {},
  data: [],
  pages: {},
  items: {},
  showDeleteSuccess: false,
  timeoutError: null,
  jobState: null,
  queryResult: null,
  timeoutErrorIngestion: null,
  jobStateIngestion: null,
  ingestionValue: null,
  timeoutErrorDbImporter: null,
  jobStateDbImporter: null,
  dbImporterValue: null,
  jobId: null,
  type: null,
  loadingTablesAndColumns: false,
  loadingJoinsResult: false,
  joinsJobId: null,
  joinsError: null,
  joinsHasError: false,
  loadingCsvUpload: false,
  loadingCsvJob: false,
  loadingCsvImport: false,
  csvImportError: null,
  csvImporterValue: null,
  timeoutErrorCsvImporter: null,
  jobStateCsvImporter: null,
  csvAction: null,
};

const reducer = function (state = initialState, action) {
  if (action.type === Constants.QUERY_MANAGER_START_INGESTION) {
    return {
      ...state,
      loading: true,
      hydrated: false,
      timeoutErrorIngestion: null,
      jobStateIngestion: null,
      ingestionValue: null,
    };
  }

  if (
    action.type === Constants.QUERY_MANAGER_START_DB_IMPORTER ||
    action.type === Constants.QUERY_MANAGER_GET_QUERY_DB_IMPORTER
  ) {
    return {
      ...state,
      loadingCsvJob: true,
      hydrated: false,
      timeoutError: null,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_START_CSV_UPLOAD) {
    return {
      ...state,
      hydrated: false,
      loadingCsvUpload: true,
      timeoutErrorDbImporter: null,
      dbImporterValue: null,
      error: null,
      csvAction: "upload",
    };
  }

  if (action.type === Constants.QUERY_MANAGER_CHECK_CONNECTION) {
    return {
      ...state,
      loading: true,
      hydrated: false,
      timeoutErrorDbImporter: null,
      jobStateDbImporter: null,
      dbImporterValue: null,
    };
  }

  if (
    action.type === Constants.QUERY_MANAGER_GET_QUERY_INGESTION ||
    action.type === Constants.QUERY_MANAGER_GET_QUERY
  ) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (
    action.type === Constants.QUERY_MANAGER_CHECK_CONNECTION_RESPONSE ||
    action.type === Constants.QUERY_MANAGER_START_INGESTION_RESPONSE
  ) {
    const validation = ParseValidation(action.response);

    const type =
      action.type === Constants.QUERY_MANAGER_CHECK_CONNECTION_RESPONSE
        ? queueNames.QUERYWORKER
        : action.type === Constants.QUERY_MANAGER_START_INGESTION_RESPONSE
        ? queueNames.INGESTION
        : queueNames.DB_IMPORTER;

    return {
      ...state,
      loading: false,
      hydrated: true,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      jobId: action.response.id,
      type,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_START_DB_IMPORTER_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingCsvJob: false,
      hydrated: true,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      jobId: action.response.id,
      type: queueNames.DB_IMPORTER,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_START_CSV_UPLOAD_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingCsvUpload: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      jobId: action.response.id,
      type: queueNames.DB_IMPORTER,
      csvAction: "upload",
    };
  }

  if (action.type === Constants.QUERY_MANAGER_GET_QUERY_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      connectionErr: action.err?.message,
      help: validation.help,
      timeoutError: action.err,
      jobState: action.response?.state,
      queryResult: action.response?.queryResult,
      type: queueNames.QUERYWORKER,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_GET_QUERY_INGESTION_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      hasError: validation.hasError,
      help: validation.help,
      timeoutErrorIngestion: action.err?.message,
      jobStateIngestion: action.response?.state,
      ingestionValue: action.response?.value,
      type: queueNames.INGESTION,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_GET_QUERY_DB_IMPORTER_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingCsvJob: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      timeoutErrorDbImporter: action.err,
      jobStateDbImporter: action.response?.state,
      dbImporterValue: action.response?.value,
      type: queueNames.DB_IMPORTER,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_CLEAR_IMPORT_STATE) {
    return {
      timeoutErrorDbImporter: null,
      jobStateDbImporter: null,
      error: null,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_GET_TABLES_AND_COLUMNS) {
    return {
      ...state,
      loadingTablesAndColumns: true,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_GET_TABLES_AND_COLUMNS_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingTablesAndColumns: false,
      jobId: action.response.id,
      error: validation.error,
      hasError: validation.hasError,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_CLEAR_STATE) {
    return {
      jobId: null,
      jobState: null,
      connectionErr: null,
      timeoutError: null,
      queryResult: null,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_VALIDATE_JOIN) {
    return {
      ...state,
      loadingJoinsResult: true,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_VALIDATE_JOIN_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingJoinsResult: false,
      joinsJobId: action.response.id,
      joinsError: validation.error,
      joinsHasError: validation.hasError,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_START_CSV_IMPORT) {
    return {
      ...state,
      loadingCsvImport: true,
      error: null,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_START_CSV_IMPORT_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingCsvImport: false,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      type: queueNames.DB_IMPORTER,
      csvAction: "import",
      jobId: action.response.id,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_REFRESH_TABLES) {
    return {
      ...state,
      refreshingTables: true,
    };
  }

  if (action.type === Constants.QUERY_MANAGER_REFRESH_TABLES_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      refreshingTables: false,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      type: queueNames.QUERYWORKER,
      jobId: action.response.id,
    };
  }

  return state;
};

export default reducer;
