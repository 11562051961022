import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Actions from "redux/actions/forgot-password";
import Footer from "components/footer/footerlogin";
import Alert from "components/alert";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import Loading from "components/loading";
import Logo from "components/logo/logo";

const schema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
});

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const { register, handleSubmit, errors, formState, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { loading, error, success } = useSelector((s) => s.forgotPassword);

  // eslint-disable-next-line no-console
  const onError = (errors, e) => console.log("onError", errors, e);

  const goToLogin = () => {
    reset("email");
    Actions.dispatch();
    navigate("/login");
  };

  useEffect(() => {
    // lets get the body classes ready for Dashboard
    const origBodyClass = document.body.className;
    document.body.className = "main";

    return () => {
      // put componentUnMount logic in here
      document.body.className = origBodyClass;
    };
  });

  const doForgot = (data, e) => {
    e.preventDefault();

    const { email } = data;

    Actions.forgot({ email });
  };

  const alerts = [];

  if (success) {
    alerts.push(
      <div key="success">
        <Alert
          key="success"
          type="success"
          message="Please follow the instructions in the email we just sent to reset your password."
        />
        <div className="text-center mt-2">
          <a className="btn btn-link" onClick={goToLogin}>
            <i className="tio-chevron-left"></i> Back to Sign in
          </a>
        </div>
      </div>
    );
  }

  if (error) {
    alerts.push(<Alert key="danger" type="danger" message={error} />);
  }

  return (
    <>
      <main id="content" role="main" className="main">
        <div
          className="position-fixed top-0 right-0 left-0 bg-img-hero"
          style={{ height: "32rem", backgroundImage: "url(./assets/svg/components/abstract-bg-4.svg)" }}
        >
          <figure className="position-absolute right-0 bottom-0 left-0">
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
              <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
            </svg>
          </figure>
        </div>
        <div className="container py-5 py-sm-7">
          <Logo
            outerClassName="d-flex justify-content-center mb-5"
            innerClassName="z-index-2"
            style={{ width: "15rem" }}
          />
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="card card-lg mb-5">
                <Loading loading={loading}></Loading>

                <div className="card-body">
                  {alerts}
                  <form style={success ? { display: "none" } : {}} onSubmit={handleSubmit(doForgot, onError)}>
                    <div className="text-center">
                      <div className="mb-5">
                        <h1 className="display-4">Forgot password?</h1>
                        <p>
                          Enter the email address you used when you joined and we&apos;ll send you instructions to reset
                          your password.
                        </p>
                      </div>
                    </div>
                    <div className="js-form-message form-group">
                      <label className="input-label" htmlFor="signinSrEmail">
                        Your email
                      </label>
                      <input
                        type="email"
                        className={"form-control form-control-lg" + (errors.email ? " is-invalid" : "")}
                        name="email"
                        id="signinSrEmail"
                        tabIndex={1}
                        ref={register}
                        placeholder="email@address.com"
                        aria-label="email@address.com"
                      />
                      <div className="invalid-feedback">{errors.email?.message}</div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-lg btn-block btn-primary"
                      disabled={success || !formState.isDirty || (formState.isDirty && !formState.isValid)}
                    >
                      Submit
                    </button>

                    <div className="text-center mt-2">
                      <Link className="btn btn-link" to="/login">
                        <i className="tio-chevron-left"></i> Back to Sign in
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ForgotPasswordPage;
