import React from "react";
import Logo from "components/logo/logo";

const NotFoundPage = () => {
  return (
    <main id="content" role="main" className="main">
      <div className="content container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <Logo outerClassName="d-flex justify-content-center mb-5" style={{ width: "15rem" }} />
          </div>
        </div>

        <div className="row align-items-sm-center py-sm-10">
          <div className="row align-items-sm-center w-100">
            <div className="col-sm-6">
              <div className="text-center text-sm-right mr-sm-4 mb-5 mb-sm-0">
                <img
                  className="w-60 w-sm-100 mx-auto"
                  src="/assets/svg/illustrations/think.svg"
                  alt="Description"
                  style={{ maxWidth: "15rem" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-4 text-center text-sm-left">
              <h1 className="display-1 mb-0">404</h1>
              <p className="lead">
                The server encountered an internal error or misconfiguration and was unable to complete your request.
              </p>
              <button className="btn btn-primary" onClick={() => window.location.reload()}>
                Reload page
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
