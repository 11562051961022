import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class InvitationActions {
  static async getAll(query = {}) {
    ApiActions.get("/api/invitations/my", query, Store, Constants.INVITATION_GET, Constants.INVITATION_GET_RESPONSE);
  }

  static async create(data) {
    ApiActions.post("/api/invitations", data, Store, Constants.INVITATION_CREATE, Constants.INVITATION_CREATE_RESPONSE);
  }

  static async accept(data) {
    ApiActions.put(
      "/api/invitations/accept",
      data,
      Store,
      Constants.INVITATION_ACCEPT,
      Constants.INVITATION_ACCEPT_RESPONSE
    );
  }

  static hideInvitationCreate() {
    Store.dispatch({
      type: Constants.INVITATION_CREATE_HIDE,
    });
  }

  static async delete(id) {
    ApiActions.delete(
      `/api/invitations/${id}`,
      undefined,
      Store,
      Constants.INVITATION_DELETE,
      Constants.INVITATION_DELETE_RESPONSE
    );
  }

  static async resend(id) {
    ApiActions.post(
      `/api/invitations/resend/${id}`,
      undefined,
      Store,
      Constants.INVITATION_RESEND,
      Constants.INVITATION_RESEND_RESPONSE
    );
  }

  static async updateRole(id, data) {
    ApiActions.post(
      `/api/invitations/${id}/update-role`,
      data,
      Store,
      Constants.INVITATION_UPDATE_ROLE,
      Constants.INVITATION_UPDATE_ROLE_RESPONSE
    );
  }

  static hideInvitationUpdateRole() {
    Store.dispatch({
      type: Constants.INVITATION_UPDATE_ROLE_HIDE,
    });
  }
}

export default InvitationActions;
