import React, { memo } from "react";
import classNames from "classnames";
import iconStyles from "./icon.module.scss";

const Icon = ({ iconName, className, isClickable = false, ...props }) => {
  return (
    <span className={classNames(className, { [iconStyles["z-icon-hover"]]: isClickable })} {...props}>
      <i className={iconName} />
    </span>
  );
};

export default memo(Icon);
