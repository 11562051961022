class AuthService {
  logout() {
    for (const key of Object.keys(window.localStorage)) {
      if (key !== "GetStorage") {
        window.localStorage.removeItem(key);
      }
    }

    const getStorageProp = JSON.parse(window.localStorage.getItem("GetStorage"));
    if (getStorageProp) {
      const { _showOnBoardingFlag, _homeTraining, _columnsTraining, _chartTraining } = getStorageProp;

      window.localStorage.setItem(
        "GetStorage",
        JSON.stringify({
          _showOnBoardingFlag,
          _homeTraining,
          _columnsTraining,
          _chartTraining,
        })
      );
    }
  }

  isLoggedIn() {
    const user = this.getUser();
    return user !== undefined && user != null;
  }

  setUser(user) {
    window.localStorage.setItem("zing", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(window.localStorage.getItem("zing"));
  }
}

export default new AuthService();
