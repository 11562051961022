import React from "react";
import { Navigate } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import amplitude from "amplitude-js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AuthService from "../helpers/auth";

function PrivateRoute({ component: Component, googleauth, ...rest }) {
  const intercomAppId = process.env.REACT_APP_INTERCOM_ID;

  // not logged in - therefore, redirect to login page with the return url
  if (!localStorage.getItem("userauth")) {
    let url = "/login";

    if (window.location.pathname && window.location.pathname !== "/") {
      url = `/login?returnUrl=${window.location.pathname}`;
    }

    const returnToUrl = new URL(url, window.location.origin);

    return (
      <Navigate
        to={{
          pathname: returnToUrl.pathname,
          ...(returnToUrl.searchParams ? { search: returnToUrl.searchParams.toString() } : {}),
          state: { from: rest.location },
        }}
      />
    );
  }

  const { user } = AuthService.getUser();

  if (user) {
    if (amplitude.getInstance().getUserId() !== user._id) {
      amplitude.getInstance().setUserId(user._id, true);
    }
  }

  if (googleauth) {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <IntercomProvider appId={intercomAppId}>
          <Component {...rest} />
        </IntercomProvider>
      </GoogleOAuthProvider>
    );
  }

  return (
    <IntercomProvider appId={intercomAppId}>
      <Component {...rest} />
    </IntercomProvider>
  );
}

export { PrivateRoute };
