import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import datasourceStyles from "./datasource.module.scss";

const ProFeature = ({ organization, onClickPreviousOnProFeature }) => {
  const navigate = useNavigate();

  const onClickUpgrade = () => {
    navigate(`/organization/${organization}?upgrade=true`);
  };

  const onClickPrevious = () => {
    onClickPreviousOnProFeature(organization);
  };

  return (
    <div className={classNames("col-12 text-center", datasourceStyles.proFeature)}>
      <h1 className="display-5 mb-4" style={{ lineHeight: "1" }}>
        You discovered a <b>Pro</b> feature!
      </h1>
      <p className="mb-0">
        Upgrading your organization &quot;<b>{organization}</b>&quot; to <b>Pro</b> will allow you to upload multiple
        files at once.
      </p>
      <p className="pb-3">Please select a plan that fits your needs.</p>

      <div className="row f-dlex">
        <button onClick={onClickPrevious} className="btn btn-outline-primary mr-3">
          Previous
        </button>
        <Button variant="primary" onClick={onClickUpgrade}>
          Upgrade
        </Button>
      </div>
    </div>
  );
};

export default memo(ProFeature);
