import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  loading: false,
  error: undefined,
  hasError: {},
  errorStatus: null,
  isAdminOfOrg: null,
};

const reducer = function (state = initialState, action) {
  if (action.type === Constants.USER_IS_ADMIN) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.USER_IS_ADMIN_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      error: validation.error,
      hasError: validation.hasError,
      isAdminOfOrg: action.response,
      errorStatus: validation.statusCode,
    };
  }

  return state;
};

export default reducer;
