import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoginActions from "redux/actions/login";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import amplitude from "amplitude-js";
import Alert from "components/alert";
import Footer from "components/footer/footerlogin";
import ShowPassword from "components/form/show-password";
import Loading from "components/loading";
import Logo from "components/logo/logo";
import AppleSignin from "components/apple-signin";

const schema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
  password: yup.string().min(4, "Password must be at least 4 characters").required("Password is required"),
});

const LoginPage = ({ history, location }) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const { error, loading, isLoadingAppleResponse } = useSelector((s) => s.login);
  const [alerts, setAlerts] = useState([]);

  // initialize amplitude instance
  const instance = amplitude.getInstance();

  const onError = () => {
    const newAlerts = [
      <Alert
        key="danger"
        type="danger"
        message="If Google sign in is not working, please restart your browser and make sure you're not in incognito mode. If this issue persists please contact Zing Support."
      />,
    ];

    setAlerts(newAlerts);

    instance.logEvent("LOGIN_ERROR");
  };

  const responseGoogle = async (response) => {
    const userInfo = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
      headers: {
        Authorization: `Bearer ${response.access_token}`,
      },
    });

    const googleData = {
      strategy: "google",
      email: userInfo.data.email,
      accessToken: response.access_token,
    };

    LoginActions.login({
      login: googleData,
      location,
      history,
    });

    instance.logEvent("LOGIN_GOOGLE", {
      email: userInfo.data.email,
    });
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: responseGoogle,
    onFailure: onError,
    flow: "implicit",
  });

  const doLoginGoogle = (e) => {
    e.preventDefault();

    setAlerts([]);

    loginGoogle();
  };

  useEffect(() => {
    // lets get the body classes ready for Dashboard
    const origBodyClass = document.body.className;
    document.body.className = "main";

    return () => {
      document.body.className = origBodyClass;
    };
  });

  const doLogin = (data, e) => {
    e.preventDefault();

    setAlerts([]);

    const { email: username, password } = data;

    LoginActions.login({
      login: { strategy: "local", username, password },
      location,
      history,
    });
  };

  useEffect(() => {
    if (error) {
      alerts.push(<Alert key="danger" type="danger" message={error} />);
      setAlerts([...alerts]);
    }
  }, [error]);

  return (
    <main id="content" role="main" className="main">
      <div
        className="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{ height: "32rem", backgroundImage: "url(./assets/svg/components/abstract-bg-4.svg)" }}
      >
        <figure className="position-absolute right-0 bottom-0 left-0">
          <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>
      <div className="container py-5 py-sm-7">
        <Logo
          outerClassName="d-flex justify-content-center mb-5"
          innerClassName="z-index-2"
          style={{ width: "15rem" }}
        />
        <div className="row justify-content-center">
          <div className="col-md-7 col-lg-5">
            <div className="card card-lg mb-5">
              <Loading loading={loading || isLoadingAppleResponse}></Loading>

              <div className="card-body">
                {alerts}
                <form onSubmit={handleSubmit(doLogin, onError)}>
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-4">Sign In</h1>
                      <p>
                        Don&apos;t have an account yet? <Link to="/signup">Sign up here</Link>
                      </p>
                    </div>

                    <a className="btn btn-lg btn-block btn-white mb-2" onClick={doLoginGoogle}>
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          className="avatar avatar-xss mr-2"
                          src="./assets/svg/brands/google.svg"
                          alt="Google Sign In"
                        />
                        Sign in with Google
                      </span>
                    </a>
                    <AppleSignin buttonText="Sign in with Apple" />
                    <Link to="/login-sso" className="btn btn-lg btn-block btn-white mb-2">
                      Sign in with SSO
                    </Link>
                    <span className="divider text-muted mb-4 mt-2">OR</span>
                  </div>

                  <div className="js-form-message form-group">
                    <label className="input-label" htmlFor="signinSrEmail">
                      Your email
                    </label>
                    <input
                      type="email"
                      className={"form-control form-control-lg" + (errors.email ? " is-invalid" : "")}
                      name="email"
                      id="signinSrEmail"
                      tabIndex={1}
                      ref={register}
                      placeholder="email@address.com"
                      aria-label="email@address.com"
                    />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                  </div>
                  <div className="js-form-message form-group">
                    <label className="input-label" htmlFor="signinSrPassword" tabIndex={0}>
                      <span className="d-flex justify-content-between align-items-center">
                        Password
                        <Link className="input-label-secondary" to="/forgot-password">
                          Forgot Password?
                        </Link>
                      </span>
                    </label>
                    <ShowPassword
                      name="password"
                      id="signinSrPassword"
                      ref={register}
                      errors={errors.password}
                      placeholder="password"
                      tabIndex={2}
                    />
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="termsCheckbox" name="termsCheckbox" />
                      <label className="custom-control-label font-size-sm text-muted" htmlFor="termsCheckbox">
                        {" "}
                        Remember me
                      </label>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-lg btn-block btn-primary">
                    Sign in
                  </button>
                </form>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
