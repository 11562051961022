import React from "react";
import { Modal, Button } from "react-bootstrap";

const ModalDeleteApiKey = ({ show, handleClose, deleteApiKey, apiKey }) => {
  return (
    <Modal backdrop="static" keyboard={false} show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete API Key: {apiKey}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h4 className="mb-5">
            This action will disable all charts embedded using this API key. This cannot be undone.
          </h4>
          <p>Do you wish to continue?</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => deleteApiKey(apiKey)}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteApiKey;
