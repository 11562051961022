import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  data: null,
  loading: false,
  error: undefined,
  hasError: {},
  pages: {},
  items: {},
  showSaveSuccess: false,
  showDeleteSuccess: false,
  fetchingJoin: false,
  showUpdateSuccess: false,
};

const reducer = function (state = initialState, action) {
  if (action.type === Constants.JOINS_CREATE_NEW) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.JOINS_CREATE_NEW_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      join: action.response,
    };
  }

  if (action.type === Constants.JOINS_CREATE_HIDE) {
    return {
      ...state,
      showSaveSuccess: false,
    };
  }

  if (action.type === Constants.JOINS_GET_BY_DATASOURCE) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.JOINS_GET_BY_DATASOURCE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      error: validation.error,
      hasError: validation.hasError,
      data: action.response.data,
      pages: action.response.pages,
      items: action.response.items,
    };
  }

  if (action.type === Constants.JOINS_GET_BY_ID) {
    return {
      ...state,
      fetchingJoin: true,
    };
  }

  if (action.type === Constants.JOINS_GET_BY_ID_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      fetchingJoin: false,
      error: validation.error,
      hasError: validation.hasError,
      selectedJoin: action.response,
    };
  }

  if (action.type === Constants.JOINS_DELETE) {
    return {
      ...state,
      loading: true,
      showDeleteSuccess: false,
    };
  }

  if (action.type === Constants.JOINS_DELETE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      error: validation.error,
      hasError: validation.hasError,
      showDeleteSuccess: !action.err,
      errorStatus: validation.statusCode,
    };
  }

  if (action.type === Constants.JOINS_UPDATE_DELETE_SUCCESS) {
    return {
      ...state,
      showDeleteSuccess: false,
    };
  }

  if (action.type === Constants.JOINS_UPDATE) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.JOINS_UPDATE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showUpdateSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      join: action.response,
    };
  }

  if (action.type === Constants.JOINS_UPDATE_HIDE) {
    return {
      ...state,
      showUpdateSuccess: false,
      selectedJoin: null,
    };
  }

  if (action.type === Constants.JOINS_CLEAR_SELECTED) {
    return {
      ...state,
      selectedJoin: null,
    };
  }

  return state;
};

export default reducer;
