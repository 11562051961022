import React, { memo, useEffect, useState, useMemo, useCallback } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNodesState, useEdgesState } from "reactflow";

import EditJoin from "components/datasource/joins/edit-join";
import CustomNode from "components/datasource/joins/custom-node";
import CustomEdge from "components/datasource/joins/custom-edge";
import Loading from "components/loading";
import Alert from "components/alert";
import ButtonDropdown from "components/button-dropdown";
import QueryManagerActions from "redux/actions/query-manager";
import JoinActions from "redux/actions/join";
import DatasourceActions from "redux/actions/datasource";
import { checkOrphanTables } from "helpers/joins";

import "reactflow/dist/style.css";

const EditJoinCanvas = ({ datasource, setAlerts, setHasError, onClickCancelJoin, refreshData, selectedJoin }) => {
  const filteredTables = datasource?.tables?.filter((t) => t.tableName !== null && t.tableName !== "null");
  const [tables, setTables] = useState(filteredTables);
  const [joinName, setJoinName] = useState(selectedJoin?.name);

  const { loading: updatingJoin, showUpdateSuccess } = useSelector((s) => s.join);
  const { showSaveTablesSuccess } = useSelector((s) => s.datasource);

  const { loading, loadingTablesAndColumns, jobId, timeoutError, queryResult, connectionErr, jobState, joinsJobId } =
    useSelector((s) => s.queryManager);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [includedColumns, setIncludedColumns] = useState();
  const [showNameError, setShowNameError] = useState(false);
  const [hasColumns, setHasColumns] = useState(false);
  const [joinData, setJoinData] = useState();

  const nodeTypes = useMemo(() => ({ tableNode: CustomNode }), []);
  const edgeTypes = useMemo(() => ({ tableEdge: CustomEdge }), []);

  useEffect(() => {
    const datasourceTables = tables;
    const hasCols = datasourceTables?.every((obj) => obj.columnSchema && obj.columnSchema.length);
    setHasColumns(hasCols);

    if (!hasCols) {
      QueryManagerActions.getTablesAndColumns({ datasource: datasource?._id, version: 2 });
    }
  }, [tables]);

  useEffect(() => {
    if (jobId) {
      QueryManagerActions.getQueryById(jobId);
    }
  }, [jobId]);

  useEffect(() => {
    if (joinsJobId) {
      QueryManagerActions.getQueryById(joinsJobId);
    }
  }, [joinsJobId]);

  useEffect(() => {
    if (showUpdateSuccess) {
      onClickCancelJoin();
      JoinActions.hideJoinUpdate();
      refreshData();
    }
  }, [showUpdateSuccess]);

  useEffect(() => {
    if (showSaveTablesSuccess) {
      DatasourceActions.hideDataSourceCreate();
    }
  }, [showSaveTablesSuccess]);

  const updateSavedTables = (updatedTables) => {
    DatasourceActions.updateTables(datasource._id, {
      tables: updatedTables.map((table) => ({
        tableName: table?.tableName,
        schemaName: table.schemaName,
        labelName: table.labelName,
        included: table.included,
        columnSchema: table.columnSchema,
      })),
    });
  };

  useEffect(() => {
    if (timeoutError || (queryResult !== undefined && queryResult !== null && !Array.isArray(queryResult))) {
      setHasError(true);
      if (joinsJobId) {
        setAlerts(<Alert key="danger" type="danger" message="Failed to create join." details={connectionErr} />);
      } else {
        setAlerts(
          <Alert
            key="danger"
            type="danger"
            message="Connection to database failed, please check your connection settings."
            details={connectionErr}
          />
        );
      }
      QueryManagerActions.clearState();
    } else if (jobState === "completed") {
      setAlerts([]);
      setHasError(false);

      if (joinsJobId) {
        JoinActions.update(selectedJoin._id, joinData);
        setJoinData();
        QueryManagerActions.clearState();
      } else {
        const updatedTables = tables.map((t) => {
          const resultTable = queryResult
            ?.filter(
              (res) =>
                res.table_name === t.tableName &&
                (res.schema_name && t.schemaName ? res.schema_name === t.schemaName : true)
            )
            .shift();
          if (resultTable) {
            return {
              ...t,
              columnSchema: resultTable.column_schema,
            };
          }
        });
        setTables(updatedTables?.filter(Boolean));
        setHasColumns(true);
        updateSavedTables(updatedTables?.filter(Boolean));
        QueryManagerActions.clearState();
      }
    }
  }, [connectionErr, queryResult, timeoutError, jobState]);

  const onChangeName = useCallback((e) => {
    if (e.target.value) {
      setShowNameError(false);
    }
    setJoinName(e.target.value);
  }, []);

  const onClickUpdate = (validateJoin = true) => {
    if (!joinName) {
      return setShowNameError(true);
    }

    const tablesArray = [];

    const hasOrhpanTables = checkOrphanTables(nodes, edges);

    if (hasOrhpanTables) {
      setAlerts(<Alert key="danger" type="danger" message="Each table should have at least 1 connection." />);
      return;
    }

    const data = {
      name: joinName,
      organizationId: datasource.organizationId,
      datasourceId: datasource._id,
      tables: nodes.map((node) => {
        let alias;
        if (!tablesArray.length) {
          if (node.table.schemaName) {
            alias = `${node.table.schemaName}.${node.table.tableName}`;
          } else if (node.googleBigQuery) {
            alias = `${node.googleBigQuery.projectId}.${node.googleBigQuery.datasetId}.${node.table.tableName}`;
          }
          tablesArray.push(node.table.tableName);
        } else {
          const tb = tablesArray.filter((t) => t === node.table.tableName);
          const length = tb.length;
          if (node.table.schemaName && node.table.schemaName !== "null") {
            alias = `${node.table.schemaName}.${node.table.tableName}${length ? `_${length + 1}` : ""}`;
          } else if (node.googleBigQuery) {
            alias = `${node.googleBigQuery.projectId}.${node.googleBigQuery.datasetId}.${node.table.tableName}${
              length ? `_${length + 1}` : ""
            }`;
          }
          tablesArray.push(node.table.tableName);
        }

        return {
          nodeId: node.id,
          alias,
          schemaName: node.table.schemaName,
          tableName: node.table.tableName,
          includedColumns: includedColumns.get(node.table.tableName),
          isCollapsed: node.data.isColumnsCollapsed,
          position: node.position,
          isRoot: node.data.isRoot,
          index: node.data.index,
        };
      }),
      connections: edges.map((edge, i) => ({
        edgeId: edge.id,
        source: edge.source,
        sourceHandle: edge.sourceHandle,
        target: edge.target,
        targetHandle: edge.targetHandle,
        joinType: edge.data.joinType,
        alias: `join_${i}`,
        style: edge.style,
        index: i,
      })),
    };

    setJoinData(data);

    if (validateJoin) {
      QueryManagerActions.validateJoin(data);
    } else {
      JoinActions.update(selectedJoin._id, data);
      setJoinData();
      QueryManagerActions.clearState();
    }
  };

  return (
    <div className="card">
      <div className="card-body px-0">
        <Loading loading={loadingTablesAndColumns || loading || updatingJoin}></Loading>
        <div>
          <div className="card-header pt-0">
            <div className="col-7 px-0">
              <input
                type="text"
                className="form-control"
                name="join-name"
                placeholder="Join-1"
                aria-label="Join Name"
                onChange={onChangeName}
                value={joinName}
                autoComplete="off"
              />
              <span className="invalid-feedback" style={showNameError ? { display: "flex" } : {}}>
                Join name is required
              </span>
            </div>
            <div className="col-5 px-0 d-flex justify-content-end">
              <Button variant="secondary" className="mr-2 btn-ghost-secondary border-0" onClick={onClickCancelJoin}>
                Cancel
              </Button>
              <ButtonDropdown
                primaryButtonTitle="Update"
                onClickPrimaryButton={onClickUpdate}
                isPrimaryButtonDisabled={!nodes?.length || !edges?.length || edges?.length + 1 < nodes?.length}
                secondaryButtonTitle="Update without validation"
                isSecondaryButtonDisabled={!nodes?.length || !edges?.length || edges?.length + 1 < nodes?.length}
                onClickSecondaryButton={() => onClickUpdate(false)}
              />
            </div>
          </div>
          <EditJoin
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            database={datasource?.databasename}
            googleBigQuery={datasource?.googleBigQuery}
            tables={tables}
            nodes={nodes}
            edges={edges}
            setNodes={setNodes}
            setEdges={setEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            includedColumns={includedColumns}
            setIncludedColumns={setIncludedColumns}
            selectedJoin={selectedJoin}
            hasColumns={hasColumns}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(EditJoinCanvas);
