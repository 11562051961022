import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import Alert from "components/alert";
import Loading from "components/loading";
import OrganizationActions from "redux/actions/organization";

const schema = yup.object().shape({
  name: yup.string().max(100, "Name cannot be longer than 100 characters").required(),
});

const ModalEditOrganizationName = ({ show, handleClose, name, id, setAlerts, alertStyle }) => {
  const { loading, error, showSaveSuccess } = useSelector((s) => s.organization);

  const {
    register,
    errors,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // eslint-disable-next-line no-console
  const onError = (errors, e) => console.log("onError", errors, e);

  const updateName = (data, e) => {
    e.preventDefault();

    setAlerts([]);
    OrganizationActions.update(id, { ...data });
  };

  const closeModal = () => {
    handleClose();
    setAlerts([]);
  };

  useEffect(() => {
    if (error) {
      setAlerts([<Alert key="danger" type="danger" style={alertStyle} message={error} />]);
    } else if (showSaveSuccess) {
      setAlerts([
        <Alert key="success" type="success" style={alertStyle} message="Organization name successfully updated!" />,
      ]);
      OrganizationActions.updateSaveSuccess();
      handleClose();
    }
  }, [error, showSaveSuccess]);

  return (
    <Modal backdrop="static" show={show} onHide={closeModal} keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Organization Name</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading}></Loading>
        <form>
          <div className="js-form-message form-group">
            <label className="input-label" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              className={"form-control " + (errors.name ? " is-invalid" : "")}
              name="name"
              placeholder="Organization name"
              aria-label="Organization name"
              ref={register}
              defaultValue={name}
            />
            <div className="invalid-feedback">{errors.name?.message}</div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button type="submit" variant="primary" disabled={!isDirty} onClick={handleSubmit(updateName, onError)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEditOrganizationName;
