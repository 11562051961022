import React from "react";
import { useLocation } from "react-router-dom";

const NavbarVertical = () => {
  const location = useLocation();

  return (
    <div
      className="navbar-nav-wrap-content-left collapse navbar-collapse"
      id="navbarNavMenu"
      style={{ justifyContent: "center" }}
    >
      <div className="navbar-body">
        <ul className="navbar-nav flex-grow-1">
          <li>
            <a
              className={`navbar-nav-link nav-link ${location.pathname === "/dashboard" ? "active" : ""}`}
              href="/dashboard"
            >
              <i className="tio-home-vs-1-outlined nav-icon" /> Home
            </a>
          </li>

          <li>
            <a
              className={`navbar-nav-link nav-link ${location.pathname === "/profile" ? "active" : ""}`}
              href="/profile"
            >
              <i className="tio-user nav-icon" /> Profile
            </a>
          </li>

          <li>
            <a
              className={`navbar-nav-link nav-link ${location.pathname === "/datasource" ? "active" : ""}`}
              href="/datasource"
            >
              <i className="tio-file nav-icon" /> Data Sources
            </a>
          </li>

          <li>
            <a
              className={`navbar-nav-link nav-link ${location.pathname === "/invitation" ? "active" : ""}`}
              href="/invitation"
            >
              <i className="tio-user-add nav-icon" /> Invitations
            </a>
          </li>

          <li>
            <a
              className={`navbar-nav-link nav-link ${location.pathname === "/organization" ? "active" : ""}`}
              href="/organization"
            >
              <i className="tio-group-add nav-icon" /> Organizations
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavbarVertical;
