import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class Actions {
  static async forgot(data) {
    ApiActions.post("/api/login/forgot", data, Store, Constants.FORGOT_PASSWORD, Constants.FORGOT_PASSWORD_RESPONSE);
  }

  static dispatch() {
    Store.dispatch({
      type: Constants.FORGOT_PASSWORD_MOUNT,
    });
  }
}

export default Actions;
