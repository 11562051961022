import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  hydrated: false,
  loading: false,
  success: false,
  show: false,
  showSaveSuccess: false,
  error: undefined,
  hasError: {},
  help: {},
  data: [],
  pages: {},
  items: {},
  invitation: {},
  showDeleteSuccess: false,
  showResendSuccess: false,
  showUpdateRoleSuccess: false,
};
const reducer = function (state = initialState, action) {
  if (action.type === Constants.INVITATION_CREATE) {
    return {
      ...state,
      loading: true,
      name: action.request.data.name,
    };
  }

  if (action.type === Constants.INVITATION_CREATE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showSaveSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      invitation: action.response,
    };
  }

  if (action.type === Constants.INVITATION_ACCEPT) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.INVITATION_ACCEPT_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      success: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      invitation: action.response,
    };
  }

  if (action.type === Constants.INVITATION_CREATE_HIDE) {
    return {
      ...state,
      showSaveSuccess: false,
      error: undefined,
      hasError: {},
      help: {},
    };
  }

  if (action.type === Constants.INVITATION_GET) {
    return {
      ...state,
      loading: true,
      hydrated: false,
    };
  }

  if (action.type === Constants.INVITATION_GET_RESPONSE) {
    return {
      ...state,
      loading: false,
      hydrated: true,
      data: action.response.data,
      pages: action.response.pages,
      items: action.response.items,
    };
  }

  if (
    action.type === Constants.INVITATION_DELETE ||
    action.type === Constants.INVITATION_RESEND ||
    action.type === Constants.INVITATION_UPDATE_ROLE
  ) {
    return {
      ...state,
      error: undefined,
      loading: true,
      showDeleteSuccess: false,
      hydrated: false,
    };
  }

  if (action.type === Constants.INVITATION_DELETE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      showDeleteSuccess: !action.err,
    };
  }

  if (action.type === Constants.INVITATION_RESEND_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      showResendSuccess: !action.err,
    };
  }

  if (action.type === Constants.INVITATION_UPDATE_ROLE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      hydrated: true,
      error: validation.error,
      hasError: validation.hasError,
      showUpdateRoleSuccess: !action.err,
    };
  }

  if (action.type === Constants.INVITATION_UPDATE_ROLE_HIDE) {
    return {
      ...state,
      showUpdateRoleSuccess: false,
      error: undefined,
      hasError: {},
      help: {},
    };
  }

  return state;
};

export default reducer;
