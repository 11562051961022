import Cookie from "cookie";
import Qs from "qs";
const Xhr = require("xhr");

const jsonFetch = function (options, callback) {
  let url = options.url;

  if (process.env.NODE_ENV === "development") {
    url = `http://localhost:9000${url}`;
  }

  const cookies = Cookie.parse(document.cookie);

  const config = {
    url,
    method: options.method,
  };

  if (!options.isFile) {
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  if (cookies.crumb) {
    config.headers["X-CSRF-Token"] = cookies.crumb;
  }

  if (options.query) {
    config.url += "?" + Qs.stringify(options.query);
  }

  if (!options.isFile && options.data) {
    config.body = JSON.stringify(options.data);
  } else {
    config.body = options.data;
  }

  Xhr(config, (err, response, body) => {
    if (err) {
      return callback(err);
    }

    let returnUrl = window.location.pathname;
    if (window.location.search.length > 0) {
      returnUrl += window.location.search;
    }
    returnUrl = encodeURIComponent(returnUrl);

    if (response.statusCode >= 200 && response.statusCode < 300) {
      if (response.headers.hasOwnProperty("x-auth-required")) {
        if (window.location.pathname === "/login") {
          return callback(Error("Auth required."));
        }

        window.location.href = `/login?returnUrl=${returnUrl}`;
      } else {
        try {
          const jsonBody = JSON.parse(body);
          callback(null, jsonBody);
        } catch (e) {
          window.location.href = `/logout?errorMessage=Session Expired`;
        }
      }
    } else {
      if (response.statusCode === 401) {
        window.location.href = `/logout?errorMessage=Session Expired`;
      }

      const httpErr = new Error(response.rawRequest.statusText);

      callback(httpErr, JSON.parse(body));
    }
  });
};

if (global.window) {
  window.jsonFetch = jsonFetch;
}

export { jsonFetch };
