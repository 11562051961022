import React, { memo, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import {
  ChartComponent,
  AccumulationChartComponent,
  SeriesCollectionDirective,
  AccumulationSeriesCollectionDirective,
  SeriesDirective,
  AccumulationSeriesDirective,
  Inject,
  Legend,
  Category,
  DataLabel,
  AccumulationDataLabel,
  Highlight,
  Tooltip,
  ColumnSeries,
  StackingColumnSeries,
  BarSeries,
  LineSeries,
  StackingLineSeries,
  AreaSeries,
  PieSeries,
} from "@syncfusion/ej2-react-charts";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Page,
  Sort,
  Group,
  Inject as InjectGrid,
} from "@syncfusion/ej2-react-grids";
import { chartColors } from "../constants";

const sortAxis = (array, direction, axis) => {
  if (typeof array[0].x === "string") {
    if (direction === "ascending") {
      return [...array].sort((a, b) => (a[axis] > b[axis] ? 1 : -1));
    } else {
      return [...array].sort((a, b) => (a[axis] > b[axis] ? -1 : 1));
    }
  } else {
    if (direction === "ascending") {
      return [...array].sort((a, b) => a[axis] - b[axis]);
    } else {
      return [...array].sort((a, b) => b[axis] - a[axis]);
    }
  }
};

const Graph = ({ chartResult, chartConfig }) => {
  let chart;
  const { chartType, questionText } = chartConfig || {};

  const primaryXAxis = useMemo(
    () => ({
      valueType: "Category",
      majorGridLines: { width: 0 },
      majorTickLines: { width: 0 },
      labelStyle: { fontFamily: "Poppins" },
    }),
    []
  );

  const primaryYAxis = useMemo(
    () => ({
      majorTickLines: { width: 0 },
      lineStyle: { width: 0 },
      labelStyle: { fontFamily: "Poppins" },
    }),
    []
  );

  const chartPalette = useMemo(() => chartColors.splice(0, chartResult.length), [chartResult]);

  const renderSeries = useCallback(({ data, type, index, width, markerIndex }) => {
    if (!data) return null;

    const sortedData = data.data ? sortAxis(data.data, "ascending", "x") : data;

    let marker = {};
    if (markerIndex === "all") {
      marker = { dataLabel: { visible: true, font: { fontFamily: "Poppins" } } };
    }

    return (
      <SeriesDirective
        key={index}
        dataSource={sortedData}
        name={data.seriesname}
        tooltipMappingName="r"
        xName="x"
        yName="y"
        columnSpacing={0.1}
        type={type}
        marker={marker}
        width={width}
      />
    );
  }, []);

  switch (chartType) {
    case "BarChart":
      chart = (
        <ChartComponent
          id="charts"
          width="100%"
          style={{ textAlign: "center", fontFamily: "Poppins" }}
          legendSettings={{
            enableHighlight: true,
            textStyle: { fontFamily: "Poppins" },
            textWrap: "Wrap",
            maximumLabelWidth: 225,
            position: "Bottom",
            alignment: "Center",
            containerPadding: { top: 50 },
          }}
          primaryXAxis={primaryXAxis}
          primaryYAxis={primaryYAxis}
          chartArea={{ border: { width: 0 } }}
          tooltip={{ enable: true, textStyle: { fontFamily: "Poppins" } }}
          palettes={chartPalette}
        >
          <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel, Highlight]} />
          <SeriesCollectionDirective>
            {chartResult?.map((data, i) => {
              return renderSeries({ data, type: "Column", index: i });
            })}
          </SeriesCollectionDirective>
        </ChartComponent>
      );
      break;
    case "StackedBarChart":
      chart = (
        <ChartComponent
          id="charts"
          width="100%"
          style={{ textAlign: "center", fontFamily: "Poppins" }}
          legendSettings={{
            enableHighlight: true,
            textStyle: { fontFamily: "Poppins" },
            textWrap: "Wrap",
            maximumLabelWidth: 225,
            position: "Bottom",
            alignment: "Center",
            containerPadding: { top: 50 },
          }}
          primaryXAxis={primaryXAxis}
          primaryYAxis={primaryYAxis}
          chartArea={{ border: { width: 0 } }}
          tooltip={{ enable: true, textStyle: { fontFamily: "Poppins" } }}
          palettes={chartPalette}
        >
          <Inject services={[StackingColumnSeries, Legend, Tooltip, Category, DataLabel, Highlight]} />
          <SeriesCollectionDirective>
            {chartResult?.map((data, i) => {
              return renderSeries({ data, type: "StackingColumn", index: i, markerIndex: "all" });
            })}
          </SeriesCollectionDirective>
        </ChartComponent>
      );
      break;
    case "HorizontalBarChart":
      chart = (
        <ChartComponent
          id="charts"
          width="100%"
          style={{ textAlign: "center", fontFamily: "Poppins" }}
          legendSettings={{
            enableHighlight: true,
            textStyle: { fontFamily: "Poppins" },
            textWrap: "Wrap",
            maximumLabelWidth: 225,
            position: "Bottom",
            alignment: "Center",
            containerPadding: { top: 50 },
          }}
          primaryXAxis={primaryXAxis}
          primaryYAxis={primaryYAxis}
          chartArea={{ border: { width: 0 } }}
          tooltip={{ enable: true, textStyle: { fontFamily: "Poppins" } }}
          palettes={chartPalette}
        >
          <Inject services={[BarSeries, Legend, Tooltip, Category, DataLabel, Highlight]} />
          <SeriesCollectionDirective>
            {chartResult?.map((data, i) => {
              return renderSeries({ data, type: "Bar", index: i, markerIndex: "all" });
            })}
          </SeriesCollectionDirective>
        </ChartComponent>
      );
      break;
    case "LineChart":
      chart = (
        <ChartComponent
          id="charts"
          width="100%"
          style={{ textAlign: "center", fontFamily: "Poppins" }}
          legendSettings={{
            enableHighlight: true,
            textStyle: { fontFamily: "Poppins" },
            textWrap: "Wrap",
            maximumLabelWidth: 225,
            position: "Bottom",
            alignment: "Center",
            containerPadding: { top: 50 },
          }}
          primaryXAxis={primaryXAxis}
          primaryYAxis={primaryYAxis}
          chartArea={{ border: { width: 0 } }}
          tooltip={{ enable: true, textStyle: { fontFamily: "Poppins" } }}
          palettes={chartPalette}
        >
          <Inject services={[LineSeries, Legend, Tooltip, Category, DataLabel, Highlight]} />
          <SeriesCollectionDirective>
            {chartResult?.map((data, i) => {
              return renderSeries({ data, type: "Line", index: i, width: 2, markerIndex: "all" });
            })}
          </SeriesCollectionDirective>
        </ChartComponent>
      );
      break;
    case "StackedLineChart":
      chart = (
        <ChartComponent
          id="charts"
          width="100%"
          style={{ textAlign: "center", fontFamily: "Poppins" }}
          legendSettings={{
            enableHighlight: true,
            textStyle: { fontFamily: "Poppins" },
            textWrap: "Wrap",
            maximumLabelWidth: 225,
            position: "Bottom",
            alignment: "Center",
            containerPadding: { top: 50 },
          }}
          primaryXAxis={primaryXAxis}
          primaryYAxis={primaryYAxis}
          chartArea={{ border: { width: 0 } }}
          tooltip={{ enable: true, textStyle: { fontFamily: "Poppins" } }}
          palettes={chartPalette}
        >
          <Inject services={[StackingLineSeries, Legend, Tooltip, Category, DataLabel, Highlight]} />
          <SeriesCollectionDirective>
            {chartResult?.map((data, i) => {
              return renderSeries({ data, type: "StackingLine", index: i, width: 3, markerIndex: "all" });
            })}
          </SeriesCollectionDirective>
        </ChartComponent>
      );
      break;
    case "AreaChart":
      chart = (
        <ChartComponent
          id="charts"
          width="100%"
          style={{ textAlign: "center", fontFamily: "Poppins" }}
          legendSettings={{
            enableHighlight: true,
            textStyle: { fontFamily: "Poppins" },
            textWrap: "Wrap",
            maximumLabelWidth: 225,
            position: "Bottom",
            alignment: "Center",
            containerPadding: { top: 50 },
          }}
          primaryXAxis={primaryXAxis}
          primaryYAxis={primaryYAxis}
          chartArea={{ border: { width: 0 } }}
          tooltip={{ enable: true, textStyle: { fontFamily: "Poppins" } }}
          palettes={chartPalette}
        >
          <Inject services={[AreaSeries, Legend, Tooltip, Category, DataLabel, Highlight]} />
          <SeriesCollectionDirective>
            {chartResult?.map((data, i) => {
              return renderSeries({ data, type: "Area", index: i, markerIndex: "all" });
            })}
          </SeriesCollectionDirective>
        </ChartComponent>
      );
      break;
    case "DonutChart":
      {
        const donutChartData = chartResult[0].data.map((ele) => ({ x: ele.x, y: ele.y, text: `${ele.x}: ${ele.y}` }));

        chart = (
          <AccumulationChartComponent
            id="doughnut-chart"
            enableSmartLabels={true}
            tooltip={{ enable: true }}
            enableBorderOnMouseMove={false}
            legendSettings={{
              enableHighlight: true,
              textStyle: { fontFamily: "Poppins" },
              textWrap: "Wrap",
              maximumLabelWidth: 225,
              position: "Bottom",
              alignment: "Center",
              containerPadding: { top: 50 },
            }}
            palettes={chartPalette}
          >
            <Inject services={[PieSeries, AccumulationDataLabel]} />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                dataSource={donutChartData}
                xName="x"
                yName="y"
                innerRadius="40%"
                startAngle={0}
                endAngle={360}
                radius="80%"
                explode={true}
                explodeOffset="10%"
                explodeIndex={0}
                dataLabel={{
                  visible: true,
                  position: "Outside",
                  name: "text",
                  font: {
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    size: 16,
                  },
                  connectorStyle: {
                    length: "20px",
                    width: 2,
                  },
                }}
              ></AccumulationSeriesDirective>
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        );
      }
      break;
    case "DataTable":
      {
        if (!chartResult || !chartResult?.length) {
          chart = <></>;
        } else {
          const columns = Object.keys(chartResult[0]);
          const pageSettings = { pageSize: 10 };
          const filterSettings = { type: "Menu" };
          chart = (
            <GridComponent
              dataSource={chartResult}
              allowPaging={true}
              pageSettings={pageSettings}
              filterSettings={filterSettings}
              allowSorting={true}
              allowFiltering={true}
            >
              <ColumnsDirective>
                {columns.map((colName, i) => (
                  <ColumnDirective key={i} field={colName} width="100" textAlign="Left" />
                ))}
              </ColumnsDirective>
              <InjectGrid services={[Page, Sort, Filter, Group]} />
            </GridComponent>
          );
        }
      }
      break;
    default:
      chart = (
        <ChartComponent
          id="charts"
          width="100%"
          style={{ textAlign: "center", fontFamily: "Poppins" }}
          legendSettings={{
            enableHighlight: true,
            textStyle: { fontFamily: "Poppins" },
            textWrap: "Wrap",
            maximumLabelWidth: 225,
            position: "Bottom",
            alignment: "Center",
            containerPadding: { top: 50 },
          }}
          primaryXAxis={primaryXAxis}
          primaryYAxis={primaryYAxis}
          chartArea={{ border: { width: 0 } }}
          tooltip={{ enable: true, textStyle: { fontFamily: "Poppins" } }}
          palettes={chartPalette}
        >
          <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel, Highlight]} />
          <SeriesCollectionDirective>
            {chartResult?.map((data, i) => {
              return renderSeries({ data, type: "Column", index: i });
            })}
          </SeriesCollectionDirective>
        </ChartComponent>
      );
  }

  return (
    <div>
      <h1 className="text-center pb-5">{questionText}</h1>
      <div className="mt-5">{chart}</div>
    </div>
  );
};

Graph.propTypes = {
  type: PropTypes.oneOf(["success", "info", "warning", "danger"]),
  onClose: PropTypes.func,
  message: PropTypes.string,
};

export default memo(Graph);
