import React, { useEffect, useMemo } from "react";
import Footer from "components/footer/footerlogin";
import Logo from "components/logo/logo";

const DownloadAppPage = () => {
  const isQuestionPage = window.location.pathname.includes("question");
  const description = useMemo(
    () =>
      isQuestionPage
        ? "To access this page you need to install our mobile app! Zing is available for Android and iOS."
        : "Zing is available for Android and iOS.",
    [isQuestionPage]
  );

  useEffect(() => {
    window.dashboardOnReady();
    const origBodyClass = document.body.className;
    document.body.className = "";

    return () => {
      document.body.className = origBodyClass;
    };
  }, []);

  return (
    <main id="content" role="main" className="main">
      <div className="content container-fluid d-flex flex-column align-items-center">
        <div className="row align-items-sm-center">
          <div className="col-sm-12">
            <Logo
              outerClassName="d-flex justify-content-center"
              innerClassName="z-index-2 mb-5"
              style={{ width: "15rem" }}
            />
          </div>
        </div>
        <div className="row align-items-sm-center py-sm-10">
          <div className="col-sm-6">
            <div className="text-center text-sm-right mr-sm-4 mb-5 mb-sm-0">
              <img
                className="w-60 w-sm-100 mx-auto"
                src="/assets/svg/illustrations/think.svg"
                alt="Description"
                style={{ maxWidth: "15rem" }}
              />
            </div>
          </div>

          <div className="col-sm-6 col-md-5 text-center text-sm-left">
            <h1 className="display-3 mb-3" style={{ lineHeight: "1" }}>
              Download the Mobile App
            </h1>
            <p className="lead">{description}</p>

            <span className="btn-group">
              <a className="btn px-0" href="https://play.google.com/store/apps/details?id=com.getzingdata.android">
                <img
                  style={{ width: "150px", marginLeft: "-10px" }}
                  src="/assets/img/stores/google-play.png"
                  alt="android"
                ></img>
              </a>
              <a className="btn" href="https://apps.apple.com/us/app/zing-data-collaborative-bi/id1563294091">
                <img style={{ marginTop: "9px" }} src="/assets/img/stores/app-store.svg" alt="ios"></img>
              </a>
            </span>
          </div>
        </div>
        <div className="d-flex col-md-7 col-lg-5 mt-10">
          <Footer />
        </div>
      </div>
    </main>
  );
};

export default DownloadAppPage;
