import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { PricingTiers } from "helpers/constants";
import CsvFilesList from "./csv-files-list";

const Dropzone = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f8fafd;
  text-align: center;
  padding: 1rem;
  border: thin solid #acbfdb;
  border-style: dashed;
  ${({ isDragActive }) =>
    isDragActive &&
    `
    background: #CFE6FD;
    border: thin solid #acbfdb;
    border-style: solid;
    border-color: #1D73C9;
  `}
`;

const Button = styled.button`
  background-color: white;
  margin-top: 1rem;
  border: thin solid #1d73c9;
  color: #1d73c9;
`;

const FileDropZone = ({ files, setFiles, tier, setHasFileLimitError }) => {
  let maxSize;
  if (!tier || (tier && tier === PricingTiers.BASIC)) {
    maxSize = 10485760; // 10MB
  } else {
    maxSize = 104857600 * 3; // 100MB x 3 = 300MB
  }

  const acceptFiles = (files, acceptedFiles) => {
    let allFiles = [];
    if (files?.length) {
      allFiles = files.concat(acceptedFiles);
    } else {
      allFiles = acceptedFiles;
    }

    setFiles([...allFiles]);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!tier || (tier && tier === PricingTiers.BASIC)) {
        const totalFilesLength = (files?.length || 0) + (acceptedFiles?.length || 0);

        if (totalFilesLength > 1) {
          setHasFileLimitError(true);
        } else {
          setHasFileLimitError(false);
          acceptFiles(files, acceptedFiles);
        }
      } else {
        setHasFileLimitError(false);
        acceptFiles(files, acceptedFiles);
      }
    },
    [files, tier]
  );

  const { getRootProps, getInputProps, fileRejections, isDragActive, open } = useDropzone({
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "text/csv": [".csv"],
      "application/dbf": [".dbf"],
      "application/geo+json": [".geojson"],
      "text/json": [".json"],
      "application/x-ndjson": [".ndjson"],
    },
    noClick: true,
    noKeyboard: true,
    maxSize,
    multiple: true,
    onDrop,
  });

  let fileRejectionError = "";
  if (fileRejections) {
    if (fileRejections[0]?.errors[0]?.code === "file-invalid-type") {
      fileRejectionError = "Invalid file type";
    } else if (fileRejections[0]?.errors[0]?.code === "file-too-large") {
      if (!tier || (tier && tier === PricingTiers.BASIC)) {
        fileRejectionError = (
          <span>
            File size should not exceed 10 MB. Upgrade to a <a href="/organization">paid plan</a> to upload files up to
            300 MB.
          </span>
        );
      } else {
        fileRejectionError = `File size should not exceed 300 MB`;
      }
    }
  }

  return (
    <>
      <div {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <Dropzone isDragActive={isDragActive}>
          <img
            className="img-fluid mb-2"
            src="./assets/img/upload-file.svg"
            alt="File upload"
            style={{ maxWidth: "2.5rem" }}
          />
          <p className="dropzone-content" style={{ color: "#000000", fontWeight: 600 }}>
            {!tier || tier === PricingTiers.BASIC
              ? "Drag and drop your file here or"
              : "Drag and drop your files here or"}
          </p>
          <Button type="button" onClick={open} className="btn mt-0">
            Browse files
          </Button>
          {fileRejectionError && <p style={{ color: "red", paddingTop: "1rem" }}>{fileRejectionError}</p>}
        </Dropzone>
      </div>
      {files?.length ? <CsvFilesList files={files} setFiles={setFiles} /> : <></>}
    </>
  );
};

export default memo(FileDropZone);
