import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { format, compareAsc } from "date-fns";
import { useToggle } from "hooks/useToggle";

import Footer from "components/footer/footermain";
import Header from "components/navbar/header";
import Table from "components/table/table";
import Alert from "components/alert";
import ModalConfirmation from "components/form/modal-confirmation";
import ModalUpdateRole from "components/modal/modal-update-role";
import Actions from "redux/actions/invitation";

const InvitationPage = () => {
  const user = useSelector((s) => s.login.user);
  const { data, items, pages, loading, showDeleteSuccess, showResendSuccess } = useSelector((s) => s.invitation);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [showResend, setShowResend] = useState(false);
  const [resendId, setResendId] = useState("");
  const [isUpdateRoleModalOpen, toggleUpdateRoleModal] = useToggle();
  const [selectedInvitation, setSelectedInvitation] = useState();

  const handleConfirmDeleteModal = () => {
    setShowDelete(false);
    Actions.delete(deleteId);
    setDeleteId("");
  };

  const handleCloseDeleteModal = () => {
    setShowDelete(false);
    setDeleteId("");
  };

  const handleConfirmResendModal = () => {
    setShowResend(false);
    Actions.resend(resendId);
    setResendId("");
  };

  const handleCloseResendModal = () => {
    setShowResend(false);
    setResendId("");
  };

  // TABLE/PAGINATION
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearch = (e) => {
    const input = e.currentTarget.value;
    // eslint-disable-next-line no-useless-escape
    if (/^[a-zA-Z0-9!@#$%^&()_\-=\[\]{};':"\\|,.<>\/?]*$/.test(input)) {
      setSearchValue(input);
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchQuery(searchValue), 500);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  const handleDeleteClick = async (data) => {
    setDeleteId(data._id);
    setShowDelete(true);
  };

  const handleResendClick = async (data) => {
    setResendId(data._id);
    setShowResend(true);
  };

  const refreshData = () => {
    setRefresh(true);
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const onClickUpdateRole = (invitation) => {
    setSelectedInvitation(invitation);
    toggleUpdateRoleModal();
  };

  const closeUpdateRoleModal = () => {
    setSelectedInvitation("");
    toggleUpdateRoleModal();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        acessor: "email",
        Cell: (row) => {
          return row.row.original.email;
        },
        canSort: true,
      },
      {
        Header: "Status",
        accessor: "isAccepted",
        Cell: (row) => {
          const expired = compareAsc(new Date(), new Date(row.row.original.inviteToken.expires)) === 1;

          return row.row.original.isAccepted ? (
            <span className="text-success">Accepted</span>
          ) : (
            <div>
              {expired ? (
                <span className="text-danger">Expired</span>
              ) : (
                <span className="text-danger">Not Accepted</span>
              )}
              <br />
              <small>Expiration: {format(new Date(row.row.original.inviteToken.expires), "MMM dd, yyyy")}</small>
            </div>
          );
        },
      },
      {
        Header: "Organization",
        id: "organization",
        Cell: (row) => {
          return row.row.original.organization?.length ? row.row.original.organization[0]?.name : "N/A";
        },
        canSort: false,
      },
      {
        Header: "Role",
        id: "role",
        Cell: (row) => {
          if (!row.row.original.groups) {
            return "N/A";
          }

          const roleKey = Object.keys(row.row.original.groups)?.shift();
          return row.row.original.groups[roleKey] || "N/A";
        },
        canSort: false,
      },
      {
        Header: "Invited At",
        accessor: "timeCreated",
        Cell: (row) => {
          return row.row.original.timeCreated ? format(new Date(row.row.original.timeCreated), "MMM dd, yyyy") : "";
        },
      },
      {
        Header: "",
        id: "actions",
        Cell: (row) => {
          return (
            <div className="d-flex align-items-center">
              <a className="btn btn-sm btn-outline-secondary ml-2" onClick={() => onClickUpdateRole(row.row.original)}>
                <i className="tio-settings-outlined"></i> Update Role
              </a>
              {!row.row.original.isAccepted && (
                <>
                  <a className="btn btn-sm btn-outline-danger ml-2" onClick={() => handleDeleteClick(row.row.original)}>
                    <i className="tio-delete-outlined"></i> Delete
                  </a>

                  <a
                    className="btn btn-sm btn-outline-primary ml-2"
                    onClick={() => handleResendClick(row.row.original)}
                  >
                    <i className="tio-send"></i> Resend
                  </a>
                </>
              )}
              {row.row.original.isAccepted && (
                <a className="btn btn-sm btn-outline-danger ml-2" onClick={() => handleDeleteClick(row.row.original)}>
                  <i className="tio-delete-outlined"></i> Remove
                </a>
              )}
            </div>
          );
        },
        sortable: false,
        filterable: false,
      },
    ],
    []
  );

  const fetchData = useCallback(({ pageSize, pageIndex, sort, filtered }) => {
    Actions.getAll({ limit: pageSize, page: pageIndex + 1, sort, q: filtered });
  }, []);

  useEffect(() => {
    if (showDeleteSuccess || showResendSuccess) refreshData();

    if (showResendSuccess) {
      const alert = <Alert key="success" type="success" message="Invitation resent successfully." />;
      setAlerts(alert);
    }
  }, [showDeleteSuccess, showResendSuccess]);

  useEffect(() => {
    window.dashboardOnReady();

    // lets get the body classes ready for Dashboard
    const origBodyClass = document.body.className;
    document.body.className = "footer-offset";

    return () => {
      document.body.className = origBodyClass;
    };
  }, []);

  return (
    <>
      <Header user={user} refreshData={refreshData} />

      <main id="content" role="main" className="main" style={isMobile ? { paddingTop: "7.75rem" } : {}}>
        <div className="content container-fluid">
          <div className="page-header pb-0">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-title">Invitations</h1>
              </div>
            </div>
          </div>

          <>{alerts}</>
          <div className="card">
            <div className="card-header">
              <div className="row justify-content-between align-items-center flex-grow-1">
                <div className="col-sm-6 col-md-4 mb-3 mb-sm-0">
                  <form>
                    <div className="input-group input-group-merge input-group-flush">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="tio-search"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        value={searchValue}
                        onChange={onChangeSearch}
                        className="form-control"
                        placeholder="Search invitations"
                        aria-label="Search invitations"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <Table
              columns={columns}
              data={data}
              items={items}
              loading={loading}
              filtered={searchQuery}
              fetchData={fetchData}
              pageCount={pages.total}
              refresh={refresh}
              cancelRefresh={() => setRefresh(false)}
            ></Table>
          </div>
        </div>

        <Footer />
      </main>

      <ModalConfirmation
        title="You are about to delete an invitation."
        message="Are you sure you want to continue with this action?"
        confirmButtonText="Yes, delete it!"
        show={showDelete}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleConfirmDeleteModal}
      ></ModalConfirmation>

      <ModalConfirmation
        title="You are about to resend an invitation."
        message="Are you sure you want to continue with this action?"
        confirmButtonText="Yes, resend it!"
        show={showResend}
        handleClose={handleCloseResendModal}
        handleConfirm={handleConfirmResendModal}
      ></ModalConfirmation>

      {isUpdateRoleModalOpen && selectedInvitation && (
        <ModalUpdateRole
          invitation={selectedInvitation}
          show={isUpdateRoleModalOpen}
          handleClose={closeUpdateRoleModal}
          refreshData={refreshData}
          setSuccessAlert={setAlerts}
        />
      )}
    </>
  );
};

export default InvitationPage;
