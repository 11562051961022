import React from "react";
import styled from "styled-components";

const DivLoading = styled.div`
  display: block;
  position: absolute;
  z-index: 99;
  left: 0;
  right: 0;
  transition: all 0.3s ease;
  top: 0;
  bottom: 0;
  text-align: center;
  ${({ opacity }) => `background: rgba(255, 255, 255, ${opacity});`}
`;

const DivSpinnerText = styled.div`
  align-self: center !important;
`;

const Loading = ({ loading, text, secondaryText, showLoadingText = true, opacity = 0.9 }) => {
  return loading ? (
    <DivLoading className="-loading -active" opacity={opacity}>
      <div className="d-flex h-100 justify-content-center">
        <DivSpinnerText>
          <div className="spinner-border align-self-center mb-2" role="status">
            {showLoadingText && <span className="sr-only">Loading...</span>}
          </div>
          {text && <h3 className={`d-block ${!secondaryText ? "mb-3" : ""}`}>{text}</h3>}
          {secondaryText && <p>{secondaryText}</p>}
        </DivSpinnerText>
      </div>
    </DivLoading>
  ) : null;
};

export default Loading;
