import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  loading: false,
  success: false,
  error: undefined,
  hasError: {},
  help: {},
  data: null,
  pages: {},
  items: {},
  showDeleteSuccess: false,
  errorStatus: null,
};

const reducer = function (state = initialState, action) {
  if (action.type === Constants.CHARTS_GET_BY_ID) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.CHARTS_GET_BY_ID_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      success: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      data: {
        chartType: action.response.chartType,
        questionText: action.response.questionText,
      },
    };
  }

  if (action.type === Constants.PUBLIC_LINKS_GET_BY_ORG) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.PUBLIC_LINKS_GET_BY_ORG_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      success: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      data: action.response.data,
      pages: action.response.pages,
      items: action.response.items,
    };
  }

  if (action.type === Constants.PUBLIC_LINKS_DELETE) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.PUBLIC_LINKS_DELETE_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showDeleteSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      errorStatus: validation.statusCode,
    };
  }

  if (action.type === Constants.PUBLIC_LINKS_UPDATE_DELETE_SUCCESS) {
    return {
      ...state,
      showDeleteSuccess: false,
    };
  }

  return state;
};

export default reducer;
