import React from "react";
import Package from "../../../package.json";

const FooterMain = () => {
  const year = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="row justify-content-between align-items-center">
        <div className="col">
          <p className="font-size-sm mb-0">
            © <span className="d-none d-sm-inline-block">{year} ZingData</span>
            <span className="d-none d-sm-inline-block ml-1">(Version: {Package.version})</span>
            <span className="d-inline-block d-sm-none ml-1">(v{Package.version})</span>
          </p>
        </div>
        <div className="col-auto">
          <div className="d-flex justify-content-end">
            <ul className="list-inline list-separator">
              <li className="list-inline-item">
                <a
                  className="list-separator-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.getzingdata.com/quickstart/"
                >
                  Documentation
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="list-separator-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://getzingdata.com/tos/"
                >
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMain;
