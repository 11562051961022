import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

import Footer from "components/footer/footermain";
import Header from "components/navbar/header";
import JoinsTable from "components/datasource/joins/joins-table";
import RowLevelSecuritySettings from "components/datasource/rls-settings";
import Loading from "components/loading";
import Unauthorized from "components/unauthorized";
import Alert from "components/alert";
import AdminSettings from "components/datasource/admin-settings";
import NlqExamples from "components/datasource/nlq-examples";
import NlqHelperText from "components/datasource/nlq-helper-text";

import DatasourceActions from "redux/actions/datasource";
import JoinActions from "redux/actions/join";
import UserActions from "redux/actions/user";
import { DATASOURCES_WITH_NO_JOINS, datasourceTypes, sampleDatasource } from "helpers/constants";
import { truncate } from "helpers/truncate";
import AuthService from "../../helpers/auth";
import NewJoinCanvas from "./new-join-canvas";
import EditJoinCanvas from "./edit-join-canvas";
import ViewJoinCanvas from "./view-join-canvas";
import Aliases from "./aliases";

const DatasourceSettings = () => {
  const { id: datasourceId } = useParams();
  const { user } = AuthService.getUser();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState(1);
  const [isCreatingNewJoin, setIsCreatingNewJoin] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [hasError, setHasError] = useState();
  const [refresh, setRefresh] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [selectedJoinIdToEdit, setSelectedJoinIdToEdit] = useState();
  const [selectedJoinIdToView, setSelectedJoinIdToView] = useState();
  const [isSampleDatasource, setIsSampleDatasource] = useState(false);

  const { datasource, loading: loadingDatasource } = useSelector((s) => s.datasource);
  const { isAdminOfOrg, loading: loadingUserRole } = useSelector((s) => s.user);
  const { selectedJoin, loading: loadingJoin, fetchingJoin } = useSelector((s) => s.join);

  useEffect(() => {
    window.dashboardOnReady();
    const origBodyClass = document.body.className;
    document.body.className = "footer-offset";
    return () => {
      document.body.className = origBodyClass;
    };
  }, []);

  useEffect(() => {
    setIsTouchDevice("ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
  }, []);

  useEffect(() => {
    const activeTab = searchParams.get("tab");
    switch (activeTab) {
      case "joins":
        setActiveTab(1);
        break;
      case "aliases":
        setActiveTab(2);
        break;
      case "rls":
        setActiveTab(3);
        break;
      case "admin":
        setActiveTab(4);
        break;
      case "examples":
        setActiveTab(5);
        break;
      case "helper-text":
        setActiveTab(6);
        break;
      default:
        setActiveTab(!DATASOURCES_WITH_NO_JOINS.includes(datasource?.type) ? 1 : 3);
        break;
    }
  }, [searchParams, datasource]);

  const fetchDatasource = useCallback((id) => {
    DatasourceActions.getDataSourceById(id);
  }, []);

  useEffect(() => {
    fetchDatasource(datasourceId);
  }, [datasourceId]);

  useEffect(() => {
    if (datasource?.organizationId && !isAdminOfOrg) {
      UserActions.getUserRole(datasource?.organizationId);
    }

    if (
      datasource?.type == datasourceTypes.POSTGRES &&
      datasource.databasename === sampleDatasource.databasename &&
      datasource.host === sampleDatasource.host
    ) {
      setIsSampleDatasource(true);
    }
  }, [datasource]);

  const onClickAddNewJoin = useCallback(() => {
    setIsCreatingNewJoin(true);
    setAlerts([]);
  }, []);

  const refreshData = () => {
    setRefresh(true);
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const onClickCancelJoin = useCallback(() => {
    setIsCreatingNewJoin(false);
    setSelectedJoinIdToEdit();
    JoinActions.clearSelectedJoin();
  }, []);

  const onClickGoBack = useCallback(() => {
    setIsCreatingNewJoin(false);
    setSelectedJoinIdToEdit();
    setSelectedJoinIdToView();
    JoinActions.clearSelectedJoin();
  }, []);

  const onChangeTab = useCallback((tabNumber, tabName) => {
    setActiveTab(tabNumber);
    setSearchParams({ tab: tabName });
    setSelectedJoinIdToEdit();
    setIsCreatingNewJoin(false);
    setSelectedJoinIdToView();
    setAlerts([]);
  }, []);

  const fetchData = useCallback(({ pageSize, pageIndex, sort }) => {
    JoinActions.getJoinsByDatasource(datasourceId, { limit: pageSize, page: pageIndex + 1, sort });
  }, []);

  const onClickEditJoin = useCallback((joinId) => {
    setSelectedJoinIdToEdit(joinId);
    JoinActions.getJoinById(joinId);
  }, []);

  const onClickViewJoin = useCallback((joinId) => {
    setSelectedJoinIdToView(joinId);
    JoinActions.getJoinById(joinId);
  }, []);

  const renderTabOneContent = () => {
    if (isCreatingNewJoin) {
      return (
        <NewJoinCanvas
          datasource={datasource}
          alerts={alerts}
          setAlerts={setAlerts}
          hasError={hasError}
          setHasError={setHasError}
          onClickCancelJoin={onClickCancelJoin}
          refreshData={refreshData}
        />
      );
    } else if (selectedJoinIdToEdit && selectedJoin) {
      return (
        <EditJoinCanvas
          datasource={datasource}
          alerts={alerts}
          setAlerts={setAlerts}
          hasError={hasError}
          setHasError={setHasError}
          onClickCancelJoin={onClickCancelJoin}
          refreshData={refreshData}
          selectedJoin={selectedJoin}
        />
      );
    } else if (selectedJoinIdToView && selectedJoin) {
      return (
        <ViewJoinCanvas
          datasource={datasource}
          alerts={alerts}
          setAlerts={setAlerts}
          hasError={hasError}
          setHasError={setHasError}
          onClickGoBack={onClickGoBack}
          refreshData={refreshData}
          selectedJoin={selectedJoin}
        />
      );
    }

    return (
      <div className="card mt-3">
        {!isTouchDevice ? (
          <div className="card-header d-flex justify-content-end">
            <button className="btn btn-primary btn-sm" onClick={onClickAddNewJoin}>
              <i className="bi-people-file me-1"></i> Add New Join
            </button>
          </div>
        ) : (
          <Alert
            type="soft-primary"
            className="mb-0"
            message="Creating and editing joins are available on Desktop browsers."
          />
        )}
        <div className="card-body">
          <JoinsTable
            datasourceId={datasourceId}
            refresh={refresh}
            setRefresh={setRefresh}
            fetchData={fetchData}
            setAlerts={setAlerts}
            refreshData={refreshData}
            isTouchDevice={isTouchDevice}
            onClickEditJoin={onClickEditJoin}
            onClickViewJoin={onClickViewJoin}
          />
        </div>
      </div>
    );
  };

  if (!loadingUserRole && !loadingDatasource && isAdminOfOrg && !isAdminOfOrg.isAdmin) {
    return <Unauthorized backPath="/datasource" />;
  }

  return (
    <>
      <Header user={user} />
      <main id="content" role="main" className="main" style={isMobile ? { paddingTop: "7.75rem" } : {}}>
        <div className="content container-fluid">
          <Loading loading={loadingDatasource || loadingUserRole || loadingJoin || fetchingJoin}></Loading>
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-title">
                  Data Source Settings:{" "}
                  {datasource?.type === datasourceTypes.GOOGLE_BIGQUERY
                    ? datasource.googleBigQuery.datasetId
                    : datasource?.type === datasourceTypes.GOOGLE_SHEETS ||
                      datasource?.type === datasourceTypes.GOOGLE_SHEETS_LIVE
                    ? truncate(datasource.googleSheets?.tableName)
                    : truncate(datasource?.displayName || datasource?.databasename)}
                </h1>
              </div>
            </div>

            <div className="w-100">{alerts}</div>

            <ul className="nav nav-tabs page-header-tabs mt-4">
              {!DATASOURCES_WITH_NO_JOINS.includes(datasource?.type) && (
                <>
                  <li className="nav-item" onClick={() => onChangeTab(1, "joins")} style={{ cursor: "pointer" }}>
                    <span className={`nav-link ${activeTab === 1 ? "active" : ""}`}>Joins</span>
                  </li>
                  <li className="nav-item" onClick={() => onChangeTab(2, "aliases")} style={{ cursor: "pointer" }}>
                    <span className={`nav-link ${activeTab === 2 ? "active" : ""}`}>Aliases</span>
                  </li>
                </>
              )}
              <li className="nav-item" onClick={() => onChangeTab(3, "rls")} style={{ cursor: "pointer" }}>
                <span className={`nav-link ${activeTab === 3 ? "active" : ""}`}>Row Level Security</span>
              </li>
              {!isSampleDatasource && (
                <li className="nav-item" onClick={() => onChangeTab(4, "admin")} style={{ cursor: "pointer" }}>
                  <span className={`nav-link ${activeTab === 4 ? "active" : ""}`}>Options</span>
                </li>
              )}
              <li className="nav-item" onClick={() => onChangeTab(5, "examples")} style={{ cursor: "pointer" }}>
                <span className={`nav-link ${activeTab === 5 ? "active" : ""}`}>Examples</span>
              </li>
              <li className="nav-item" onClick={() => onChangeTab(6, "helper-text")} style={{ cursor: "pointer" }}>
                <span className={`nav-link ${activeTab === 6 ? "active" : ""}`}>Helper Text</span>
              </li>
            </ul>
          </div>
          <div>
            {activeTab === 1 && !DATASOURCES_WITH_NO_JOINS.includes(datasource?.type) && renderTabOneContent()}
            {activeTab === 2 && datasource && (
              <Aliases datasource={datasource} setAlerts={setAlerts} setHasError={setHasError} />
            )}
            {activeTab === 3 && datasource && (
              <RowLevelSecuritySettings
                datasource={datasource}
                fetchDatasource={fetchDatasource}
                setAlerts={setAlerts}
                user={user}
              />
            )}
            {activeTab === 4 && datasource && !isSampleDatasource && (
              <AdminSettings datasource={datasource} fetchDatasource={fetchDatasource} setAlerts={setAlerts} />
            )}
            {activeTab === 5 && datasource && (
              <NlqExamples
                datasource={datasource}
                fetchDatasource={fetchDatasource}
                setAlerts={setAlerts}
                user={user}
              />
            )}
            {activeTab === 6 && datasource && (
              <NlqHelperText
                datasource={datasource}
                fetchDatasource={fetchDatasource}
                setAlerts={setAlerts}
                user={user}
              />
            )}
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default memo(DatasourceSettings);
