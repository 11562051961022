import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { capitalize } from "helpers/capitalize";
import { useToggle } from "hooks/useToggle";
import ModalUpgradePlan from "components/modal/modal-upgrade-plan";
import ModalTransferOrgOwnership from "components/modal/modal-transfer-org-ownership";
import AccountActions from "redux/actions/account";
import { PricingTiers } from "helpers/constants";

const Overview = ({ accountData, organizationId, organizationOwner, loggedInUserId }) => {
  const { tier, chargeFrequency, plan, quantity } = accountData?.billingPlans || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransferOwnershipModalOpen, toggleTransferOwnershipModal] = useToggle();

  let [searchParams, setSearchParams] = useSearchParams();
  const isUpgrade = searchParams.get("upgrade");

  const isNotBasicTier = useMemo(() => tier && tier !== "basic", [tier]);

  useEffect(() => {
    if (isUpgrade) {
      setIsModalOpen(true);
    }
  }, [isUpgrade]);

  const frequency = useMemo(() => {
    if (plan) {
      return plan.interval === "month" ? "monthly" : "yearly";
    } else if (chargeFrequency) {
      return chargeFrequency === "monthly" ? "monthly" : "yearly";
    }
  }, [plan, chargeFrequency]);

  const pricePerYear = useMemo(() => {
    if (!tier || tier === "basic") {
      return "$0 USD";
    } else if (plan) {
      let price = plan.amount / 100;
      if (quantity && tier !== "enterprise") {
        price = price * quantity;
      }

      if (frequency === "monthly") {
        return `$${price * 12} USD`;
      }

      return `$${price} USD`;
    }
  }, [tier, plan, frequency]);

  const handleManageBilling = useCallback(() => {
    AccountActions.manageBilling();
  }, []);

  const onClickUpgradePlan = () => {
    if (!isUpgrade && !isModalOpen) {
      const params = new URLSearchParams();
      params.set("upgrade", true);
      setSearchParams(params);
    }
    setIsModalOpen(true);
  };

  const onClickCloseUpgradeModal = () => {
    if (isUpgrade && isModalOpen) {
      const params = new URLSearchParams();
      params.delete("upgrade");
      setSearchParams(params);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="card mt-5 mb-3">
      <div className="card-header card-header-content-between border-bottom">
        <h4 className="card-header-title">Overview</h4>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-md mb-4 mb-md-0">
            <div className="mb-4">
              <span className="account-overview-subtitle">
                Organization plan{isNotBasicTier && frequency ? ` (billed ${frequency} per user)` : ""}:
              </span>
              <h3>{capitalize(tier || "Basic")}</h3>
            </div>

            {organizationOwner?.toString() === loggedInUserId?.toString() && tier !== "enterprise" && (
              <div>
                <span className="account-overview-subtitle">Total per year:</span>
                {pricePerYear && <h1 className="text-primary">{pricePerYear}</h1>}
              </div>
            )}
          </div>

          {organizationOwner?.toString() === loggedInUserId?.toString() && (
            <div className="col-md-auto">
              <div className="d-grid d-sm-flex flex-column gap-3">
                {isNotBasicTier && (
                  <button type="button" className="btn btn-primary w-100 w-sm-auto" onClick={handleManageBilling}>
                    Manage Subscription
                  </button>
                )}
                {!isNotBasicTier && (
                  <button type="button" className="btn btn-primary w-100 w-sm-auto" onClick={onClickUpgradePlan}>
                    Upgrade Plan
                  </button>
                )}
                <button type="button" className="btn btn-white w-100 w-sm-auto" onClick={toggleTransferOwnershipModal}>
                  Transfer Ownership
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <ModalUpgradePlan show={isModalOpen} handleClose={onClickCloseUpgradeModal} organizationId={organizationId} />
      )}
      {isTransferOwnershipModalOpen && (
        <ModalTransferOrgOwnership
          show={isTransferOwnershipModalOpen}
          handleClose={toggleTransferOwnershipModal}
          loggedInUserId={loggedInUserId}
          orgHasBillingPlan={tier && tier !== PricingTiers.BASIC}
        />
      )}
    </div>
  );
};

export default memo(Overview);
