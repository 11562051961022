import React from "react";
import { Modal } from "react-bootstrap";

const ModalDeleteFieldAlias = ({ show, handleClose, onClickDeleteConfirm, dataToDelete }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Alias</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete the alias <b>&quot;{dataToDelete?.alias}&quot;</b> for the column{" "}
          <b>{dataToDelete?.columnName}</b> in table <b>{dataToDelete?.tableName}</b>?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-primary" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-sm btn-outline-danger" onClick={onClickDeleteConfirm}>
          Yes, Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteFieldAlias;
