import React from "react";
import { Button, Badge } from "react-bootstrap";

const FieldBadge = ({ field, setFields, fields }) => {
  const onClickDelete = () => {
    const existingFields = [...fields];
    existingFields.splice(existingFields.indexOf(field), 1);
    setFields(existingFields);
  };

  return (
    <Badge
      variant="secondary"
      className="d-flex align-items-center p-2"
      style={{ width: "fit-content", backgroundColor: "#F2F4F6", fontWeight: 500 }}
    >
      <span className="mr-1 text-dark">{field}</span>
      <Button
        variant="light"
        onClick={onClickDelete}
        className="p-0 text-md-center"
        style={{ background: "transparent", border: "none", marginTop: "-3px" }}
      >
        <img
          className="img-fluid"
          src="../../assets/img/delete-icon.svg"
          alt="delete file"
          width="18px"
          height="18px"
        />
      </Button>
    </Badge>
  );
};

export default FieldBadge;
