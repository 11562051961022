export const chartColors = [
  "#4B87B9FF",
  "#C06C84FF",
  "#F67280FF",
  "#F8B195FF",
  "#74B49BFF",
  "#00A8B5FF",
  "#494CA2FF",
  "#FFCD60FF",
  "#FFF0DBFF",
  "#EEEEEEFF",
];
