import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class ProfileActions {
  static async getDetails() {
    ApiActions.get("/api/accounts/my", undefined, Store, Constants.GET_DETAILS, Constants.GET_DETAILS_RESPONSE);
  }

  static async saveDetails(data) {
    ApiActions.put("/api/accounts/my", data, Store, Constants.SAVE_DETAILS, Constants.SAVE_DETAILS_RESPONSE);
  }

  static hideDetailsSaveSuccess() {
    Store.dispatch({
      type: Constants.HIDE_DETAILS_SAVE_SUCCESS,
    });
  }

  static async savePassword(data) {
    ApiActions.put("/api/users/my/password", data, Store, Constants.SAVE_PASSWORD, Constants.SAVE_PASSWORD_RESPONSE);
  }

  static hidePasswordSaveSuccess() {
    Store.dispatch({
      type: Constants.HIDE_PASSWORD_SAVE_SUCCESS,
    });
  }

  static async saveSettings(data) {
    ApiActions.put("/api/users/my/settings", data, Store, Constants.SAVE_SETTINGS, Constants.SAVE_SETTINGS_RESPONSE);
  }

  static async saveOrganizationSettings(data) {
    ApiActions.put(
      "/api/organizations/my/settings",
      data,
      Store,
      Constants.SAVE_ORGANIZATION_SETTINGS,
      Constants.SAVE_ORGANIZATION_SETTINGS_RESPONSE
    );
  }

  static hideSettingsSaveSuccess() {
    Store.dispatch({
      type: Constants.HIDE_SETTINGS_SAVE_SUCCESS,
    });
  }

  static async deleteAccount() {
    ApiActions.delete(
      "/api/accounts/my/delete",
      null,
      Store,
      Constants.DELETE_ACCOUNT,
      Constants.DELETE_ACCOUNT_RESPONSE
    );
  }

  static hideDeleteAccount() {
    Store.dispatch({
      type: Constants.DELETE_ACCOUNT_HIDE,
    });
  }

  static hideCropImage() {
    Store.dispatch({
      type: Constants.USER_CROP_IMAGE_HIDE,
    });
  }

  static async savePicture(data) {
    ApiActions.put(
      "/api/users/my/photo",
      data,
      Store,
      Constants.SAVE_PICTURE,
      Constants.SAVE_PICTURE_RESPONSE,
      null,
      true
    );
  }

  static hidePictureSaveSuccess() {
    Store.dispatch({
      type: Constants.HIDE_PICTURE_SAVE_SUCCESS,
    });
  }
}

export default ProfileActions;
