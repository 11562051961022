import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  loading: false,
  success: false,
  error: undefined,
  hasError: {},
  help: {},
};

const reducer = function (state = initialState, action) {
  if (action.type === Constants.FORGOT_PASSWORD_MOUNT) {
    return {
      ...initialState,
    };
  }

  if (action.type === Constants.FORGOT_PASSWORD) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.FORGOT_PASSWORD_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      success: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  return state;
};

export default reducer;
