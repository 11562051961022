import React, { useState } from "react";

const ShowPassword = React.forwardRef((props, ref) => {
  const { errors, placeholder, tabIndex, name, id } = props;

  const type = "password";

  const [inputState, setInputState] = useState(type);

  const toggleInputState = () => {
    setInputState((state) => {
      if (state === "input") {
        return "password";
      }
      return "input";
    });
  };

  React.useEffect(() => {
    setInputState(type);
  }, [type]);

  return (
    <div>
      <div className="input-group input-group-merge">
        <input
          type={inputState}
          name={name}
          id={id}
          ref={ref}
          className={"js-toggle-password form-control form-control-lg" + (errors?.message ? " is-invalid" : "")}
          placeholder={placeholder}
          aria-label={placeholder}
          tabIndex={tabIndex}
        />
        <div id="changePassTarget" className="input-group-append">
          <a className="input-group-text" onClick={toggleInputState}>
            <i
              id="changePassIcon"
              className={inputState === "input" ? "tio-hidden-outlined" : "tio-visible-outlined"}
            ></i>
          </a>
        </div>
      </div>
      <div style={{ display: errors?.message ? "block" : "none" }} className="invalid-feedback">
        {errors?.message}
      </div>
    </div>
  );
});

ShowPassword.displayName = "ShowPassword";

export default ShowPassword;
