import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class HelperTextActions {
  static async create(data) {
    ApiActions.post(
      "/api/v1/helper-text",
      data,
      Store,
      Constants.HELPER_TEXT_CREATE_NEW,
      Constants.HELPER_TEXT_CREATE_NEW_RESPONSE
    );
  }

  static clearFewshotSuccessState() {
    Store.dispatch({
      type: Constants.HELPER_TEXT_CLEAR_SUCCESS_STATE,
    });
  }

  static async update(id, data) {
    ApiActions.put(
      `/api/v1/helper-text/${id}`,
      data,
      Store,
      Constants.HELPER_TEXT_UPDATE,
      Constants.HELPER_TEXT_UPDATE_RESPONSE
    );
  }

  static async delete(id) {
    ApiActions.delete(
      `/api/v1/helper-text/${id}`,
      undefined,
      Store,
      Constants.HELPER_TEXT_DELETE,
      Constants.HELPER_TEXT_DELETE_RESPONSE
    );
  }
}

export default HelperTextActions;
