import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import AccountActions from "redux/actions/account";
import Loading from "components/loading";
import Alert from "components/alert";

const ModalUpgradePlan = ({ show, handleClose, organizationId, hasAtLeastOneBillingPlan }) => {
  const [isAnnual, setIsAnnual] = useState(false);
  const [hasPlan] = useState(hasAtLeastOneBillingPlan || localStorage.getItem("hasPlan") === "true");
  const [alerts, setAlerts] = useState([]);
  const { creatingSession, upgradeSuccess, upgradeError } = useSelector((s) => s.account);

  useEffect(() => {
    if (window.toggleSwitch) {
      window.toggleSwitch();
    }
  }, []);

  const onChangeToggle = useCallback((e) => {
    setIsAnnual(e.target.checked);
  }, []);

  const onClickStartTrial = useCallback(({ plan, frequency }) => {
    AccountActions.upgradeOrganization({ organizationId, plan, frequency });
  }, []);

  useEffect(() => {
    if (upgradeSuccess) {
      setAlerts([]);
      handleClose();
    }
  }, [upgradeSuccess]);

  useEffect(() => {
    if (upgradeError) {
      setAlerts([<Alert key="danger" type="danger" message={upgradeError} />]);
    }
  }, [upgradeError]);

  return (
    <Modal backdrop="static" show={show} onHide={handleClose} keyboard={false} centered size="xl">
      <Modal.Header closeButton />
      <Modal.Body style={{ paddingTop: 0 }}>
        <Loading loading={creatingSession}></Loading>
        <div>{alerts}</div>
        <div className="d-flex justify-content-center mb-5">
          <div className="form-check form-switch form-switch-between">
            <label className="form-check-label">Monthly</label>
            <input
              className="js-toggle-switch form-check-input"
              type="checkbox"
              data-hs-toggle-switch-options='{
                "targetSelector": "#pricingCount1, #pricingCount2, #pricingCount3"
              }'
              style={{ position: "relative" }}
              onChange={onChangeToggle}
              checked={isAnnual}
            />
            <label className="form-check-label form-switch-promotion">
              Annually
              <span className="form-switch-promotion-container">
                <span className="form-switch-promotion-body">
                  <img src="/assets/svg/illustrations/arrow.svg" alt="arrow" style={{ marginTop: "0.5rem" }} />
                  <span className="form-switch-promotion-text">
                    <span className="badge bg-primary rounded-pill ms-1">Save up to 16%</span>
                  </span>
                </span>
              </span>
            </label>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md mb-3">
            <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
              <div className="card-header text-center d-flex justify-content-center">
                <div className="d-flex flex-column">
                  <span className="text-uppercase h4 text-primary">Basic</span>
                  <h2 className="card-title display-3 text-dark">Free</h2>
                  <p className="card-text">For Individuals</p>
                </div>
              </div>

              <div className="card-body d-flex flex-column p-1">
                <p className="mb-0 p-3">
                  <strong>Free includes:</strong>
                </p>
                <ul className="list-checked list-checked-primary mb-0">
                  <li className="list-checked-item">Unlimited queries</li>
                  <li className="list-checked-item">Unlimited datasources</li>
                  <li className="list-checked-item">Google Single-Sign-On</li>
                  <li className="list-checked-item">
                    Postgres, MySQL, BigQuery, Snowflake, Trino, Clickhouse, Amazon RDS, Databricks, gSheet support
                  </li>
                  <li className="list-checked-item">CSV, Excel files up to 10 MB each</li>
                  <li className="list-checked-item">Tap to drill-down</li>
                  <li className="list-checked-item">3 user maximum</li>
                </ul>
              </div>

              <div className="card-footer border-0 text-center">
                <div className="d-grid mb-2">
                  <button type="button" className="form-check-select-stretched-btn btn btn-secondary" disabled>
                    Current Plan
                  </button>
                </div>
                <p className="fs-6 card-text text-muted">Free forever</p>
              </div>
            </div>
          </div>

          <div className="col-md mb-3">
            <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
              <div className="card-header text-center d-flex flex-column justify-content-center">
                <span className="text-uppercase h4 text-primary">Standard</span>
                <h2 className="card-title display-3 text-dark">
                  $
                  <span
                    id="pricingCount1"
                    data-hs-toggle-switch-item-options='{
                      "min": 12,
                      "max": 120
                    }'
                  >
                    12
                  </span>
                  <span className="fs-6 text-muted">{isAnnual ? "/ year" : "/ mo"}</span>
                </h2>
                <p className="card-text">Covers the Essentials</p>
              </div>

              <div className="card-body d-flex flex-column p-1">
                <p className="mb-0 p-3">
                  <strong>Everything on Basic, plus:</strong>
                </p>
                <ul className="list-checked list-checked-primary mb-0">
                  <li className="list-checked-item">Natural Language Querying using OpenAI</li>
                  <li className="list-checked-item">Dashboards</li>
                  <li className="list-checked-item">Push + Email alerts up to every minute</li>
                  <li className="list-checked-item">SSH tunneling</li>
                  <li className="list-checked-item">CSV, Excel files up to 100 MB each</li>
                </ul>
              </div>

              <div className="card-footer border-0 text-center">
                <div className="d-grid mb-2">
                  <button
                    type="button"
                    className="form-check-select-stretched-btn btn btn-primary"
                    onClick={() => onClickStartTrial({ plan: "standard", frequency: isAnnual ? "annual" : "monthly" })}
                  >
                    {hasPlan ? "Select Plan" : "Start 14-day Trial"}
                  </button>
                </div>
                <p className="fs-6 card-text text-muted">
                  {isAnnual ? "Billed $120 per user annually" : "Billed $12 per user monthly"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md mb-3">
            <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
              <div className="card-header text-center d-flex flex-column justify-content-center">
                <span className="text-uppercase h4 text-primary">Pro</span>
                <h2 className="card-title display-3 text-dark">
                  $
                  <span
                    id="pricingCount2"
                    data-hs-toggle-switch-item-options='{
                      "min": 18,
                      "max": 180
                    }'
                  >
                    18
                  </span>
                  <span className="fs-6 text-muted">{isAnnual ? "/ year" : "/ mo"}</span>
                </h2>
                <p className="card-text">Best for Teams</p>
              </div>

              <div className="card-body d-flex flex-column p-1">
                <p className="mb-0 p-3">
                  <strong>Everything on Standard, plus:</strong>
                </p>
                <ul className="list-checked list-checked-primary mb-0">
                  <li className="list-checked-item">Granular access control (editor, viewer, asker)</li>
                  <li className="list-checked-item">Chat support</li>
                  <li className="list-checked-item">Choice of LLMs (including fine tuned)</li>
                  <li className="list-checked-item">Embedded analytics</li>
                </ul>
              </div>

              <div className="card-footer border-0 text-center">
                <div className="d-grid mb-2">
                  <button
                    type="button"
                    className="form-check-select-stretched-btn btn btn-primary"
                    onClick={() => onClickStartTrial({ plan: "pro", frequency: isAnnual ? "annual" : "monthly" })}
                  >
                    {hasPlan ? "Select Plan" : "Start 14-day Trial"}
                  </button>
                </div>
                <p className="fs-6 card-text text-muted">
                  {isAnnual ? "Billed $180 per user annually" : "Billed $18 per user monthly"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md mb-3">
            <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
              <div className="card-header text-center d-flex flex-column justify-content-center">
                <span className="text-uppercase h4 text-primary">Enterprise</span>
                <p className="card-text" style={{ paddingBottom: "2.8rem" }}>
                  Best for Larger Companies
                </p>
              </div>

              <div className="card-body d-flex flex-column p-1">
                <p className="mb-0 p-3">
                  <strong>Everything on Pro, plus:</strong>
                </p>
                <ul className="list-checked list-checked-primary mb-0">
                  <li className="list-checked-item">Okta & Microsoft Active Directory SSO</li>
                  <li className="list-checked-item">Salesforce, Hubspot, SAP, and Oracle support</li>
                  <li className="list-checked-item">Slack + Teams integration</li>
                  <li className="list-checked-item">Implementation support</li>
                  <li className="list-checked-item">Service level agreement and uptime guarantee</li>
                  <li className="list-checked-item">User access logs</li>
                  <li className="list-checked-item">On-prem options</li>
                </ul>
              </div>

              <div className="card-footer border-0 text-center">
                <div className="d-grid mb-2">
                  <a
                    href="https://calendly.com/zingdata/zing-data-demo"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="form-check-select-stretched-btn btn btn-primary"
                  >
                    Contact Us
                  </a>
                </div>
                <p className="fs-6 card-text text-muted">Design a custom package</p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalUpgradePlan;
