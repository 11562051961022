import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import Icon from "components/icon";
import { ReactComponent as LeftJoinIcon } from "../icons/left-join-color.svg";
import { ReactComponent as RightJoinIcon } from "../icons/right-join-color.svg";
import { ReactComponent as FullJoinIcon } from "../icons/full-join-color.svg";
import { ReactComponent as InnerJoinIcon } from "../icons/inner-join-color.svg";
import "./custom-edge.scss";

const EdgeDropdown = ({
  id,
  onSelectJoinType,
  selectedJoin,
  color,
  deleteEdgeById,
  nodeToEdgeArray,
  source,
  target,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDropDownDisabled, setIsDropdownDisabled] = useState(false);

  useEffect(() => {
    const sourceTableName = source?.split("_node").shift();
    const targetTableName = target?.split("_node").shift();

    // eslint-disable-next-line no-unused-expressions
    nodeToEdgeArray?.map((o) => {
      if (o.sourceTableName === sourceTableName && o.targetTableName === targetTableName) {
        // enable/disable dropdown
        if (o.edgeId === id) {
          if (o.order > 1) {
            setIsDropdownDisabled(true);
          } else {
            setIsDropdownDisabled(false);
          }
        }
      }
    });
  }, [nodeToEdgeArray, source, target]);

  const onClickDownArrow = useCallback(() => {
    if (showDropdown) {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
    }
  }, [showDropdown]);

  const onClickDelete = useCallback(() => {
    deleteEdgeById(id);
  }, []);

  const renderSelectedJoinImage = useMemo(() => {
    switch (selectedJoin) {
      case "left":
        return <LeftJoinIcon fill={color} />;
      case "right":
        return <RightJoinIcon fill={color} />;
      case "inner":
        return <InnerJoinIcon fill={color} />;
      case "full":
        return <FullJoinIcon fill={color} />;
    }
  }, [selectedJoin]);

  return (
    <div className="btn-group">
      <button
        className={classNames("btn btn-xs btn-white dropdown-toggle custom-edge-button", {
          "with-dropdown": showDropdown,
        })}
        type="button"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={isHover || showDropdown ? { borderColor: color } : {}}
      >
        {renderSelectedJoinImage}
        {(isHover || showDropdown) && (
          <>
            {!isDropDownDisabled && (
              <Icon
                iconName={showDropdown ? "tio-chevron-up" : "tio-chevron-down"}
                className="ml-1"
                isClickable
                onClick={onClickDownArrow}
              />
            )}
            <Icon
              iconName={classNames("tio-clear", { "ml-1": isDropDownDisabled })}
              onClick={onClickDelete}
              isClickable
            />
          </>
        )}
      </button>
      {showDropdown && !isDropDownDisabled && (
        <div className="dropdown-menu joins-dropdown-menu">
          <span className="dropdown-item" onClick={() => onSelectJoinType("left", setShowDropdown)}>
            <img src="/assets/svg/joins/left-join.svg" />
            Left
          </span>
          <span className="dropdown-item" onClick={() => onSelectJoinType("right", setShowDropdown)}>
            <img src="/assets/svg/joins/right-join.svg" />
            Right
          </span>
          <span className="dropdown-item" onClick={() => onSelectJoinType("inner", setShowDropdown)}>
            <img src="/assets/svg/joins/inner-join.svg" />
            Inner
          </span>
          <span className="dropdown-item" onClick={() => onSelectJoinType("full", setShowDropdown)}>
            <img src="/assets/svg/joins/full-join.svg" />
            Full
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(EdgeDropdown);
