import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Actions from "redux/actions/logout";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import Footer from "components/footer/footerlogin";
import Alert from "components/alert";
import Logo from "components/logo/logo";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LogoutPage = () => {
  const stateLogout = useSelector((s) => s.logout);
  const [alerts, setAlerts] = useState([]);

  const { shutdown } = useIntercom();

  const query = useQuery();
  const errorMessage = query.get("errorMessage");

  // equivalent for componentWillMount
  useEffect(() => {
    window.loginOnReady();
    document.body.className = "main";

    shutdown();

    Actions.logout();

    if (errorMessage) {
      setAlerts([<Alert key="danger" type="danger" message={errorMessage} />]);
    }
  }, []);

  useEffect(() => {
    if (stateLogout.error) {
      alerts.push(<Alert key="danger" type="danger" message={stateLogout.error} />);
      setAlerts([...alerts]);
    } else if (!errorMessage && stateLogout.success) {
      setAlerts([<Alert key="success" type="success" message="Sign out successful" />]);
    }
  }, [stateLogout]);

  return (
    <main id="content" role="main" className="main">
      <div
        className="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{ height: "28rem", backgroundImage: "url(./assets/svg/components/abstract-bg-4.svg)" }}
      >
        <figure className="position-absolute right-0 bottom-0 left-0">
          <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>
      <div className="container py-5 py-sm-7">
        <Logo
          outerClassName="d-flex justify-content-center mb-5"
          innerClassName="z-index-2"
          style={{ width: "15rem" }}
        />
        <div className="row justify-content-center">
          <div className="col-md-7 col-lg-5">
            <div className="card card-lg mb-10">
              <div className="card-body">
                {alerts}
                <form className="js-validate">
                  <div className="text-center">
                    <a className="btn btn-link" href="/login">
                      <i className="tio-chevron-left" /> Back to Sign in
                    </a>
                  </div>
                </form>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default LogoutPage;
