import React, { memo, useEffect } from "react";
import * as yup from "yup";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
  message: yup.string().required(),
});

const ModalSendEmail = ({ show, handleClose }) => {
  const { register, errors, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("message", "Hey, can you help me connect our database to Zing Data?");
  }, []);

  // eslint-disable-next-line no-console
  const onError = (errors, e) => console.log("onError", errors, e);

  const save = (data, e) => {
    e.preventDefault();
    const { email, message } = data;

    window.location.href = `mailto:${email}?subject=Connect%20datasource%20to%20Zing%20Data&body=${message}`;
    handleClose();
  };

  return (
    <Modal backdrop="static" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Ask a teammate for help</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form>
            <div className="js-form-message form-group">
              <label className="input-label">To</label>
              <input
                type="text"
                className={"form-control " + (errors.email ? " is-invalid" : "")}
                name="email"
                placeholder="teammate@acme.com"
                aria-label="To email"
                ref={register}
              />
              <div className="invalid-feedback">{errors.email?.message}</div>
            </div>
            <div className="js-form-message form-group">
              <label className="input-label">Message</label>
              <textarea
                type="text"
                className={"form-control " + (errors.message ? " is-invalid" : "")}
                name="message"
                placeholder="teammate@acme.com"
                aria-label="Message"
                ref={register}
              />
              <div className="invalid-feedback">{errors.message?.message}</div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button type="submit" variant="primary" id="help-send-request" onClick={handleSubmit(save, onError)}>
          Send Request
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(ModalSendEmail);
