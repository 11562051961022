import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import Alert from "components/alert";
import Loading from "components/loading";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { getCroppedImage } from "helpers/canvas-preview";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(mediaWidth, mediaHeight, aspect, maxWidth) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: maxWidth || 50,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ModalCropImage = ({
  show,
  file,
  handleClose,
  handleSave,
  maxHeight,
  maxWidth,
  unit,
  width,
  height,
  aspectRatio = 1,
}) => {
  const { loading, error } = useSelector((s) => s.settings);

  const [alerts, setAlerts] = useState([]);
  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: unit || "%",
    x: 25,
    y: 25,
    width: width || 50,
    height: height || 50,
  });
  const [completedCrop, setCompletedCrop] = useState(undefined);
  const [aspect] = useState(aspectRatio);

  const save = async () => {
    const croppedImage = await getCroppedImage(imgRef.current, completedCrop);
    handleSave(croppedImage);
  };

  const close = () => {
    setAlerts([]);
    setCrop(undefined);
    setImgSrc("");
    handleClose();
  };

  useEffect(() => {
    if (error) {
      const newAlerts = alerts.concat(<Alert key="danger" type="danger" message={error} />);
      setAlerts(newAlerts);
    }
  }, [error]);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect, maxWidth));
    }
  }

  useEffect(() => {
    setCrop(undefined); // Makes crop preview update between images.
    setImgSrc(file || "");
    setAlerts([]);
  }, [file, show]);

  return (
    <Modal backdrop="static" size="lg" show={show} onHide={handleClose} keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center flex-column">
        <Loading loading={loading}></Loading>
        {Boolean(imgSrc) && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            maxHeight={maxHeight || 300}
            maxWidth={maxWidth || 300}
            width={width}
            height={height}
            aspect={aspect}
          >
            <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
          </ReactCrop>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button
          disabled={!(completedCrop?.width && completedCrop?.height && imgRef.current)}
          variant="primary"
          onClick={save}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCropImage;
