import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import Alert from "components/alert";
import Loading from "components/loading";
import HelperTextActions from "redux/actions/helper-text";

const ModalAddOrEditHelperText = ({ show, handleClose, selectedHelperText, datasourceId }) => {
  const [text, setText] = useState(selectedHelperText?.text || "");
  const { loading, error, showSaveSuccess, showUpdateSuccess } = useSelector((s) => s.helperText);

  const [alerts, setAlerts] = useState([]);

  const close = () => {
    setAlerts([]);
    handleClose();
  };

  useEffect(() => {
    if (error) {
      setAlerts([<Alert key="danger" type="danger" message={error} />]);
    }
  }, [error]);

  useEffect(() => {
    setAlerts([]);
  }, [show]);

  useEffect(() => {
    if (showSaveSuccess || showUpdateSuccess) {
      handleClose({ shouldRefreshData: true });
      HelperTextActions.clearFewshotSuccessState();
    }
  }, [showSaveSuccess, showUpdateSuccess]);

  const onChangeText = (val) => {
    setText(val);
  };

  const onClickSave = () => {
    setAlerts([]);
    HelperTextActions.create({ datasourceId, text: text?.trim() });
  };

  const onClickUpdate = () => {
    setAlerts([]);
    HelperTextActions.update(selectedHelperText?._id, { text: text?.trim() });
  };

  return (
    <Modal backdrop="static" show={show} onHide={handleClose} keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>{selectedHelperText ? "Edit Helper Text" : "Add Helper Text"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading}></Loading>
        {alerts}
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Helper Text</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter text..."
              value={text}
              onChange={(e) => onChangeText(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={selectedHelperText ? onClickUpdate : onClickSave}
          disabled={!text}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddOrEditHelperText;
