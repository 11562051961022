import React, { memo } from "react";
import { format } from "date-fns";

const UserRow = ({ user }) => {
  return (
    <tr>
      <td>
        <div className="d-flex">
          <div className="flex-shrink-0">
            <div className="avatar avatar-circle">
              <img
                className="avatar-img"
                src={user?.photoUrl || "/assets/svg/illustrations/profilepic.svg"}
                alt="Profile picture"
              />
            </div>
          </div>
          <div className="flex-grow-1 ml-3">
            <span className="d-block h5 text-inherit mb-0">
              {user?.roles?.account?.name}{" "}
              <i
                className="bi-patch-check-fill text-primary"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Top endorsed"
              ></i>
            </span>
            <span className="d-block font-size-sm text-body">{user?.email}</span>
          </div>
        </div>
      </td>
      <td>{user?.role || ""}</td>
      <td>
        {user.status === "active" ? (
          <h3 className="mb-0">
            <span className="badge bg-soft-success text-success">Active</span>
          </h3>
        ) : (
          <h3 className="mb-0">
            <span className="badge bg-soft-warning text-warning">Pending</span>
          </h3>
        )}
      </td>
      <td>
        <span className="d-block mb-0">
          {user.timeCreated ? format(new Date(user.timeCreated), "MMM dd, yyyy") : ""}
        </span>
      </td>
    </tr>
  );
};

export default memo(UserRow);
