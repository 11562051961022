import React, { useState } from "react";
import { Form } from "react-bootstrap";

const rowHeight = 24;
const minRows = 5;
const maxRows = 10;
const minHeight = rowHeight * minRows;
const maxHeight = rowHeight * maxRows;

const SqlInput = ({ className, label, value, setValue, highlightWords }) => {
  const [editing, setEditing] = useState(value ? false : true);
  const [sql, setSql] = useState(value);

  const formatText = (text, words) => {
    return text.replace(/\[(.*?)\]/g, (match, p1) => {
      if (words.includes(p1)) {
        return `[<span style="color:#FF9D00">${p1}</span>]`;
      }
      return match;
    });
  };

  const handleBlur = () => {
    setEditing(false);
    setValue(sql);
  };

  const handleFocus = () => {
    setEditing(true);
  };

  const handleInput = (e) => {
    setSql(e.target.value);
  };

  return (
    <Form.Group className={className}>
      <Form.Label className="fw-semiboldlight">{label}</Form.Label>
      {editing ? (
        <Form.Control
          as="textarea"
          value={sql}
          onChange={handleInput}
          onBlur={handleBlur}
          style={{
            width: "100%",
            minHeight: `${minHeight}px`,
            maxHeight: `${maxHeight}px`,
            overflowY: "auto",
            fontFamily: "Space Mono, monospace",
            fontSize: "12px",
          }}
        />
      ) : (
        <div
          className="form-control"
          onClick={handleFocus}
          autoFocus
          style={{
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            width: "100%",
            boxSizing: "border-box",
            maxHeight: `${maxHeight}px`,
            minHeight: `${minHeight}px`,
            overflowY: "auto",
            cursor: "pointer",
            height: `${minHeight}px`,
            fontFamily: "Space Mono, monospace",
            fontSize: "12px",
          }}
          dangerouslySetInnerHTML={{ __html: formatText(value, highlightWords) }}
        />
      )}
    </Form.Group>
  );
};

export default SqlInput;
