import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class FewshotActions {
  static async create(data) {
    ApiActions.post(
      "/api/v1/fewshot",
      data,
      Store,
      Constants.FEWSHOTS_CREATE_NEW,
      Constants.FEWSHOTS_CREATE_NEW_RESPONSE
    );
  }

  static clearFewshotSuccessState() {
    Store.dispatch({
      type: Constants.FEWSHOTS_CLEAR_SUCCESS_STATE,
    });
  }

  static async update(id, data) {
    ApiActions.put(`/api/v1/fewshot/${id}`, data, Store, Constants.FEWSHOTS_UPDATE, Constants.FEWSHOTS_UPDATE_RESPONSE);
  }

  static async delete(id) {
    ApiActions.delete(
      `/api/v1/fewshot/${id}`,
      undefined,
      Store,
      Constants.FEWSHOTS_DELETE,
      Constants.FEWSHOTS_DELETE_RESPONSE
    );
  }
}

export default FewshotActions;
