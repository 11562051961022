import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Logo = ({ outerClassName, innerClassName, href, style, customLogo, loadingCustomLogo }) => {
  if (!customLogo && loadingCustomLogo) return <div style={{ width: "104px" }} />;

  return (
    <a className={outerClassName} href={href} aria-label="Zing Data">
      {customLogo ? (
        <img className={classNames(innerClassName, "p-2")} style={style} src={customLogo} alt="Custom-Logo" />
      ) : (
        <img className={innerClassName} style={style} src="/assets/svg/logos/zing-logo.png" alt="Default-Logo" />
      )}
    </a>
  );
};

Logo.propTypes = {
  outerClassName: PropTypes.string,
  innerClassName: PropTypes.string,
  href: PropTypes.string,
  style: PropTypes.object,
};

Logo.defaultProps = {
  outerClassName: "",
  innerClassName: "",
  href: "/",
  style: {},
};

export default memo(Logo);
