import { datasourceTypes, DATASOURCES_WITH_SSL_CUSTOMCERTS } from "./constants";

export const getDatasourceObject = ({
  type,
  host,
  port,
  username,
  password,
  databasename,
  catalog,
  schema,
  warehouse,
  sheetUrl,
  projectId,
  datasetId,
  serviceAccount,
  ssl,
  sshtunnel,
  sshtunnelusername,
  sshtunnelpassword,
  sshtunnelport,
  sshprivatekey,
  httpPath,
  token,
  organization,
  encrypt,
  clientId,
  msiEndpoint,
  msiSecret,
  domain,
  clientSecret,
  tenantId,
  authType,
  sslwithcerts,
  sslserverrootcert,
  sslclientkey,
  sslclientcert,
  role,
  displayName,
  location,
}) => {
  let datasource = {
    type,
    organization,
    displayName,
  };

  if (type !== datasourceTypes.GOOGLE_BIGQUERY && type !== datasourceTypes.GOOGLE_SHEETS) {
    datasource = {
      ...datasource,
      host,
      port,
      username,
      password,
      databasename,
      ssl,
    };

    if (type === datasourceTypes.TRINO || type === datasourceTypes.PRESTO) {
      datasource = {
        ...datasource,
        catalog,
        schema,
        role,
      };
    }

    if (type === datasourceTypes.POSTGRES) {
      datasource = {
        ...datasource,
        schema,
      };
    }

    if (type === datasourceTypes.POSTGRES || type === datasourceTypes.MYSQL) {
      datasource = {
        ...datasource,
        sshtunnel,
      };

      if (sshtunnel) {
        datasource = {
          ...datasource,
          sshtunnelusername,
          sshtunnelpassword,
          sshprivatekey,
          ...(sshtunnelport ? { sshtunnelport } : {}),
        };
      }
    }

    if (type === datasourceTypes.DATABRICKS) {
      datasource = {
        ...datasource,
        httpPath,
        token,
      };
    }

    if (type === datasourceTypes.MICROSOFT_SQL) {
      datasource = {
        ...datasource,
        encrypt,
        clientId,
        msiEndpoint,
        msiSecret,
        domain,
        clientSecret,
        tenantId,
        authType,
        token,
      };

      delete datasource.port;
      delete datasource.ssl;
    }

    if (DATASOURCES_WITH_SSL_CUSTOMCERTS.includes(type)) {
      datasource = {
        ...datasource,
        sslwithcerts,
      };

      if (sslwithcerts) {
        datasource = {
          ...datasource,
          sslserverrootcert,
          sslclientkey,
          sslclientcert,
        };
      }
    }
  } else if (type === datasourceTypes.GOOGLE_SHEETS) {
    datasource = {
      ...datasource,
      sheetUrl,
    };
  } else if (type === datasourceTypes.GOOGLE_BIGQUERY) {
    datasource = {
      ...datasource,
      projectId,
      datasetId,
      serviceAccount,
      location,
    };
  }

  if (type === datasourceTypes.SNOWFLAKE) {
    datasource = {
      ...datasource,
      warehouse,
    };
  }

  return datasource;
};
