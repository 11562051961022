import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";
import AuthService from "helpers/auth";

class SignupActions {
  static async signUp(data) {
    ApiActions.post("/api/signup/v2", data, Store, Constants.REGISTER, Constants.REGISTER_RESPONSE, (err, response) => {
      if (!err) {
        if (response && response.session && response.session._id) {
          window.localStorage.setItem("userauth", response.session._id);

          if (response.user && response.user.email) {
            window.localStorage.setItem("email", response.user.email);
          }

          if (response.user && response.user.roles && response.user.roles.account && response.user.roles.account.name) {
            window.localStorage.setItem("name", response.user.roles.account.name);
          }

          const { user, datasource, datasourceList, firebaseToken } = response;

          AuthService.setUser({
            user,
            datasource,
            datasourceList,
            firebaseToken,
          });

          window.location = "/dashboard";
          return;
        }

        if (response?.redirectUrl) {
          window.location = `${response.redirectUrl}?email=${data.email}`;
        }
      }
    });
  }

  static async confirmEmail(data) {
    ApiActions.post(
      "/api/signup/verify-email",
      data,
      Store,
      Constants.VERIFY_EMAIL,
      Constants.VERIFY_EMAIL_RESPONSE,
      (err, response) => {
        if (!err) {
          if (response?.session?._id) {
            window.localStorage.setItem("userauth", response.session._id);

            if (response.user?.email) {
              window.localStorage.setItem("email", response.user.email);
            }

            if (response.user?.roles?.account?.name) {
              window.localStorage.setItem("name", response.user.roles.account.name);
            }

            const { user, datasource, datasourceList, firebaseToken, redirectUrl } = response;

            AuthService.setUser({
              user,
              datasource,
              datasourceList,
              firebaseToken,
            });

            if (redirectUrl) {
              window.location = `${redirectUrl}`;
            } else {
              const userOrgs = user.organizations;
              const isMemberOrViewerOnly = userOrgs.every((o) => o.groups.member || o.groups.viewer);

              if (isMemberOrViewerOnly) {
                window.location = "/web";
              } else {
                window.location = "/dashboard";
              }
            }
          }
        }
      }
    );
  }

  static async resendEmailConfirmation(data) {
    ApiActions.post(
      "/api/signup/resend-confirmation",
      data,
      Store,
      Constants.RESEND_CONFIRMATION,
      Constants.RESEND_CONFIRMATION_RESPONSE
    );
  }
}

export default SignupActions;
