import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  loading: false,
  success: false,
  error: undefined,
  hasError: {},
  help: {},
  statusCode: undefined,
  verifyEmailError: undefined,
  resentConfirmation: false,
};
const reducer = function (state = initialState, action) {
  if (action.type === Constants.REGISTER || action.type === Constants.VERIFY_EMAIL) {
    return {
      ...state,
      loading: true,
      error: undefined,
      verifyEmailError: undefined,
    };
  }

  if (action.type === Constants.RESEND_CONFIRMATION) {
    return {
      ...state,
      resentConfirmation: false,
      loading: true,
    };
  }

  if (action.type === Constants.REGISTER_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      success: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.VERIFY_EMAIL_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      success: !action.err,
      verifyEmailError: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      statusCode: action.response?.statusCode,
    };
  }

  if (action.type === Constants.RESEND_CONFIRMATION_RESPONSE) {
    return {
      ...state,
      resentConfirmation: true,
      loading: false,
    };
  }

  return state;
};

export default reducer;
