import React, { useCallback, useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Badge from "react-bootstrap/Badge";

import Actions from "redux/actions/datasource";
import OrganizationActions from "redux/actions/organization";
import AuthService from "helpers/auth";
import { datasources, datasourceTypes } from "helpers/constants";
import { truncate } from "helpers/truncate";
import { ORGGROUPS_ADMIN } from "helpers/constants";

import Alert from "components/alert";
import Footer from "components/footer/footermain";
import Header from "components/navbar/header";
import Table from "components/table/table";
import ModalDataSource from "components/modal/modal-create-datasource";
import ModalDeleteDatasource from "components/modal/modal-delete-datasource";
import ModalEditDatasource from "components/modal/modal-edit-datasource";

import amplitude from "amplitude-js";

const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: "smooth" });

const DatasourcePage = () => {
  const user = useSelector((s) => s.login.user);
  const { user: loggedInUser } = AuthService.getUser();
  const loggedInUserUsername = loggedInUser?.username;
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const { error, data, items, pages, loading, showDeleteSuccess } = useSelector((s) => s.datasource);
  const { organizationsByRole, loadingOrgsByRole } = useSelector((s) => s.organization);

  const [showNewDatasourceModal, setShowNewDatasourceModal] = useState(false);
  const [showEditDatasourceModal, setShowEditDatasourceModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteDatasourceName, setDeleteDatasourceName] = useState();
  const [refresh, setRefresh] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
  const [csvDataToEdit, setCsvDataToEdit] = useState(false);

  const errorsRef = useRef(null);
  const executeScroll = () => scrollToRef(errorsRef);

  // initialize amplitude instance
  const instance = amplitude.getInstance();

  useEffect(() => {
    if (!loadingOrgsByRole && !organizationsByRole) {
      OrganizationActions.getOrganizationsByRole("admin");
    }
  }, [organizationsByRole]);

  const handleCloseNewDatasourceModal = () => {
    setShowNewDatasourceModal(false);
    Actions.hideDataSourceCreate();
    refreshData();
  };

  const handleCloseEditDatasourceModal = () => {
    setShowEditDatasourceModal(false);
    Actions.hideDataSourceCreate();
    refreshData();
  };

  const handleShowNewDatasourceModal = () => {
    setShowNewDatasourceModal(true);

    instance.logEvent("DATASOURCE_CREATE_TAPPED", {
      clicked_from: "/datasource",
    });
  };

  const handleConfirmDeleteModal = () => {
    setShowDelete(false);
    Actions.delete(deleteId);
    setDeleteId("");
    setDeleteDatasourceName("");
    setIsDeleteButtonDisabled(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDelete(false);
    setDeleteId("");
    setDeleteDatasourceName("");
    setIsDeleteButtonDisabled(true);
  };

  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const onChangeSearch = (e) => {
    const input = e.currentTarget.value;
    // eslint-disable-next-line no-useless-escape
    if (/^[a-zA-Z0-9!@#$%^&()_\-=\[\]{};':"\\|,.<>\/?]*$/.test(input)) {
      setSearchValue(input);
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchQuery(searchValue), 500);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  const handleEditClick = useCallback(async (data) => {
    window.location = `/datasource/${data._id}`;
  }, []);

  const handleSettingsClick = useCallback((data) => {
    window.location = `/datasource/${data._id}/settings`;
  }, []);

  const handleUpdateDataClick = useCallback((data) => {
    setCsvDataToEdit(data);
    setShowEditDatasourceModal(true);
  }, []);

  const handleDeleteClick = async (data, name) => {
    setDeleteId(data._id);
    setShowDelete(true);
    setDeleteDatasourceName(name);
  };

  const refreshData = () => {
    fetchData({ pageSize: 10, pageIndex: currentPage - 1 });
  };

  const renderDatabaseImage = (row) => {
    if (
      row.row.original.type === datasourceTypes.UPLOADED_CSV ||
      row.row.original.type === datasourceTypes.CLICKHOUSE
    ) {
      return `/assets/img/databases/${row.row.original.type}.svg`;
    } else if (row.row.original.type === datasourceTypes.TRINO) {
      return `/assets/img/databases/starburst.png`;
    } else {
      return `/assets/img/databases/${row.row.original.type}.png`;
    }
  };

  const showEditButton = useCallback(
    (row) =>
      row.row.original.type !== datasourceTypes.GOOGLE_SHEETS &&
      row.row.original.type !== datasourceTypes.GOOGLE_SHEETS_LIVE &&
      row.row.original.type !== datasourceTypes.UPLOADED_CSV &&
      row.row.original.role === ORGGROUPS_ADMIN,
    []
  );

  const showSettingsButton = useCallback((row) => row.row.original.role === ORGGROUPS_ADMIN, []);

  const showUpdateDataButton = useCallback(
    (row) =>
      row.row.original.type === datasourceTypes.UPLOADED_CSV &&
      loggedInUserUsername === row.row.original.user[0]?.username,
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Database Name",
        id: "databasename",
        acessor: "databasename",
        Cell: (row) => {
          return (
            <a
              className="d-flex align-items-center"
              href={`/web/sources/${row.row.original._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="avatar avatar-circle">
                <img
                  className="avatar-img"
                  style={{ borderRadius: 0, height: "unset" }}
                  src={renderDatabaseImage(row)}
                  alt="avatar"
                />
              </div>
              <div className="ml-3">
                <span className="d-block h5 text-hover-primary mb-0">
                  {row.row.original.displayName
                    ? row.row.original.displayName
                    : row.row.original.type === datasourceTypes.GOOGLE_BIGQUERY
                    ? row.row.original.googleBigQuery.datasetId
                    : row.row.original.type === datasourceTypes.GOOGLE_SHEETS ||
                      row.row.original.type === datasourceTypes.GOOGLE_SHEETS_LIVE
                    ? truncate(row.row.original.googleSheets?.tableName)
                    : truncate(row.row.original.databasename)}
                </span>
              </div>
            </a>
          );
        },
        canSort: true,
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: (row) => {
          if (row.row.original.type === datasourceTypes.GOOGLE_SHEETS_LIVE) {
            return (
              <>
                <span>Google Sheets</span>
                <Badge pill className="badge badge-primary ml-2">
                  Live
                </Badge>
              </>
            );
          }
          return datasources.find((c) => c.value === row.row.original.type)?.label || "Unknown";
        },
      },
      {
        Header: "Organization",
        accessor: "organization",
        Cell: (row) => {
          return row.row.original.organization?.name || "Unknown";
        },
      },
      {
        Header: "Date Created",
        accessor: "timeCreated",
        Cell: (row) => {
          return format(new Date(row.row.original.timeCreated), "MMM dd, yyyy");
        },
      },
      {
        Header: "Created By",
        id: "username",
        Cell: (row) => {
          return row.row.original.user[0]?.username || "";
        },
        canSort: true,
      },
      {
        Header: "",
        id: "actions",
        Cell: (row) => {
          const shouldShowEditButton = showEditButton(row);
          const shouldShowSettingsButton = showSettingsButton(row);
          const shouldShowUpdateDataButton = showUpdateDataButton(row);

          let name;
          if (row.row.original.type === datasourceTypes.GOOGLE_BIGQUERY) {
            name = row.row.original.googleBigQuery.datasetId;
          } else if (
            row.row.original.type === datasourceTypes.GOOGLE_SHEETS ||
            row.row.original.type === datasourceTypes.GOOGLE_SHEETS_LIVE
          ) {
            name = truncate(row.row.original.googleSheets?.tableName);
          } else {
            name = truncate(row.row.original.databasename);
          }

          return (
            <div className="d-flex align-items-center">
              {shouldShowEditButton && (
                <button className="btn btn-sm btn-white" onClick={() => handleEditClick(row.row.original)}>
                  <i className="tio-edit"></i> Edit
                </button>
              )}
              {shouldShowUpdateDataButton && (
                <button className="btn btn-sm btn-white" onClick={() => handleUpdateDataClick(row.row.original)}>
                  <i className="tio-edit"></i> Edit
                </button>
              )}
              {shouldShowSettingsButton && (
                <button
                  className={classNames("btn btn-sm btn-white", {
                    "ml-2": shouldShowEditButton || shouldShowUpdateDataButton,
                  })}
                  onClick={() => handleSettingsClick(row.row.original)}
                >
                  <i className="tio-settings"></i> Settings
                </button>
              )}
              {loggedInUserUsername === row.row.original.user[0]?.username && (
                <button
                  className={classNames("btn btn-sm btn-outline-danger", {
                    "ml-2": shouldShowEditButton || shouldShowSettingsButton,
                  })}
                  onClick={() => handleDeleteClick(row.row.original, name)}
                >
                  <i className="tio-delete-outlined"></i> Delete
                </button>
              )}
            </div>
          );
        },
        sortable: false,
        filterable: false,
      },
    ],
    []
  );

  const fetchData = useCallback(({ pageSize, pageIndex, sort, filtered }) => {
    Actions.getDataSource({ limit: pageSize, page: pageIndex + 1, sort, q: filtered });
  }, []);

  useEffect(() => {
    if (showDeleteSuccess) refreshData();
  }, [showDeleteSuccess]);

  useEffect(() => {
    if (error) {
      setAlerts([<Alert key="danger" type="danger" message={error} />]);

      executeScroll();
    }
  }, [error]);

  useEffect(() => {
    window.dashboardOnReady();

    const origBodyClass = document.body.className;
    document.body.className = "footer-offset";

    return () => {
      document.body.className = origBodyClass;
    };
  }, []);

  return (
    <>
      <Header user={user} />
      <main id="content" role="main" className="main" style={isMobile ? { paddingTop: "7.75rem" } : {}}>
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-title">Data Sources</h1>
              </div>

              <div className="col-sm-auto">
                <button className="btn btn-primary" onClick={handleShowNewDatasourceModal}>
                  <i className="tio-cloud mr-1" /> New Data Source
                </button>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <div className="row justify-content-between align-items-center flex-grow-1">
                <div className="col-sm-6 col-md-4 mb-3 mb-sm-0">
                  <form>
                    <div className="input-group input-group-merge input-group-flush">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="tio-search"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        value={searchValue}
                        onChange={onChangeSearch}
                        className="form-control"
                        placeholder="Search datasources"
                        aria-label="Search datasources"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div ref={errorsRef}>{alerts}</div>

            <Table
              columns={columns}
              data={data ?? []}
              items={items ?? []}
              loading={loading}
              filtered={searchQuery}
              fetchData={fetchData}
              pageCount={pages?.total ?? 0}
              refresh={refresh}
              cancelRefresh={() => setRefresh(false)}
              setCurrentPage={setCurrentPage}
            />
          </div>

          {showNewDatasourceModal && (
            <ModalDataSource
              show={showNewDatasourceModal}
              handleClose={handleCloseNewDatasourceModal}
              organizationsByRole={organizationsByRole}
            />
          )}

          {showEditDatasourceModal && csvDataToEdit && (
            <ModalEditDatasource
              show={showEditDatasourceModal}
              handleClose={handleCloseEditDatasourceModal}
              organizationsByRole={organizationsByRole}
              dataToEdit={csvDataToEdit}
              datasourceType={csvDataToEdit?.type}
            />
          )}
        </div>

        <Footer />
      </main>

      <ModalDeleteDatasource
        deleteDatasourceName={deleteDatasourceName}
        show={showDelete}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleConfirmDeleteModal}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
        setIsDeleteButtonDisabled={setIsDeleteButtonDisabled}
      />
    </>
  );
};

export default DatasourcePage;
