import React, { memo } from "react";
import ModalDeletePublicLinks from "components/modal/modal-delete-public-links";

const rowStyle = {
  borderBottom: "thin solid #eeeeee",
  paddingBottom: "1rem",
  marginLeft: "0.3rem",
  marginRight: "0.3rem",
  marginBottom: "1rem",
};

const OrgSettings = ({
  hasGoogleId,
  organization,
  handleInputRequireSSO,
  userId,
  handleInputAllowInviteOutsideDomain,
  handleInputEnableCustomSQL,
  handleInputEnablePublicSharing,
  handleSaveOrganizationSettings,
  loadingSettings,
  showDeletePublicLinksModal,
  toggleDeletePublicLinksModal,
  deletePublicLinks,
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div>
          <label className="row form-group toggle-switch justify-content-between" style={rowStyle}>
            <span className="col-8 col-sm-9 toggle-switch-content ml-0 px-0">
              <span className="d-block text-dark">Require Google SSO (disallow email-only login)</span>
            </span>
            <span className="col-2 col-sm-3 d-flex justify-content-end px-0">
              <input
                type="checkbox"
                name="requireGoogleSsoRadio"
                disabled={!(hasGoogleId && organization?.ownerId === userId)}
                className="toggle-switch-input"
                checked={organization?.settings?.requireGoogleSSO || false}
                onChange={handleInputRequireSSO}
              />
              <span className="toggle-switch-label">
                <span className="toggle-switch-indicator"></span>
              </span>
            </span>
          </label>

          <label className="row form-group toggle-switch justify-content-between" style={rowStyle}>
            <span className="col-8 col-sm-9 toggle-switch-content ml-0 px-0">
              <span className="d-block text-dark">
                Allow invites to email addresses outside domain? (org domain is: email@domain.com)
              </span>
            </span>
            <span className="col-2 col-sm-3 d-flex justify-content-end px-0">
              <input
                type="checkbox"
                name="allowInviteRadio"
                disabled={organization?.ownerId !== userId}
                className="toggle-switch-input"
                checked={organization?.settings?.allowInviteOutsideDomain || false}
                onChange={handleInputAllowInviteOutsideDomain}
              />
              <span className="toggle-switch-label">
                <span className="toggle-switch-indicator"></span>
              </span>
            </span>
          </label>

          <label className="row form-group toggle-switch justify-content-between" style={rowStyle}>
            <span className="col-8 col-sm-9 toggle-switch-content ml-0 px-0">
              <span className="d-block text-dark">Enable Custom SQL IDE</span>
            </span>
            <span className="col-4 col-sm-3 d-flex justify-content-end px-0">
              <input
                type="checkbox"
                name="allowCustomSQLRadio"
                disabled={organization?.ownerId !== userId}
                className="toggle-switch-input"
                checked={organization?.settings?.enableCustomSQL || false}
                onChange={handleInputEnableCustomSQL}
              />
              <span className="toggle-switch-label">
                <span className="toggle-switch-indicator"></span>
              </span>
            </span>
          </label>

          <label
            className="row form-group toggle-switch justify-content-between"
            style={{ ...rowStyle, borderBottom: "none" }}
          >
            <span className="col-8 col-sm-9 toggle-switch-content ml-0 px-0">
              <span className="d-block text-dark">Enable Public Sharing</span>
            </span>
            <span className="col-4 col-sm-3 d-flex justify-content-end px-0">
              <input
                type="checkbox"
                name="allowCustomSQLRadio"
                disabled={organization?.ownerId !== userId}
                className="toggle-switch-input"
                checked={organization?.settings?.enablePublicShare || false}
                onChange={handleInputEnablePublicSharing}
              />
              <span className="toggle-switch-label">
                <span className="toggle-switch-indicator"></span>
              </span>
            </span>
          </label>
        </div>
        {organization?.ownerId === userId && (
          <div className="d-flex justify-content-end">
            <button type="button" onClick={handleSaveOrganizationSettings} className="btn btn-primary">
              {loadingSettings ? "Saving" : "Save Changes"}
            </button>
          </div>
        )}
      </div>
      {showDeletePublicLinksModal && (
        <ModalDeletePublicLinks
          show={showDeletePublicLinksModal}
          handleClose={toggleDeletePublicLinksModal}
          deletePublicLinks={deletePublicLinks}
        />
      )}
    </div>
  );
};

export default memo(OrgSettings);
