import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class JoinActions {
  static async create(data) {
    ApiActions.post("/api/joins", data, Store, Constants.JOINS_CREATE_NEW, Constants.JOINS_CREATE_NEW_RESPONSE);
  }

  static hideJoinCreate() {
    Store.dispatch({
      type: Constants.JOINS_CREATE_HIDE,
    });
  }

  static async getJoinsByDatasource(datasourceId, query) {
    ApiActions.get(
      `/api/joins/datasource/${datasourceId}`,
      query,
      Store,
      Constants.JOINS_GET_BY_DATASOURCE,
      Constants.JOINS_GET_BY_DATASOURCE_RESPONSE
    );
  }

  static async getJoinById(id) {
    ApiActions.get(`/api/joins/${id}`, undefined, Store, Constants.JOINS_GET_BY_ID, Constants.JOINS_GET_BY_ID_RESPONSE);
  }

  static async update(id, data) {
    ApiActions.put(`/api/joins/${id}`, data, Store, Constants.JOINS_UPDATE, Constants.JOINS_UPDATE_RESPONSE);
  }

  static async delete(id) {
    ApiActions.delete(`/api/joins/${id}`, undefined, Store, Constants.JOINS_DELETE, Constants.JOINS_DELETE_RESPONSE);
  }

  static updateJoinDeleteSuccess() {
    Store.dispatch({
      type: Constants.JOINS_UPDATE_DELETE_SUCCESS,
    });
  }

  static hideJoinUpdate() {
    Store.dispatch({
      type: Constants.JOINS_UPDATE_HIDE,
    });
  }

  static clearSelectedJoin() {
    Store.dispatch({
      type: Constants.JOINS_CLEAR_SELECTED,
    });
  }
}

export default JoinActions;
