import React from "react";

const Navbar = ({ accountData }) => {
  return (
    <div className="col-lg-3">
      <div className="navbar-vertical navbar-expand-lg mb-3 mb-lg-5">
        <button
          type="button"
          className="navbar-toggler btn btn-block btn-white mb-3"
          aria-label="Toggle navigation"
          aria-expanded="false"
          aria-controls="navbarVerticalNavMenu"
          data-toggle="collapse"
          data-target="#navbarVerticalNavMenu"
        >
          <span className="d-flex justify-content-between align-items-center">
            <span className="h5 mb-0">Nav menu</span>
            <span className="navbar-toggle-default">
              <i className="tio-menu-hamburger" />
            </span>
            <span className="navbar-toggle-toggled">
              <i className="tio-clear" />
            </span>
          </span>
        </button>
        <div id="navbarVerticalNavMenu" className="collapse navbar-collapse">
          <ul
            id="navbarSettings"
            className="js-sticky-block js-scrollspy navbar-nav navbar-nav-lg nav-tabs card card-navbar-nav"
            data-hs-sticky-block-options='{
                            "parentSelector": "#navbarVerticalNavMenu",
                            "breakpoint": "lg",
                            "startPoint": "#navbarVerticalNavMenu",
                            "endPoint": "#stickyBlockEndPoint",
                            "stickyOffsetTop": 20
                            }'
          >
            <li className="nav-item">
              <a className="nav-link active" href="#content">
                <i className="tio-user-outlined nav-icon" />
                Basic information
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#passwordSection">
                <i className="tio-lock-outlined nav-icon" />
                Password
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#preferencesSection">
                <i className="tio-settings-outlined nav-icon" />
                Preferences
              </a>
            </li>

            {accountData && (
              <li className="nav-item">
                <a className="nav-link" href="#transferAccountSection">
                  <i className="tio-autorenew nav-icon"></i>
                  Transfer billing account
                </a>
              </li>
            )}

            <li className="nav-item">
              <a className="nav-link" href="#deleteAccountSection">
                <i className="tio-delete-outlined nav-icon"></i>
                Delete account
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
