import React from "react";
import { Navigate } from "react-router-dom";

export const redirect =
  (outerProps) =>
  // eslint-disable-next-line react/display-name
  (innerProps) => {
    if (innerProps.staticContext) {
      innerProps.staticContext.code = outerProps.code;
    }

    return <Navigate from={outerProps.from} to={outerProps.to} />;
  };
