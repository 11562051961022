import React, { memo } from "react";
import { datasourceTypes } from "helpers/constants";
import "./dashboard.scss";

const DatasourceTypeBox = ({ name, value, handleShow }) => {
  const imgExtension = value === datasourceTypes.CLICKHOUSE || value === datasourceTypes.UPLOADED_CSV ? "svg" : "png";

  return (
    <div className="datasource-box card my-2">
      <div className="card-body d-flex justify-content-between px-3 py-2">
        <div className="d-flex align-items-center">
          <img
            className="avatar avatar-small"
            src={`/assets/img/databases/${value}.${imgExtension}`}
            alt="datasource type"
          />
          <span className="ml-2 datasource-title">{name}</span>
        </div>
        <button className="btn btn-sm btn-soft-primary" onClick={() => handleShow(value)}>
          Connect
        </button>
      </div>
    </div>
  );
};

export default memo(DatasourceTypeBox);
