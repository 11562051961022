import React from "react";
import { Modal } from "react-bootstrap";

const ModalCancelEditingAlias = ({ show, handleClose, onClickConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to cancel editing aliases? You may lose any unsaved changes.</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-primary" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-sm btn-outline-danger" onClick={onClickConfirm}>
          Yes, Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCancelEditingAlias;
