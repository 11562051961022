import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "redux/store";
import { registerLicense } from "@syncfusion/ej2-base";
import CacheBuster from "react-cache-buster";
import Loading from "components/loading";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "helpers/azure-ad-config";
import packageDetails from "../package.json";

import App from "./App";
import "./index.css";

// Registering Syncfusion license key
registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);

// Azure AD initialization
const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const container = document.getElementById("app-mount");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <CacheBuster
      currentVersion={packageDetails.version}
      isEnabled={true}
      isVerboseMode={false}
      loadingComponent={<Loading />}
      metaFileDirectory={"."}
    >
      <App msalInstance={msalInstance} />
    </CacheBuster>
  </Provider>
);
