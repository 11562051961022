import React, { memo, useCallback, useState } from "react";
import Icon from "../icon";
import classNames from "classnames";

const CopyToClipboard = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = useCallback(async () => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(textToCopy);
    }
  }, [textToCopy]);

  const handleCopyClick = () => {
    copyTextToClipboard().then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 5000);
    });
  };

  return (
    <Icon
      iconName={classNames({ "tio-copy": !isCopied, "tio-checkmark-circle": isCopied })}
      className="ml-2"
      isClickable={!isCopied}
      onClick={handleCopyClick}
    />
  );
};

export default memo(CopyToClipboard);
