import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, ListGroup } from "react-bootstrap";
import Alert from "components/alert";
import Loading from "components/loading";
import AccountActions from "redux/actions/account";

const ModalTransferAccountOwnership = ({ show, handleClose }) => {
  const { loadingAdmins, adminsOfAllOrgs, errorLoadingAdmins, transferError, transferSuccess, loadingTransfer } =
    useSelector((s) => s.account);

  const [alerts, setAlerts] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState();
  const [loadingText, setLoadingText] = useState(null);

  useEffect(() => {
    AccountActions.getAdminsOfAllOrgsInAccount();
  }, []);

  const close = () => {
    setSelectedUserId("");
    setAlerts([]);
    handleClose();
  };

  const onClickTransferOwnership = (event) => {
    event.preventDefault();
    setLoadingText("Transferring ownership...");
    AccountActions.transferOwnership({ userId: selectedUserId });
  };

  useEffect(() => {
    if (transferSuccess) {
      close();
    }
  }, [transferSuccess]);

  useEffect(() => {
    if (errorLoadingAdmins) {
      setAlerts([<Alert key="danger" type="danger" message={errorLoadingAdmins} />]);
    }
  }, [errorLoadingAdmins]);

  useEffect(() => {
    if (transferError) {
      setAlerts([<Alert key="danger" type="danger" message={transferError} />]);
    }
  }, [transferError]);

  if (!adminsOfAllOrgs || !adminsOfAllOrgs.length) {
    return (
      <Modal backdrop="static" keyboard={false} show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Transfer Ownership of Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            You don&apos;t have users who are admins of all your organizations with a billing plan, to transfer
            ownership.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal backdrop="static" keyboard={false} show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Transfer Ownership of Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading text={loadingText} loading={loadingAdmins || loadingTransfer}></Loading>
        <div>
          <p>
            Transferring account ownership will transfer all organizations, datasources, questions, dashboards, etc., to
            the new owner.
          </p>
          <p className="mb-5">
            <b>Please select who you want to transfer ownership of the account.</b>
          </p>

          {alerts}

          <div className="accordion" id="accordionExample">
            <div className="card">
              <div className="card-body pt-0 pb-0">
                <ListGroup variant="flush">
                  {adminsOfAllOrgs?.map((user) => (
                    <ListGroup.Item
                      key={user._id}
                      value={user._id}
                      className="d-flex align-items-center justify-content-between"
                    >
                      {user.email}
                      {selectedUserId === user._id ? (
                        <button disabled="disabled" className="btn btn-sm btn-white float-right">
                          <i className="tio-done"></i>
                          Selected
                        </button>
                      ) : (
                        <a
                          className="btn btn-sm btn-white float-right"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedUserId(user._id)}
                        >
                          Select User
                        </a>
                      )}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button
          disabled={loadingAdmins || !selectedUserId || loadingTransfer}
          variant="primary"
          onClick={onClickTransferOwnership}
        >
          Transfer Ownership
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTransferAccountOwnership;
