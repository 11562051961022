import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams, Link } from "react-router-dom";
import { Buffer } from "buffer";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import Qs from "qs";
import LoginActions from "redux/actions/login";
import SignupActions from "redux/actions/signup";
import { useGoogleLogin } from "@react-oauth/google";
// import { isFreeEmail } from "helpers/check-free-emails";
import ShowPassword from "components/form/show-password";
import Footer from "components/footer/footerlogin";
import Alert from "components/alert";
import Loading from "components/loading";
import Logo from "components/logo/logo";
import AppleSignin from "components/apple-signin";
import { PricingTiers } from "helpers/constants";

const schema = yup.object().shape({
  fullName: yup.string().max(30, "Name cannot be longer than 30 characters").required("Name is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .max(36, "Password must be less than 36 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .max(36, "Password must be less than 36 characters")
    .required("Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  email: yup.string().email("Email is invalid").required("Email is required"),
  termsCheckbox: yup.bool().oneOf([true], "Please accept the terms and conditions"),
});

const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: "smooth" });

const SignupPage = ({ history, location }) => {
  const query = Qs.parse(window.location.search.substring(1));
  const { plan, frequency } = query || {};

  const { register, setValue, handleSubmit, errors, getValues } = useForm({
    resolver: yupResolver(schema),
  });
  const [showError, setShowError] = useState("");
  const [blockEmail, setBlockEmail] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [hasEmail, setHasEmail] = useState(false);
  // const [showEmailError, setShowEmailError] = useState(false);

  const errorsRef = useRef(null);
  const recaptchaRef = useRef();
  const executeScroll = () => scrollToRef(errorsRef);

  const { error, loading } = useSelector((s) => s.signup);
  const { error: errorLogin, loading: loadingLogin, tokens, isLoadingAppleResponse } = useSelector((s) => s.login);

  const { token } = useParams();

  let email, key, orgId;

  if (token) {
    const _token = Buffer.from(token, "base64").toString("binary");
    const [_orgId, _email, _key] = _token.split(":");
    email = _email;
    key = _key;
    orgId = _orgId;
  }

  const onError = (data) => {
    const { isFormSignup } = data || {};

    if (!isFormSignup) {
      const newAlerts = [
        <Alert
          key="danger"
          type="danger"
          message="If Google sign up is not working, please restart your browser and make sure you're not in incognito mode. If this issue persists please contact Zing Support."
        />,
      ];

      setAlerts(newAlerts);
    }

    executeScroll();
  };

  const responseGoogle = (response) => {
    LoginActions.getGoogleTokens({
      code: response.code,
    });
  };

  useEffect(() => {
    if (tokens) {
      const googleData = {
        strategy: "google",
        email: tokens.email,
        accessToken: tokens.tokens.access_token,
        refreshToken: tokens.tokens.refresh_token,
        idToken: tokens.tokens.id_token,
      };

      if (plan && plan !== PricingTiers.BASIC) {
        googleData.plan = plan;
        googleData.frequency = frequency;
      }

      if (key) googleData.key = key;
      if (orgId) googleData.orgId = orgId;

      LoginActions.login({
        login: googleData,
        location,
        history,
      });
    }
  }, [tokens]);

  const loginGoogle = useGoogleLogin({
    onSuccess: responseGoogle,
    onFailure: () => onError({ isFormSignup: false }),
    flow: "auth-code",
  });

  const doSignUpGoogle = (e) => {
    e.preventDefault();
    setAlerts([]);
    loginGoogle();
  };

  const doSignup = async (data, e) => {
    if (window && window.grecaptcha) {
      window.grecaptcha.reset();
    }

    e.preventDefault();
    const tokenRecaptcha = await recaptchaRef.current.executeAsync();

    if (!tokenRecaptcha) {
      const newAlerts = [
        <Alert
          key="danger"
          type="danger"
          message="There was an issue when executing the ReCaptcha check. Please reload the page and try again."
        />,
      ];

      setAlerts(newAlerts);

      executeScroll();

      return;
    }

    setShowError("");
    setAlerts([]);

    const name = data.fullName;
    const email = data.email;
    const password = data.password;
    const confirmPassword = data.confirmPassword;

    let isValid = true;
    if (confirmPassword !== password) {
      isValid = false;
      setShowError("password");
      executeScroll();
    }

    if (isValid) {
      const signupData = {
        name,
        password,
        email,
        recaptcha: tokenRecaptcha,
      };

      if (plan && plan !== PricingTiers.BASIC) {
        signupData.plan = plan;
        signupData.frequency = frequency;
      }

      if (key) signupData.key = key;
      if (orgId) signupData.orgId = orgId;

      SignupActions.signUp(signupData);
    }
  };

  useEffect(() => {
    if (email) {
      setValue("email", email, { shouldValidate: true });
      setBlockEmail(true);
    }

    // lets get the body classes ready for Dashboard
    const origBodyClass = document.body.className;
    document.body.className = "main";

    return () => {
      // put componentUnMount logic in here
      document.body.className = origBodyClass;
    };
  }, []);

  useEffect(() => {
    if (error || errorLogin) {
      alerts.push(<Alert key="danger" type="danger" message={error ?? errorLogin} />);
      setAlerts([...alerts]);
      executeScroll();
    }
  }, [error, errorLogin]);

  const onChangeEmailAddress = () => {
    const email = getValues("email");
    if (email) {
      setHasEmail(true);
    } else {
      setHasEmail(false);
    }

    // setShowEmailError(isFreeEmail(email));
  };

  return (
    <main id="content" role="main" className="main">
      <div
        className="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{ height: "32rem", backgroundImage: "url(/assets/svg/components/abstract-bg-4.svg)" }}
      >
        <figure className="position-absolute right-0 bottom-0 left-0">
          <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>
      <div className="container py-5 py-sm-7">
        <Logo
          outerClassName="d-flex justify-content-center mb-5"
          innerClassName="z-index-2"
          style={{ width: "15rem" }}
        />
        <div className="row justify-content-center">
          <div className="col-md-7 col-lg-5">
            <div className="card card-lg mb-5">
              <Loading loading={loading || loadingLogin || isLoadingAppleResponse}></Loading>
              <div className="card-body" ref={errorsRef}>
                {alerts}
                {showError === "password" && <Alert key="danger" type="danger" message={"Passwords do not match"} />}
                {plan === PricingTiers.STANDARD && (
                  <Alert
                    key="info"
                    type="info"
                    message="You are signing up for a free 14 day trial of the Standard Plan."
                    className="d-flex justify-content-center small fw-semibold text-center"
                  />
                )}
                {plan === PricingTiers.PRO && (
                  <Alert
                    key="info"
                    type="info"
                    message="You are signing up for a free 14 day trial of the Pro Plan."
                    className="d-flex justify-content-center small fw-semibold text-center"
                  />
                )}
                <form onSubmit={handleSubmit(doSignup, () => onError({ isFormSignup: true }))}>
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-4">Create your account</h1>
                      <p>
                        Already have an account? <Link to="/login">Sign in here</Link>
                      </p>
                    </div>

                    <a className="btn btn-lg btn-block btn-white mb-2" onClick={doSignUpGoogle}>
                      <span className="d-flex justify-content-center align-items-center">
                        <img
                          className="avatar avatar-xss mr-2"
                          src="/assets/svg/brands/google.svg"
                          alt="Sign up with Google"
                        />
                        Sign up with Google
                      </span>
                    </a>
                    <AppleSignin
                      buttonText="Sign up with Apple"
                      inviteKey={key}
                      orgId={orgId}
                      plan={plan}
                      frequency={frequency}
                    />

                    <span className="divider text-muted mb-4">OR</span>
                  </div>
                  <label className="input-label" htmlFor="fullName">
                    Full name
                  </label>
                  <div className="js-form-message form-group">
                    <input
                      type="text"
                      className={"form-control form-control-lg" + (errors.fullName ? " is-invalid" : "")}
                      name="fullName"
                      id="fullName"
                      placeholder="Full Name"
                      aria-label="fullname"
                      ref={register}
                      tabIndex={1}
                    />
                    <div className="invalid-feedback">{errors.fullName?.message}</div>
                  </div>
                  <div className="js-form-message form-group">
                    <label className="input-label" htmlFor="signupSrEmail">
                      Work Email
                    </label>

                    <div className="input-group input-group-merge">
                      <input
                        type="email"
                        className={"form-control form-control-lg" + (errors.email ? " is-invalid" : "")}
                        name="email"
                        id="signupSrEmail"
                        placeholder="user@example.com"
                        aria-label="user@example.com"
                        readOnly={blockEmail}
                        ref={register}
                        tabIndex={3}
                        aria-describedby="inputGroupMergeEmailOptionalAddOn"
                        onChange={onChangeEmailAddress}
                      />

                      <div className="input-group-append">
                        <span className="input-group-text" id="inputGroupMergeEmailOptionalAddOn">
                          <i className={blockEmail ? "tio-lock" : "tio-email-outlined"}></i>
                        </span>
                      </div>
                    </div>
                    {/* {showEmailError && (
                      <div className="invalid-feedback" style={{ display: "block" }}>
                        Free email domains are not allowed.
                      </div>
                    )} */}
                    {hasEmail && (
                      <p className="mt-1 figure-caption text-dark">
                        You will get a verification email at this address.
                      </p>
                    )}
                    <div style={{ display: errors.email?.message ? "block" : "none" }} className="invalid-feedback">
                      {errors.email?.message}
                    </div>
                  </div>
                  <div className="js-form-message form-group">
                    <label className="input-label" htmlFor="signupSrPassword">
                      Password
                    </label>
                    <ShowPassword
                      name="password"
                      id="signupSrPassword"
                      ref={register}
                      errors={errors.password}
                      placeholder="6+ characters required"
                      tabIndex={4}
                    ></ShowPassword>
                  </div>
                  <div className="js-form-message form-group">
                    <label className="input-label" htmlFor="signupSrConfirmPassword">
                      Confirm password
                    </label>
                    <ShowPassword
                      name="confirmPassword"
                      id="signupSrConfirmPassword"
                      ref={register}
                      errors={errors.confirmPassword}
                      placeholder="6+ characters required"
                      tabIndex={5}
                    ></ShowPassword>
                  </div>
                  <div className="js-form-message form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        name="termsCheckbox"
                        type="checkbox"
                        ref={register}
                        id="termsCheckbox"
                        className={`custom-control-input ${errors.termsCheckbox ? "is-invalid" : ""}`}
                      />
                      <label className="custom-control-label font-size-sm text-muted" htmlFor="termsCheckbox">
                        {" "}
                        I accept the{" "}
                        <a href="https://getzingdata.com/tos/" target="_blank" rel="noopener noreferrer">
                          Terms and Conditions
                        </a>
                      </label>
                      <div className="invalid-feedback">{errors.termsCheckbox?.message}</div>
                    </div>
                  </div>

                  <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} />

                  <button
                    type="submit"
                    className="btn btn-lg mt-2 btn-block btn-primary mb-2"
                    // disabled={showEmailError}
                  >
                    Create an account
                  </button>
                  <p className="text-muted small mt-3">
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                    <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                  </p>
                </form>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignupPage;
