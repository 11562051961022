import React from "react";
import { Modal, Button } from "react-bootstrap";

const ModalDeleteJoin = ({ show, handleClose, deleteJoin, joinId, joinName }) => {
  return (
    <Modal backdrop="static" keyboard={false} show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to delete this join: {joinName}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className="mb-5">
            This will remove all questions and public links associated with the questions created using this join.
          </p>
          <p>Do you wish to continue?</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => deleteJoin(joinId)}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteJoin;
