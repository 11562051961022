import React, { memo, useCallback } from "react";
import Header from "components/navbar/header";
import { useMediaQuery } from "react-responsive";

const Unauthorized = ({ user, backPath }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const onClickGoBack = useCallback(() => {
    window.location = backPath;
  }, []);

  return (
    <>
      <Header user={user} />
      <main id="content" role="main" className="main" style={isMobile ? { paddingTop: "7.75rem" } : {}}>
        <div className="content container-fluid">
          <div className="card col-lg-4 p-5 m-auto text-center align-items-center">
            <img
              className="img-fluid mb-3"
              src="/assets/svg/illustrations/unlock.svg"
              alt="Not authorized"
              style={{ maxWidth: "18rem" }}
            />
            <h4 className="h1 mt-4">You are not authorized to view this page.</h4>
            <button className="btn btn-primary mt-5" onClick={onClickGoBack}>
              Go Back
            </button>
          </div>
        </div>
      </main>
    </>
  );
};

export default memo(Unauthorized);
