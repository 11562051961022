import React, { useEffect } from "react";
import Actions from "redux/actions/invitation";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Buffer } from "buffer";
import Footer from "components/footer/footerlogin";
import Loading from "components/loading";
import Alert from "components/alert";
import Logo from "components/logo/logo";

const AcceptInvitePage = () => {
  const navigate = useNavigate();

  const { loading, error, success } = useSelector((s) => s.invitation);

  const { token } = useParams();
  let email, key, orgId;

  if (token) {
    const _token = Buffer.from(token, "base64").toString("binary");
    const [_ordId, _email, _key] = _token.split(":");
    email = _email;
    key = _key;
    orgId = _ordId;
  }

  useEffect(() => {
    // lets get the body classes ready for Dashboard
    const origBodyClass = document.body.className;
    document.body.className = "main";

    Actions.accept({ email, key, orgId });

    return () => {
      document.body.className = origBodyClass;
    };
  }, []);

  const alerts = [];

  if (success) {
    alerts.push(
      <div key="success">
        <Alert
          key="success"
          type="success"
          message="You accepted the invitation please sign in to discover what we have for you!"
        />
        <div className="text-center mt-2">
          <a className="btn btn-link" onClick={() => navigate("/login")}>
            <i className="tio-chevron-left"></i> Back to Sign in
          </a>
        </div>
      </div>
    );
  }

  if (error) {
    alerts.push(
      <div key="danger">
        <Alert key="danger" type="danger" message={error} />
        <div className="text-center mt-2">
          <a className="btn btn-link" onClick={() => navigate("/login")}>
            <i className="tio-chevron-left"></i> Back to Sign in
          </a>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <main id="content" role="main" className="main">
        <div
          className="position-fixed top-0 right-0 left-0 bg-img-hero"
          style={{ height: "32rem", backgroundImage: "url(/assets/svg/components/abstract-bg-4.svg)" }}
        >
          <figure className="position-absolute right-0 bottom-0 left-0">
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
              <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
            </svg>
          </figure>
        </div>
        <div className="container py-5 py-sm-7">
          <Logo
            outerClassName="d-flex justify-content-center mb-5"
            innerClassName="z-index-2"
            style={{ width: "15rem" }}
          />
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="card card-lg mb-5">
                <Loading loading={loading}></Loading>

                <div className="card-body">{alerts}</div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default AcceptInvitePage;
