import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  loading: false,
  success: false,
  error: undefined,
  hasError: {},
  help: {},
  tokens: null,
  isLoadingAppleResponse: false,
  ssoResponse: null,
};
const reducer = function (state = initialState, action) {
  if (
    action.type === Constants.LOGIN ||
    action.type === Constants.LOGIN_GOOGLE_GET_TOKEN ||
    action.type === Constants.LOGIN_WITH_APPLE ||
    action.type === Constants.LOGIN_WITH_SSO
  ) {
    return {
      ...state,
      loading: true,
      error: undefined,
      isLoadingAppleResponse: true,
    };
  }

  if (action.type === Constants.LOGIN_GOOGLE_GET_TOKEN_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      success: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      tokens: !action.err ? action.response : null,
    };
  }

  if (
    action.type === Constants.LOGIN_RESPONSE ||
    action.type === Constants.LOGIN_WITH_APPLE_RESPONSE ||
    action.type === Constants.LOGIN_WITH_SSO_RESPONSE
  ) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      success: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      user: action.response.user,
      isLoadingAppleResponse: false,
    };
  }

  if (action.type === Constants.CLEAR_TOKENS) {
    return {
      ...state,
      tokens: null,
    };
  }

  if (action.type === Constants.VERIFY_LOGIN_WITH_SSO_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      success: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      ssoResponse: action.response,
    };
  }

  return state;
};

export default reducer;
