import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import SignupActions from "redux/actions/signup";
import { Buffer } from "buffer";
import PublicPageLayout from "layouts/PublicPageLayout";
import Alert from "components/alert";
import Loading from "components/loading";

const EmailVerificationPage = () => {
  const { token } = useParams();
  let email;
  let key;

  if (token) {
    const _token = Buffer.from(token, "base64").toString("binary");
    const [_email, _key] = _token.split(":");
    email = _email;
    key = _key;
  }

  const { verifyEmailError, loading, statusCode, resentConfirmation } = useSelector((s) => s.signup);

  useEffect(() => {
    SignupActions.confirmEmail({ key, email });
  }, [key, email]);

  const onClickResendConfirmation = () => {
    SignupActions.resendEmailConfirmation({ email });
  };

  if (loading) {
    return <Loading loading={loading}></Loading>;
  }

  if (resentConfirmation && !loading) {
    return (
      <PublicPageLayout>
        <h2 className="mb-3 text-center">Resent confirmation email</h2>
        <p className="text-center">
          We have resent an email verification link. Please verify your email to get started.
        </p>
      </PublicPageLayout>
    );
  }

  if (verifyEmailError && !loading) {
    return (
      <PublicPageLayout>
        <h2 className="mb-3 text-center">Unable to verify Zing account</h2>
        <Alert type="danger" message={verifyEmailError} />
        {statusCode === 400 && (
          <div className="d-flex justify-content-center mt-5 pt-5">
            <Button onClick={onClickResendConfirmation}>Resend Email Verification Link</Button>
          </div>
        )}
      </PublicPageLayout>
    );
  }

  return <></>;
};

export default EmailVerificationPage;
