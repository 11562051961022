import React, { memo, useState, useCallback, useMemo, useEffect } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import PublicLinkActions from "redux/actions/public-link";
import { useToggle } from "hooks/useToggle";
import Table from "components/table/table";
import Alert from "components/alert";
import ModalDeleteSinglePublicLink from "components/modal/modal-delete-single-public-link";

const PublicLinksTable = ({ orgId, setAlerts }) => {
  const [refresh, setRefresh] = useState(false);
  const { data, items, pages, loading, showDeleteSuccess, error, errorStatus } = useSelector((s) => s.publicLink);
  const [publicLinkData, setPublicLinkData] = useState(data);
  const [selectedPublicLinkToDelete, setSelectedPublicLinkToDelete] = useState();
  const [showDeletePublicLinkModal, toggleDeletePublicLinkModal] = useToggle();

  const fetchData = useCallback(({ pageSize, pageIndex, sort }) => {
    PublicLinkActions.getPublicLinksForOrg(orgId, { limit: pageSize, page: pageIndex + 1, sort });
  }, []);

  useEffect(() => {
    if (!data) {
      fetchData({ pageSize: 20, pageIndex: 0 });
    }
  }, [data]);

  useEffect(() => {
    setPublicLinkData(data);
  }, [data]);

  const refreshData = () => {
    setRefresh(true);
    fetchData({ pageSize: 20, pageIndex: 0 });
  };

  useEffect(() => {
    if (error) {
      setAlerts([<Alert key="danger" type="danger" message={error} />]);
      toggleDeletePublicLinkModal();

      if (errorStatus === 404) {
        refreshData();
      }
    } else if (!loading && showDeleteSuccess) {
      setSelectedPublicLinkToDelete();
      toggleDeletePublicLinkModal();
      refreshData();
      setAlerts([<Alert key="success" type="success" message="Public link deleted successfully!" />]);
      PublicLinkActions.updatePublicLinkDeleteSuccess();
    }
  }, [error, loading, showDeleteSuccess]);

  const handlePublicLinkDeleteClick = async (id) => {
    setSelectedPublicLinkToDelete(id);
    toggleDeletePublicLinkModal();
  };

  const deletePublicLink = (id) => {
    PublicLinkActions.deletePublicLink(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Question",
        id: "question",
        acessor: "question",
        Cell: (row) => {
          return row.row.original.question?.questionText;
        },
      },
      {
        Header: "Link Id",
        accessor: "_id",
        Cell: (row) => {
          return (
            <a
              href={`${process.env.REACT_APP_BASE_URL || "https://console.getzingdata.com"}/chart/${
                row.row.original._id
              }`}
              target="__blank"
              rel="noopener noreferrer"
            >
              {row.row.original._id}
            </a>
          );
        },
      },
      {
        Header: "Date Created",
        accessor: "timeCreated",
        Cell: (row) => {
          return format(new Date(row.row.original.timeCreated), "MMM dd, yyyy");
        },
      },
      {
        Header: "Created By",
        id: "owner",
        Cell: (row) => {
          return row.row.original.createdBy.email;
        },
        canSort: true,
      },
      {
        Header: "",
        id: "actions",
        Cell: (row) => {
          return (
            <div className="d-flex justify-content-center">
              <div>
                <button
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => handlePublicLinkDeleteClick(row.row.original._id)}
                >
                  <i className="tio-delete"></i> Delete
                </button>
              </div>
            </div>
          );
        },
        sortable: false,
        filterable: false,
      },
    ],
    []
  );

  if (!publicLinkData?.length) {
    return <span>No public links generated yet.</span>;
  }

  return (
    <>
      <Table
        columns={columns}
        data={publicLinkData ?? []}
        items={items ?? []}
        loading={loading}
        pageCount={pages?.total ?? 0}
        refresh={refresh}
        cancelRefresh={() => setRefresh(false)}
      />
      {showDeletePublicLinkModal && (
        <ModalDeleteSinglePublicLink
          show={showDeletePublicLinkModal}
          handleClose={toggleDeletePublicLinkModal}
          linkId={selectedPublicLinkToDelete}
          deletePublicLink={deletePublicLink}
        />
      )}
    </>
  );
};

export default memo(PublicLinksTable);
