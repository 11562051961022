import Constants from "redux/constants";
import ParseValidation from "helpers/parse-validation";

const initialState = {
  loading: false,
  loadingApiKeys: false,
  success: false,
  error: undefined,
  hasError: {},
  help: {},
  data: null,
  pages: {},
  items: {},
  apiKeyData: null,
  apiKeyPages: {},
  apiKeyItems: {},
  toggleEmbedIdSuccess: false,
  toggleApiKeySuccess: false,
  showDeleteSuccess: false,
  showCreateSuccess: false,
  creatingApiKey: false,
  errorCreatingApiKey: undefined,
};

const reducer = function (state = initialState, action) {
  if (action.type === Constants.EMBEDS_GET_BY_ORG) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.EMBEDS_GET_BY_ORG_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      success: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      data: action.response.data,
      pages: action.response.pages,
      items: action.response.items,
    };
  }

  if (action.type === Constants.EMBEDS_GET_API_KEYS_BY_ORG) {
    return {
      ...state,
      loadingApiKeys: true,
    };
  }

  if (action.type === Constants.EMBEDS_GET_API_KEYS_BY_ORG_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loadingApiKeys: false,
      success: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
      apiKeyData: action.response.data,
      apiKeyPages: action.response.pages,
      apiKeyItems: action.response.items,
    };
  }

  if (action.type === Constants.EMBEDS_TOGGLE_EMBED_ID) {
    return {
      ...state,
      loading: true,
      toggleEmbedIdSuccess: false,
    };
  }

  if (action.type === Constants.EMBEDS_TOGGLE_EMBED_ID_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      toggleEmbedIdSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.EMBEDS_TOGGLE_API_KEY) {
    return {
      ...state,
      loading: true,
      toggleApiKeySuccess: false,
    };
  }

  if (action.type === Constants.EMBEDS_TOGGLE_API_KEY_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      toggleApiKeySuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.EMBEDS_UPDATE_EMBED_ID_TOGGLE_SUCCESS) {
    return {
      ...state,
      toggleEmbedIdSuccess: false,
    };
  }

  if (action.type === Constants.EMBEDS_UPDATE_API_KEY_TOGGLE_SUCCESS) {
    return {
      ...state,
      toggleApiKeySuccess: false,
    };
  }

  if (action.type === Constants.EMBEDS_DELETE_API_KEY) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === Constants.EMBEDS_DELETE_API_KEY_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      loading: false,
      showDeleteSuccess: !action.err,
      error: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.EMBEDS_UPDATE_DELETE_API_KEY_SUCCESS) {
    return {
      ...state,
      showDeleteSuccess: false,
    };
  }

  if (action.type === Constants.EMBEDS_CREATE_API_KEY) {
    return {
      ...state,
      creatingApiKey: true,
      showCreateSuccess: false,
    };
  }

  if (action.type === Constants.EMBEDS_CREATE_API_KEY_RESPONSE) {
    const validation = ParseValidation(action.response);

    return {
      ...state,
      creatingApiKey: false,
      showCreateSuccess: !action.err,
      errorCreatingApiKey: validation.error,
      hasError: validation.hasError,
      help: validation.help,
    };
  }

  if (action.type === Constants.EMBEDS_UPDATE_CREATE_API_KEY_SUCCESS) {
    return {
      ...state,
      showCreateSuccess: false,
    };
  }

  return state;
};

export default reducer;
