import React, { memo, useState, useCallback, useMemo, useEffect } from "react";
import { format, differenceInSeconds } from "date-fns";
import { useSelector } from "react-redux";
import EmbedActions from "redux/actions/embed";
import { useToggle } from "hooks/useToggle";
import Table from "components/table/table";
import Alert from "components/alert";
import ModalDeleteApiKey from "components/modal/modal-delete-api-key";
import ModalDisableApiKey from "components/modal/modal-disable-api-key";
import CopyToClipboard from "components/copy-to-clipboard";

const THIRTY_MINUTES_IN_SECONDS = 30 * 60;

const ApiKeysTable = ({ orgId, setAlerts, showCreateSuccess }) => {
  const [refresh, setRefresh] = useState(false);
  const {
    apiKeyData,
    apiKeyItems,
    apiKeyPages,
    loadingApiKeys,
    error,
    loading,
    toggleApiKeySuccess,
    showDeleteSuccess,
  } = useSelector((s) => s.embed);
  const [apiKeys, setApiKeys] = useState(apiKeyData);
  const [selectedApiKeyToDelete, setSelectedApiKeyToDelete] = useState();
  const [selectedApiKeyDataToDisable, setSelectedApiKeyDataToDisable] = useState();
  const [showDeleteApiKeyModal, toggleDeleteApiKeyModal] = useToggle();
  const [showDisableApiKeyModal, toggleDisableApiKeyModal] = useToggle();

  const fetchData = useCallback(({ pageSize, pageIndex, sort }) => {
    EmbedActions.getApiKeysForOrg(orgId, { limit: pageSize, page: pageIndex + 1, sort });
  }, []);

  useEffect(() => {
    if (!apiKeyData) {
      fetchData({ pageSize: 20, pageIndex: 0 });
    }
  }, [apiKeyData]);

  useEffect(() => {
    setApiKeys(apiKeyData);
  }, [apiKeyData]);

  const refreshData = () => {
    setRefresh(true);
    fetchData({ pageSize: 20, pageIndex: 0 });
  };

  useEffect(() => {
    if (!error && !loading && toggleApiKeySuccess) {
      setSelectedApiKeyDataToDisable();

      if (showDisableApiKeyModal) {
        toggleDisableApiKeyModal();
      }

      refreshData();
      EmbedActions.updateApiKeyToggleSuccess();
    }
  }, [error, loading, toggleApiKeySuccess]);

  useEffect(() => {
    if (error) {
      setAlerts([<Alert key="danger" type="danger" message={error} />]);
    } else if (!loading && showDeleteSuccess) {
      setSelectedApiKeyToDelete();
      toggleDeleteApiKeyModal();
      refreshData();
      setAlerts([<Alert key="success" type="success" message="API key deleted successfully!" />]);
      EmbedActions.updateApiKeyDeleteSuccess();
    }
  }, [error, loading, showDeleteSuccess]);

  useEffect(() => {
    if (showCreateSuccess) {
      refreshData();
      EmbedActions.updateApiKeyCreateSuccess();
    }
  }, [showCreateSuccess]);

  const toggleApiKey = (data) => {
    const { apiKey, isActive } = data;
    const update = {
      isActive: !isActive,
    };

    EmbedActions.toggleApiKeyStatus(apiKey, update);
  };

  const handleToggleClick = (data) => {
    if (data.isActive) {
      setSelectedApiKeyDataToDisable(data);
      toggleDisableApiKeyModal();
    } else {
      toggleApiKey(data);
    }
  };

  const deleteApiKey = (key) => {
    EmbedActions.deleteApiKey(key);
  };

  const handleApiKeyDeleteClick = async (key) => {
    setSelectedApiKeyToDelete(key);
    toggleDeleteApiKeyModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: "API Key",
        accessor: "_id",
        Cell: (row) => {
          return (
            <div className="d-flex justify-content-between">
              {row.row.original.apiKey}
              <CopyToClipboard textToCopy={row.row.original.apiKey} />
            </div>
          );
        },
      },
      {
        Header: "Embed Secret",
        accessor: "secret",
        Cell: (row) => {
          return (
            <div className="d-flex justify-content-between">
              {row.row.original.embedSecret
                ? differenceInSeconds(new Date(), new Date(row.row.original.timeUpdated)) < THIRTY_MINUTES_IN_SECONDS
                  ? row.row.original.embedSecret
                  : `${row.row.original.embedSecret.slice(0, 6)}....${row.row.original.embedSecret.slice(-6)}`
                : ""}
              <CopyToClipboard textToCopy={row.row.original.embedSecret} />
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          return row.row.original.isActive ? "Active" : "Inactive";
        },
      },
      {
        Header: "Date Created",
        accessor: "timeCreated",
        Cell: (row) => {
          return format(new Date(row.row.original.timeCreated), "MMM dd, yyyy");
        },
      },
      {
        Header: "Created By",
        id: "owner",
        Cell: (row) => {
          return row.row.original.createdBy.roles.account.name;
        },
        canSort: true,
      },
      {
        Header: "",
        id: "actions",
        Cell: (row) => {
          return (
            <div className="d-flex justify-content-end">
              <div>
                {row.row.original.isActive ? (
                  <button
                    className="btn btn-sm btn-outline-danger mr-1"
                    onClick={() => handleToggleClick(row.row.original)}
                  >
                    <i className="tio-link-off"></i> Disable
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-outline-success mr-1"
                    onClick={() => handleToggleClick(row.row.original)}
                  >
                    <i className="tio-link"></i> Enable
                  </button>
                )}
                <button
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => handleApiKeyDeleteClick(row.row.original.apiKey)}
                >
                  <i className="tio-delete"></i> Delete
                </button>
              </div>
            </div>
          );
        },
        sortable: false,
        filterable: false,
      },
    ],
    []
  );

  if (!apiKeys?.length) {
    return <span>No API keys generated yet.</span>;
  }

  return (
    <>
      <Table
        columns={columns}
        data={apiKeys ?? []}
        items={apiKeyItems ?? []}
        loading={loadingApiKeys}
        pageCount={apiKeyPages?.total ?? 0}
        refresh={refresh}
        cancelRefresh={() => setRefresh(false)}
      />
      {showDeleteApiKeyModal && (
        <ModalDeleteApiKey
          show={showDeleteApiKeyModal}
          handleClose={toggleDeleteApiKeyModal}
          apiKey={selectedApiKeyToDelete}
          deleteApiKey={deleteApiKey}
        />
      )}
      {showDisableApiKeyModal && (
        <ModalDisableApiKey
          show={showDisableApiKeyModal}
          handleClose={toggleDisableApiKeyModal}
          apiKeyData={selectedApiKeyDataToDisable}
          disableApiKey={toggleApiKey}
        />
      )}
    </>
  );
};

export default memo(ApiKeysTable);
