import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { fewshotTypes } from "helpers/constants";

const FilterNlqExample = ({
  categories,
  onClickEditList,
  selectedFilterType,
  selectedFilterCategories,
  setSelectedFilterType,
  setSelectedFilterCategories,
  toggleDropdown,
  onClickApplyFilters,
}) => {
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    if (Array.isArray(categories)) {
      const options = categories?.map((c) => ({ label: c.name, value: c.name, id: c._id }));
      setCategoryOptions(options);
    }
  }, [categories]);

  const handleFilterTypeChange = (e) => {
    setSelectedFilterType(e?.value || null);
  };

  const handleFilterCategoryChange = (categories) => {
    setSelectedFilterCategories(categories);
  };

  const onClickClearFilters = () => {
    setSelectedFilterType(null);
    setSelectedFilterCategories([]);
  };

  return (
    <div className="card">
      <div className="card-header card-header-content-between py-2">
        <h5 className="card-header-title">Filter Examples</h5>

        <button
          type="button"
          className="btn btn-ghost-secondary btn-icon justify-content-end btn-sm ms-2"
          onClick={toggleDropdown}
          style={{ backgroundColor: "transparent", boxShadow: "none", border: "none" }}
        >
          <i className="tio-clear"></i>
        </button>
      </div>
      <div className="card-body">
        <Form>
          <Form.Group className="d-flex flex-column w-100 mb-3">
            <Form.Label className="fw-semiboldlight">
              <span className="d-flex justify-content-between">
                <span>Categories</span>
                <button type="button" className="btn btn-link p-0" onClick={onClickEditList}>
                  Edit List
                </button>
              </span>
            </Form.Label>
            <Select
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "rgba(140, 152, 164, 0.25)" : "rgba(231, 234, 243, 0.7)",
                  boxShadow: state.isFocused ? "0 0 1rem 0 rgba(140, 152, 164, 0.25)" : "",
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: "#E6F2FD",
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: "#092D53",
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  ":hover": {
                    color: "black",
                    cursor: "pointer",
                  },
                }),
              }}
              isMulti
              isSearchable
              isClearable
              className="w-100"
              classNamePrefix="multi-select"
              options={categoryOptions}
              value={selectedFilterCategories}
              onChange={handleFilterCategoryChange}
              isDisabled={!categories?.length}
              placeholder={categories?.length ? "Select category..." : "No categories"}
            />
          </Form.Group>
          <Form.Group className="d-flex flex-column w-100 mb-3">
            <Form.Label className="fw-semiboldlight">Type</Form.Label>
            <Select
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "rgba(140, 152, 164, 0.25)" : "rgba(231, 234, 243, 0.7)",
                  boxShadow: state.isFocused ? "0 0 1rem 0 rgba(140, 152, 164, 0.25)" : "",
                }),
              }}
              isClearable
              className="w-100"
              classNamePrefix="select"
              options={fewshotTypes}
              value={fewshotTypes.find((c) => c.value === selectedFilterType) || null}
              onChange={handleFilterTypeChange}
              placeholder="Select type..."
            />
          </Form.Group>
        </Form>
      </div>
      <div className="card-footer d-flex">
        <Button variant="outline-secondary" className="min-w-48 mr-2" onClick={onClickClearFilters}>
          Clear All Filters
        </Button>
        <Button type="submit" variant="primary" className="min-w-48" onClick={onClickApplyFilters}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterNlqExample;
