import { jsonFetch } from "helpers/json-fetch";
import amplitude from "amplitude-js";
import { omit } from "lodash";

const removeSensitiveData = (data) => {
  return omit(data, [
    "username",
    "password",
    "token",
    "sshtunnelusername",
    "sshtunnelpassword",
    "sshprivatekey",
    "serviceAccount",
  ]);
};

class Actions {
  static get(url, query, store, typeReq, typeRes, callback) {
    const request = { method: "GET", url, query };
    this.makeRequest(request, store, typeReq, typeRes, callback);
  }

  static put(url, data, store, typeReq, typeRes, callback, isFile = false) {
    const request = { method: "PUT", url, data, isFile };
    this.makeRequest(request, store, typeReq, typeRes, callback);
  }

  static post(url, data, store, typeReq, typeRes, callback, isFile = false) {
    const request = { method: "POST", url, data, isFile };
    this.makeRequest(request, store, typeReq, typeRes, callback);
  }

  static delete(url, query, store, typeReq, typeRes, callback) {
    const request = { method: "DELETE", url, query };
    this.makeRequest(request, store, typeReq, typeRes, callback);
  }

  // used when sending a payload of data to be deleted
  static deleteData(url, data, store, typeReq, typeRes, callback) {
    const request = { method: "DELETE", url, data };
    this.makeRequest(request, store, typeReq, typeRes, callback);
  }

  static makeRequest(request, store, typeReq, typeRes, callback) {
    const eventData = (request.method === "GET" ? request.query : request.data) ?? null;

    // delete sensitive data when logging into Amplitude
    let dataForAmplitude = { ...eventData };
    if (dataForAmplitude.datasource) {
      dataForAmplitude.datasource = removeSensitiveData(dataForAmplitude.datasource);
    } else {
      dataForAmplitude = removeSensitiveData(dataForAmplitude);
    }

    amplitude.getInstance().logEvent(typeReq, dataForAmplitude);

    store.dispatch({
      type: typeReq,
      request,
    });

    jsonFetch(request, (err, response) => {
      store.dispatch({
        type: typeRes,
        err,
        response,
      });

      if (callback) {
        callback(err, response);
      }
    });
  }

  static getPoll(url, query, store, typeReq, typeRes, validate, maxAttempts) {
    const request = { method: "GET", url, query };
    this.poll(request, store, typeReq, typeRes, validate, 4000, maxAttempts);
  }

  static async poll(request, store, typeReq, typeRes, validate, interval = 4000, maxAttempts = 15) {
    let attempts = 0;

    store.dispatch({
      type: typeReq,
      request,
    });

    const executePoll = async (resolve, reject) => {
      // make request
      const result = await new Promise((res, rej) => {
        jsonFetch(request, (err, response) => {
          if (err) rej(err);

          res(response);
        });
      });

      attempts++;

      const retResult = validate(result);
      if (retResult?.state === "completed") {
        return resolve(result);
      } else if (retResult?.state === "failed") {
        let message;
        if (result?.error?.originalMessage) {
          message = result.error.originalMessage;
        } else if (result?.reason) {
          message = result.reason;
        } else {
          message = "The connection timed out";
        }
        return reject(new Error(message));
      } else if (maxAttempts && attempts === maxAttempts) {
        return reject(new Error("The connection timed out"));
      } else {
        setTimeout(executePoll, interval, resolve, reject);
      }
    };

    try {
      const result = await new Promise(executePoll);
      store.dispatch({
        type: typeRes,
        err: null,
        response: result,
      });
    } catch (e) {
      store.dispatch({
        type: typeRes,
        err: e,
        response: null,
      });
    }
  }
}

export default Actions;
